import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { environment } from 'src/environments/environment';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';

import { Storage } from '@ionic/storage';
import Swal from 'sweetalert2';
import { isNumeric } from 'rxjs/util/isNumeric';
import { isNull } from 'util';

import { Cell, Columns, Img, Item, Line, PageReference, PdfMakeWrapper, Stack, Table, Toc, Txt, Ul } from 'pdfmake-wrapper';
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';

PdfMakeWrapper.setFonts(pdfFonts);
const GET_ALL_ENTRY_REQUEST_BY_COMPANY_ID = environment.Global.API_GET_ALL_REQUEST_BY_COMPANY_ID;
const GET_ALL_ENTRY_REQUEST = environment.Global.API_GET_ALL_REQUEST;
const GET_ALL_REQUEST_INDUCTION = environment.Global.API_GET_ALL_REQUEST_INDUCTION;
const GET_ALL_REQUEST_INDUCTION_BY_ID = environment.Global.API_GET_ALL_REQUEST_INDUCTION_BY_ID;
const GET_WORK_AUTH_BY_ID = environment.Global.API_GET_WORK_AUTH_BY_ID;
const GET_ALL_WORK_AUTHS_FOR_ADMIN = environment.Global.API_GET_ALL_WORK_AUTHS_FOR_ADMIN;
const GET_DOCUMENTS_BY_ID = environment.Global.API_GET_DOCUMENTS_BY_ID;
const GET_ALL_COMPANIES = environment.Global.API_GET_ALL_COMPANIES;
const GET_DOCUMENTS_ALL_PARAM = environment.Global.API_GET_DOCUMENTS_ALL;
const GET_DOCUMENTS_ALL_BY_COMPANYID = environment.Global.API_GET_DOCUMENTS_ALL_BY_COMPANYID;

@Component({
  selector: 'app-reportforms',
  templateUrl: './reportforms.component.html',
  styleUrls: ['./reportforms.component.css']
})

export class ReportformsComponent extends BaseCrudComponent implements OnInit {
  option_processes = null;
  date_init = null;
  date_end = null;
  list_processes = [{title:'docs_workers_Ext', id: 1}]
  translations;
  isFind = false;
  options_ruc = null;
  options_company = null;
  disabledCompany = false;
  disabledRuc = false;
  companies_list = [];
  ruc_list = [];
  request_selected = true;
  isSkretting = false;
  isClean = false;
  pageSize = 10;
  userInfo;
  fecha_Actual = new Date();
  fecha_sin_formato = this.formatOnlyDate(this.fecha_Actual);
  l = [];

  private workbook: Workbook;
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService) {
      super(srv, modalService, storage, router);
      this.search_fields = ['Name', 'NumberIdentification','EmployeeName'];
  }

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
          this.translations = translations;
    });
    this.storage.get('userinfo').then(x => {
      this.userInfo = x;
      // console.log(this.userInfo)
      if (isNumeric(this.userInfo.logon)) {
        this.isSkretting = false;
      } else {
        this.isSkretting = true;
      }
    });
    this.getCompanies();
  }


  dataToFind() {
    this.isFind = true;

    if (this.option_processes === null) {
      this.isFind = false;
      return Swal.fire(this.translations.msg_all_required, '', 'error');
    }

    if (this.date_end < this.date_init) {
      this.isFind = false;
      return Swal.fire(this.translations.msg_error_date, this.translations.msg_error_date_end, 'error');
    }
    if (this.date_init!== null && this.date_end === null) {
      this.isFind = false;
      return Swal.fire(this.translations.msg_should_date_end_select,'','error');
    }
    if (this.date_end!== null && this.date_init === null) {
      this.isFind = false;
      return Swal.fire(this.translations.msg_should_date_init_select,'','error');
    }
    this.setUrlToFindData();
  }

  setUrlToFindData() {
    let url = '';
    switch (Number(this.option_processes)) {
      case 1:
        // console.log('entro aqui 1')
        if (isNumeric(this.userInfo.logon)) {
          this.isSkretting = false;
          url = GET_DOCUMENTS_ALL_BY_COMPANYID + this.userInfo.selectedCompanyId;
        } else {
          this.isSkretting = true;
          url = GET_DOCUMENTS_ALL_PARAM;
        }
        break;
    }
    // console.log(url);
    this.isFind = false;
    this.getListFromApi(url);
  }

  getListFromApi(url) {
    this.isFind = true;
    let list_tmp = [];
    // console.log(url);
    this.srv.getCall(url).subscribe(x => {
      if(x.success){
        if(x.val != null){
          // console.log(x.val)
          this.rawdata = x.val;
          let new_list = [];
          list_tmp = x.val;
          if ((!isNull(this.date_init) && !isNull(this.date_end)) && (this.options_ruc == -1 || this.options_ruc == null) && (this.options_company == -1 || this.options_company == null) && this.option_processes !== null) {
            // console.log('Primer filtro')
            if (this.option_processes == 1) {
              list_tmp.forEach(tmp => {
                tmp.DateDocument = this.formatOnlyDate (new Date(tmp.DateDocument));
                if (tmp.DateDocument >= this.date_init && tmp.DateDocument <= this.date_end) {
                  new_list.push(tmp);
                }
              })
            }
            this.rawdata = new_list;
            this.rows = new_list;
          } else if ((this.options_ruc !== -1 || this.options_company !== -1 ) && (!isNull(this.date_init) && !isNull(this.date_end)) ) {
            // console.log('Segundo filtro')
            if (this.option_processes == 1) {
              list_tmp.forEach(tmp => {
                tmp.DateDocument = this.formatOnlyDate (new Date(tmp.DateDocument));
                if (tmp.DateDocument >= this.date_init && tmp.DateDocument <= this.date_end) {
                  if (tmp.CompanyID == this.options_ruc || tmp.CompanyID ==  this.options_company) {
                    new_list.push(tmp);
                  }
                }
              })
            }
            this.rawdata = new_list;
            this.rows = new_list;
          } else if ((this.options_ruc !== -1 || this.options_company !== -1 ) && (isNull(this.date_init) && isNull(this.date_end)) ) {
            // console.log('Tercer filtro')
            if (this.isSkretting) {
              if (this.option_processes == 1 && (this.options_ruc !== null || this.options_company !== null )) {
                // console.log('entro aqui if')
                list_tmp.forEach(tmp => {
                  // console.log(tmp.CompanyID)
                  if (tmp.CompanyID == this.options_ruc || tmp.CompanyID ==  this.options_company) {
                    new_list.push(tmp);
                  }
                })
              }
              // console.log(list_tmp)
              if (new_list.length === 0) {
                this.rawdata = list_tmp;
                this.rows = list_tmp;
              } else {
                this.rawdata = new_list;
                this.rows = new_list;
              }
            } else {
              this.rawdata = list_tmp;
              this.rows = list_tmp;
            }
          }
          // console.log(this.rows)
          this.page = 1;
          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          this.isFind = false;
          if (this.rows.length === 0) {
            Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          }
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.isFind = false;
          return;
        }
      }else {
        this.isFind = false;
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  async downloadReport() {
    // console.log(this.rows)

    Swal.fire(
      {
        title: this.translations['download_report'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    let listInReport = this.rows;
    let mapaTitles = new Map();
    let lista_titulos = [];
    let lista_widths = [];
    let nueva_lista_reportes = [];
    // console.log(...this.rows);
    // console.log(listInReport)
    listInReport.forEach(element => {
      // console.log(element)
      let obj = [];
      if (this.isSkretting) {
        obj= [
          element.EmployeeName,
          element.NumberIdentification,
          element.Name,
          element.Ruc,
          element.SubCompanyName,
          element.NameDoc,
          element.DateEnd == null ? "":element.DateEnd.split('T')[0],
          element.status_name,
        ]
        // console.log(obj)
        lista_widths = [
          'auto','auto','auto','auto','auto','auto','auto','auto'
        ]
      } else {
        obj= [
          element.EmployeeName,
          element.NumberIdentification,
          element.SubCompanyName,
          element.NameDoc,
          element.DateEnd == null ? "":element.DateEnd.split('T')[0],
          element.status_name,
        ]
        lista_widths = [
          'auto','auto','auto','auto','auto','*'
        ]
      }
      nueva_lista_reportes.push(obj);

      if (!mapaTitles.has(element.NameDoc)){
        mapaTitles.set(element.NameDoc, element.NameDoc);
      }

    });

    if ( this.isSkretting) {
      lista_titulos = [
        new Cell( new Txt(this.translations.name_last_name).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.ci).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.menu_contract).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.ruc).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.menu_subcontract_emp).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.doc_worker).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.doc_vige).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.status).margin([0,5,0,5]).color('white').bold().end).end
      ]
    } else {
      lista_titulos = [
        new Cell( new Txt(this.translations.name_last_name).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.ci).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.menu_subcontract_emp).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.doc_worker).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.doc_vige).margin([0,5,0,5]).color('white').bold().end).end,
        new Cell( new Txt(this.translations.status).margin([0,5,0,5]).color('white').bold().end).end
      ]
    }
    // console.log(lista_titulos);
    // console.log(nueva_lista_reportes);
    const pdf = new PdfMakeWrapper();
    // INFORMATION IN TITLE NAV
    pdf.info({
      title: `${this.translations.msg_doc_Ext}`,
      author: `${this.userInfo.name}`,
      subject: 'Listado',
    });
    //OPTIONS
    pdf.defaultStyle({
      bold: false,
      fontSize: 10,
      alignment:'justify'
    });
    pdf.pageMargins([ 40, 110, 40, 110]);
    // pdf.header(await
    //   new Img('../../../../assets/images/logo-icon1.png')
    //   .width('100')
    //   //.alignment("right")
    //   .margin(20)
    //   .build()
    // )
    pdf.header(
      new Table(
        [
          [
            new Cell(await new Img('../../../../assets/images/Skretting-logo.png').width('160').build()).end,
          ]
        ]
      )
        .margin([0,30,30,0])
        .alignment('right')
        .layout('noBorders')
        .widths('*')
        .end,
    );

    pdf.background(
      await new Img('../../../../assets/images/Skretting-footer.png').absolutePosition(0, 515).width(900).build() //505
    )
    pdf.pageOrientation('landscape');
    pdf.add(
      [
        new Txt(this.translations.msg_doc_Ext).fontSize(14).bold().margin([0,0,0,20]).alignment('center').end,
        new Txt([new Txt(this.translations.date + ': ').bold().end, this.fecha_sin_formato]).fontSize(10).alignment('left').end,
        new Txt([new Txt(this.translations.requested_by + ': ').bold().end,this.userInfo.name]).fontSize(10).alignment('left').end,
        new Txt([new Txt(this.translations.user + ': ').bold().end, this.userInfo.logon]).fontSize(10).alignment('left').margin([0,0,0,20]).end,
        new Table(
          [
            lista_titulos,
            ...nueva_lista_reportes
          ]
        ).layout({
          fillColor: (rowIndex: number, node:any, columnIndex: number) => {
            return rowIndex === 0 ? '#f62d51' : '';
          },
        })
        .headerRows(1)
        .dontBreakRows(true)
        .fontSize(8)
        .widths(lista_widths)
        .alignment('center')
        .end
      ]
    );
    // pdf.footer( function(currentPage, pageCount) {
    //   return new Txt(' Página ' + currentPage.toString() + ' de ' + pageCount).alignment('right').margin([0, 20, 50, 0]).end
    // });
    pdf.footer( function(currentPage, pageCount) {
      return new Stack(
        [
          new Cell(new Txt('Skretting Ecuador | Km 4.5 & 6.5 vía Durán - Tambo. Durán - Ecuador | Telf: + 593 4 2598100 + 593 4 2815737').fontSize(8).color('gray').alignment('center').margin([0,10,0,0]).end).end,
          new Cell(new Txt('www.skretting.ec').link('https://www.skretting.com/es-ec/').fontSize(8).color('gray').alignment('center').end).end,
          new Cell(new Txt(' Página ' + currentPage.toString() + ' de ' + pageCount).bold().alignment('right').fontSize(9).color('white').margin([0, 40,20, 0]).end).end
        ]
      ).end
    });


    let title = '';
    if (this.option_processes == 1) {
      title = 'Reporte de Documentos de trabajadores externos'
    }
    pdf.create().download(title);
    Swal.close();
  }


  clearFilters() {
    this.option_processes = null;
    this.options_company = null;
    this.options_ruc = null;
    this.date_end = null;
    this.date_init = null;
    this.rows = [];
    this.rawdata = [];
    this.isFind = false;
    this.page = 0;
    this.pageTotal = 0;
  }

  rucSelected() {
    if (this.options_ruc == -1) {
      this.disabledCompany = false;
    } else {
      this.disabledCompany = true;
    }
  }

  companySelected() {
    if (this.options_company == -1 ) {
      this.disabledRuc = false;
    } else {
      this.disabledRuc= true;
    }
  }

  selectRequest() {
    this.request_selected = true;
    this.rows = [];
    this.page = 0;
    this.disabledCompany = false;
    this.disabledRuc = false;
  }

  getCompanies() {
    let url = GET_ALL_COMPANIES;
    this.srv.getCall(url).subscribe(data => {
      if(data.success) {
        if(data.val !== null){
          let rs = data.val;
          rs.forEach(element => {
            this.companies_list.push({id:element.CompanyID ,name: element.Name});
            this.ruc_list.push({id: element.CompanyID, ruc: element.Ruc});
          })
        }
      }
    })


  }

  formatOnlyDate(date: Date) {
    return date.getFullYear().toString() + '-' + (date.getMonth() <= 9 ? `0${(date.getMonth() + 1).toString()}`: (date.getMonth() + 1).toString()) + '-' + (date.getDate() <= 9 ? `0${date.getDate().toString()}`: date.getDate().toString());
  }

  formatDate(date: Date) {
    return date.getFullYear().toString() + '-' + (date.getMonth() <= 9 ? `0${(date.getMonth() + 1).toString()}`: (date.getMonth() + 1).toString())
    + '-' + (date.getDate() <= 9 ? `0${date.getDate().toString()}`: date.getDate().toString()) + ' ' +
    (date.getHours() <= 9 ? `0${date.getHours().toString()}`: date.getHours().toString()) + ':' +
    (date.getMinutes() <= 9 ? `0${date.getMinutes().toString()}`: date.getMinutes().toString()) + ':' +
    (date.getSeconds() <= 9 ? `0${date.getSeconds().toString()}`: date.getSeconds().toString());
  }

  async downloadExcel() {
    let lista_titulos = [];
    let listInReport = this.rows;
    let nueva_lista_reportes = [];
    Swal.fire(
      {
        title: this.translations['download_excel'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();

    this.workbook = new Workbook();
    let nombreXLSX = "";

    if (this.isSkretting) {
        nombreXLSX = "Reporte de Solicitud de Inducciòn.xlsx";
        lista_titulos = [
          this.translations.name_last_name, this.translations.ci,
          this.translations.menu_contract,
          this.translations.ruc, this.translations.menu_subcontract_emp,
          this.translations.doc_worker,
          this.translations.doc_vige, this.translations.status
        ];
    }else {
      nombreXLSX = "Reporte de Solicitud de Inducciòn.xlsx";
      lista_titulos = [
        this.translations.name_last_name, this.translations.ci,
        this.translations.menu_subcontract_emp,
        this.translations.doc_worker,
        this.translations.doc_vige, this.translations.status
      ];
    }

    const sheet = this.workbook.addWorksheet('Sheet');

    sheet.getColumn("A").width = 33;
    sheet.getColumn("B").width = 33;
    sheet.getColumn("C").width = 33;
    sheet.getColumn("D").width = 33;
    sheet.getColumn("E").width = 33;
    sheet.getColumn("F").width = 33;
    sheet.getColumn("G").width = 33;
    sheet.getColumn("H").width = 33;
    sheet.getColumn("I").width = 33;
    sheet.getColumn("J").width = 33;
    sheet.getColumn("K").width = 33;
    sheet.getColumn("L").width = 33;
    sheet.getColumn("M").width = 33;
    sheet.getColumn("N").width = 33;
    sheet.getColumn("O").width = 33;
    sheet.getColumn("P").width = 33;
    sheet.getColumn("Q").width = 33;
    sheet.getColumn("R").width = 33;
    sheet.getColumn("S").width = 33;
    sheet.getColumn("T").width = 33;
    sheet.getColumn("U").width = 33;
    sheet.getColumn("V").width = 33;
    sheet.getColumn("W").width = 33;
    sheet.getColumn("X").width = 33;
    sheet.getColumn("Y").width = 33;
    sheet.getColumn("Z").width = 33;

    // ALINEAR COLUMNAS
    sheet.columns.forEach((column) => {
      column.alignment = { vertical: 'middle', wrapText: true }
    });

    // establecer los valores de las columnas y del header

    //establecer los header
    const headerRow = sheet.getRow(1);
    console.log("Lista de titulos:", lista_titulos);
    headerRow.values = lista_titulos;
    // headerRow.values = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
    headerRow.font = { bold: true, size: 12 };


    listInReport.forEach(element => {
      // console.log(element)
      let obj = [];
      if (this.isSkretting) {
        obj= [
          element.EmployeeName,
          element.NumberIdentification,
          element.Name,
          element.Ruc,
          element.SubCompanyName,
          element.NameDoc,
          element.DateEnd == null ? "":element.DateEnd.split('T')[0],
          element.status_name,
        ]

      } else {
        obj= [
          element.EmployeeName,
          element.NumberIdentification,
          element.SubCompanyName,
          element.NameDoc,
          element.DateEnd == null ? "":element.DateEnd.split('T')[0],
          element.status_name,
        ]

      }
      sheet.addRow(obj);

    });

    this.workbook.creator = "Skomex";
    this.workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data]);
      // fs.saveAs(blob, "valor.xlsx");
      // console.log("Nombre:", nombreXLSX);
      fs.saveAs(blob, nombreXLSX);
    })
    Swal.close();
  }


}
