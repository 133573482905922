import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

const ALLCOMPANIES = environment.Global.API_ALLCOMPANIESWITHDOCUMENT;

@Component({
  selector: 'app-proapprovedocuments',
  templateUrl: './proapprovedocuments.component.html',
  styleUrls: ['./proapprovedocuments.component.css']
})
export class ProapprovedocumentsComponent extends BaseCrudComponent {
  userinfo:any;

  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService) {
      super(srv, modalService, storage, router);

      this.read_url = ALLCOMPANIES;
      this.search_fields = ['CompanyID','Ruc','Name'];

      this.c_obj['Company'] = [];

      this.reload.subscribe(val => {
          if( val ){
              super.clearData();
              this.getList();
          }
      });
    }

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
          this.translations = translations;
          super.ngOnInit();
          //this.getList();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    this.storage.get('userinfo').then(x => {
        this.userinfo = x;
        this.getList();
    });
  }

  public getList()
  {
    var qst = this.read_url;
    this.loadingList = true;
    // Swal.fire(
    //   {
    //     title: this.translations['loading'],
    //     html: '',
    //     icon: 'info',
    //   }
    // );
    // Swal.showLoading();
    this.srv.getCall(qst).subscribe(x => {
        this.loadingList = false;
        
        if(x.success){
          if(x.val != null){
            this.rawdata = x.val;
            var rs = x.val;
            this.rows = rs;
            // console.log("rs:",rs);

            this.page = 1;
            if( Array.isArray(this.rows) ){
               this.pageTotal = this.rows.length;
            }
            this.filterAll();
            Swal.close()
          }else {
            Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
            this.rows = [];
            return;
              }
        }else {
          Swal.fire(x.msg, '', 'error');
          return;
            }
       });
  }
}
