import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../services/authentication/login.service';

@Component({
  selector: 'app-prochome',
  templateUrl: './prochome.component.html',
  styleUrls: ['./prochome.component.css']
})
export class ProchomeComponent implements OnInit {

  	translations:any;
    available_languages:any;
    currlang:any;
    version:any;
    items:any;
    permissions = [];

  	constructor(
  		public _router: Router,
      public translate: TranslateService,
      public auth: LoginService
  	) { }

  	ngOnInit() {
        this.items = [
          {title: 'menu_docapproval', url: '/proapprovedocuments', icon: 'fa fa-check', perm: 'SI_PROAPPROVEDOCUMENTS'},
          {title: 'menu_docaddrating', url: '/proaddrating', icon: 'fa fa-check', perm: 'SI_PROADDRATING'},
          {title: 'menu_indicators', url: '/proindicator', icon: 'fa fa-users', perm: 'SI_INDICATOR'},
          {title: 'menu_soinduccion', url: '/prorequest', icon: 'mdi mdi-presentation-play', perm:'SI_INDUCCION'},// 'SI_REGSOLINDUCCION'},
          {title: 'menu_soingreso', url: '/proadmissionapp', icon: 'mdi mdi-adjust', perm: 'SI_REGSOLINGRESO'}, // ''SI_REGSUBCONTRATISTA
          {title: 'menu_soingreso_emergencia', url: '/proadmissemergapp', icon: 'mdi mdi-adjust', perm: 'SI_REGSOLINGRESOEMERG'},
          {title: 'menu_permiso', url: '/repocreator', icon: 'mdi mdi-adjust', perm: 'SI_REGSOLPERMISO'},
          // {title: 'menu_riesgo', url: '/reportingapproval', icon: 'mdi mdi-file-check', perm: 'SI_PERMISO'},
          {title: 'menu_reporting', url: '/reporting', icon: 'fas fa-chart-bar', perm: 'SI_PROREPORTING'},
          {title: 'menu_reporting_personal', url: '/reporting-personal', icon: 'fas fa-chart-bar', perm: 'SI_PROREPORTING_PERSONAL'},
          {title: 'menu_list_of_tools_and_equipment', url: '/regtoolsequipment', icon: 'fas fa-history', perm: 'SI_LISTTOOLSEQUIPMENT'},
          {title: 'menu_change_worker', url: '/changeworker', icon: 'mdi mdi-adjust', perm: 'SI_REGSOLCAMBTRAB'},
          // {title: 'menu_assignment_evaluation_internal', url: '/evaluationinternal', icon: 'mdi mdi-adjust', perm: 'SI_INTERNALEVALUATION'},
          // {title: 'menu_work_permit', url: '/propasswork', icon: 'mdi mdi-adjust', perm:"SI_LISTTOOLSEQUIPMENT"},
        ];

        this.auth.getPermissions().then(x => {
            this.permissions = x;
        });
  	}

    hasPermission(str:string){
      //console.log(str)
        if( this.permissions.indexOf(str) > -1 ){
            return true;
        }
        else{
            return false;
        }
    }

}
