import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { isNumeric } from 'rxjs/util/isNumeric';
import { Newsubcomjob } from '../../../interfaces/newsubcomjob';
import { saveAs as importedSaveAs } from "file-saver";
import { NumberFormatStyle } from '@angular/common';
//import { Console } from 'console';
const existeEval = environment.Global.API_GET_EXIST_EVAL_FOR_WORK_REQUEST;

@Component({
  selector: 'app-prorequest',
  templateUrl: './prorequest.component.html',
  styleUrls: ['./prorequest.component.css']
})
export class ProrequestComponent extends BaseCrudComponent {
  userinfo: any;
  row_emp: any;
  row_plantas: any;
  row_typejob: any;
  curr_parameter: any;
  loadingRequired = false;
  habilitar = false;
  evaluacion: any;
  ID: any;
  video;
  public u_obj1: any;
  pageTotal2 = 0;
  page2 = 0;
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService) {
    super(srv, modalService, storage, router);

    // this.read_url = "Employee/GetEmployeesByCompany";
    this.search_fields = ['RequestId', 'Ruc', 'namecompany', 'WhoRequest', 'status_eval'];
    //this.ID=0;

    this.c_obj['Request'] = [];
    this.curr_parameter = 0;

    this.reload.subscribe(val => {
      if (val) {
        super.clearData();
        this.getList();
      }
    });
  }


  async ngOnInit() {
    await this.translate.getTranslation(this.translate.currentLang)
      .toPromise().then((translations) => {
        this.translations = translations;
        super.ngOnInit();
        //this.getList();
      });
    this.pageSize = 10;
    this.pageSize2 = 5;
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    await this.storage.get('userinfo').then(x => {
      this.userinfo = x;
      this.getList();
    });
  }


  getFileDetails(e) {
    console.log(e.target.files);
    this.video = e.target.files[0]
  }

  uploadFiles() {
    let url = 'Evaluation/UploadFile';
    // this.storage.post()
  }







  public getList() {
    //debugger;
    var qst = "";//this.read_url;
    this.loadingList = true;

    if (isNumeric(this.userinfo.logon)) {
      qst = "Request/AllByCompany/" + this.userinfo.selectedCompanyId;
      this.habilitar = true;
    }
    else {
      qst = "Request/All";
      this.habilitar = false;
    }


    this.GetPlantas();
    // Swal.fire(
    //   {
    //     title: this.translations['loading'],
    //     html: '',
    //     icon: 'info',
    //   }
    // );
    // Swal.showLoading();
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;
      if (x.success) {
        if (x.val != null) {debugger;
          console.log('kkk',x.val)
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          console.log("rs:", rs);

          this.page = 1;
          if (Array.isArray(this.rows)) {
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          Swal.close()
        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }


  public GetTrabajadores() {
    var qst = this.read_url;
    //this.loadingList = true;

    if (isNumeric(this.userinfo.logon)) {
      this.read_url = "Employee/GetEmployeesByCompany/" + this.userinfo.selectedCompanyId;
    }
    else {
      this.read_url = "Employee/GetEmployeesAllCompany";
    }
    var qst = this.read_url;
    //console.log("usuario logoneado:",this.userinfo)
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;
      if (x.success) {
        if (x.val != null) {
          //this.rawdata = x.val;
          var rs = x.val;
          this.row_emp = rs;
          //console.log("rs:",rs);

          this.page2 = 1;
          if (Array.isArray(this.row_emp)) {
            this.pageTotal2 = this.row_emp.length;
          }
        } else {
          //Swal.fire('¡Vacio!', 'La consulta no devolvio registros', 'success');
          this.row_emp = [];
          return;
        }
      } else {
        //Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public updateItem(content: any, form: any) {
    var validated = true;
    this.isCreating = true;
    var qst = "Request/Update";//this.update_url;

    if (form == undefined) {
      form = {};
    }

    validated = this.validateCreateInfo(form);
    if (!validated) {
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }
    // if (form.PlantaID=="" || form.PlantaID==null || form.PlantaID==undefined)
    // {
    //     Swal.fire(this.translations['msg_all_required'], '', 'error');
    //     return;
    // }
    var to: any;
    to = {};

    form.CompanyID = this.userinfo.selectedCompanyId;
    //form.CreatorUser = this.userinfo['userId'];//this.userinfo['logon'];
    // form.Status ="A";
    form.LastModifierUserID = this.userinfo['userId'];
    to = form;
    //to.durationtime=form.durationtime;


    this.srv.postCall(qst, to).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if (x.success) {
        this.reload.next(true);
        this.closeModal('update1');
        Swal.fire(this.translations['MENSAJE'], x.msg, 'success');
        this.getList();

      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });

  }

  public async AssigneItem(content: any, form: any) {
    //debugger;
    var validated = true;
    this.isCreating = true;
    var qst = "Request/Assigne";//this.update_url;
    var to: any;
    to = {};

    if (content == "" || content == undefined) {
      to.RequestId = form
      to.StatusEvalId = 42;
      to.AssignedUser = "Automatic";
      to.CompanyID = this.userinfo.selectedCompanyId;
    } else {
      if (form == undefined) {
        form = {};
      }

      //form.CompanyID=CompanyID
      //form.CreatorUser = this.userinfo['userId'];//this.userinfo['logon'];
      form.StatusEvalId = 42;
      form.AssignedUser = this.userinfo['logon'];
      to = form;
    }

    // console.log("to:",to);

    await this.srv.postCall(qst, to).toPromise().then(async x => {
      this.isCreating = false;
      Swal.close();
      if (x.success) {
        await this.SendMail(to);
        // console.log(x.val)
        this.closeModal(content);
        //debugger;
        //Swal.fire(this.translations.msg_assigne, x.msg, 'success');
        //Swal.fire(this.translations.msg_assigne, '', 'success');
        this.getList();

      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });

  }

  public async SendMail(data: any) {
    var validated = true;
    this.isCreating = true;
    var qst = "Request/SenMail";//this.update_url;
    //debugger;

    var to: any;
    to = {};
    to = data;

    await this.srv.postCall(qst, to).toPromise().then(x => {
      this.isCreating = false;
      Swal.close();
      if (x.success) {

      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });

  }


  openUpdateModal(content: any, obj: any) {
    this.GetTrabajadores();
    this.curr_parameter = 0;
    super.openUpdateModal(content, obj, 'lg');
    this.loadingRequired = true;
    this.GetTypeJob().subscribe(x => {
      this.loadingRequired = false;
      if (x != false) {
        this.row_typejob = x;
      }
    });
  }
  openUpdateModal3(content: any, obj: any) {

    this.curr_parameter = 0;
    let newobj = JSON.parse(JSON.stringify(obj));
    this.u_obj1 = newobj;
    // console.log(this.u_obj)
    this.modalService.open(content, { centered: true, size: 'lg' });
    this.loadingRequired = true;
  }

  openUpdateModal2(content: any, obj: any) {
    //this.GetTrabajadores();
    this.curr_parameter = 0;
    super.openUpdateModal(content, obj, 'lg');
    this.loadingRequired = true;
    this.GetEvaluation().subscribe(x => {
      this.loadingRequired = false;
      if (x != false) {
        this.evaluacion = x;
      }
    });
  }

  public openCreateModal(content: any) {
    this.GetTrabajadores();
    //this.GetTypeJob();
    this.curr_parameter = 0;
    this.c_obj = {};
    //this.c_obj.Ruc=this.userinfo['logon'];
    this.c_obj['RequestDetails'] = [];
    this.c_obj['IsVirtual'] = 0;
    this.modalService.open(content, { size: 'lg', backdrop: 'static' });
    //this.modalService.open(content, { backdrop: 'static', keyboard: false });
    this.loadingRequired = true;
    this.GetTypeJob().subscribe(x => {
      this.loadingRequired = false;
      if (x != false) {
        this.row_typejob = x;
      }
    });
  }

  public async createItem(content: any, form: any) {
    this.create_url = "Request/Create";
    var validated = true;
    if (form == undefined) {
      form = {};
    }
    validated = this.validateCreateInfo(form);
    if (!validated) {
      Swal.fire(this.translations['msg_all_required'], '', 'error');

      return;
    }
    var to: any;
    //var ID:any;
    form.CompanyID = this.userinfo.selectedCompanyId;
    form.CreatorUser = this.userinfo['userId'];//this.userinfo['logon'];
    form.Status = "A";
    to = form;

    if (this.c_obj['RequestDetails'] == undefined || this.c_obj['RequestDetails'].length == 0) {
      Swal.fire(this.translations['msg_worker_required'], '', 'error');
      return;
    }
    var qst = this.create_url;
    this.isCreating = true;

    // console.log(to)
    await this.srv.postCall(qst, to).toPromise().then(async data => {
      //console.log(`Data mostrada : ${data.success} - ${data.msg} - ${data.val["RequestId"]}`)
      await this.AssigneItem("", data.val["RequestId"]);
      this.c_obj = {};
      this.c_obj['RequestDetails'] = [];
      this.curr_parameter = 0;
      this.closeModal(content);
      // Swal.fire("", data.msg, 'success');
      Swal.fire(this.translations.msg_create, '', 'success');

    }, catchError => {
      catchError.message;

    });

  }


  toggleEditable(event, id) {
    console.log(event, id);
    if (event.target.checked) {
      this.ActivarItem(id, 1);
    } else {
      this.ActivarItem(id, 2);

    }
  }

  ActivarItem(id: any, accion: any) {
    var to: any;
    to = {};
    to.RequestId = id;
    to.Status = accion;
    to.LastModifierUserID = this.userinfo['userId'];
    var qst = "Request/UpdateStatus";
    this.srv.postCall(qst, to).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if (x.success) {
        this.reload.next(true);
        Swal.fire(this.translations['MENSAJE'], x.msg, 'success');
        this.getList();
      }
      else {
        Swal.fire(x.msg, '', 'error');
      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }

  GetPlantas() {
    var qst = "ConsultasGenerales/ConsultaPlantasActivas";

    //console.log("usuario logoneado:",this.userinfo)
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;
      if (x.success) {
        if (x.val != null) {
          var rs = x.val;
          this.row_plantas = rs;
          //console.log("rs:",rs);

        } else {
          //Swal.fire('¡Vacio!', 'La consulta no devolvio registros', 'success');
          this.row_plantas = [];
          return;
        }
      } else {
        //Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  GetTypeJob() {
    var qst = "ConsultasGenerales/ConsultaJobTypeActive";

    return this.srv.getCall(qst).pipe(
      mergeMap(data => {
        let mydata: any = { success: false, msg: '', val: [] };
        mydata = data;
        //console.log("data:",data);
        //console.log("mydata:",mydata);

        if (mydata.success == true) {
          return of(mydata.val);
        }
        else {
          return of(false);
        }
      }),
      //timeout(MAX_TIME),
      catchError(data => of(false))
    );
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  addParameter2(obj: any) {
    console.log(obj)
    if (this.curr_parameter == 0) {
      return;
    }
    else {


      // debugger;
      let parm = this.evaluacion.filter(f => {
        return f.ParameEvaID == this.curr_parameter;
      });

      // debugger;
      let exists = obj['RequestEvualuation'].filter(f => {
        return f.ParameEvaID == this.curr_parameter;
      });
      let newList = [];
      for (let l of obj['listWorksIdEmp']) {
        newList.push(l.split('-')[0]);
      }
      //console.log(newList)
      // SE BUSCA SI EXISTE EL TIPO DE TRABAJO PARA ESE EMPLEADO
      if (newList.indexOf(this.curr_parameter) === -1) {
        if (newList.length === 1) {
          return Swal.fire(this.translations.Error_Type_Job, this.translations.Type_job_habilitate + `: <br> ${obj['listWorksIdEmp'][0].split('-')[1]}`, 'error')
        } else {
          let works = '';
          for (let x of obj['listWorksIdEmp']) {
            works = works + x.split('-')[1] + ',';
          }
          return Swal.fire(this.translations.Error_Type_Job, this.translations.Type_job_habilitate + `: <br> ${works.slice(0, -1)}`, 'error')
        }

      }
      if (exists.length > 0) {
        return;
      }

      if (parm.length > 0) {
        let parameter = parm[0];
        obj['RequestEvualuation'].push({ ParameEvaID: parameter['ParameEvaID'], EvaluationName: parameter['EvaluationName'] });
      }
    }
  }

  addParameter(obj: any) {
    // console.log(obj)
    // let to = {
    //   employeeID: obj.EmployeeId,
    //   jobTypeId: obj['listWorksIdEmp'][0].split('-')[0]
    // }
    // this.srv.postCall(existeEval, to).subscribe(x => {
    //   this.isCreating = false;
    //   Swal.close();
    //   if (x.success) {
    //     console.log(x)
    //   }
    // });
    if (obj['NumberIdentification'] == undefined || obj['EmployeeId'] == undefined) {
      return Swal.fire(this.translations.msg_all_required, '', 'error');
    }
    // console.log(obj['RequestDetails']);
    if (this.curr_parameter == 0 || this.curr_parameter == null) {
      return Swal.fire(this.translations.msg_all_required, '', 'error');
    }
    else {
      // confirma la existencia de un trabajador en la lista con la misma cedula
      let exists = obj['RequestDetails'].filter(f => {
        return f.NumberIdentification == obj['NumberIdentification'];
      });
      // console.log(exists);
      // console.log(this.curr_parameter)
      // console.log(obj['listWorksIdEmp'])
      let newList = [];
      for (let l of obj['listWorksIdEmp']) {
        newList.push(Number(l.split('-')[0])); // convierto el id del trabajo en numerico para buscarlo despues
      }
      // console.log({newList})
      // SE BUSCA SI EXISTE EL TIPO DE TRABAJO PARA ESE EMPLEADO
      if (newList.indexOf(this.curr_parameter) === -1) {
        if (newList.length === 1) {
          return Swal.fire(this.translations.Error_Type_Job, this.translations.Type_job_habilitate + `: <br> ${obj['listWorksIdEmp'][0].split('-')[1]}`, 'error')
        } else {
          let works = '';
          for (let x of obj['listWorksIdEmp']) {
            works = works + x.split('-')[1] + ',';
          }
          return Swal.fire(this.translations.Error_Type_Job, this.translations.Type_job_habilitate + `: <br> ${works.slice(0, -1)}`, 'error')
        }

      }
      if (exists.length > 0) {
        return Swal.fire(this.translations.error, this.translations.identificaction_repeated, 'error');;
      }
      // debugger;
      let parm = this.row_typejob.filter(f => {
        return f.JobTypeID == this.curr_parameter;
      });

      if (parm.length > 0) {
        let parameter = parm[0];
        obj['RequestDetails'].push({ NumberIdentification: obj['NumberIdentification'], NamesubCompany: obj['namesubcompany'], NameEmployee: obj['NameEmployee'], position: obj['position'], EmployeeId: obj['EmployeeId'], JobTypeID: parameter['JobTypeID'], JobtypeName: parameter['Name'] });
      }
      this.c_obj['NumberIdentification'] = undefined;
      this.c_obj['namesubcompany'] = undefined;
      this.c_obj['NameEmployee'] = undefined;
      this.c_obj['position'] = undefined;
      this.c_obj['EmployeeId'] = undefined;
      this.c_obj['JobTypeID'] = undefined;
      this.c_obj['JobtypeName'] = undefined;
      //console.log("obj:",obj);
    }
  }

  public delParameter(obj: any, position: number) {
    // debugger;
    obj['RequestDetails'].splice(position, 1);
  }

  public delParameter2(obj: any, position: number) {
    // debugger;
    obj['RequestEvualuation'].splice(position, 1);
  }

  GetEmployeeforIdentification(obj: any, val: any) {
    //debugger;
    var qst = "Employee/GetIdentification/" + obj;

    if (obj == "") {
      return;
    }
    this.c_obj['listWorksIdEmp'] = [];
    this.u_obj['listWorksIdEmp'] = [];
    //console.log("usuario logoneado:",this.userinfo)
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;
      //console.log("X:",x);
      if (x.success) {
        if (x.val != null) { debugger;
          console.log(x.val)
          if (x.val['Status_doc'] != 'VALIDADO') {
            //Swal.fire(this.translations.error, this.translations.msg_hability_request+" "+x.val['Status_doc'], 'error');
            Swal.fire({
              title: this.translations.warning,
              text: this.translations.msg_hability_request + " " + x.val['Status_doc'] + ", " + this.translations.msg_hability_request_1,
              icon: 'warning',
              showCancelButton: true,
              showLoaderOnConfirm: true,
              cancelButtonText: this.translations.cancel,
              confirmButtonText: this.translations.ok
            }).then((result) => {
              //debugger;
              if (result.value) {
                if (val == 1) {debugger;
                  this.c_obj['NameEmployee'] = x.val['Name'];
                  this.c_obj['namesubcompany'] = x.val['namesubcompany'];
                  this.c_obj['position'] = x.val['position'];
                  this.c_obj['EmployeeId'] = x.val['EmployeeId'];
                  this.c_obj['Status_doc'] = x.val['Status_doc'];
                }
                else {
                  this.u_obj['NameEmployee'] = x.val['Name'];
                  this.u_obj['namesubcompany'] = x.val['namesubcompany'];
                  this.u_obj['position'] = x.val['position'];
                  this.u_obj['EmployeeId'] = x.val['EmployeeId'];
                  this.u_obj['Status_doc'] = x.val['Status_doc'];
                }
                for (let object of x.val['_JobTypeEmployee']) {
                  this.c_obj['listWorksIdEmp'].push(object.JobTypeID.toString() + '-' + object.Name);
                  this.u_obj['listWorksIdEmp'].push(object.JobTypeID.toString() + '-' + object.Name);
                };
              } else {
                this.c_obj['NumberIdentification'] = "";
                this.u_obj['NumberIdentification'] = "";
                //Swal.fire('', 'Trabajador no esta habilitado', 'success');
                return;
              }
            });

          } else {
            if (val == 1) {
              this.c_obj['NameEmployee'] = x.val['Name'];
              this.c_obj['namesubcompany'] = x.val['namesubcompany'];
              this.c_obj['position'] = x.val['position'];
              this.c_obj['EmployeeId'] = x.val['EmployeeId'];
              this.c_obj['Status_doc'] = x.val['Status_doc'];
            }
            else {
              this.u_obj['NameEmployee'] = x.val['Name'];
              this.u_obj['namesubcompany'] = x.val['namesubcompany'];
              this.u_obj['position'] = x.val['position'];
              this.u_obj['EmployeeId'] = x.val['EmployeeId'];
              this.u_obj['Status_doc'] = x.val['Status_doc'];
            }
            for (let object of x.val['_JobTypeEmployee']) {
              this.c_obj['listWorksIdEmp'].push(object.JobTypeID.toString() + '-' + object.Name);
              this.u_obj['listWorksIdEmp'].push(object.JobTypeID.toString() + '-' + object.Name);
            };
          }
        } else {
          //Swal.fire('¡Vacio!', 'La consulta no devolvio registros', 'success');
          //this.row_plantas = [];
          return;
        }
      } else {
        //Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  validateCreateInfo(fields: any) {
    let mygroup: any = {};
    mygroup = new FormGroup({
      'WhoRequest': new FormControl(fields.WhoRequest, [Validators.required])
    });

    return mygroup.valid;
  }

  GetEvaluation() {
    var qst = "Evaluation/AllActive";

    return this.srv.getCall(qst).pipe(
      mergeMap(data => {
        let mydata: any = { success: false, msg: '', val: [] };
        mydata = data;
        //console.log("data:",data);
        //console.log("mydata:",mydata);

        if (mydata.success == true) {
          return of(mydata.val);
        }
        else {
          return of(false);
        }
      }),
      //timeout(MAX_TIME),
      catchError(data => of(false))
    );
  }



}
