import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'menu_home',
    icon: 'far fa-comments',
    class: '',
    extralink: false,
    perm: 'home',
    submenu: []
  },
  {
    path: '/formshome',
    title: 'menu_forms',
    icon: 'icon icon-note',
    class: '',
    extralink: false,
    perm: 'SI_ADMREGISTRO',
    submenu: []
  },
  {
    path: '/prochome',
    title: 'menu_processes',
    icon: 'fa fa-cogs',
    class: '',
    extralink: false,
    perm: 'SI_ADMSOLICITUD',
    submenu: []
  },
  {
    path: '/excelenciahome',
    title: 'menu_operacional',
    icon: 'icon icon-globe',
    class: '',
    extralink: false,
    perm: 'SI_ADMOPERACIONAL',
    submenu: []
  },
  {
    path: '/cruds',
    title: 'menu_maintenance',
    icon: 'fa fa-wrench',
    class: '',
    extralink: false,
    perm: 'SI_ADMCONSULTA',
    submenu: []
  },
  {
    path: '/settings',
    title: 'menu_general',
    icon: 'icon icon-settings',
    class: '',
    extralink: false,
    perm: 'sett',
    submenu: []
  }
];
