import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

const API_CONSUDOCUJOB = environment.API_CONSUDOCUJOB;
const API_REGISTERDOCUJOB = environment.API_REGISTERDOCUJOB;
const API_UPDATEDOCUJOB = environment.API_UPDATEDOCUJOB;
const API_DELETEDOCUJOB = environment.API_DELETEDOCUJOB;
const API_ACTIVEDOCUJOB = environment.API_ACTIVEDOCUJOB;
const API_CONSULTAJOBTYPEACTIVE = environment.API_CONSULTAJOBTYPEACTIVE;

@Component({
  selector: 'app-paramdocujobtype',
  templateUrl: './paramdocujobtype.component.html',
  styleUrls: ['./paramdocujobtype.component.css']
})
export class ParamdocujobtypeComponent extends BaseCrudComponent {
  id: any;
  loadingRequired = false;
  listAgr: any;
  translations: any;
  userinfo: any;
  jobtypeID: any;

  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
    this.id = route.snapshot.params.id;

    this.read_url = API_CONSUDOCUJOB;
    this.create_url = API_REGISTERDOCUJOB;
    this.update_url = API_UPDATEDOCUJOB;
    this.delete_url = API_DELETEDOCUJOB;
    this.active_url = API_ACTIVEDOCUJOB;
    this.urlJob = API_CONSULTAJOBTYPEACTIVE;

    this.search_fields = ['NameType', 'Description', 'NameTypeJob'];

    this.c_obj['AnalysisFeature'] = [];

    this.reload.subscribe(val => {
      if (val) {
        super.clearData();
        this.getList();
      }
    });
    this.getListJobType();
  }

  async ngOnInit() {
    await this.translate.getTranslation(this.translate.currentLang)
      .toPromise().then((translations) => {
        this.translations = translations;
        super.ngOnInit();
        this.getList();
      });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    await this.storage.get('userinfo').then(x => {
      this.userinfo = x;
    });
  }

  public getList() {
    debugger;
    var qst = this.read_url +"/"+ this.id;
    this.loadingList = true;
    Swal.fire(
      {
        title: this.translations['loading'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    this.srv.getCall(qst).subscribe(x => {
      debugger;
      this.loadingList = false;

      if (x.success) {
        if (x.val != null) {
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          this.page = 1;
          if (Array.isArray(this.rows)) {
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          Swal.close()
        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public getListJobType() {

    var qst = this.urlJob;
    this.loadingList = true;

    this.srv.getCall(qst).subscribe(x => {
      debugger;
      this.loadingList = false;

      if (x.success) {
        var rs = x.val;
        this.rowJob = rs;
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public createItem(content: any, form: any) {
    debugger;
    var validated = true;

    if (form == undefined) {
      form = {};
    }

    validated = this.validateCreateInfo(form);
    if (!validated) {
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }

    var to: any;
    form.CreatorUser = this.userinfo['logon'];
    form.LastModifierUser = this.userinfo['logon'];
    to = form;


    var qst = this.create_url;
    this.isCreating = true;

    this.srv.postCall(qst, to).subscribe(x => {
      this.isCreating = false;
      if (x.success) {
        this.getList();
        this.closeModal(content);
        Swal.fire('¡Creado!', x.msg, 'success');
      }//end success
      else {
        //Swal.fire(this.translations[x.msg], '', 'error');
        Swal.fire(x.msg, '', 'error');
      }
    });
  }


  validateCreateInfo(fields: any) {
    let mygroup: any = {};
    mygroup = new FormGroup({
      'NameType': new FormControl(fields.NameType, [Validators.required]),
      'JobTypeID': new FormControl(fields.JobTypeID, []),
      'DiasVigencia': new FormControl(fields.DiasVigencia, [Validators.required]),
      'Description': new FormControl(fields.Description, [])
    });

    return mygroup.valid;
  }

  public filterAnalysisGroup(id: any) {
    debugger;
    this.jobtypeID = id.split(':')[1].trim();

  }

  public updateItem(content: any, form: any) {
    // console.log(form)
    // debugger;
    var qst = this.update_url;
    this.isCreating = true;

    if (form == undefined) {
      form = {};
    }

    var to: any;

    if ((form.NameType == undefined) || (form.NameType == '') || (form.DiasVigencia == '')) {
      Swal.fire('Ingrese todos los campos requeridos', 'msg', 'warning');
      return;
    }


    to = {};
    to.NameType = form.NameType;
    to.Description = form.Description;
    to.JobTypeID = form.JobTypeID;
    to.LastModifierUser = this.userinfo['logon'];
    to.docuTypeID = form.docuTypeID;
    to.DiasVigencia = form.DiasVigencia;

    this.srv.postCall(qst, to).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if (x.success) {
        this.reload.next(true);
        this.closeModal('update1');
        Swal.fire('¡Actualizado!', x.msg, 'success');
        this.getList();

      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });

  }


  toggleEditable(event, id) {
    debugger;
    if (event.target.checked) {
      this.Activar(id);
    } else {
      this.deleteItem(id);

    }
  }

  Activar(obj: any) {
    debugger;
    var id: any;
    id = {};
    id.docuTypeID = obj;
    id.LastModifierUser = this.userinfo['logon'];
    var qst = this.active_url;

    this.srv.postCall(qst, id).subscribe(x => {
      this.isCreating = false;
      Swal.close();

      if (x.success) {
        this.reload.next(true);
        Swal.fire('¡Activado!', x.msg, 'success');
        this.getList();
      }
      else {
        Swal.fire(x.msg, '', 'error');
      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }

  deleteItem(obj: any) {

    var id: any;
    id = {};
    id.docuTypeID = obj;
    id.LastModifierUser = this.userinfo['logon'];
    id.DeleteUser = this.userinfo['logon'];
    var qst = this.delete_url;

    this.srv.postCall(qst, id).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      this.isCreating = false;
      Swal.close();
      if (x.success) {
        this.reload.next(true);
        Swal.fire('Desactivado!', x.msg, 'success');
        this.getList();
      }
      else {
        Swal.fire(x.msg, '', 'error');
      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }




}
