import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

import { Storage } from '@ionic/storage';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { isNumeric } from 'rxjs/util/isNumeric';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Columns, Img, Item, PdfMakeWrapper, Stack, Table, Toc, Txt, Ul } from 'pdfmake-wrapper';
import { ITable } from 'pdfmake-wrapper/lib/interfaces';
import * as pdfFonts from "pdfmake/build/vfs_fonts"; // fonts provided for pdfmake
// If any issue using previous fonts import. you can try this:
// import pdfFonts from "pdfmake/build/vfs_fonts";

// Set the fonts to use
PdfMakeWrapper.setFonts(pdfFonts);

const API_UPDATE_STATUS_TOOLS = environment.Global.API_UPDATE_STATUS_TOOLS;
const API_GET_TOOLS = environment.Global.API_GET_ALL_TOOLS;
const API_CREATE_TOOLS = environment.Global.API_CREATE_TOOLS;
const API_UPDATE_TOOLS = environment.Global.API_UPDATE_TOOLS;
const GET_ALL_REQUEST_BY_COMPANY_ID = environment.Global.API_GET_ALL_REQUEST_BY_COMPANY_ID;
const GET_REQUEST_BY_ID = environment.Global.API_GET_REQUEST_BY_ID;
const GET_ALL_TOOLS_FOR_SKRETTING = environment.Global.API_GET_ALL_TOOLS_FOR_SKRETTING;



@Component({
  selector: 'app-regtoolsequipment',
  templateUrl: './regtoolsequipment.component.html',
  styleUrls: ['./regtoolsequipment.component.css']
})

export class RegtoolsequipmentComponent extends BaseCrudComponent {
  userinfo:any;
  loadingRequired = false;
  edit_obj;
  habilitar = false;
  id;
  date_doc:any;
  date;
  company:any;
  mostarselectemp;
  isSkretting = false;
  update_status_url = API_UPDATE_STATUS_TOOLS;
  entryRequestId = null;
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        this.create_url = API_CREATE_TOOLS;
        this.read_url = API_GET_TOOLS;
        this.update_url = API_UPDATE_TOOLS;
        this.date_doc =new Date();
        this.search_fields = ['ListsToolsId','Lt_Supervisor'];

        this.c_obj['Company'] = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
        // this.crearFormEmp();
   }

   ngOnInit() {

    this.route.queryParams.subscribe(params => {
      this.entryRequestId = params['id'];
    });

    this.date = new Date();
    //console.log(this.date)
    this.company =[];
    //this.u_obj=[];

    ////debugger;
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
          this.translations = translations;
          super.ngOnInit();
          //   this.getList();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    this.storage.get('userinfo').then(x => {
        this.userinfo = x;
        // console.log(x)
        if( isNumeric( this.userinfo.logon) ) {
          this.mostarselectemp = false;
          this.isSkretting = false;
          this.getList();
        } else {
          this.isSkretting = true;
          this.getList();
        }
    });
    
  }


  /**
     * @method
     * @public
     * @version 1.0.0
     * @author Danny Rios <danny.rios@royalticgroup.com>
     * @returns no return.
     * @desc function to open modal to create tools. <br> Creation Date: 07/13/2021
     * @param {content} NgbModal modal
     * @type {void} void type.
  */
  public openCreateModal(content: NgbModal)  {
    let qst = GET_REQUEST_BY_ID + this.entryRequestId;
    this.srv.getCall(qst).subscribe(x => { 
      if(x.success){
        if(x.val != null){
          this.c_obj['date_register'] = x.val['StartDate'].split('T')[0];
          this.c_obj['date_out'] = x.val['EndDte'].split('T')[0];
        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
        }     
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
    // let isFind = this.rows.find( row =>{
    //   return row.EntryRequestId == this.entryRequestId
    // });
    // if (isFind !== undefined) {
    //   return Swal.fire(this.translations.error, this.translations.tool_list_exist, 'error');
    // } else {

    // }
    this.c_obj = {};
    this.c_obj['ToolsDetails'] = [];
    this.c_obj['entryRequestId'] = this.entryRequestId;
    this.modalService.open(content, { backdrop: 'static', size: 'lg' });
  }

  /**
     * @method
     * @public
     * @version 1.0.0
     * @author Danny Rios <danny.rios@royalticgroup.com>
     * @returns return boolean data.
     * @desc function to validate tools form in modal. <br> Creation Date: 07/13/2021
     * @param {fields} any form
     * @type {boolean} boolean type.
  */
  public validateCreateInfo(fields:any): boolean {
    let mygroup:any = {};
    mygroup = new FormGroup({
        'Supervisor': new FormControl(fields.Supervisor, [Validators.required]),
        'date_register': new FormControl(fields.date_register, [Validators.required]),
        'date_out': new FormControl(fields.date_out, [Validators.required]),
        'detailTool': new FormControl(fields.detailTool),
        'quantity': new FormControl(fields.quantity),
        'mModel': new FormControl(fields.mModel),
        'noticeI': new FormControl(fields.noticeI), //, [Validators.required]
        'noticeO': new FormControl(fields.noticeO), //, [Validators.required]
    });
    return mygroup.valid;
  }

   /**
     * @method
     * @public
     * @version 1.0.0
     * @author Danny Rios <danny.rios@royalticgroup.com>
     * @returns no return.
     * @desc function to get alls tools from database <br> Creation Date: 07/13/2021
     * @param {fields} any form
     * @type {void} void type.
  */
  public getList() {
    let qst = '';
    if(this.isSkretting)
    {
      qst =  GET_ALL_TOOLS_FOR_SKRETTING;
    } else {
      qst = this.read_url + `/${this.userinfo.selectedCompanyId}`; // URL TO GET  TOOL LIST
    }
    // Swal.fire(
    //   {
    //     title: this.translations['loading'],
    //     html: '',
    //     icon: 'info',
    //   }
    // );
    // Swal.showLoading();
    this.loadingList = true;
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          this.rows = x.val;
          this.page = 1;
          if (Array.isArray(this.rows)) {
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          Swal.close()
        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers,'success');
          this.rows = [];
          return;
        }     
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
    //console.log(this.rows);
  }

  /**
     * @method
     * @public
     * @version 1.0.0
     * @author Danny Rios <danny.rios@royalticgroup.com>
     * @returns no return.
     * @desc function to activo/inactive tools list from supervisor <br> Creation Date: 07/13/2021
     * @param {event} any input checkbox
     * @param {id} Number id from supervisor
     * @type {void} void type.
  */
  public toggleEditable(event: any, id: Number): void {
    
    if ( event.target.checked ) {
       this.ActivarItem(id,1);
    }else {
     this.ActivarItem(id,2);
  
    }
  }

  /**
     * @method
     * @public
     * @version 1.0.0
     * @author Danny Rios <danny.rios@royalticgroup.com>
     * @returns no return.
     * @desc function to activo/inactive tools list from supervisor <br> Creation Date: 07/13/2021
     * @param {Accion} any status from tools list
     * @param {id} Number id from supervisor
     * @type {void} void type.
  */
  public ActivarItem(id:any, accion:any): void
  {
    var to:any;
    to = {};
    to.ListsToolsId = id;
    to.Status = accion;
    to.LastModifierUser = this.userinfo['userId'];
    this.srv.postCall(this.update_status_url, to).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if(x.success){
        this.reload.next(true);
        Swal.fire(this.translations['MENSAJE'], x.msg, 'success');
        this.getList();
      }
      else{
          Swal.fire(x.msg, '', 'error');
      }
    }, err => {
        Swal.close();
        Swal.fire('Error');
    });
  }

  /**
     * @method
     * @public
     * @version 1.0.0
     * @author Danny Rios <danny.rios@royalticgroup.com>
     * @returns no return.
     * @desc function to create tools list in database<br> Creation Date: 07/13/2021
     * @param {content} NgbModal modal to close
     * @param {form} Form data set in form
     * @type {any} any type.
  */
  public createItem(content:any, form:any): any{
    var validated = true;
    this.isCreating = true;
    if( form == undefined ){
      form = {};
    }

    validated = this.validateCreateInfo(form);
    if( !validated ){
      this.isCreating = false;
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }

    
    let array_Tools = [];
    if (this.c_obj['ToolsDetails'].length <= 0) {
      this.isCreating = false;
      return Swal.fire(this.translations.error, this.translations.msg_register_tool,'error');
    }
    for (let obj of this.c_obj['ToolsDetails']) {
      let tool = {
        ListsToolsId: this.userinfo.selectedCompanyId,
        Description: obj['tool_detail'],
        quantity: obj['quantity'],
        ModelBrand: obj['brand_model'].toUpperCase(),
        EntryNews: obj['notice_in'],
        DeparturesNews: obj['notice_out']
      }
      array_Tools.push(tool);
    };

    var to = {
      CompanyID : this.userinfo.selectedCompanyId,
      Lt_DateBegin : form.date_register,
      Lt_DaTeend : form.date_out,
      Lt_Supervisor : form.Supervisor,
      CreatorUser : this.userinfo['logon'],
      LastModifierUser : this.userinfo['logon'],
      EntryRequestId: this.entryRequestId,
      ListsToolsDetails : array_Tools,
    };
    
    this.srv.postCall(this.create_url, to).toPromise().then( data =>{
      if(data.success){
        this.c_obj = {};
        this.closeModal(content);
        Swal.fire(this.translations.msg_create, '', 'success');
        this.isCreating = false;
        this.getList();
      } else {
        this.isCreating = false;
        Swal.fire(data.msg, '', 'error');
      }
      
    },catchError =>{
      // catchError.message;
      Swal.fire(catchError.message, '', 'error');
      this.isCreating = false;
    });

  }

  /**
     * @method
     * @public
     * @version 1.0.0
     * @author Danny Rios <danny.rios@royalticgroup.com>
     * @returns return if quantity input is digit or not.
     * @desc function validate the user digit numbers in quantity input<br> Creation Date: 07/13/2021
     * @param {event} Any action from input
     * @type {boolean} boolean type.
  */
  public onlyNumberKey(event: any): boolean {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }


  /**
     * @method
     * @public
     * @version 1.0.0
     * @author Danny Rios <danny.rios@royalticgroup.com>
     * @returns no return.
     * @desc function add parameters in table <br> Creation Date: 07/13/2021
     * @param {obj} Object object with data
     * @type {Any} any type.
  */
  public addParameter(obj:any): any
  {
    if (obj.noticeI === undefined) obj.noticeI = '';
    if (obj.noticeO === undefined) obj.noticeO = '';
    
    // console.log(obj)
    if (obj['detailTool']==undefined  || obj['mModel']==undefined || obj['quantity']==undefined){
      return Swal.fire(this.translations.error, this.translations.msg_add_parameter_tool_empty,'error');
    }

    if ((obj['detailTool']== '' || obj['detailTool']== null)  || (obj['mModel']== '' || obj['mModel']== null) || (obj['quantity']== '' || obj['quantity']== null)){
      return Swal.fire(this.translations.error, this.translations.msg_add_parameter_tool_empty,'error');
    }
    
    let exists = obj['ToolsDetails'].filter(f => {
      return ((f.tool_detail.toLowerCase() ==  obj['detailTool'].toLowerCase()) && (f.brand_model.toLowerCase() == obj['mModel'].toLowerCase()));
    });
    if(exists.length > 0)
    {
      return Swal.fire('Error', 'Ya existe la herramienta','error');
    }

    obj['ToolsDetails'].push({
      tool_detail: obj['detailTool'],
      brand_model:obj['mModel'],
      quantity:obj['quantity'],
      notice_in:obj['noticeI'],
      notice_out:obj['noticeO'],
      }
    );
    this.c_obj['detailTool']="";
    this.c_obj['mModel']="";
    this.c_obj['quantity']="";
    this.c_obj['noticeI']="";
    this.c_obj['noticeO']="";
      

    }

    /**
     * @method
     * @public
     * @version 1.0.0
     * @author Danny Rios <danny.rios@royalticgroup.com>
     * @returns no return.
     * @desc function to delete element from table in modal <br> Creation Date: 07/13/2021
     * @param {obj} Object object with data
     * @param {position} Number position to eliminate data
     * @type {Any} any type.
    */
    public delParameter(obj:any, position:number): void{
      obj['ToolsDetails'].splice(position, 1);

    }

    /**
     * @method
     * @public
     * @version 1.0.0
     * @author Danny Rios <danny.rios@royalticgroup.com>
     * @returns no return.
     * @desc function to delete item from table in component <br> Creation Date: 07/13/2021
     * @param {obj} Object object with data
     * @type {Any} any type.
    */
    public deleteItem(obj:any): void {
      this.ActivarItem(obj.ListsToolsId,3);
    }

    /**
     * @method
     * @public
     * @version 1.0.0
     * @author Danny Rios <danny.rios@royalticgroup.com>
     * @returns no return.
     * @desc function to open update modal <br> Creation Date: 07/13/2021
     * @param {obj} Object object with data
     * @param {content} NgbModal update modal
     * @type {void} void type.
    */
    public openUpdateModal(content:any, obj: any, size?: any): void{
      this.edit_obj = obj
      this.c_obj['Supervisor'] = obj.Lt_Supervisor;
      this.c_obj['date_register'] = obj.StartDate.split('T')[0];
      this.c_obj['date_out'] = obj.EndDte.split('T')[0];
      this.c_obj['ToolsDetails'] = [];
      this.c_obj['EntryRequestId'] = obj.EntryRequestId;
      for (let item of obj.ListsToolsDetails) {
        let itemFromDataBase = {
          tool_detail: item.Description,
          brand_model: item.ModelBrand,
          quantity: item.quantity,
          notice_in: item.EntryNews,
          notice_out: item.DeparturesNews
        }
        this.c_obj['ToolsDetails'].push(
          itemFromDataBase
        )
      }
      this.modalService.open(content, { backdrop: 'static', size: size });
    }

    public verifyDateEnd() {
      if (this.c_obj['date_register'] === undefined ) {
        this.c_obj['date_out'] = null;
        return Swal.fire(this.translations.error,this.translations.msg_not_found_date_init, 'error');
      }
      const date_init = new Date(this.c_obj['date_register']);
      const date_end = new Date(this.c_obj['date_out']);

      if (date_init > date_end ){
        (<HTMLInputElement> document.getElementById("fechaFinal")).value = "";
        return Swal.fire(this.translations.error,this.translations.msg_date_end_max, 'error');
      }
      
      
    }

    async  printListTool(row) {
      const pdf = new PdfMakeWrapper();
      // INFORMATION IN TITLE NAV
      pdf.info({
        title: 'Ficha de Herramientas  Equipos',
        author: `${row.Lt_Supervisor}`,
        subject: 'Listado',
      });
      //OPTIONS
      pdf.defaultStyle({
        bold: false,
        fontSize: 10,
        alignment:'justify'
      });
      pdf.pageMargins([ 40, 90, 40, 60]);
      //PERMISIONS
      pdf.permissions('Nutreco2021', {
        printing: 'highResolution',
        copying: false,
        modifying: false,
        annotating: true,
        fillingForms: true,
        documentAssembly: true,
        contentAccessibility: true
      })
      pdf.header(await 
        new Img('../../../../assets/images/logo-icon1.png')
        .width('150')
        //.alignment("right")
        .margin(20)
        .build())
      // SET DATA IN PDF
      // pdf.header();
      
      // pdf.add(new Txt('Preparado por: ').fontSize(6).bold().alignment('left')['end']);
      // pdf.add(new Columns([ new Txt('Preparado por: ').fontSize(6).bold().alignment('left')['end'], new Txt('Analía Merchán').fontSize(6).alignment('left').margin(0)['end']]).end);
      // pdf.add(new Columns([ new Txt('Referencia Interna: ').fontSize(6).bold().alignment('left')['end'], new Txt('QRN12DF-2').fontSize(6).bold().alignment('left')['end'] ]).end);
      // pdf.add(new Columns([ new Txt('Fecha de llegada: ').fontSize(6).bold().alignment('left')['end'], new Txt('2021-07-22').fontSize(6).bold().alignment('left')['end'] ]).end);
      // pdf.add(new Columns([ new Txt('Fecha de envío: ').fontSize(6).bold().alignment('left')['end'], new Txt('2021-07-23').fontSize(6).bold().alignment('left')['end'] ]).end);
      // pdf.add(new Txt('Referencia Interna: ').fontSize(6).bold().alignment('left')['end']);
      // pdf.add(new Txt('Fecha de llegada: ').fontSize(6).bold().alignment('left')['end']);
      // pdf.add(new Txt('Fecha de envío: ').fontSize(6).bold().alignment('left')['end']);

      pdf.add(new Txt('LISTADO DE HERRAMIENTAS Y EQUIPOS').fontSize(12).bold().alignment('center')['end']);
      // pdf.add(new Txt('GISIS S.A.').fontSize(12).bold().alignment('center')['end']);



      pdf.add(new Ul([
        new Txt(`Empresa Contratista: ${row.CompanyName}`).fontSize(12)['end'],
        new Txt(`Supervisor Contratista: ${row.Lt_Supervisor}`).fontSize(12)['end'],
        new Txt(`Fecha de Ingreso: ${row.Lt_DateBegin.split('T')[0]}`).fontSize(12)['end'],
        new Txt(`Fecha de Salida: ${row.Lt_DaTeend.split('T')[0]}`).fontSize(12)['end'],
      ])
      .lineHeight(2)
      .margin([0,30,0,30])
      ['end']);

      pdf.header(await new Img('../../../../assets/images/logo-icon1.png').width('100').margin(20).build())
      pdf.add(
        new Table([
        [ 'ID', 'DETALLE DE HERRAMIENTAS Y EQUIPOS', 'MARCA/MODELO', 'CANTIDAD', 'NOVEDADES INGRESO', 'NOVEDADES SALIDA'],
        ...this.convertDataToRow(row)
        
      ])
      .fontSize(8)
      .heights( rowIndex => {
        return rowIndex === 0 ? 20: 0;
      })
      .headerRows(2)
      .alignment("center")
      .layout({
        fillColor: (rowIndex: number, node:any, columnIndex: number) => {
          return rowIndex === 0 ? '#cccccc' : '';
        },
      })
      .widths([ 10, 'auto', 70, 40, 'auto','auto'])
      // .margin([20,0,20, 50])
      ['end']); 
      

      pdf.create().open();
    }

    convertDataToRow (data) {
      return data.ListsToolsDetails.map((row, index) => [index + 1, row.Description,row.ModelBrand,row.quantity,row.EntryNews,row.DeparturesNews]);
    }



    public updateItem(content:any, form:any) {
      var validated = true;
      this.isUpdating = true;
      if(form == undefined ){
        form = {};
      }
  
      validated = this.validateCreateInfo(form);
      if( !validated ){
        this.isUpdating = false;
        Swal.fire(this.translations['msg_all_required'], '', 'error');
        return;
      }
  
      
      let array_Tools = [];
      if (this.c_obj['ToolsDetails'].length <= 0) {
        this.isUpdating = false;
        return Swal.fire('Error', 'Debe registrar al menos 1 herramienta','error');
      }
      for (let obj of this.c_obj['ToolsDetails']) {
        let tool = {
          ListsToolsId: this.edit_obj.ListsToolsId,
          Description: obj['tool_detail'],
          quantity: obj['quantity'],
          ModelBrand: obj['brand_model'].toUpperCase(),
          EntryNews: obj['notice_in'],
          DeparturesNews: obj['notice_out']
        }
        array_Tools.push(tool);
      };
  
      var to = {
        CompanyID : this.userinfo.selectedCompanyId,
        Lt_DateBegin : form.date_register,
        Lt_DaTeend : form.date_out,
        Lt_Supervisor : form.Supervisor,
        CreatorUser : this.userinfo['logon'],
        LastModifierUser : this.userinfo['logon'],
        EntryRequestId: this.c_obj['EntryRequestId'],
        ListsToolsDetails : array_Tools,
        ListsToolsId: this.edit_obj.ListsToolsId
      };
      // console.log(to);
      this.srv.postCall(this.update_url, to).toPromise().then( data =>{
        if(data.success){
          this.c_obj = {};
          this.closeModal(content);
          Swal.fire(this.translations.msg_create, '', 'success');
          this.isUpdating = false;
          this.getList();
        } else {
          this.isUpdating = false;
          Swal.fire(data.msg, '', 'error');
        }       
      },catchError =>{
        Swal.fire(catchError.message, '', 'error');
        this.isUpdating = false;
      });
    }
}
