import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../../base-crud/base-crud.component';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/services/main/global.service';
import Swal from 'sweetalert2';
import { LoginService } from 'src/app/services/authentication/login.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Columns,Img,PdfMakeWrapper,Stack,Txt } from 'pdfmake-wrapper';
import * as pdfFonts from "pdfmake/build/vfs_fonts"; // fonts provided for pdfmake

PdfMakeWrapper.setFonts(pdfFonts);

const API_CONSULTATYPEFALTA = environment.API_CONSULTATYPEFALTA;
const API_CONSULTAFALTADETAIL = environment.API_CONSULTAFALTADETAIL;
const API_SANCION = environment.API_SANCION;
const GET_EMPLOYEE_BY_IDENTIFICATION = environment.Global.API_GET_EMPLOYEE_BY_IDENTIFICATION;
const API_UPDATE_UNSAFEACT = environment.Global.API_UPDATE_UNSAFEACT;
const GET_ALL_SITES_ACTIVE = environment.Global.API_GET_ALL_SITES_ACTIVE;
const GET_ALL_AREA_BY_ID = environment.Global.API_GET_ALL_AREA_BY_ID;
const API_GET_UNSAFEACT_BY_USERNAME = environment.Global.API_GET_UNSAFEACT_BY_USERNAME;
const API_GET_UNSAFEACT = environment.Global.API_GET_UNSAFEACT;
const API_UPDATE_UNSAFEACT_STATUS = environment.Global.API_UPDATE_UNSAFEACT_STATUS;
const API_CREATE_UNSAFEACT = environment.Global.API_CREATE_UNSAFEACT;
const API_UPLOAD_FILE_UNSAFEACT = environment.Global.API_UPLOAD_FILE_UNSAFEACT;
const API_UNSAFEACT_FOULS = environment.Global.API_UNSAFEACT_FOULS;
const API_UNSAFE_SEND_MAIL = environment.Global.API_UNSAFE_SEND_MAIL;
const API_USER_SYSTEM_GET_ALL = environment.Global.API_USER_SYSTEM_GET_ALL;
const API_EXIST_PLAN_ACTION = environment.Global.API_EXIST_PLAN_ACTION;
const API_GET_EMPLOYEE_INTERNAL_ALL = environment.Global.API_GET_EMPLOYEE_INTERNAL_ALL;
const API_GET_EMPLOYEE_INTERNAL_BY_NUMBERIDENTIFICATION = environment.Global.API_GET_EMPLOYEE_INTERNAL_BY_NUMBERIDENTIFICATION;
@Component({
  selector: 'app-actnosecure',
  templateUrl: './actnosecure.component.html',
  styleUrls: ['./actnosecure.component.css']
})
export class ActnosecureComponent extends BaseCrudComponent implements OnInit {
  list_workers = [{id: 1, type: 'Interno'},{id: 2, type:'Externo'}];
  list_option_fault = [{id: 1, type: 'Sí'},{id: 2, type:'No'}];
  workerId;
  list_work_perm = []; // lista para los permisos de trabajo
  public isCollapsed = false;
  list_fouls = [];
  list_fouls_detail = [];
  list_sanction = [];
  list_employees_worker = [];
  row_plantas_area = [];
  archivos=[];
  row_plantas = [];
  namefile:any;
  fechaActual = new Date();
  loadingList = false;
  isUpdating = false;
  enabled = true;
  hasWork = false;
  isCreating = false;
  userinfo:any;
  idcreatd: any;
  flag = true;
  listfouls = [];
  contadorFaltas;
  falta;
  pageSize = 10;
  descriptionFoul = '';
  getEmployee = false;
  permissions;

  userSystemList = [];
  isRemove = false;
  isEditable = false;
  existePlan = true;

  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    public auth: LoginService,
    private globalService: GlobalService,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
  }

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
      this.translations = translations;
      super.ngOnInit();
      
    });
    this.getTypeFouls();
    this.search_fields = ['UnsafeActId','Name'];
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';
    this.storage.get('userinfo').then(x => {
      this.userinfo = x;
    });
    this.auth.getPermissions().then(x => {
      this.permissions = x;
      // console.log(this.permissions)
      this.getList(); 
    });
    this.getUserSystem();
  }

  public getUserSystem() {
    this.userSystemList = [];
    this.srv.getCall(API_USER_SYSTEM_GET_ALL).subscribe(x => {
      if(x.success){
        if(x.val != null){
          x.val.forEach(element => {
            element.Permissions = JSON.parse(element.Permissions);
            if (element.Permissions != null) {
              element.Permissions.Modules.forEach(permission => {
                if(permission.Name == "SI_PLANACTION") {
                  this.userSystemList.push(element)
                }
              });
            }
          })
          // console.log(x.val)
          // console.log(this.userSystemList);
        }else {
          Swal.fire(this.translations.empty, this.translations.not_user_registers, 'success');
          this.userSystemList = [];
          return;
        }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public openCreateModal(content: any) {
    this.workerId = 2;
    this.getEmployee = false;
    // this.list_fouls = [];
    this.listfouls = [];
    this.c_obj = {};
    this.list_employees_worker = [];
    this.c_obj['fault_type'] = null;
    this.c_obj['fault_associated'] = null;
    this.c_obj['type_sanction'] = null;
    this.c_obj['close_fault'] = 2;
    this.c_obj['cmp_area'] = null;
    this.archivos = [];
    this.c_obj['report_by'] = this.userinfo['name'];
    this.getWorkPerm(); // se llama a todos los permisos de trabajo
    // this.getUserSystem();
    this.modalService.open(content,  { backdrop: 'static',  keyboard : false, size: 'lg' });
  }

  public getList() {
    this.loadingList = true; 
    // if (this.flag)
    // Swal.fire(
    //   {
    //     title: this.translations.loading,
    //     html: '',
    //     icon: 'info',
    //   }
    // );
    // Swal.showLoading();
    let url = '';
    // SE VALIDA SI EL USUARIO ES ADMINISTRADOR
    if (this.permissions.includes('SI_ADMIN')) {
      url = API_GET_UNSAFEACT;
    } else {
      url = API_GET_UNSAFEACT_BY_USERNAME + this.userinfo['logon'];
    }
    this.srv.getCall(url).subscribe(x => {
      this.loadingList = false; 
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          this.rows = this.rawdata;
          // console.log(this.rows)
          this.page = 1;
          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          if(this.flag) {
            Swal.close()
          }
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
    this.GetPlantas();
  }

  public setTypeWorker() {
    if(this.workerId == 2) { // Externo
      // this.c_obj['worker_perm'] = null; 
      this.c_obj = {};
      this.c_obj['report_by'] = this.userinfo['name'];
    } else { // Interno
      this.getEmployee = false;
      // this.list_fouls = [];
      this.c_obj = {};
      this.list_employees_worker = [];
      this.c_obj['close_fault'] = 2;
      this.c_obj['report_by'] = this.userinfo['name'];
    }
    
  }

  public getWorkPerm() {
    const url = environment.Global.API_GET_ALL_WORK_AUTHS_FOR_ADMIN;
    this.list_work_perm = [];
    this.srv.getCall(url).subscribe(x => {
      if(x.success){
        if(x.val != null){
          x.val.forEach(element => {
            this.list_work_perm.push(element);
          })
          this.page = 1;
          if( Array.isArray(x.val) ){
            this.pageTotal = x.val.length;
          }
        }
      }
    });
    
    
  }

  public onlyNumberKey(event: any): boolean {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  public setDataInForm() {
    // console.log(this.c_obj['worker_perm'])
    if (this.c_obj['worker_perm'] !== '0' && this.c_obj['worker_perm'] !== null) { // existe el permisod e trabajo
      this.enabled = true;
      this.hasWork = true;
      let work_perm_selected = JSON.parse(this.c_obj['worker_perm']);
      this.list_employees_worker = [];
      work_perm_selected.RequestDetails.forEach(element => {
        this.list_employees_worker.push({
          name: element.EmployeeName,
          cod: element.NumberIdentification,
          typeJob: element.JobtypeName,
          position: element.position
        })
      });
      if (work_perm_selected !== null && work_perm_selected !== undefined) {
        this.c_obj['perm_number'] = work_perm_selected['WorkAuthId'];
        this.c_obj['level'] = work_perm_selected['PlantaName'];
        this.c_obj['levelCode'] = work_perm_selected['PlantaID'];
        this.c_obj['dateActInsecure'] = this.globalService.formatOnlyDate(this.fechaActual);
        this.c_obj['company'] = work_perm_selected['name'];
      } else {
        this.c_obj['NumberIdentification'] = null;
        return Swal.fire(this.translations.should_select_perm_work, '', 'error');
      }
      this.setLevelArea();
    } else if(this.c_obj['worker_perm'] === '0') { // no existe el permiso de trabajo
      this.list_employees_worker = [];
      this.hasWork = false;
      this.enabled = false; 
      this.c_obj['NumberIdentification'] = null;
      this.c_obj['NameEmployee'] = null;
      this.c_obj['perm_number'] = null;
      this.c_obj['level'] = null;
      this.c_obj['levelCode'] = null;
      this.c_obj['dateActInsecure'] = this.globalService.formatOnlyDate(this.fechaActual);
      this.c_obj['company'] = null;
    } else if (this.c_obj['worker_perm'] === null){
      this.list_employees_worker = [];
      this.hasWork = false;
      this.enabled = false; 
      this.c_obj['NumberIdentification'] = null;
      this.c_obj['NameEmployee'] = null;
      this.c_obj['perm_number'] = null;
      this.c_obj['level'] = null;
      this.c_obj['levelCode'] = null;
      this.c_obj['dateActInsecure'] = this.globalService.formatOnlyDate(this.fechaActual);
      this.c_obj['company'] = null;
    } 
  }

  public GetEmployeeforIdentification(obj:any,val:any) {
    var qst  = "";
    if (obj === "" || obj == " ")
    {
      return;
    }
    if (val == 1) { // empleado interno
      qst = API_GET_EMPLOYEE_INTERNAL_BY_NUMBERIDENTIFICATION + obj;
      this.srv.getCall(qst).subscribe(x => {
        // this.loadingList = false; 0908546328
        if(x.success){
          if(x.val != null){
            this.c_obj['code'] = x.val['CodeEmployee'];
            this.c_obj['position'] = x.val['Position'];            
            this.c_obj['EmployeeId'] = x.val['EmployeeId'];
            this.c_obj['dateActInsecure'] = this.globalService.formatOnlyDate(new Date())
            this.c_obj['NameEmployee'] = x.val['Name'] + ' ' +x.val['LastName'];
          } else {
            Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
            return;
          }
        }else {
          Swal.fire(x.msg, '', 'error');
          return;
        }
        this.getEmployee = true;
        if (this.getEmployee) {
          this.GetFoulByEmployee(obj);
        }
      });
    } else { // empleado externo
      qst =  GET_EMPLOYEE_BY_IDENTIFICATION + obj;
      this.srv.getCall(qst).subscribe(x => {
        // this.loadingList = false;
        if(x.success){
          if(x.val != null){
            this.c_obj['NameEmployee'] = x.val['Name'];
            this.c_obj['EmployeeId'] = x.val['EmployeeId'];
            this.c_obj['namesubcompany'] = x.val['namesubcompany'];
            this.c_obj['position'] = x.val['position'];
            this.c_obj['dateActInsecure'] = this.globalService.formatOnlyDate(new Date())
            this.c_obj['company'] = x.val['namecompany'];
            this.c_obj['CompanyID'] = x.val['CompanyID'];
          } else {
            Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
            return;
          }
        }else {
          Swal.fire(x.msg, '', 'error');
          return;
        }
      });
      this.getEmployee = true;
      if (this.getEmployee) {
        this.GetFoulByEmployee(obj);
      }
    }
    
    
  }

  public GetFoulByEmployee (ci: string) {
    let new_obj = { //0908546328
      id: ci,
      typeUser : this.workerId == 1? 1:2
    }
    var qst =  API_UNSAFEACT_FOULS;
    this.srv.postCall(qst, new_obj).subscribe(x => {
      if(x.success){
        if(x.val != null){
          this.listfouls = x.val;
        } else {
          return;
        }
      }else {
        return;
      }
    });
  }

  public getTypeFouls () {
    let url = API_CONSULTATYPEFALTA;
    this.srv.getCall(url).subscribe(x => {
      if(x.success){
        if(x.val != null){
          // console.log('FALTAS')
          // console.log(x.val)
          this.list_fouls = x.val
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          return;
        }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public getFouls(falta_id) {
    if (falta_id !== null){
      this.c_obj['fault_associated'] = null;
      let url = API_CONSULTAFALTADETAIL;
      let id = {FoulTypeID: falta_id};
      this.srv.postCall(url, id).subscribe(x => {
        if(x.success){
          if(x.val != null){
            this.list_fouls_detail = x.val;
          }else {
            Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
            return;
              }
        }else {
          Swal.fire(x.msg, '', 'error');
          return;
            }
      });
      this.getSanction(falta_id);
    } else {
      this.c_obj['fault_associated'] = null;
      this.c_obj['type_sanction'] = null;
    }
  }

  public getSanction(falta_id) {
    let url = API_SANCION;
    let id = {FoulTypeID: falta_id};
    this.srv.postCall(url, id).subscribe(x => {
      if(x.success){
        if(x.val != null){
          // console.log('SANCIONES ASOCIADAS')
          // console.log(x.val)
          this.list_sanction = x.val;
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
          }
    });
    setTimeout(()=>{
      this.setFoulsAndSanction(falta_id);
    }, 2000);
  }

  public setdescriptionFoul() {
    this.list_fouls_detail.forEach(element => {
      // console.log(element)
      if (element.ParaDetaFoulID == this.c_obj['fault_associated']) {
        this.descriptionFoul = element.Descripcion
      }
    })
  }

  public setFoulsAndSanction(falta_id) {
    this.contadorFaltas = 0;
    // console.log(falta_id)
    this.listfouls.forEach(element => {
      // console.log(element)
      if (element.typefouls == falta_id) {
        // console.log('entro')
        this.contadorFaltas = element.quantity + 1;
      }
    })
    // en caso de que la falta asociada sea 0
    if (this.contadorFaltas == 0) {
      this.contadorFaltas = 1;
    }

    if (this.contadorFaltas > this.list_sanction.length) {
      this.c_obj['type_sanction'] = this.list_sanction[this.list_sanction.length - 1].SanctionID;
    } else if (this.contadorFaltas <= this.list_sanction.length) {
      this.c_obj['type_sanction'] = this.list_sanction[this.contadorFaltas - 1].SanctionID;
    }
  }

  public handleInputChange(e){
    this.archivos=[];
    var file = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    let files = Object.values(file);
    files.forEach(element => {
      if (this.archivos.length < 2) {
        this.archivos.push(element);
      } else {
        return Swal.fire(this.translations.only_two_imgs,'','error');
      }
    });
  }

  public searchArea() {
    // console.log(this.c_obj['level'])
    this.setLevelArea();
  }

  public setLevelArea() {
    // valido si es un trabajador externo y si tiene permiso
    // console.log(this.c_obj['levelCode']);
    // console.log(this.c_obj['level']);
    var qst = GET_ALL_AREA_BY_ID + ((this.c_obj['levelCode'] !== null && this.c_obj['levelCode'] !== undefined) ? this.c_obj['levelCode'] : this.c_obj['level']);
    // console.log(qst)
    this.srv.getCall(qst).subscribe(x => {
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.row_plantas_area = rs;
          // console.log(rs);
        }else {
          this.row_plantas_area = [];
          return;
        }
      }else {
        return;
          }
    });
  }

  public changeValueFoult() {
    if(this.c_obj['close_fault'] == 2) {
      this.c_obj['samp_comment'] = null;
    }
  }

  public changeValueFoultUpdate() {
    if(this.u_obj['StatusInsp'] == 2) {
      this.u_obj['Commentary'] = null;
    }
  }

  public async createItem(modal, object) {
    let validated = this.validateCreateInfo(object);
    if( !validated ){
      this.isCreating = false;
      Swal.fire(this.translations['msg_all_required'], '', 'error');      
      return;
    }
    if (this.workerId == 2) {
      // se valida si es empleado externo
      if (this.c_obj['user_sol'] == undefined) {  
        this.isCreating = false;
        Swal.fire(this.translations['msg_all_required'], '', 'error');      
        return;
      }
      if (this.c_obj['email_sol'] == undefined) {  
        this.isCreating = false;
        Swal.fire(this.translations['msg_all_required'], '', 'error');      
        return;
      }
    } else if (this.workerId == 1) {
      // se valida si es empleado interno
      if (this.c_obj['boss_inme'] == undefined) {  
        this.isCreating = false;
        Swal.fire(this.translations['msg_all_required'], '', 'error');      
        return;
      }
      if (this.c_obj['mail_boss'] == undefined) {  
        this.isCreating = false;
        Swal.fire(this.translations['msg_all_required'], '', 'error');      
        return;
      }
    }

    if (this.archivos.length == 0) {
      return Swal.fire(this.translations.should_upload_imgs, '','error');
    }
    
    if(object['close_fault'] == 1 && object['samp_comment'] == null) {
      return Swal.fire(this.translations.add_comment_act, '','error');
    }

    let codePlant = 0;
    if (this.c_obj['worker_perm'] == 0) {
      codePlant = object['level'];
    } else if (this.c_obj['worker_perm'] != 0 && this.c_obj['worker_perm'] !== undefined) {
      codePlant = object['levelCode'];
    } else {
      codePlant = object['level'];
    }
    let new_obj = {
      WorkerTypeId: Number(this.workerId),
      WorkAuthId: object['perm_number'] == null ? null: object['perm_number'],
      EmployeeId: object['EmployeeId'],
      PlantaID: codePlant == 0 ? this.c_obj['level']: codePlant,
      SeccionID: object['cmp_area'],
      FoulTypeID: object['fault_type'],
      ParaDetaFoulID: object['fault_associated'],
      SanctionID: object['type_sanction'],
      Photos: '',
      StatusInsp: object['close_fault'],
      Commentary: object['samp_comment'] == null ? null : object['samp_comment'],
      Reportby: this.userinfo['logon'],
      Requiredby: object['user_sol'] != undefined ? object['user_sol'] : null,
      RequiredbyMail: object['email_sol'] != undefined ?  object['email_sol'] : null,
      CreatorUser: this.userinfo['logon'],
      ResponsableUser: object['responsableUser'],
      BossInmediatly : this.c_obj['boss_inme'] != undefined ? this.c_obj['boss_inme'] : null,
      MailBoss : this.c_obj['mail_boss'] != undefined ? this.c_obj['mail_boss'] : null,
      CodeEmployee: this.c_obj['code'] != undefined ? this.c_obj['code'] : null
    }
    // console.log(new_obj)
    this.srv.postCall(API_CREATE_UNSAFEACT, new_obj).toPromise().then( async (data)=> {
      this.isCreating = true;
      if(data.success){
        this.flag = false;
        await this.sendPhotos(data);
        await this.createReportToSend(data.val['UnsafeActId']);
        Swal.fire(this.translations.msg_create,'', 'success');
        this.getList();
        this.isCreating = false;
        this.c_obj = {};
        this.closeModal(modal);
      } else {
        this.isCreating = false;
        Swal.fire(data.msg, '', 'error');
      }      
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
      this.isCreating = false;
    });
  }

  public async sendPhotos(data?) {
    // console.log(data)
    let formData = new FormData();
    for(let file of this.archivos) {
      formData.append(file.name, file);
    }
    formData.append('CompanyID', this.c_obj['CompanyID']);
    formData.append('TypeWorker', this.workerId == 1 ? 'Interno': 'Externo');
    formData.append('UnsafeActId', data.val.UnsafeActId);
    // console.log(formData);
    await this.srv.AddFiles(API_UPLOAD_FILE_UNSAFEACT, formData).toPromise().then(result => {
      if (result=="OK") {
        this.archivos=[];
        // Swal.fire(this.translations.evidence, result, 'success');     
        // this.getList();
      }
      else{
        Swal.fire(this.translations.evidence, result, 'error');
        this.archivos=[];
        // this.getList();
      }
    });
  }

  public GetPlantas() {
    var qst = GET_ALL_SITES_ACTIVE;
    // console.log(qst)
    this.srv.getCall(qst).subscribe(x => {
      this.row_plantas = [];
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.row_plantas = rs;
        }else {
          this.row_plantas = [];
          return;
        }
      }else {
        return;
          }
    });
  }

  public validateCreateInfo(fields:any): boolean {
    let internalWorker:any = {};
    internalWorker = new FormGroup({
        'NumberIdentification': new FormControl(fields.NumberIdentification, [Validators.required]),
        // 'user_sol': new FormControl(fields.user_sol, [Validators.required]),
        // 'email_sol': new FormControl(fields.email_sol, [Validators.required]),
        'cmp_area': new FormControl(fields.cmp_area, [Validators.required]),
        'report_by': new FormControl(fields.report_by, [Validators.required]),
        'fault_type': new FormControl(fields.fault_type, [Validators.required]),
        'fault_associated': new FormControl(fields.fault_associated, [Validators.required]),
        'type_sanction': new FormControl(fields.type_sanction, [Validators.required]),
        'responsableUser': new FormControl(fields.responsableUser, [Validators.required])
    });
    return internalWorker.valid;
  }

  public async deleteInsp (row) {
    await this.verifyExistPlan(row);
    if (this.existePlan) {
      return Swal.fire( this.translations.have_actionplan , this.translations.you_have_action_plan,'error');
    } else {
      let obj = {
        UnsafeActId : row.UnsafeActId,
        LastModifierUser: this.userinfo['logon'],
        Status : 3
      }
      this.srv.postCall(API_UPDATE_UNSAFEACT_STATUS, obj).toPromise().then( data =>{
        if(data.success){
          this.flag = false;
          Swal.fire(this.translations.msg_update, '', 'success');
          this.getList();
        }else {
          Swal.fire(data.msg, '', 'error');
        }    
      },catchError =>{
        Swal.fire(catchError.message, '', 'error');
      });
    } 
  }


  public edit(modal, row) {
    // this.u_obj = {};
    this.u_obj = row;
    this.u_obj['evidencia'] = [];
    this.u_obj['ResponsableUserNew'] = row.ResponsableUser;
    this.u_obj['PlantaIDNew'] = row.PlantaID;
    this.u_obj['SeccionIDNew'] = row.SeccionID;
    this.u_obj['Reportby'] = this.userinfo['name'];
    row._Evidences.forEach(element => {
      this.u_obj['evidencia'].push(element.imagen);
    });
    this.userSystemList.forEach(element => {
      element.Username == row.ResponsableUser && (this.u_obj['ResponsableName'] = element.FirstName + ' ' + element.LastName);
    })
    // console.log(this.u_obj)
    this.setLevelAreaUpdate(row.PlantaID);
    this.modalService.open(modal,  { backdrop: 'static',  keyboard : false, size: 'lg' });
    this.verifyExistPlan(row)
  }

  public updateItem(modal) {
    this.isUpdating = true;
    let obj = {
      UnsafeActId: this.u_obj.UnsafeActId,
      LastModifierUser: this.userinfo['logon'],
      PlantaID: this.u_obj['PlantaID'] != this.u_obj['PlantaIDNew'] ? this.u_obj['PlantaIDNew'] : this.u_obj['PlantaID'],
      SeccionID: this.u_obj['SeccionID'] != this.u_obj['SeccionIDNew'] ? this.u_obj['SeccionIDNew'] : this.u_obj['SeccionID'],
      ResponsableUser: this.u_obj['ResponsableUser'] != this.u_obj['ResponsableUserNew'] ? this.u_obj['ResponsableUserNew']: this.u_obj['ResponsableUser']
    }
    this.srv.postCall(API_UPDATE_UNSAFEACT, obj).toPromise().then( data =>{
      if(data.success){
        this.u_obj = {};
        this.flag = false;
        Swal.fire(this.translations.msg_update, '', 'success');
        this.isUpdating = false;
        this.getList();
        this.closeModal(modal);
      } else {
        this.isUpdating = false;
        Swal.fire(data.msg, '', 'error');
      }       
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
      this.isUpdating = false;
    });
  }

  public async createReportToSend(id) {
    let foult = '', descriptionFoul = '', elemento = {}, sanction = '';
    this.list_fouls.forEach(element => {
      if (element.FoulTypeID == this.c_obj['fault_type']) {
        foult = element.NameFoul;
        descriptionFoul = element.Descripcion;
        elemento = element;
      }
    })

    this.list_sanction.forEach(element =>{
      if (element.SanctionID == this.c_obj['type_sanction']) {
        sanction = element.NameSan;
      }
    });
    const pdf = new PdfMakeWrapper();
    pdf.info({
      title: `${this.c_obj['company'] + ' - ' + this.c_obj['NameEmployee']}`,
      author: `SKRETTING`,
      subject: 'Notificación de incumplimiento',
    });
    //OPTIONS
    pdf.defaultStyle({
      bold: false,
      fontSize: 10,
      alignment:'justify'
    });
    pdf.pageMargins([ 60, 110, 60, 110]);

    pdf.background(
      [
        await new Img('../../../../assets/images/Skretting-logo.png').absolutePosition(405, 35).width(160).build()
      ]
    )

    pdf.add(
      [
        new Stack([
          new Txt('NOTIFICACION DE INCUMPLIMIENTO').margin([0,10,0,5]).fontSize(13).bold().alignment('center').end,
          new Txt('PERSONAL TERCERO').margin([0,0,0,25]).fontSize(13).alignment('center').bold().end,
        ]).margin([0,25,0,0]).end,
        new Columns([new Txt([new Txt('DE: ').end,new Txt('GISIS S.A.').end]).margin([0,0,0,5]).bold().fontSize(10).end]).end,
        new Columns([new Txt([new Txt('PARA: ').end,
                    new Txt(this.c_obj['boss_inme'] == undefined ? `${this.c_obj['company']}`: `${this.c_obj['boss_inme']}`).end])
                    .margin([0,0,0,5])
                    .bold()
                    .fontSize(10)
                    .end]
                  ).end,
        new Columns([new Txt([new Txt('ASUNTO: ').end,new Txt(`${foult}`.toUpperCase()).end]).margin([0,0,0,5]).bold().fontSize(10).end,]).end,
        new Columns([new Txt([new Txt('FECHA: ').end,new Txt(`${this.globalService.formatOnlyDate(this.fechaActual)}`).end]).bold().fontSize(10).end]).margin([0,0,0,25]).end,
        new Txt(
          [
            new Txt('Por medio de la presente se da conocimiento la sanción al (los) Sr. (a)(s)').end,
            new Txt(this.c_obj['NameEmployee'].toUpperCase()).bold().end,
            new Txt(', con cédula de identidad ').end,
            new Txt(this.c_obj['NumberIdentification']).bold().end,
            new Txt(' como consecuencia de la ').end,
            new Txt(foult.toUpperCase()).bold().end,
            new Txt(' las normas establecidas de HSE en la empresa GISIS S.A., la misma que corresponde a:').end,
          ]
        ).margin([0,0,0,20]).end,
        new Txt(this.descriptionFoul.toUpperCase()).italics().margin([0,0,0,35]).bold().end,
        new Txt(
          [
            new Txt('La cual corresponde a la ').end,
            new Txt(this.contadorFaltas.toString() + '° ' + foult.toUpperCase() + ': ').bold().end,
            new Txt(sanction.toUpperCase()).end,
          ]
        ).margin([0,0,0,35]).end,
        new Txt("Con este documento se deja constancia del incumplimiento y a su vez se espera su compromiso de evitar reincidencias/sanciones en nuestras instalaciones")
        .margin([0,0,0,20])
        .end,
        new Txt("Atentamente").margin([0,0,0,10]).bold().end,
        // new Txt(this.userinfo['name'].toUpperCase()).end,
        new Txt(('Departamento HSE').toUpperCase()).end,
        new Txt(('GISIS S.A.')).end,
      ]
    )

    // pdf.create().open();
    pdf.create().getBase64(base64 => {
      // console.log(base64)
      var to:any;    
      to = {};
      to['_id'] = id;
      to['_pdfbase64'] = base64;
      let qst = API_UNSAFE_SEND_MAIL;
      this.srv.postCall(qst ,to).subscribe(x => {
        Swal.close();
      }, err => {
        Swal.close();
        Swal.fire('Error');
      });
    })
  }

  public validatePlan(): boolean{
    return !this.permissions.includes('SI_PLANACTION');
  }

  public searchAreaUpdate() {
      // console.log(this.u_obj['PlantaID'])
      this.setLevelAreaUpdate(this.u_obj['PlantaID']);
  }

  public setLevelAreaUpdate(plantaId) {
    var qst = GET_ALL_AREA_BY_ID + plantaId;
    this.srv.getCall(qst).toPromise().then(x => {
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.row_plantas_area = rs;
        }else {
          this.row_plantas_area = [];
          return;
        }
      }else {
        return;
          }
    });
  }

 
  public async closeIncidence(row) {
    await this.verifyExistPlan(row);
    if (this.existePlan) {
      return Swal.fire( this.translations.have_actionplan , this.translations.you_have_action_plan,'error');
    } else {
      Swal.fire({
        title: this.translations.are_you_sure,
        text: this.translations.text_comment,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#003d6a',
        cancelButtonColor: '#C8102E',
        confirmButtonText: this.translations.close_incidence,
        cancelButtonText: this.translations.cancel,
        input: 'text',
        allowOutsideClick: () => Swal.isLoading(),
        preConfirm: (value) => {
          if(value == '') {
            Swal.showValidationMessage(
              this.translations.should_add_comment
            )
          }
        }
      }).then((result) => {
        if (result.value) {
          let obj = {
            UnsafeActId: row['UnsafeActId'],
            LastModifierUser: this.userinfo['logon'],
            Commentary: result.value
          }
          this.srv.postCall(API_UPDATE_UNSAFEACT, obj).toPromise().then( data =>{
              if(data.success){
                this.flag = false;
                Swal.fire(this.translations.msg_update, '', 'success');
                this.getList();
              } else {
                Swal.fire(data.msg, '', 'error');
              }       
            },catchError =>{
              Swal.fire(catchError.message, '', 'error');
            });
        } 
      })
    }
   
  }

  public async verifyExistPlan(row) {
    let obj = {
      idInspection : row.UnsafeActId,
      justification : "Acto inseguro" 
    }
    await this.srv.postCall(API_EXIST_PLAN_ACTION, obj).toPromise().then( data =>{
      if(data.success){
        if (data.val == null) {
          this.existePlan = false;
          this.isEditable = true;
        } else {
          if (data.val.length > 0 || row['StatusInsp'] == 1 ) {
            this.existePlan = true;
            this.isEditable = false;
          } else {
            this.isEditable= false;
            this.existePlan = false;
          }
        }
      } else {
        Swal.fire(data.msg, '', 'error');
      }       
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
    });
  }

}