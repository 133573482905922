import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from 'src/app/services/authentication/login.service';

@Component({
  selector: 'app-audits',
  templateUrl: './audits.component.html',
  styleUrls: ['./audits.component.css']
})
export class AuditsComponent implements OnInit {

  translations:any;
  available_languages:any;
  currlang:any;
  version:any;
  items:any;
  permissions = [];
  userinfo = [];
  isSkretting = false;
  object;

  constructor(
    public _router: Router,
    public translate: TranslateService,
    public auth: LoginService,) { }

  ngOnInit() {
    this.items = [
      {title: 'menu_hse', url: '/hse', icon: 'mdi mdi-adjust', perm: 'SI_AUDITHSE'},
      {title: 'menu_plan_action_audits', url: '/planActionAudits', icon: 'mdi mdi-adjust', perm: 'SI_PLANACTIONAUDITS'},
      {title: 'menu_action_per_plan', url: '/actionplanAudits', icon: 'mdi mdi-adjust', perm: 'SI_ACTIONPERPLANAUDIT'},
      {title: 'menu_cronograma', url: '/cronograma', icon: 'mdi mdi-adjust', perm: 'SI_CRONOGRAMA'},//'HA_SAMPLING_INPUT'},
      {title: 'menu_charla', url: '/charla',icon: 'mdi mdi-adjust', perm:"SI_CHARLAS"},
      {title: 'menu_charlaAudits', url: '/charlaAudits',icon: 'mdi mdi-adjust', perm:"SI_CHARLASAUDITS"},
      {title: 'menu_calificaciones', url: '/calificaciones',icon: 'mdi mdi-adjust', perm:"SI_CALIFICATION"},
    ];
    
    this.auth.getPermissions().then(x => {
      this.permissions = x;
    });

    
    
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';
  }
  hasPermission(str:string){
    if( this.permissions.indexOf(str) > -1 ){
      return true;
    }
    else{
      return false;
    }
  }

}
