import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseCrudComponent } from 'src/app/ic2/base-crud/base-crud.component';
import { ApiService } from 'src/app/services/main/api.service';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/services/authentication/login.service';
import Swal from 'sweetalert2';

const API_USER_SYSTEM_GET_ALL = environment.Global.API_USER_SYSTEM_GET_ALL;
const API_GET_ALL_HSE_BY_STATUS = environment.Global.API_GET_ALL_HSE_BY_STATUS;

const API_CREATE_PLANACTION_PLANAUDIT = environment.Global.API_CREATE_PLANACTION_PLANAUDIT;
const API_UPDATE_PLANACTION_PLANAUDIT = environment.Global.API_UPDATE_PLANACTION_PLANAUDIT;
const API_ALL_PLANACTION_PLANAUDIT = environment.Global.API_ALL_PLANACTION_PLANAUDIT;
const API_GET_PLANACTION_BY_USER_PLANAUDIT = environment.Global.API_GET_PLANACTION_BY_USER_PLANAUDIT;
const API_UPDATE_APPROVE_REJECT_ACTION_AUDIT = environment.Global.API_UPDATE_APPROVE_REJECT_ACTION_AUDIT;
const API_DELETE_PLAN_ACTION_PLANAUDIT = environment.Global.API_DELETE_PLAN_ACTION_PLANAUDIT;
const API_CLOSE_PLAN_ACTION_PLANAUDIT = environment.Global.API_CLOSE_PLAN_ACTION_PLANAUDIT;
const API_GET_HSE_IFNOTEXIST_PLAN_BY_USER = environment.Global.API_GET_HSE_IFNOTEXIST_PLAN_BY_USER;
const API_GET_HSE_IFNOTEXIST_PLAN_BY_ALL = environment.Global.API_GET_HSE_IFNOTEXIST_PLAN_BY_ALL;

const API_GET_ALL_HSE_WITH_NOAPPLYQUESTION_USER = environment.Global.API_GET_ALL_HSE_WITH_NOAPPLYQUESTION_USER;
const API_GET_ALL_HSE_WITH_NOAPPLYQUESTION = environment.Global.API_GET_ALL_HSE_WITH_NOAPPLYQUESTION;

@Component({
  selector: 'app-plan',
  templateUrl: './plan-audits.component.html',
  styleUrls: ['./plan-audits.component.css']
})
export class PlanAuditsComponent extends BaseCrudComponent implements OnInit {

  listaPreguntasPorHse = []
  listaAcciones = [];
  newActionsList = [];
  listaHse = [];
  userinfo:any;
  permissions;
  hseSelected = null;
  hseInfo = {};
  preguntaSelected = "";
  userSystemList;
  idPregunta = null;
  pageTotal2 = 0;
  pageTotal3 = 0;
  pageTotal4 = 0;
  page3 = 0;
  page2 = 0;
  page4 = 0;
  pageSize3;
  pageSize4;
  plantaName = null;
  seccionName = null;
  close_object: any;
  isClosed = false;
  closeResult;
  isApproved = false;
  isRejected = false;
  contElimiActions = 0;
  isClosing = false;
  accionporplan;
  evidencias = [];
  modalReference: NgbModalRef;
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private auth : LoginService
  ) {
    super(srv, modalService, storage, router);
  }

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
          this.translations = translations;
          super.ngOnInit();
    });
    this.storage.get('userinfo').then(x => {
      this.userinfo = x;
      
    });
    
    
    this.pageSize2 = 3;
    this.pageSize3 = 3;
    this.pageSize4 = 3;
    this.page2 = 1;
    this.page3 = 1;
    this.page4 = 1;

    this.auth.getPermissions().then(x => {
      this.permissions = x;
      this.getList();
    });
  }

  public openCreateModal(modal: any): void {
    this.isClosed = false;
    this.c_obj = {};
    this.modalService.open(modal, { backdrop: 'static',  keyboard : false, size: "lg" });
    this.hseSelected = {};
    this.getUserSystem();
    
    this.getHSE();
  }

  public getList() {
    this.loadingList = true;
    if (this.permissions.includes('SI_ADMIN')) {
      this.srv.getCall(API_ALL_PLANACTION_PLANAUDIT).subscribe(x => {
        this.loadingList = false; 
        if(x.success){
          if(x.val != null){
            this.rawdata = x.val;
            this.rows = this.rawdata;
            this.page = 1;
            // console.log(this.rows)
            if( Array.isArray(this.rows) ){
              this.pageTotal = this.rows.length;
            }
            this.filterAll();        
          }else {
            Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
            this.rows = [];
            return;
              }
        }else {
          Swal.fire(x.msg, '', 'error');
          return;
        }
      });
    } else {
      this.srv.getCall(API_GET_PLANACTION_BY_USER_PLANAUDIT + this.userinfo['logon']).subscribe(x => {
        this.loadingList = false; 
        if(x.success){
          if(x.val != null){
            this.rawdata = x.val;
            this.rows = this.rawdata;
            this.page = 1;
            if( Array.isArray(this.rows) ){
              this.pageTotal = this.rows.length;
            }
            this.filterAll();    
          }else {
            Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
            this.rows = [];
            return;
              }
        }else {
          Swal.fire(x.msg, '', 'error');
          return;
        }
      });
    }   
  }


  public getUserSystem() {
    this.userSystemList = [];
    this.srv.getCall(API_USER_SYSTEM_GET_ALL).subscribe(x => {
      if(x.success){
        if(x.val != null){
          x.val.forEach(element => {
            element.Permissions = JSON.parse(element.Permissions);
            if (element.Permissions != null) {
              element.Permissions.Modules.forEach(permission => {
                if(permission.Name == "SI_OPORTUNITEVALEXT") {
                  this.userSystemList.push(element)
                }
              });
            }    
          })
        }else {
          Swal.fire(this.translations.empty, this.translations.not_user_registers, 'success');
          this.userSystemList = [];
          return;
        }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public async getHSE() {
    this.listaHse = [];
    if (this.isClosed) {
      if (this.permissions.includes('SI_ADMIN')) {
        let url = API_GET_ALL_HSE_WITH_NOAPPLYQUESTION;
        await this.srv.getCall(url).toPromise().then(x => {
          if(x.success){
            if(x.val != null){
              var rs = x.val;
              rs.forEach(element => {
                element['type'] = 'HSE';
              })
              // console.log(rs)
              this.listaHse = rs;
            }else {
              this.listaHse = [];
              return;
            }
          }else {
            this.listaHse = [];
            return;
          }
        });
      } else {
        let url = API_GET_ALL_HSE_WITH_NOAPPLYQUESTION_USER + this.userinfo['logon'];
        await this.srv.getCall(url).toPromise().then(x => {
          if(x.success){
            if(x.val != null){
              var rs = x.val;
              rs.forEach(element => {
                element['type'] = 'HSE';
              })
              this.listaHse = rs;
            }else {
              this.listaHse = [];
              return;
            }
          }else {
            this.listaHse = [];
            return;
          }
        });
      }
    } else {
      if (this.permissions.includes('SI_ADMIN')) {
        let url = API_GET_HSE_IFNOTEXIST_PLAN_BY_ALL;
        await this.srv.getCall(url).toPromise().then(x => {
          if(x.success){
            if(x.val != null){
              var rs = x.val;
              rs.forEach(element => {
                element['type'] = 'HSE';
              })
              // console.log(rs)
              this.listaHse = rs;
            }else {
              this.listaHse = [];
              return;
            }
          }else {
            this.listaHse = [];
            return;
          }
        });
      } else {
        let url = API_GET_HSE_IFNOTEXIST_PLAN_BY_USER + this.userinfo['logon'];
        await this.srv.getCall(url).toPromise().then(x => {
          if(x.success){
            if(x.val != null){
              var rs = x.val;
              rs.forEach(element => {
                element['type'] = 'HSE';
              })
              // console.log(rs)
              this.listaHse = rs;
            }else {
              this.listaHse = [];
              return;
            }
          }else {
            this.listaHse = [];
            return;
          }
        });
      }
    }
    
  }


  public setQuestionsHse() {
    this.listaPreguntasPorHse = [];
    if (this.isClosed) {
      if (this.hseSelected != null ) {
        this.plantaName = this.hseSelected.plantaName;
        this.seccionName = this.hseSelected.seccionName;
        this.hseSelected.hseDetails.forEach(preguntas => {
          if (preguntas.Answer == '2') { // se almacenan todas las que no cumple
            this.listaPreguntasPorHse.push({
              Descripcion: preguntas.Description,
              PreguntaID: preguntas.PreguntaID
            })
          }
        });
      } else {
        this.listaPreguntasPorHse = [];
        this.close_object['Pregunta'] = null;
        this.preguntaSelected = null;
        this.idPregunta = null;
        this.hseSelected = null;
      }
    } else {
      if (this.hseSelected != null ) {
        this.plantaName = this.hseSelected.plantaName;
        this.seccionName = this.hseSelected.seccionName;
        this.hseSelected.hseDetails.forEach(preguntas => {
          if (preguntas.Answer == '2') { // se almacenan todas las que no cumple
            this.listaPreguntasPorHse.push({
              Descripcion: preguntas.Description,
              PreguntaID: preguntas.PreguntaID
            })
          }
        });
      } else {
        this.listaPreguntasPorHse = [];
        this.c_obj['Pregunta'] = null;
        this.preguntaSelected = null;
        this.idPregunta = null;
        this.hseSelected = null;
        this.plantaName = null;
        this.seccionName = null;
      }
    }
  }
  
  public setQuestionInTextArea() {
    if (this.isClosed) {
      if (this.close_object['Pregunta'] != undefined && this.close_object['Pregunta'] != null) {
        this.preguntaSelected = this.close_object['Pregunta'].Descripcion;
        this.idPregunta = this.close_object['Pregunta'].PreguntaID;
      } else {
        this.preguntaSelected = null;
        this.idPregunta = null;
      }
    } else {
      if (this.c_obj['Pregunta'] != undefined && this.c_obj['Pregunta'] != null) {
        this.preguntaSelected = this.c_obj['Pregunta'].Descripcion;
        this.idPregunta = this.c_obj['Pregunta'].PreguntaID;
      } else {
        this.preguntaSelected = null;
        this.idPregunta = null;
      }
    }    
  }

  public setEndDate () {
    if (this.isClosed) {
      this.close_object['date_fini'] = this.close_object['date_planea_end'];
    } else {
      this.c_obj['date_fini'] = this.c_obj['date_planea_end'];
    }
  }

  public addToTable(obj) {
    if (this.isClosed) {
      const indice = this.listaAcciones.length + 1;
      if (obj.date_planea_init == null ||
        obj.date_planea_end == null || 
        obj.responsable == null ||
        obj.actionCon == null ||
        obj.detailEject == null ||
        obj.Pregunta == null) {
        return Swal.fire(this.translations['msg_all_required'], '', 'error');
      }
      let new_acction = {
        Nro: indice,
        PreguntaId: obj.Pregunta.PreguntaID,
        Responsable: obj.responsable.Email,
        UserName: obj.responsable.Username,
        Action: obj['actionCon'],
        Details: obj['detailEject'],
        Observation: obj['observation'],
        InitDate: obj['date_planea_init'],
        PlanEndDate: obj['date_planea_end'],
        PlanProgDate: null,
        PlanProgDate2: null,
        EndDate: obj['date_planea_end'],
        DateApproved: null,
        StatusEvidence: 'P',
        ResponsableName: obj.responsable.FirstName + ' ' + obj.responsable.LastName,
        StatusPlanDetail: 'A'
      }
      if(this.newActionsList.length > 0) {
        let cont = 0;
        this.newActionsList.forEach(element => {
          if(element.Action === new_acction['Action']) {
            cont = 1;
          }
        })
        if(cont == 0) {
          this.newActionsList.push(
            new_acction
          );
        } else {
          return Swal.fire(this.translations['action_repeated'], '', 'error');
        }
      } else {
        this.newActionsList.push(
          new_acction
        );
      }
  
      this.close_object['actionCon'] = null;
      this.close_object['detailEject'] = null;
      // this.c_obj['observation'] = null; La observacion no e borra porque puede ser general
      this.close_object['date_planea_init'] = null;
      this.close_object['date_planea_end'] = null;
      this.close_object['date_fini'] = null;
      this.close_object['responsable'] = null;
  
      if( Array.isArray(this.newActionsList) ){
        this.pageTotal3 = this.newActionsList.length;
      }
    } else {
      const indice = this.listaAcciones.length + 1;
      if (obj.date_planea_init == null ||
        obj.date_planea_end == null || 
        obj.responsable == null ||
        obj.actionCon == null ||
        obj.detailEject == null ||
        obj.Pregunta == null) {
        return Swal.fire(this.translations['msg_all_required'], '', 'error');
      }
      let new_acction = {
        Nro: indice,
        PreguntaId: obj.Pregunta.PreguntaID,
        Responsable: obj.responsable.Email,
        UserName: obj.responsable.Username,
        Action: obj['actionCon'],
        Details: obj['detailEject'],
        Observation: obj['observation'],
        InitDate: obj['date_planea_init'],
        PlanEndDate: obj['date_planea_end'],
        PlanProgDate: null,
        PlanProgDate2: null,
        EndDate: obj['date_planea_end'],
        DateApproved: null,
        StatusEvidence: 'P',
        ResponsableName: obj.responsable.FirstName + ' ' + obj.responsable.LastName,
        StatusPlanDetail: 'A'
      }
      
      if(this.listaAcciones.length > 0) {
        let cont = 0;
        this.listaAcciones.forEach(element => {
          if(element.Action === new_acction['Action']) {
            cont = 1;
          }
        })
        if(cont == 0) {
          this.listaAcciones.push(
            new_acction
          );
        } else {
          return Swal.fire(this.translations['action_repeated'], '', 'error');
        }
      } else {
        this.listaAcciones.push(
          new_acction
        );
      }
  
      this.c_obj['actionCon'] = null;
      this.c_obj['detailEject'] = null;
      // this.c_obj['observation'] = null; La observacion no e borra porque puede ser general
      this.c_obj['date_planea_init'] = null;
      this.c_obj['date_planea_end'] = null;
      this.c_obj['date_fini'] = null;
      this.c_obj['responsable'] = null;
  
      if( Array.isArray(this.listaAcciones) ){
        this.pageTotal2 = this.listaAcciones.length;
      }
    }
    
  }

  public createItem(modal: any, form: any) {
    this.isCreating = true;
    if (this.hseSelected == null  || 
      form['Pregunta'] == null  || 
      form['Pregunta'] == undefined ||
      this.listaAcciones.length == 0) {
      this.isCreating = false;
      return Swal.fire(this.translations['msg_all_required'], '', 'error');
    }
    let obj = {
      AuditID: this.hseSelected.HseID,
      Status: null,
      StatusPlan: null,
      TypeAudit: this.hseSelected.type,
      ActionPlanAuditDetails: this.listaAcciones,
      CreatorUser: this.userinfo['logon']
    }

    // console.log(obj)
    this.srv.postCall(API_CREATE_PLANACTION_PLANAUDIT, obj).toPromise().then(  (data)=> {
      if(data.success){
        this.c_obj = {};
        this.listaAcciones = [];
        Swal.fire(this.translations.msg_create,'', 'success');
        this.getList();
        this.isCreating = false;
        this.closeModal(modal);
      } else {
        this.isCreating = false;
        Swal.fire(data.msg, '', 'error');
      }      
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
      this.isCreating = false;
    });
  }

  public deleteAction(indice) {
    if (this.isClosed) {
      if (this.newActionsList.length > 0 && Array.isArray(this.newActionsList)) {
        let i = 0;
        for (let index = 0; index < this.newActionsList.length; index++) {
          const element = this.newActionsList[index];
          if (element.Nro == indice) {
            i = index;
          }
        }
        this.newActionsList.splice(i, 1);
        this.pageTotal3 = this.newActionsList.length;
      }
    } else {
      if (this.listaAcciones.length > 0 && Array.isArray(this.listaAcciones)) {
        let i = 0;
        for (let index = 0; index < this.listaAcciones.length; index++) {
          const element = this.listaAcciones[index];
          if (element.Nro == indice) {
            i = index;
          }
        }
        this.listaAcciones.splice(i, 1);
        this.pageTotal2 = this.listaAcciones.length;
      }
    }
    
  }

  public getColorStatusPlan() {
    let color = '';
    this.close_object['StatusPlan'] == 'is_open' ? color = '#C8102E' : color = '#005c3c';
    return color;
  }

  public async openCloseModal (content, row) {
    this.isClosed = true;
    this.getUserSystem();
    await this.getHSE();
    let obj = JSON.parse(JSON.stringify(row))
    this.close_object = obj;
    this.modalService.open(content, { backdrop: 'static', keyboard : false, windowClass: 'myCustomModalClass' });
    this.close_object['StatusPlan'] = row['StatusPlan'] == 'C'? 'is_closed' : 'is_open';
    // this.close_object['date_ha'] = obj['FindingDate'].split('T')[0];
    this.close_object['ActionPlanDetails'] = [];
    row['_ActionPlanAuditDetails'].forEach(element => {
      if (element.StatusPlanDetail != 'E') {
        this.close_object['ActionPlanDetails'].push(element)
      }
    });
    this.close_object['lengthActions'] = this.close_object['ActionPlanDetails'].length;
    if( Array.isArray(this.close_object['ActionPlanDetails']) ){
      this.pageTotal4 = this.close_object['ActionPlanDetails'].length;
    }
    
    this.listaHse.forEach(hse => {
      if (hse.type == "HSE" && hse.HseID == obj.AuditID) {
        // console.log('entro')
        this.hseSelected = hse;
        // console.log(this.hseSelected)
      }
    })

  }

  public deleteActionPerPlan (action) {
    Swal.fire({
      title: this.translations.are_you_sure,
      text: this.translations.text_comment,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#005c3c',
      cancelButtonColor: '#C8102E',
      confirmButtonText: this.translations.remove,
      input: 'text',
      allowOutsideClick: () => Swal.isLoading(),
      preConfirm: (value) => {
        if(value == '') {
          Swal.showValidationMessage(
            this.translations.should_add_comment
          )
        }
      }
    }).then((result) => {
      if (result.value) {
        action.StatusPlanDetail = 'E',
        action.CommentPlanDetail = result.value;
        this.contElimiActions += 1;
      }
    })
    // console.log(action);
    // console.log(this.close_object['ActionPlanDetails'])
  }

  public erasePlanAction(row) {
    // console.log(row)
    let obj = {
      id: row.PlanAuditID,
      user: this.userinfo['logon']
    }
    this.srv.postCall(API_DELETE_PLAN_ACTION_PLANAUDIT, obj).toPromise().then( (data)=> {
      if(data.success){
        Swal.fire(this.translations.msg_update,'', 'success');
        this.getList();
      } else {
        Swal.fire(data.msg, '', 'error');
      }      
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
    });
  }

  public verifyDeletePlan(plan) {
    var statusplan = plan.StatusPlan;
    let cont = 0;
    plan._ActionPlanAuditDetails.forEach(accion => {
      if (accion.StatusEvidence == 'AP' || accion.StatusEvidence == 'R'|| accion.StatusEvidence == 'ER') {
        cont += 1;
      }
    });
    // console.log(cont , statusplan) 
    if (cont == 0 && statusplan == 'A') {
      return true;
    } else {
      return false;
    }
  }

  public verifyClose() {
    let contador = 0;
    this.close_object['ActionPlanDetails'].forEach(element => {
      if (element.StatusEvidence == 'AP') {
        contador += 1;
      }
    });
    if (contador == this.close_object['ActionPlanDetails'].length) {
      return true;
    } else {
      return false;
    }
  }

  public updateItem(modal, object) {
    this.isUpdating = true;
    let new_obj = {
      PlanAuditID: object['PlanAuditID'],
      ActionPlanAuditDetails: object['ActionPlanDetails'],
      LastModifierUser:  this.userinfo['logon'],
      NewActionsList: this.newActionsList
    }
    // console.log(new_obj);
    this.srv.postCall(API_UPDATE_PLANACTION_PLANAUDIT, new_obj).toPromise().then(  (data)=> {
      if(data.success){
        Swal.fire(this.translations.msg_update,'', 'success');
        this.getList();
        this.isUpdating = false;
        this.closeModal(modal);
        this.newActionsList = [];
      } else {
        this.isUpdating = false;
        Swal.fire(data.msg, '', 'error');
      }      
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
      this.isUpdating = false;
    });
  }

  public closePlan(modal) {
    this.isClosing = true;
    this.srv.postCall(API_CLOSE_PLAN_ACTION_PLANAUDIT + this.close_object['PlanAuditID'], {}).toPromise().then(  (data)=> {
      if(data.success){
        this.isClosing = false;
        Swal.fire(this.translations.msg_plan_action_closed,'', 'success');
        this.getList();
        this.closeModal(modal);
      } else {
        this.isClosing = false;
        Swal.fire(data.msg, '', 'error');
      }      
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
      this.isClosing = false;
    });
  }

  public seeEvidence(modal, objeto) {
    let obj = JSON.parse(JSON.stringify(objeto))
    this.accionporplan = obj;
    this.evidencias = obj._Evidences;
    this.isRejected = false;
    this.modalReference = this.modalService.open(modal, { backdrop: 'static',  keyboard : false, size: 'lg' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });  
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  public rejectEvidence() {
    this.isRejected = true;
    let obj = {
      actionPlanDetailsId: this.accionporplan.PlanActionAuditID,
      typeUpdate: 0,
      commentRejectAction: null,
      userRejectApproved: this.userinfo['logon']
    }
    Swal.fire({
      title: this.translations.are_you_sure,
      text: this.translations.text_comment,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#005c3c',
      cancelButtonColor: '#C8102E',
      confirmButtonText: this.translations.accept,
      input: 'text',
      allowOutsideClick: () => Swal.isLoading(),
      preConfirm: (value) => {
        if(value == '') {
          Swal.showValidationMessage(
            this.translations.should_add_comment
            )
          } else {
            this.isRejected = false;
          }
        }
      }).then((result) => {
        if (result.value) {
          obj.commentRejectAction = result.value;
          this.modalReference.close();
          this.srv.postCall(API_UPDATE_APPROVE_REJECT_ACTION_AUDIT, obj).toPromise().then(  (data)=> {
            if(data.success){
              Swal.fire(this.translations.msg_update,'', 'success');
              this.getList();
              this.isRejected = false;
              this.closeModal(this.modalReference)
            } else {
              this.isApproved = false;
              Swal.fire(data.msg, '', 'error');
            }      
          },catchError =>{
            Swal.fire(catchError.message, '', 'error');
            this.isRejected = false;
          });
        }
    })
    
  }
  public approveEvidence() {
    this.isApproved = true;
    let obj = {
      actionPlanDetailsId: this.accionporplan.PlanActionAuditID,
      typeUpdate: 1,
      commentRejectAction: null,
      userRejectApproved: this.userinfo['logon']
    }
    // console.log(obj)
    this.srv.postCall(API_UPDATE_APPROVE_REJECT_ACTION_AUDIT, obj).toPromise().then(  (data)=> {
      if(data.success){
        Swal.fire(this.translations.msg_update,'', 'success');
        this.getList();
        this.isApproved = false;
        this.closeModal(this.modalReference)
      } else {
        this.isApproved = false;
        Swal.fire(data.msg, '', 'error');
      }      
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
      this.isApproved = false;
    });
  }

}
