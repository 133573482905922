import { Component, AfterViewInit, EventEmitter, Output } from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { LoginService } from '../../../services/authentication/login.service';
import { Observable } from "rxjs/Observable";
import { UserCx } from "../../../interfaces/User";
import { AuthService } from "../../../services/authentication/auth.service";
import {environment} from "../../../../environments/environment";

declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit {



  @Output() toggleSidebar = new EventEmitter<void>();
  public user$: Observable<UserCx>;
  version:any;
  public user: any;
  public userinfo: any;
  public typeUser = 0;
  public Username:string = "";

  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;

  constructor(
    private modalService: NgbModal,
    private _router: Router,
    private storage: Storage,
    private srv: LoginService,
    private _authService: AuthService
  ) {
    this.version = environment.version;
    storage.get('username').then(x => {
      this.user = x;
    });


  }

  ngOnInit():void {
    // this.user$ = this._authService.UserObservable;
    this.getUsernameLogin();

  }

 async getUsernameLogin () {

    await this.storage.get('userinfo').then(x => {
      this.Username = x.name
    });
  }
  ngAfterViewInit() { }


  public logout() {
    this.srv.clearData().then(() => {
      this._router.navigate(['/authentication/login']);
    });
  }
  public logout2() {
    this._authService.logout();
  }

}
