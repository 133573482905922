import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { isNumeric } from 'rxjs/util/isNumeric';
import { Newsubcomjob } from '../../../interfaces/newsubcomjob';
import { saveAs as importedSaveAs } from "file-saver";
//import Any = jasmine.Any;
const API_CONSDOCUMENEMP = Global.API_CONSPARAMDOCUMENT_1;
const API_DELETE_DOCUMET = environment.Global.API_DELETE_EMPLOYEE_DOCUMENT;
@Component({
  selector: 'app-regemployeedoc',
  templateUrl: './regemployeedoc.component.html',
  styleUrls: ['./regemployeedoc.component.css']
})
export class RegemployeedocComponent extends BaseCrudComponent {
  id: any;
  date_actually = new Date();
  userinfo: any;
  date_doc: any;
  archivos: any;
  namefile: any;
  mensaje = "";
  size_max = 3145728;//3mb
  cargavacia = false;
  cargados = false;
  alertMsj: any

  constructor(srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService) {
    super(srv, modalService, storage, router);
    this.id = route.snapshot.params.id;


    this.read_url = API_CONSDOCUMENEMP;
    this.delete_url_details = API_DELETE_DOCUMET;
    this.date_doc = new Date();

    this.search_fields = ['DocumentName'];

    this.c_obj['EmployeeDocument'] = [];

    this.reload.subscribe(val => {
      if (val) {
        super.clearData();
        this.GetCompanyData2();
        this.GetDocumentUpload();

        //this.getList();
      }
    });

  }

  async ngOnInit() {
    // console.log('LLEGA')
    await this.translate.getTranslation(this.translate.currentLang)
      .toPromise().then((translations) => {
        this.translations = translations;
        super.ngOnInit();
        this.getList();
        this.GetCompanyData2();
        this.GetDocumentUpload();

      });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    await this.storage.get('userinfo').then(x => {
      this.userinfo = x;
    });

  }

  public async getList() {
    // debugger
    var qst = this.read_url;
    this.loadingList = true;
    var to: any;
    to = {};
    to._IdComp = this.id;
    to._Id = 27;
    Swal.fire(
      {
        title: this.translations['loading'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    await this.srv.postCall(qst, to).subscribe(x => {
      this.loadingList = false;
      //debugger;
      if (x.success) {
        if (x.val != null) {
          var rs = x.val;
          console.log("Datos:",rs)
          this.rows = rs;

          Swal.close()
        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });

  }


  public showAlertDocuments(content: any) {

    // Consult Api
    var ID = 144 // Set ID Message, #SystemParameter
    var to: any;
    to = {};
    var par_url = "ParameterDocument/getAlertMessage" + '/' + ID;
    this.isUpdating = true;
    this.srv.getCall(par_url).subscribe(x => {
      this.isUpdating = false;
      Swal.close();
      if (x.success) {
        if (x.val != null) {
          var msj: any
          msj = x.val
          this.alertMsj = msj.value

          // Open Modal
          this.modalService.open(content, { backdrop: 'static', keyboard: false, size: 'lg', centered: true });
        }

      }
      else {
        Swal.fire(x.msg, '', 'error');
      }
    });

  }


  handleInputChange(e) {//, obj:any) {
    //debugger;
    this.archivos = [];
    //console.log("e:",e);
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    //var pattern = /image-*/;
    //var reader = new FileReader();

    const archivocapture = e.target.files[0];
    this.archivos.push(archivocapture);
    this.namefile = file.name;
    //console.log("archivo capturado:" ,archivocapture);
    //console.log("archivo array:" ,this.archivos);
  }

  async onExpSubmit(row: any) {
    if (this.archivos.length == 0) {
      Swal.fire(this.translations.document, this.translations.document_error_minqty, 'error');
      return;
    }

    var size_document = this.archivos[0].size;
    if (size_document > this.size_max) {
      Swal.fire(this.translations.document, this.translations.pdi_img_toobig, 'error');
      this.archivos = [];
      return;
    }
    if (row.date_doc == undefined || row.date_doc.trim() == "") {
      // this.isuploading = false;
      Swal.fire(this.translations.enter_date, '', 'error');
      return;
    }
    let formData = new FormData();
    formData.append('FileUpload', this.archivos[0]);
    formData.append('CompanyID', this.userinfo.selectedCompanyId);
    formData.append('EmployeeId', this.id);
    formData.append('CreatorUser', this.userinfo.logon);
    //formData.append('ParamDocumentID', this.userinfo.logon);
    await this.srv.AddFileEmployee(formData).toPromise().then(async result => {
      if (result == "OK") {
        // this.reload.next(true);
        await this.uploadDataBase(row);
      }
      else {
        Swal.fire('¡Documento!', result, 'success');
        this.archivos = [];
        this.getList();
      }
    });
  }

  public async uploadDataBase(row: any) {
    // debugger
    //console.log("datosfila:",row)
    var par_url = "EmployeeDocument/Update";
    var to: any;
    to = {};
    if (row.DocTypeWorker == true) { //documento por tipo de trabajo
      to.docuTypeID = row.TypeJob;//row.ParamDocumentID;
      to.ParamDocumentID = 0;
      to.DocumentId = row.ParamDocumentID;
    } else { //documento que NO es por tipo de trabajo
      to.ParamDocumentID = row.ParamDocumentID;
      //to.DocumentId=row.ParamDocumentID;
      //to.docuTypeID == 0;
    }

    to.EmployeeId = this.id;
    to.LastModifierUser = this.userinfo.logon;
    to.CreatorUser = this.userinfo.logon;
    to.FileName = this.namefile;
    to.DateDocument = row.date_doc;
    //to.TypeJob=row.TypeJob;
    await this.srv.postCall(par_url, to).toPromise().then(x => {
      if (x.success) {
        this.reload.next(true);
        this.closeModal('update5');
        Swal.fire(this.translations.msg_update, x.msg, 'success');
        this.archivos = [];
        this.getList();
      }

    }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }

  public GetDocumentUpload() {

    var par_url = "EmployeeDocument/GetDocumentsId/" + this.id;
    var to: any;
    to = {};
    to.EmployeeId = this.id;
    this.srv.postCall(par_url, to).subscribe(x => {
      this.loadingList = false;
      if (x.success) {
        if (x.val.length != 0) {
          var rs = x.val;
          console.log("Documentos cargados:",rs)
          this.rowdocumentupload = rs;
          //if (this.u_obj["Status_doc"]=="A" ){
          if (rs[0].status_doc == "A" || rs[0].status_doc == "R") {
            this.cargados = true;
          }
          else { this.cargados = false; }
          if(rs[0].ParamDocumentID == 107) {
            // eliminamos el documento con ID 107, correspondiente al IESS
            // this.rows = this.rows.filter(element => !(element.ParamDocumentID === 107 && element.isRecurrent === false));
            this.rows = this.rows.filter(element => !(element.isRecurrent === false));

          }
          // validamos el icono de eliminar de cada linea
          let tmp = new Date();
          console.log("nuevo rows",this.rows)

        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rowdocumentupload = [];
          if (this.u_obj["Status_doc"] == null) {
            this.cargavacia = true;
          }
          else { this.cargavacia = false; }

          this.mensaje = "";
          return;
        }


      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public GetCompanyData2() {
    //debugger;
    var par_url = "EmployeeDocument/EmployeeSelect/" + this.id;
    var to: any;
    to = {};
    to.EmployeeId = this.id;
    //console.log("api:",par_url,"CompanyId:",to);

    //debugger;
    this.srv.postCall(par_url, to).subscribe(x => {
      this.loadingList = false;

      if (x.success) {
        if (x.val != null) {
          var rs = x.val;
          this.u_obj = rs[0];
          //console.log("u_obj:",this.u_obj);
        } else {
          this.u_obj = [];
          return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  downloadDocFile(data) {
    //console.log(data)

    const DocFileName = data.EmployeeId;
    const DocFileId = data.EmployeeDocumentId;
    const name = data.FileName;
    //var DocFile = DocFileName.slice(0, -5);
    this.srv.downloadFileEmp(DocFileName, DocFileId).subscribe((data) => {
      // console.log(data)
      importedSaveAs(data, name)//DocFileName)
    });


  }

  public UpdateStatus() {

    this.closeModal('alert'); // Close Modal Alert

    var par_url = "EmployeeDocument/UpdateStatus";
    var to: any;
    to = {};
    to.EmployeeId = this.id;
    to.Status = "P";
    to.StatusId = 38;
    to.FileName = this.userinfo['name'];
    let trans = this.translations;
    Swal.fire({
      title: trans.sampapp_refmethodswarn,
      text: '',
      icon: 'warning',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      cancelButtonText: trans.cancel,
      confirmButtonText: trans.ok,
      preConfirm: () => {
        this.isUpdating = true;
        this.saveVerificationAPI(to);
      }
    }).then((result) => {
    });
  }

  saveVerificationAPI(to: any) {
    var par_url = "EmployeeDocument/UpdateStatus";
    this.isUpdating = true;

    this.srv.postCall(par_url, to).subscribe(x => {
      this.isUpdating = false;
      Swal.close();
      if (x.success) {
        this.reload.next(true);
        this.closeModal('update5');
        Swal.fire(this.translations.msg_update, x.msg, 'success');
        this.getList();
      }
      else {
        Swal.fire(x.msg, '', 'error');
      }
    });
  }

  public deleteFile(position, modal, docName, CompDocumentId) {
    //this.position = position
    //this.docName = docName
    //this.CompDocumentId = CompDocumentId
    //this.modalService.open(modal, { backdrop: 'static', keyboard: false, size: 'lg', centered:true });
    // debugger
    //console.log("row1:",position);

    let trans = this.translations;
    Swal.fire({
      title: '¿Está seguro que desea eliminar el archivo ' + docName + ' ?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      cancelButtonText: trans.cancel,
      confirmButtonText: trans.ok,
      allowOutsideClick: false,
      preConfirm: () => {
        this.confirmDeleteFile(CompDocumentId, position);
      }
    }).then((result) => {
    });
  }

  confirmDeleteFile(CompDocumentId, obj: any) {
    // debugger
    var qst = this.delete_url_details;
    var to: any;

    //console.log("Eliminar:",CompDocumentId,"objeto:",obj)
    to = {};
    to.EmployeeDocumentId = CompDocumentId
    to.DeleteUser = this.userinfo.logon;
    //to.docuTypeID=obj.docuTypeID;

    this.isUpdating = true;
    this.srv.postCall(qst, to).subscribe(x => {
      this.isUpdating = false;
      // debugger
      if (x.success) {
        this.getList();
      }
      else {
        Swal.fire(x.msg, '', 'error');
      }
    });
    location.reload();
  }


  validarStatus(row: any): boolean {
    let dateEnd = new Date(row.DateEnd);
    // Documentos que estén en revisión, validados, activos o renovar y validación de fecha de caducidad
    const validStatusNames = ['ACTIVO', 'REVISIÓN', 'RENOVAR', 'VALIDADO'];
    const isRenewalStatusValid = this.isRenewalStatus(dateEnd);
  
    // Condición para documentos en revisión, validados, activos o renovar, y validación de fecha de caducidad
    if (validStatusNames.includes(row.status_name) && isRenewalStatusValid) {
      // Excluir documentos tipo 107 que estén validados
      if (row.ParamDocumentID !== 107 || row.status_name !== 'VALIDADO') {
        row.status_name = "RENOVAR";
        return true; // Habilitar botón de eliminar para poder renovar
      }
    } else if (row.status_name === 'RECHAZADO') {
      return true; // Habilitar botón de eliminar para poder renovar
    }
  
    return false; // Inhabilitar botón de eliminar
  }  
  
  isRenewalStatus(expirationDate: Date): boolean {
    // Obtener la fecha actual
    const currentDate = new Date();

    // Calcular la fecha límite para la renovación (10 días antes de la fecha de caducidad)
    const renewalLimit = new Date(expirationDate);
    renewalLimit.setDate(renewalLimit.getDate() - 10);

    // Comparar la fecha actual con la fecha de caducidad y la fecha límite para la renovación
    if (currentDate > expirationDate || currentDate >= renewalLimit) {
      return true; // Documento vencido o Renovación necesaria (faltan menos de 10 días para la caducidad)
    } else {
      return false; // Documento válido
    }
  }


}
