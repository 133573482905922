import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from '../global';
import { ApiService } from '../../services/main/api.service';
import { Router, Params, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ReturnStatement } from '@angular/compiler';
import { Observable, from, of } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
//import { ApiService } from '../main/api.service';
import * as moment from 'moment-timezone';
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2';
import { AuthService } from './auth.service';
import { User } from 'oidc-client';

const API_GENERAL_URL = environment.Global.url;
const API_CONSULTACOMPANIESRUC = Global.API_CONSULTACOMPANIESRUC;

//max time for api requests
const MAX_TIME = 60000;
const TOKEN_KEY = 'token';
const REFRESHTOKEN_KEY = 'refresh';
const PERMISSIONS_KEY = 'permissions';
const COMPANIES_KEY = 'companies';

const TOKEN_KEY_AQ = 'token_aquasim';
const REFRESHTOKEN_KEY_AQ = 'refreshtoken_aquasim';

const TOKEN_EXPIRE_KEY = 'token_expires_aquasim';
const TOKEN_EXPIRES_PERIOD = 20;

@Injectable({
  providedIn: 'root'
})

export class LoginService implements CanActivate {

  private url: any;
  private select_url:any;
  private token: any;
  public cont: number;
  momentjs: any = moment;

  user: User | null = null;
  tokenExpired = false;

  pages = [
    //FORMULARIOS
    {id: 'formshome', name: "SI_ADMREGISTRO"},
    {id: 'regcompanie', name: "SI_REGCOMPANIES"},
    {id: 'regcompanieemp', name: "SI_REGCOMPANIESEMP"},
    {id: 'regsubcontract', name: "SI_REGSUBCONTRATISTA"},
    {id: 'regsubcontracttypejob', name: "SI_REGSOLINDUCCION"},
    {id: 'regsubcontracdocu', name: "SI_INPUTDOCUMENTSUBCOMPANY"},
    {id: 'regemployee',name:"SI_REGEMPLOYE"},
    {id: 'regemployeedoc',name:"SI_CONEMPLOYEDOCUMENT"},
    {id: 'consdochistorycompanies',name:"SI_DOCUMENTSCOMPANIES"},
    {id: 'consdochistorysubcompanies',name:"SI_DOCUMENTSSUBCOMPANIES"},
    {id: 'consdochistoryemployees',name:"SI_DOCUMENTSEMPLOYEES"},
    {id: 'reportforms' , name:'SI_REPORT_RESULT'},
    {id: 'consdochistory',name:"SI_DOCUMENTS"},

    // PROCESOS
    {id: 'prochome', name: "SI_QUERY"},
    {id: 'proapprovedocuments',name:"SI_PROAPPROVEDOCUMENTS"},
    {id: 'proaddrating',name:"SI_PROADDRATING"},
    {id: 'proindicator',name:"SI_INDICATOR"},
    {id: 'proapprovedocumentscompany',name:"SI_PROAPPROVEDOCUMENTSCOMPANY"},
    {id: 'proapprovedocumentssubcompany',name:"SI_PROAPPROVEDOCUMENTSSUBCOMPANY"},
    {id: 'proapprovedocumentsemployees',name:"SI_PROAPPROVEDOCUMENTSEMPLOYEES"},
    {id: 'prorequest',name:"SI_INDUCCION"},
    {id: 'proadmissionapp',name:"SI_REGSOLINGRESO"},
    {id: 'proadmissemergapp', name: "SI_REGSOLINGRESOEMERG"},
    {id: 'repocreator',name:"SI_REGSOLPERMISO"},
    {id: 'regtoolsequipment',name:"SI_LISTTOOLSEQUIPMENT"},
    {id: 'reporting',name:"SI_PROREPORTING"},
    {id: 'reporting-personal',name:"SI_PROREPORTING_PERSONAL"},
    {id: 'changeworker',name:"SI_REGSOLCAMBTRAB"},


    //EXCELENCIA
    {id: 'excelenciahome', name: "SI_ADMOPERACIONAL"},
    {id: 'myevaluation',name:"SI_CONSOLINDUCCION"},
    {id: 'incidents', name: 'SI_REGINSPECCION' },
    {id: '/condinosecure', name: 'SI_CONDINSECURE'},
    {id: '/actnosecure', name: 'SI_ACTINSECURE'},
    {id: '/inciacci', name: 'SI_ACCIDEINCIDENT'},
    {id: '/oportunitymejor', name: 'SI_MEJOROPORTU'},
    {id: '/planaction', name: 'SI_PLANACTION'},
    {id: '/actionperplan', name: 'SI_REGPLANACCION'},
    {id: '/hse', name: 'SI_AUDITHSE'},
    {id: '/planActionAudits', name: 'SI_PLANACTIONAUDITS'},
    {id: 'audits', name: 'SI_OPORTUNITEVALEXT' },
    {id: 'workersPlant',name:"SI_PERMISO"},
    {id: 'cronograma',name:"SI_CRONOGRAMA"},
    {id: 'charla',name:"SI_CHARLAS"},
    {id: 'charlaAudits',name:"SI_CHARLASAUDITS"},
    {id: 'actionplanAudits', name: "SI_ACTIONPERPLANAUDIT"},
    {id: 'calificaciones', name: "SI_CALIFICATION"},
    {id: 'qrRequest', name: "SI_QR_REQUEST"},
    {id: 'qrcodeValidationComponent', name: "SI_QR_VERIFICATION"},

    // MANTENIMIENTO
    {id: 'cruds', name: "SI_ADMCONSULTA"},
    {id: 'paramjobtype', name: "SI_CONFTRABAJO"},
    {id: 'paramplanta', name: "SI_CONFPLANTAS"},
    {id: 'paramarea', name: "SI_PARAMAREA"},
    {id: 'paramgenerales', name: "SI_CONSOLPERMISO"},
    {id: 'paramgenedetail', name: "SI_CONINSPECCION"},
    {id: 'paramdocu', name: "SI_CONFDOCUMENTOS"},
    {id: 'paramdocudetail', name: "SI_CONEMPLOYE"},
    {id: 'paramtypefouls', name: "SI_CONFFALTAS"},
    {id: 'paramfouls', name: "SI_FALTASDETAIL"},
    {id: 'paramsancion', name: "SI_CONFSANCION"},
    {id: 'paramcomunicados', name: "SI_COMUNICADOS"},
    {id: 'comunicados', name: "SI_CONAUDITORIA"},
    {id: 'paramtipopregunta', name: "SI_QUESTIONTYPE"},
    {id: 'opportunities', name: "SI_CONFOPORTUNIDADESEVALUACION"},
    {id: 'paramevaluacion', name: "SI_CONFEVALUACION"},
    {id: 'questions',name: "SI_QUESTIONS"},
    {id: 'questiondetail',name: "SI_ANSWER"},
    {id: 'formsQuestions', name: 'SI_FORMSQUESTIONS'},
    {id: 'evaluationinternal',name: "SI_INTERNALEVALUATION"},
    {id: 'maintuser',name: "SI_MAINTUSER"},
    {id: 'maintareagf', name: 'SI_MAINTAREAGF'},


    ];

  constructor(
    private _router: Router,
    private _http: HttpClient,
    private storage: Storage,
    private route: ActivatedRoute,/*,
    private api: ApiService*/
    private srv: ApiService,
    private auth: AuthService
  ) {
    //   this.url = Global.url;
    this.url = API_GENERAL_URL;
    this.select_url = API_CONSULTACOMPANIESRUC;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // debugger
        //var page = route.routeConfig.path;
        var page = state.url;
        //console.log(route);
        //console.log(state);
        //console.log(page);

        return this.storage.get(PERMISSIONS_KEY).then(mods => {
            let permissions = ['dashboard', 'settings'];
            let ipage:any;
            //console.log('inside permissions check');
            if( navigator.onLine ){
              /**/// Obtener el usuario actual
              this.auth.getUsuario().then(user => {
                this.user = user;

                // Verificar si el token está próximo a expirar o ya ha expirado
                this.checkTokenExpiry();

                // Renovar el token si es necesario
                this.renewTokenIfNeeded();
              }).catch(error => {
                console.error('Error al obtener el usuario:', error);
              });
            }

            if( !Array.isArray(mods) ){
                this.clearData().then(() => {
                    this._router.navigate(['/authentication/login']);
                });
                return false;
            }

            mods.forEach(i => {
                ipage = this.getPageIdFromModule(i);
                if( ipage != false ){
                    permissions.push(ipage);
                }
            });

            /*console.log(page);
            console.log(permissions);*/

            let checker = permissions.filter(p => {
                  return page.toLowerCase().includes(p.toLowerCase());
            });

            //console.log(checker);

            if( checker.length > 0 ){
                return true;
            }
            else{
                this.clearData().then(() => {
                    this._router.navigate(['/authentication/login']);
                });

                return false;
            }
        });

        /*return this.refreshToken().toPromise().then(x => {
            console.log(x);
            if( x != false ){
                //permissions check
            }
            else{
                this.clearData().then(() => {
                    this._router.navigate(['/authentication/login']);
                });

                return false;
            }
        });*/
    }

  getPageIdFromModule(md){
      let tmp = [];
      tmp = this.pages.filter(function(a){
                        return a.name == md;
                      });

      if( tmp.length > 0 ){
          return tmp[0].id;
      }

      return false;
  }

  getPermissions(){
      return this.storage.get(PERMISSIONS_KEY);
  }

  /*public getToken() {
    if (localStorage.getItem('Token') && localStorage.getItem('Refresh')) {
        this.token = localStorage.getItem('Token'), localStorage.getItem('Refresh');
    } else {
        this.token = null;
    }
  }

  public log() {
    return (this.token != null) ? true : false;
  }*/

  public login(data: any) {
    const params = JSON.stringify(data);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(`${this.url}login/authenticate`, params, { headers });
  }


  public loginExternal(data: any) {
    const params = JSON.stringify(data);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(`${this.url}loginExternal/ExternalLogin`, params, { headers });
}

  public getPinCode(email: any) {

    var aq_url='https://wa-p-prevent.azurewebsites.net';
    //var aq_apptoken = '{22261112-BC9B-4042-A9D6-5B9AEC292F85}';
    ////debugger;
    const params = {
        //servicetoken: aq_apptoken,
        Ruc: email/*,
        pincode: pin,
        newpassword: pwd*/
    };
    // console.log("params:",params);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(`${aq_url}/api/CompaniesConsulta/CompanySelectRuc`, params, { headers });
    //https://aquasim.skretting.com/
    /*var aq_url = 'https://az-aquasimuat.skretting.com/';
    var aq_apptoken = '{22261112-BC9B-4042-A9D6-5B9AEC292F85}';

    const params = {
        servicetoken: aq_apptoken,
        email: email
    };

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(`${aq_url}services/api/v1.0/passwordreset/GetPincode`, params, { headers });*/
  }

  public resetPwd(email: any, pin:any, pwd:any) {
    //https://aquasim.skretting.com/
    var aq_url = 'https://az-aquasimuat.skretting.com/';
    var aq_apptoken = '{22261112-BC9B-4042-A9D6-5B9AEC292F85}';
    ////debugger;
    const params = {
        servicetoken: aq_apptoken,
        email: email,
        pincode: pin,
        newpassword: pwd
    };

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(`${aq_url}services/api/v1.0/passwordreset/resetpassword`, params, { headers });
  }

  public saveData(data: any) {
    // console.log("DATA:",data)
      this.cont = 0;
      var permissions = [];
      const my_companies: Array<any> = [];
      const temp = Object.keys(data['Permissions']['Result']).map(function(key) {
        return [(data['Permissions']['Result'])[key]];
      });

      if( (data['Permissions'] != undefined) && (data['Permissions']['Result'] != undefined) ){
          if( Array.isArray(data['Permissions']['Result']) ){
              var companies = data['Permissions']['Result'];

              companies.forEach(x => {
                //console.log(x);
                  my_companies.push({name: x.cmpName, id: x.cmpID});
                  x.Modules.forEach(y => {
                      if( permissions.indexOf(y.Name) < 0 ){
                          permissions.push(y.Name);
                      }
                  });
              });
          }
      }

      var exp_time = this.momentjs().add(TOKEN_EXPIRES_PERIOD, 'm').unix();
      var promises =
      [
        this.storage.set('username', data['FullName']),
        this.storage.set('userinfo', data['UserInfo']['result']),
        this.storage.set('token', data['TokenIC2']),
        this.storage.set('refresh', data['RefreshTokenIC2']),
        this.storage.set('token_aquasim', data['TokenAquasym']),
        this.storage.set('refreshtoken_aquasim', data['RefreshTokenAquasym']),
        this.storage.set('token_expires_aquasim', exp_time),
        this.storage.set('permissions', permissions),
        //this.storage.set('companies', my_companies),
        this.storage.set('company_id', data['UserInfo']['result']['selectedCompanyId'])
        //this.storage.set()
        // this.storage.set('username', data['FullName']),
        // this.storage.set('token', data['TokenHealthAdvisor']),
        // this.storage.set('refresh', data['RefreshTokenHealthAdvisor']),
        // this.storage.set('token_aquasim', data['TokenAquasym']),
        // this.storage.set('refreshtoken_aquasim', data['RefreshTokenAquasym']),
        // this.storage.set('permissions', permissions),
        //this.storage.set('companies', my_companies),
        //this.storage.set('company_id', data['UserInfo']['result']['selectedCompanyId'])
      ];

      return Promise.all(promises);
  }

  public saveAndRefreshInitialData(data: any) {

    this.cont = 0;
    var permissions = [];
    const my_companies: Array<any> = [];
    const temp = Object.keys(data['Permissions']['Result']).map(function(key) {
      return [(data['Permissions']['Result'])[key]];
    });

    if( (data['Permissions'] != undefined) && (data['Permissions']['Result'] != undefined) ){
        if( Array.isArray(data['Permissions']['Result']) ){
            var companies = data['Permissions']['Result'];

            companies.forEach(x => {
                //console.log(x);
                my_companies.push({name: x.cmpName, id: x.cmpID});
                x.Modules.forEach(y => {
                    if( permissions.indexOf(y.Name) < 0 ){
                        permissions.push(y.Name);
                    }
                });
            });
        }
    }

    var exp_time = this.momentjs().add(TOKEN_EXPIRES_PERIOD, 'm').unix();
    // //debugger;
    var promises =
    [
      this.storage.set('username', data['UserInfo']['result']['name']),
      this.storage.set('userinfo', data['UserInfo']['result']),
      this.storage.set('token', data['TokenIC2']),
      this.storage.set('refresh', data['RefreshTokenIC2']),
      this.storage.set('token_aquasim', data['TokenAquasym']),
      this.storage.set('refreshtoken_aquasim', data['RefreshTokenAquasym']),
      this.storage.set('token_expires_aquasim', exp_time),
      this.storage.set('permissions', permissions),
      this.storage.set('company_id', data['UserInfo']['result']['selectedCompanyId'])
    ];

    return Promise.all(promises);
}//end saveAndRefreshInitialData

  public clearData(){
      return this.storage.clear();
  }

  checkTokenExpiry(): void {debugger;
    if (this.user && this.user.expires_in) {
      const expiryTime = new Date(this.user.expires_at * 1000);
      const currentTime = new Date();

      // Verificar si el token ya ha expirado
      if (expiryTime <= currentTime) {
        this.tokenExpired = true;
        return;
      }

      // Definir un margen de tiempo antes de la expiración para considerar "próximo a expirar"
      const marginSeconds = 60; // Por ejemplo, 60 segundos antes de la expiración

      // Verificar si el token está próximo a expirar
      if (expiryTime.getTime() - currentTime.getTime() <= marginSeconds * 1000) {
        this.tokenExpired = true;
      }
    }
  }

  renewTokenIfNeeded(): void {debugger;
    if (this.tokenExpired) {
      // Renovar el token
      this.auth.renewToken().then(() => {
        console.log('Token renovado con éxito.');

        // Obtener el usuario actualizado
        return this.auth.getUser();
      }).then(user => {
        this.user = user;

        // Resetear el estado de tokenExpired
        this.tokenExpired = false;
      }).catch(error => {
        console.error('Error al renovar el token:', error);
      });
    }
  }
}
