import { Component, OnInit } from '@angular/core';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

const ALL = environment.Global.API_ALL_CHANGE_REQUEST;

@Component({
  selector: 'app-proapprovechangeemployees',
  templateUrl: './proapprovechangeemployees.component.html',
  styles: []
})
export class ProapprovechangeemployeesComponent extends BaseCrudComponent implements OnInit {
  userinfo: any;
  flag: any;
  username: any;

  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService) {
    super(srv, modalService, storage, router);

    this.read_url = ALL;
    this.search_fields = ['CompanyID', 'Ruc', 'EmployeeName', 'CompanyOldName', 'CompanyNewName'];

    this.c_obj['Company'] = [];
    this.flag = 0;
    this.reload.subscribe(val => {
      if (val) {
        super.clearData();
        this.getList();
      }
    });
  }

  async ngOnInit() {
    await this.translate.getTranslation(this.translate.currentLang)
      .toPromise().then((translations) => {
        this.translations = translations;
        super.ngOnInit();
        //this.getList();
      });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    await this.storage.get('username').then(x => {
      this.username = x;
    });
    await this.storage.get('userinfo').then(x => {
      this.userinfo = x;

      this.getList();
    });
  }

  public getList() {
    debugger
    var qst = this.read_url + this.username;
    this.loadingList = true;
    // Swal.fire(
    //   {
    //     title: this.translations['loading'],
    //     html: '',
    //     icon: 'info',
    //   }
    // );
    // Swal.showLoading();
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;

      if (x.success) {
        if (x.val != null) {
          // ordenar por fecha de forma descendente

          x.val = x.val.sort((a, b) => {
            return new Date(b.CreationTime).getTime() - new Date(a.CreationTime).getTime();
          });
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          // console.log("rs:",rs);

          this.page = 1;
          if (Array.isArray(this.rows)) {
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          Swal.close()
        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });

  }
  changeSelect(row) {
    if (row.valor == 0) {
      this.flag = 0;
    } else {
      this.flag = 1;
    }
  }
  verificateprocesardoc(row: any) {
    console.log(row)
    debugger
    let status;
    var mensaje: any;

    let trans = this.translations;


    if (row.valor == 0) { // aprobado
      status = "A"
      mensaje = trans.show_message_change_approve;

    } else if (row.valor == 1) { // reprobado
      status = "R"
      mensaje = trans.show_message_change_reject;
    }
    else {
      return Swal.fire('', this.translations.aprroveorrevoce, 'error');

    }

    var qst = "Employee/StatusEmployeeChangeWorker/" + row.EmployeeChangeId + "/" + status + "/" + this.username


    Swal.fire({
      title: mensaje,//trans.sampapp_refmethodswarn,
      text: "",
      icon: 'warning',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      cancelButtonText: trans.cancel,
      confirmButtonText: trans.ok,
      preConfirm: () => {
        this.procesardoc(qst);

      }
    }).then((result) => {
    });

  }
  procesardoc(qst: any) {
    this.loadingList = true;

    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;
      if (x.success) {
        Swal.fire(this.translations.msg_update, x.msg, 'success');
        this.getList();
      } else {
        Swal.fire(x.msg, '', 'error');
      }
    });

  }


}
