import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { isNumeric } from 'rxjs/util/isNumeric';
import { Newsubcomjob } from '../../../interfaces/newsubcomjob';
import {saveAs as importedSaveAs  } from "file-saver";
const API_UPDATESTATUSEMPLOYEE = Global.API_STATUSEMPLOYEE_INSURANCE;
const API_POST_UPDATE_STATUS_REVERSE_DOCUMENT = environment.Global.API_POST_UPDATE_STATUS_REVERSE_DOCUMENT;
const API_GET_DOCUMENTSLOG = environment.Global.API_GET_DOCUMENTSBYCOMPANYLOG;
@Component({
  selector: 'app-consdochistorycompanies',
  templateUrl: './consdochistorycompanies.component.html',
  styleUrls: ['./consdochistorycompanies.component.css']
})
export class ConsdochistorycompaniesComponent extends BaseCrudComponent  {
  id:any;
  userinfo:any;
  name_company:any;
  public modalPage: number;
  public modalPageTotal: number;
  public modalPageSize: number;
  public modalRows: any[];

  public rowEmployee: any;
  listdoc: any;

  constructor(srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
  )
  {
    super(srv, modalService, storage, router);
    this.id = route.snapshot.params.id;

    this.active_url = API_UPDATESTATUSEMPLOYEE;

    this.read_url="CompanyDocument/GetDocumentsId";
    this.search_fields = ['CompDocumentId','NameDoc','VerificateUser','status_name'];

    this.c_obj['CompanyDocument'] = [];

    this.reload.subscribe(val => {
      if( val ){
          super.clearData();
          this.getList();
      }
    });

    this.modalPage = 1;
    this.modalPageTotal = 0;
    this.modalPageSize = 10;
    this.modalRows = [];
  }

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
          this.translations = translations;
          super.ngOnInit();
          this.getList();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    this.storage.get('userinfo').then(x => {
        this.userinfo = x;
    });
  }

  public getList()
  {
    var qst = this.read_url+"/"+this.id;
    console.log('qst getList: ', qst);
    this.loadingList = true;

    var to:any;
    to = {};
    Swal.fire(
      {
        title: this.translations['loading'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    this.srv.postCall(qst, to).subscribe(x => {
      this.loadingList = false;

      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          //console.log("rs:",rs);
          this.page = 1;
          if( Array.isArray(this.rows) ){
             this.pageTotal = this.rows.length;
             if (this.pageTotal>0)
               this.name_company=this.rows[0].name_company;
          }
          this.filterAll();
          Swal.close()
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
          }
     });
  }

  public openUpdateModal(content: any, obj1: any) {
    //console.log("objeto::",obj1)
    let obj = JSON.parse(JSON.stringify(obj1));
    this.u_objSub = obj;
    this.getEmployeeList();

    this.modalService.open(content, { backdrop: 'static', keyboard: false, size: 'lg', centered: true });
  }

  public setupModalPagination(data: any[]) {
      this.rowEmployee = data;
      //console.log(this.rowEmployee.length)
      //console.log(this.modalPageSize)
      this.modalPageTotal = Math.ceil(this.rowEmployee.length / this.modalPageSize);

      //console.log(this.modalPageTotal)
      this.updateModalPage();
  }

  public updateModalPage() {
      const start = (this.modalPage - 1) * this.modalPageSize;
      //console.log(start)
      const end = start + this.modalPageSize;
      //console.log(end)
      this.modalRows = this.rowEmployee.slice(start, end);
      //console.log(this.modalRows)
  }

  public changeModalPage(page: number) {
      if (page > 0 && page <= this.modalPageTotal) {
          this.modalPage = page;
          this.updateModalPage();
      }
  }

  downloadDocFile(data) {
    const DocFileName = data.CompDocumentId;
    const name=data.ImageFileName;
    //var DocFile = DocFileName.slice(0, -5);
    this.srv.downloadFile(DocFileName).subscribe((data) => {
        importedSaveAs(data,name )//DocFileName)
    });
  }

  public getEmployeeList() {
    var qst = Global.API_ALLEMPLOYEECOMPANYONLYID + "/" + this.id;
    //console.log("qst:",qst)
    this.loadingList = true;

    Swal.fire({
        title: this.translations['loading'],
        html: '',
        icon: 'info',
    });
    Swal.showLoading();
    this.srv.getCall(qst).subscribe(x => {
        this.loadingList = false;

        if (x.success) {
            if (x.val != null) {
                var rs = x.val;
                this.rowEmployee = rs;
                this.modalPage = 1;
                //console.log("objEmployee:",this.rowEmployee)
                if (Array.isArray(this.rowEmployee)) {
                    this.setupModalPagination(this.rowEmployee);
                }
                Swal.close();
            } else {
                Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
                this.rowEmployee = [];
            }
        } else {
            Swal.fire(x.msg, '', 'error');
        }
    });
  }

  toggleEditable(event, id) {
    if (event.target.checked) {
      this.Activar(id, 1);
    } else {
      this.Activar(id, 2);
    }
  }

  Activar(obj: any, valor: any) {
    var to: any;
    to = {};
    to.EmployeeId = obj;
    to.Status = valor;
    var qst = this.active_url;
    this.srv.postCall(qst, to).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if (x.success) {
        this.reload.next(true);
        if (valor == "1") {
          Swal.fire('¡Activado!', x.msg, 'success');
        }
        else {
          Swal.fire('¡Desactivado!', x.msg, 'success');
        }

        this.getEmployeeList();
      }
      else {
        Swal.fire(x.msg, '', 'error');
      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }

  StatusReverse(data) {
    this.srv.postCall(API_POST_UPDATE_STATUS_REVERSE_DOCUMENT, data).subscribe(x => {
      this.isUpdating = false;
      if (x.success) {
        Swal.fire(this.translations.msg_update, x.msg, 'success');
      }
      this.getList();
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }

  ModalHistoryDoc(content, data){
    console.log(data);
    var qst = API_GET_DOCUMENTSLOG;
    this.u_objSub = data;
    this.modalService.open(content, {backdrop: 'static', keyboard: false, size: 'lg', centered: true });

    var to: any;
    Swal.fire(
      {
        title: this.translations['loading'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();

    to = {};
    to.CompanyID = data.CompanyID;
    to.ParamDocumentID = data.ParamDocumentID;

    this.srv.postCall(qst, to).subscribe(x => {
      this.loadingList = false;
      if(x.success){debugger;
        if(x.val != null && x.val.length > 0){
          var rs = x.val;
          this.listdoc = rs;
          console.log("listdoc:",this.listdoc);debugger;
          this.page = 1;
          if( Array.isArray(this.listdoc) ){
            this.pageTotal = this.listdoc.length;
            if (this.pageTotal>0)
              this.name_company=this.listdoc[0].name_company;
          }
          this.filterAll();
          Swal.close()
        }else {
          Swal.fire(this.translations.empty, this.translations.docu_previous_exist, 'success');
          this.listdoc = [];
          return;
        }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });

  }

}
