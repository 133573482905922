import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { Global } from '../../services/global';
import { BaseCrudComponent } from '../base-crud/base-crud.component';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css']
})

export class CompaniesComponent extends BaseCrudComponent {
    myfields:any;

  	constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router
      ) {
        super(srv, modalService, storage, router);
        this.read_url = 'Company/CompanyByUser';
        this.update_url = 'Company/Update';

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });

        this.myfields = ['CompJiraUser', 'CompJiraPassword', 'CompJiraProjectId', 'CompJiraUrl'];
    }

    ngOnInit() {
        super.ngOnInit();
        super.getList();
    }

    updateItem(content:any, fields:any){
        var allempty = true;
        var resp = true;

        //validate is not clearing
        this.myfields.forEach(x => {
            if( fields[x] != null ){
                allempty = false;
                return;
            }
        });

        //when clearing no validation needed
        if( !allempty ){
            resp = this.validateUpdateInfo(fields);
            if( !resp ){
                Swal.fire('Data incorrecta', '', 'error');
                return;
            }
        }

        super.updateItem(content, fields);
    }

    clearCompanyData(){
        this.u_obj.CompJiraUser = null;
        this.u_obj.CompJiraPassword = null;
        this.u_obj.CompJiraProjectId = null;
        this.u_obj.CompJiraUrl = null;
    }

    validateUpdateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'CompJiraUser': new FormControl(fields.CompJiraUser, [Validators.required, Validators.email]),
            'CompJiraPassword': new FormControl(fields.CompJiraPassword, [Validators.required]),
            'CompJiraProjectId': new FormControl(fields.CompJiraProjectId, [Validators.required]),
            'CompJiraUrl': new FormControl(fields.CompJiraUrl, [Validators.required])
        });

        return mygroup.valid;
    }

}
