import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import {FormControl, FormGroup, Validators} from "@angular/forms";

const ALLCOMPANIES = environment.Global.API_ALLCOMPANIESWITHDOCUMENT;

@Component({
  selector: 'app-proaddrating',
  templateUrl: './proaddrating.component.html',
  styleUrls: ['./proaddrating.component.css']
})
export class ProaddratingComponent extends BaseCrudComponent {
  userinfo:any;

  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService) {
      super(srv, modalService, storage, router);

      this.create_url = "Myevaluation/Create";
      this.search_fields  = ['EvaluationId','EvaluationName','CompanyName','EmployeedNumberIdentification','EmployeeName','myevaluation','AssigneDateTime',];

      this.c_obj['Company'] = [];

      this.reload.subscribe(val => {
          if( val ){
              super.clearData();
              this.getList();
          }
      });
    }

  async ngOnInit() {
    await this.translate.getTranslation(this.translate.currentLang)
    .toPromise().then((translations) => {
          this.translations = translations;
          super.ngOnInit();
          //this.getList();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    await this.storage.get('userinfo').then(x => {
        this.userinfo = x;
        this.getList();
    });
  }

  public getList()
  {
    var qst = "Request/AllByRating";//this.read_url;
    this.loadingList = true;
    // Swal.fire(
    //   {
    //     title: this.translations['loading'],
    //     html: '',
    //     icon: 'info',
    //   }
    // );
    // Swal.showLoading();
    this.srv.getCall(qst).subscribe(x => {
        this.loadingList = false;
        if(x.success){
          if(x.val != null){
            this.rawdata = x.val;
            var rs = x.val;
            this.rows = rs;
            // console.log("rs:",rs);
            this.page = 1;
            if( Array.isArray(this.rows) ){
               this.pageTotal = this.rows.length;
            }
            this.filterAll();
            Swal.close()
          }else {
            Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
            this.rows = [];
            return;
          }
        }else {
          Swal.fire(x.msg, '', 'error');
          return;
            }
       });
  }
  public createItem(modal, object) {
    this.isCreating = true;
    let validated = this.validateCreateInfo(object);
    if( !validated ){
      this.isCreating = false;
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }
    var status='';
    if (object['score_end'] < object['PorcentajeCalificacionMinima']) {
      status = 'RP';
    } else {
      status = 'AP';
    }
    let new_obj = {
      EmployeeId: object['EmployeeId'],
      RequestId: object['RequestId'],
      max_repetition: 1,
      score_end: object['score_end'],
      number_questions: object['NumeroPregunta'],
      score_evaluation: object['PorcentajeCalificacion'],
      ParameEvaID: object['EvaluationId'],
      date_evaluation: object['date_evaluation'],

      status_end: status
    }
    this.srv.postCall(this.create_url, new_obj).toPromise().then( async (data)=> {
      if(data.success){
        this.isCreating = false;
        this.c_obj = {};
        //await this.sendPhotos(data.val['AccIncId']);
        Swal.fire(this.translations.msg_create,'', 'success');
        this.closeModal(modal);
        //await this.sendMail(data.val['AccIncId']);
        this.getList();
      } else {
        this.isCreating = false;
        Swal.fire(data.msg, '', 'error');
      }
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
      this.isCreating = false;
    });
  }
  public validateCreateInfo(fields:any): boolean {
    let inciacci:any = {};
    inciacci = new FormGroup({
      'score_end': new FormControl(fields.score_end, [Validators.required])
    });
    // console.log(inciacci)
    return inciacci.valid;
  }
  public openUpdateModal(content: any, obj: any, size?:any) {
    let newobj = JSON.parse(JSON.stringify(obj));
    this.c_obj = newobj;
    // console.log(this.u_obj)
    if( size == undefined ){
      this.modalService.open(content, { centered: true });
    }
    else{
      this.modalService.open(content, { centered: true, size: size });
    }
  }
}
