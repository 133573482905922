import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
  export class GlobalService {
 
    constructor() {}

    formatOnlyDate(date: Date) {
        return date.getFullYear().toString() + '-' + (date.getMonth() <= 8 ? `0${(date.getMonth() + 1).toString()}`: (date.getMonth() + 1).toString()) + '-' + (date.getDate() <= 9 ? `0${date.getDate().toString()}`: date.getDate().toString());
    }
    
    formatDate(date: Date) {
        return date.getFullYear().toString() + '-' + (date.getMonth() <= 8 ? `0${(date.getMonth() + 1).toString()}`: (date.getMonth() + 1).toString()) 
        + '-' + (date.getDate() <= 9 ? `0${date.getDate().toString()}`: date.getDate().toString()) + ' ' + 
        (date.getHours() <= 9 ? `0${date.getHours().toString()}`: date.getHours().toString()) + ':' +
        (date.getMinutes() <= 9 ? `0${date.getMinutes().toString()}`: date.getMinutes().toString()) + ':' +
        (date.getSeconds() <= 9 ? `0${date.getSeconds().toString()}`: date.getSeconds().toString());
    }

    getWeek(date) {
      let target  = new Date(date.valueOf());
      let dayNr   = (date.getDay() + 6) % 7;
      target.setDate(target.getDate() - dayNr + 3);
      let firstThursday = target.valueOf();
      target.setMonth(0, 1);
      if (target.getDay() != 4) {
          target.setMonth(0, 1 + ((4 - target.getDay()) + 7) % 7);
      }
      let result =  1 + Math.ceil((firstThursday - target.getTime()) / 604800000);
      return result;
    }
  
    getDateRangeOfWeek(weekNo){
      let d1 = new Date();
      let numOfdaysPastSinceLastMonday = d1.getDay()- 1;      
      d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
      let weekNoToday = this.getWeek(d1);
      let weeksInTheFuture = weekNo - weekNoToday;
      d1.setDate(d1.getDate() + ( 7 * weeksInTheFuture ));
      let rangeIsFrom =  (d1.getDate()) + "/" + (d1.getMonth()+1) +"/" + + d1.getFullYear();
      d1.setDate(d1.getDate() + 6);
      let rangeIsTo = d1.getDate() + "/" + (d1.getMonth()+1) +"/"  + d1.getFullYear();
      return rangeIsFrom + " - "+rangeIsTo;
    };

    getDateRangeOfWeekP(weekNo, date){
      // console.log(weekNo, date)
      let d1 = new Date(date.getTime());
      // console.log({d1})
      let numOfdaysPastSinceLastMonday = d1.getDay()- 1;
      // console.log({numOfdaysPastSinceLastMonday})
      d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
      let weekNoToday = this.getWeek(d1);
      // console.log({weekNoToday})
      let weeksInTheFuture = weekNo - weekNoToday;
      // console.log({weeksInTheFuture})
      // console.log({d1})
      d1.setDate(d1.getDate() + ( 7 * weeksInTheFuture ));
      let rangeIsFrom =  (d1.getFullYear()  + "/" + (d1.getMonth()+1) + '/' + d1.getDate());
      // console.log({rangeIsFrom})
      d1.setDate(d1.getDate() + 6);
      // console.log({d1})
      let rangeIsTo = (d1.getFullYear()  + "/" + (d1.getMonth()+1) + '/' + d1.getDate());
      // console.log({rangeIsTo})
      return rangeIsFrom + " - "+rangeIsTo;
    };

    getISOWeeks(y) {
      let d,
      isLeap;  
      d = new Date(y, 0, 1);
      isLeap = new Date(y, 1, 29).getMonth() === 1;
      return d.getDay() === 4 || isLeap && d.getDay() === 3 ? 53 : 52
    }
  
    getWeekNumber(d : any) {
      d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
      d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
      let yearStart: any = new Date(Date.UTC(d.getUTCFullYear(),0,1));
      let weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
      return [d.getUTCFullYear(), weekNo];
    }


    getWeeks(fechaActual): string[]{
      let listaSemanas = [];
      for( let i = 0; i < this.getISOWeeks(fechaActual.getFullYear()); i++) {
        let rangoFechas = this.getDateRangeOfWeekP(i,fechaActual);
        let fechaFinal = rangoFechas.split('-')[1].trim().split('/');
        let fd = new Date(fechaFinal[0] + "-" + fechaFinal[1] + "-" + fechaFinal[2]);
        if (fechaActual.getFullYear() == fd.getFullYear()) {
          listaSemanas.push( 
            {
              week:  "Semana " + `${(i + 1).toString()}`,
              idWeek : i + 1,
              rangeDates : this.getDateRangeOfWeekP(i,fechaActual)
            }  
          )
        }
      }
      return listaSemanas;
    }
        
  }