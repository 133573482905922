import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from 'src/app/services/authentication/login.service';
import { ApiService } from 'src/app/services/main/api.service';
import { GlobalService } from 'src/app/services/main/global.service';
import { environment } from 'src/environments/environment';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { Storage } from '@ionic/storage';
import Swal from 'sweetalert2';


const API_GET_SECCION_GODFATHER = environment.Global.API_GET_SECCION_GODFATHER;
const API_CREATE_AREA_GODFATHER = environment.Global.API_CREATE_AREA_GODFATHER;
const GET_ALL_SITES_ACTIVE = environment.Global.API_GET_ALL_SITES_ACTIVE;
const GET_ALL_AREA_BY_ID = environment.Global.API_GET_ALL_AREA_BY_ID;


@Component({
  selector: 'app-maintareabygodfather',
  templateUrl: './maintareabygodfather.component.html',
  styleUrls: ['./maintareabygodfather.component.css']
})
export class MaintareabygodfatherComponent extends BaseCrudComponent implements OnInit {
  row_plantas_area = [];
  row_plantas = [];
  rowGeneral = [];
  userinfo: any;
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private globalService: GlobalService,
    private route: ActivatedRoute,
    public auth: LoginService,
    public translate: TranslateService,
  ) {
    super(srv, modalService, storage, router);
  }


  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
      this.translations = translations;
      super.ngOnInit();
    });
    this.pageSize = 8;
    this.search_fields = ['EmployeeId','NumberIdentification', 'EmployeeName', 'PlantaName', 'SeccionName'];
    this.storage.get('userinfo').then(x => {
      this.userinfo = x;
    });

    this.getList();
  }
  
  public getList() {
    this.loadingList = true;
    let url = API_GET_SECCION_GODFATHER;
    this.srv.getCall(url).subscribe(x => {
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.rowGeneral = rs;
          this.rawdata = x.val;
          this.rows = rs;
          this.page = 1;
          // console.log(rs)
          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
        }else {
          this.rows = [];
          return;
        }
      }else {
        this.rows = [];
        return;
      }
    });
  }

  public openUpdateModal(modal, row) {
    let obj = JSON.parse(JSON.stringify(row))
    this.u_obj = obj;
    this.u_obj['plantaID'] = this.u_obj['PlantaAssign'] !== null ?  this.u_obj['PlantaAssign'] : null;
    this.u_obj['seccionID'] = this.u_obj['SeccionAssign'] !== null ?  this.u_obj['SeccionAssign'] : null;
    this.modalService.open(modal, { backdrop: 'static', keyboard : false, size:"lg" });
    // console.log(this.u_obj)
    this.GetPlantas();
    if (this.u_obj['seccionID'] != null) {
      this.setLevelArea();
    }
  }

  public GetPlantas() {
    var qst = GET_ALL_SITES_ACTIVE;
    // console.log(qst)
    this.srv.getCall(qst).subscribe(x => {
      this.row_plantas = [];
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.row_plantas = rs;
        } else {
          this.row_plantas = [];
          return;
        }
      } else {
        return;
      }
    });
  }

  public setLevelArea() {
    var qst = GET_ALL_AREA_BY_ID + this.u_obj['plantaID'];
    this.srv.getCall(qst).subscribe(x => {
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.row_plantas_area = rs;
        }else {
          this.row_plantas_area = [];
          return;
        }
      }else {
        return;
      }
    });
  }

  public verifyArea() {
    let flag = false;
    this.rowGeneral.forEach(empleado => {
      if (empleado.PlantaAssign == this.u_obj['plantaID'] && empleado.SeccionAssign == this.u_obj['seccionID']) {
        // console.log(empleado)
        flag = true;
      }
    });
    if (flag) {
      this.u_obj['seccionID'] = null;
      Swal.fire(this.translations.area_exist,this.translations.gf_contain_area,'error');
    }
  }

  public updateItem(modal) {
    this.isUpdating = true;
    if (this.u_obj['plantaID'] == null || this.u_obj['seccionID']== null) {
      this.isUpdating = false;
      return Swal.fire(this.translations.msg_all_required, '','error');
    }
    let new_obj = {
      EmployeeId: this.u_obj['EmployeeId'],
      PlantaID: this.u_obj['plantaID'],
      SeccionID: this.u_obj['seccionID'],
      CreatorUser: this.userinfo['logon']
    }

    // console.log(new_obj)
    this.srv.postCall(API_CREATE_AREA_GODFATHER, new_obj).subscribe(x => {
      if (x.success) {
        this.isUpdating = false;
        Swal.fire(this.translations.msg_update, '', 'success');
        this.getList();
        this.closeModal(modal);
      } else {
        this.isUpdating = false;
        Swal.fire(x.msg, '', 'error');
      }
    })
  }

}
