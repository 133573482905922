import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { ValidationPathQr } from './app/services/qrvalidationpath.redirect';

if (environment.production) {
  enableProdMode();
}

// Redirect to qrcodeValidationComponent if the path is wrong
const val = new ValidationPathQr(window.location);

if (val.redirect) {
    console.log('redirecting qrComponent');
    console.log(val.urlProcesed);
    window.location.href = val.urlProcesed;
} else {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.log(err));
}