export const Global = {
  url:'https://wa-p-prevent-api.azurewebsites.net/api/', // apuntando a Azure
  //  url:'http://localhost:5000/api/',
  KEY: '234NutrecoCo4562',//clave para encryptar/desencryptar claves de userextarnal
  UrlRedirect: 'https://wa-p-prevent.azurewebsites.net/',
  XMS_TEST: 'https://apim.skretting.com/prevent/v3/applications/40/companies/237/user-permissions',//https://xmsapitest.skretting.com/v3/account/authorizeclaim
  XMS_USERS: 'https://apim.skretting.com/squinsy/v3/applications/243/users',


  // Identity server
  /**/
  redirect_uri: "https://prevent.skrettingdigital.com/callback",
  post_logout_redirect_uri: "https://prevent.skrettingdigital.com",
  silent_redirect_uri: 'https://prevent.skrettingdigital.com/silent-refresh',

  // LocalHost
  /*
  redirect_uri: "http://localhost:4200/callback",
  post_logout_redirect_uri: "http://localhost:4200",
  silent_redirect_uri: 'http://localhost:4200/silent-refresh',*/

  authority: "https://aquasimlogin.skretting.com",
  client_id: "Prevent",
  scope: "openid profile AquaSim offline_access apiApp",
  response_type: "code",
  acr_values: 'idp:azureadb2c-prevent',

  // user profile session storage
  key_user: "oidc.user:https://aquasimlogin.skretting.com:Prevent",
  //key_user: "oidc.user:https://aquasimlogintest.skretting.com:Skomex",


  // permission from XMS
  permissions_user: 'permissions',//"permissions_user",

  //Parameters
  MiliSeconds: 1000,
  Seconds: 3600,
  Minutes: 60,
  Hour: 1,
  MiliSecondsToHour: 3600000,
  MicrosecondstoMinute: 60000000,

  COMPANY_ID: 237,
  USER_LIST_SUB: ['4077', '4249', '4252', '4253', '4254', '4289', '3843'],
  // User
  API_SET_USER_LOG: 'login/SetUserLogged',
  API_GET_USER_LOG: 'User/UserLogged',
  API_UPDATE_USER_LIST: 'User/UpdateUserList/',

  //APIS COMPANIES ADMIN
    API_CONSULTACOMPANIESRUC: 'CompaniesConsulta/CompanySelectRuc',
    API_UPDATECOMPANIE:'RegisterCompany/UpdateCompaniemp',

    //APIS COMPANIES CONTRATISTA
    API_CONSULTACOMPANIESID: 'CompaniesConsulta/PostCompanyById',//CompanyById',
    API_GET_COMPANYBYID: 'CompaniesConsulta/CompanyById/',

    //APIS PREGUNTAS
    API_CONSPREGUNTA: 'Questions/All',
    API_REGPREGUNTA: 'Questions/Create',
    API_UPDATEPREGUNTAS: 'Questions/Update',
    API_UPDATESTATUS: 'Questions/UpdateStatus',
    API_CONSPREGUNTAESPECIFICA: 'Questions/GetQuestionsId',

    //APIS RESPUESTAS/DETALLEPREGUNTAS
    API_CONSRESPUESTAS: 'QuestionsDetail/All',
    API_REGRESPUESTA: 'QuestionsDetail/Create',
    API_UPDATARESPUESTA: 'QuestionsDetail/Update',
    API_UPDATESTATUSRESPUESTA: 'QuestionsDetail/UpdateStatus',
    API_CONSRESPUESTAESPECIFICA: 'QuestionsDetail/GetQuestionsId',
    API_CONSRESPUESTAESPECIFICAPORPREGUNTA: 'QuestionsDetail/GetAnswerByQuestionId',


    //APIS EVALUATION
    API_CONSEVALUACION: 'Evaluation/All',
    API_REGEVALUACION: 'Evaluation/Create',
    API_UPDATEEVALUACION: 'Evaluation/Update',
    API_DESACTEVALUACION: 'RegisterGeneral/desaevaluacion',
    API_ACTIVEEVALUACION: 'RegisterGeneral/activeevaluacion',
    API_UPDATESTTUSEVALUATION:"Evaluation/UpdateStatus",

    //APIS PARAMDOCUMENT
    API_CONSPARAMDOCUMENT: 'ParameterDocument/All',
    API_REGPARAMDOCUMEN: 'ParameterDocument/Create',
    API_UPDATEPARAMDOCUMEN: 'ParameterDocument/Update',
    API_UPDATESTATUSPARAMDOCUMEN: 'ParameterDocument/UpdateStatus',
    API_CONSESPECIFICAPARAMDOCUMEN: 'ParameterDocument/GetParamDocumentId',
    API_CONSPARAMDOCUMENTYPE: 'ParameterDocument/GetParamDocumenttype',
    API_CONSPARAMDOCUMENT_1: 'ParameterDocument/GetParamDocument',

    //APIS EMPLOYEE
    API_REGISTEREMPLOYEE:"Employee/Create",
    API_UPDATEEMPLOYEE:"Employee/Update",
    API_STATUSEMPLOYEE:"Employee/UpdateStatus",
    API_ALLEMPLOYEE:"Employee/All",
    API_ALLEMPLOYEECOMPANYID:"Employee/Allid",
    API_ALLEMPLOYEEBYID: "Employee/GetByIdEmployee",
    API_DELETE_EMPLOYEE_DOCUMENT: "Employee/DeleteDocuments",
  API_STATUSEMPLOYEE_INSURANCE:"Employee/UpdateStatusInsurance",
    API_ALLEMPLOYEECOMPANYONLYID:"Employee/AllOnlyId",
  API_ALLEMPLOYEESUBCOMPANYID:"Employee/AllSubId",

  //APIS INDICATOR
  API_REGISTERINDICATOR:"Indicator/Create",
  API_UPDATEINDICATOR:"Indicator/Update",
  API_STATUSINDICATOR:"Indicator/UpdateStatus",
  API_ALLINDICATOR:"Indicator/All",
  API_ALLINDICATORCOMPANYID:"Indicator/Allid",
  API_ALLINDICATORBYID: "Indicator/GetByIdIndicator",

    //APIS COMPANYDOCUMENT
    API_ALLCOMPANIES:"CompanyDocument/Companies",
    API_ALLCOMPANIESWITHDOCUMENT:"CompanyDocument/CompanieswhitDcocuments",
    API_DELETE_DOCUMET: 'CompanyDocument/DeleteDocuments',


    // APIS SUBCOMPANYDOCUMENTS
    API_DELETE_DOCUMENT_SUBCOMPANY: 'SubCompanyDocument/DeleteDocuments',


    //APIS AUMENTO OPORTUNIDADES USUARIOS EXTERNOS
    API_UPDATEOPORTUNITIES:"Myevaluation/Update",



    //APIS EQUIPMENTS TOOLS
    API_CREATE_TOOLS : "ListsTools/Create",
    API_UPDATE_TOOLS : "ListsTools/Update",
    API_UPDATE_STATUS_TOOLS : "ListsTools/UpdateStatus",
    API_GET_ALL_TOOLS : "ListsTools/All", //recibe el id de la compañia
    API_GET_SPECIFIC_TOOLS : "ListsTools/Get", //recibe el id de la compañia
    API_GET_ALL_TOOLS_FOR_SKRETTING : "ListsTools/AllListTool",


    //APIS EMPLOYEES
    API_GET_EMPLOYEES_BY_COMPANY : "Employee/GetEmployeesByCompany/",
    API_GET_EMPLOYEES_ALL_COMPANY : "Employee/GetEmployeesAllCompany",

    //APIS JOBS TYPE
    API_GET_JOB_TYPE: 'ConsultasGenerales/ConsultaJobTypeActive',

    //APIS GET EMPLOOYE
    API_GET_EMPLOYEE_BY_IDENTIFICATION : 'Employee/GetIdentification/',
    API_gET_EMPLOYEE_BY_IDENTIFICATIONREQUEST: 'Employee/GetEmployeesByCompanyRequest/',

    //APIS GET ACTIVE SITES COMPANY
    API_GET_ALL_SITES_ACTIVE : "ConsultasGenerales/ConsultaPlantasActivas",

    //APIS GET LEVELS BY ID
    API_GET_ALL_AREA_BY_ID : "ConsultasGenerales/AllAreaByPlanta/",

    //APIS REQUEST FROM
    API_UPDATE_ADMISSION_REQUEST: "EntryRequest/Update",
    API_CREATE_ADMISSION_REQUEST: "EntryRequest/Create",
    API_SEND_EMAIL_ADMISSION_REQUEST: "EntryRequest/SendMail",
    API_UPDATE_STATUS__ADMISSION_REQUEST: "EntryRequest/UpdateStatus",
    API_UPDATE_STATUS__EMERGENCY: "EntryRequest/UpdateEmergency",
    API_GET_ALL_REQUEST: "EntryRequest/All",
    API_GET_ALL_REQUEST_BY_COMPANY_ID: "EntryRequest/AllByCompany/",
    API_SEND_MAIL_TO_REJECT : "EntryRequest/SendMail",
    API_SEND_MAIL_TO_NOTIFIED_SKRETTING : "EntryRequest/SendMailToSkretting",
    API_GET_ENTRY_REQUEST_BY_EMPLOYEE: "EntryRequest/GetERequestByEmployee/",

    //API GENERATE QR
    API_CREATE_QR_AND_SEND_EMAIL : "EntryRequest/GenerarQR/",
    API_DOWNLOAD_QR : "EntryRequest/GetFileQR/",

    //API GET REQUEST ID
    API_GET_REQUEST_BY_ID: 'EntryRequest/GetId/',

    //API WORK AUTH
    API_CREATE_WORK_AUTH: 'WorkAuth/Create',
    API_GET_BY_ID_COMPANY_WORK_AUTH:  'WorkAuth/AllByCompany/',
    API_UPDATE_STATUS_WORK_AUTH: 'WorkAuth/UpdateStatus',
    API_UPDATE_WORK_AUTH:  'WorkAuth/Update',
    API_GET_WORK_AUTH_BY_ID: 'WorkAuthByID/',
    API_GET_ALL_WORK_AUTHS_FOR_ADMIN: 'WorkAuth/AllByCompany',


    //API ENTER OUT WORK
    API_GET_ENTER_OUT_WORK_BY_ID: 'EnterOutWorker/All_ID/',
    API_GET_ALL_ENTER_OUT_WORK: 'EnterOutWorker/All',
    API_CREATE_ENTER_OUT_WORK: 'EnterOutWorker/Create',
    API_UPDATE_ENTER_OUT_WORK: 'EnterOutWorker/Update',
    API_VALIDATE_QR_EMPLOYEE:'EnterOutWorker/VerificateQR/',

    //API COMPANIE
    API_GET_ALL_COMPANIES: 'RegisterCompany/All',

  //API REQUEST
  API_GET_ALL_REQUEST_INDUCTION_BY_ID : 'Request/AllByCompany/',
  API_GET_ALL_REQUEST_INDUCTION : 'Request/All',
  API_GET_EXIST_EVAL_FOR_WORK_REQUEST: 'Request/ExistEval',
  API_GET_PERSONAL_REPORT: 'Request/GetStatusReport',
  API_GET_PERSONAL_RESUMED: 'Request/GetStatusReportResumed',
  API_GET_PERSONAL_RESUMED_BY_ID: 'Request/GetStatusReportResumedById',
  API_GET_PERSONAL_REPORT_BY_ID: 'Request/GetStatusReportById/',

    //API DOCUMENTS BY EMPLOYEE
    API_GET_DOCUMENTS_BY_ID : 'GetDocumentsId/',


    API_GET_DOCUMENTS_ALL : 'EmployeeDocument/All',
    API_GET_DOCUMENTS_ALL_BY_COMPANYID : 'EmployeeDocument/AllDocumentsByIdCompany/',
    API_REPORT_ENTRY_REQUEST: 'EntryRequest/Report',
    API_REPORT_REQUEST: 'Request/Report',



    API_GET_UNSAFEACT : 'UnsafeAct/All',
    API_GET_UNSAFEACTBYID : 'UnsafeAct/UnsafeActById/',
    API_GET_UNSAFEACT_BY_USERNAME : 'UnsafeAct/ByUser/',
    API_UPDATE_UNSAFEACT_STATUS : 'UnsafeAct/UpdateStatus',
    API_UPDATE_UNSAFEACT: 'UnsafeAct/Update',
    API_CREATE_UNSAFEACT: 'UnsafeAct/Create',
    API_UPLOAD_FILE_UNSAFEACT: 'UnsafeAct/UploadFile',



    API_CREATE_ACCIDENTEINCIDENT: 'AccidentIncident/Create',
    API_UPDATE_ACCIDENTEINCIDENT: 'AccidentIncident/Update',
    API_UPDATESTATUS_ACCIDENTEINCIDENT: 'AccidentIncident/UpdateStatus',
    API_GET_ACCIDENTEINCIDENT: 'AccidentIncident/All',
    API_GET_ACCIDENTEINCIDENTBYID: 'AccidentIncident/AccidentIncidentId/',
    API_GET_ACCIDENTINCIDENTBYUSER: 'AccidentIncident/AccidentIncidentByUser/',
    API_UPLOAD_FILE_ACCIDENTEINCIDENT: 'AccidentIncident/UploadFile',
    API_ACCIDENTINCIDENT_MAIL: 'AccidentIncident/SendMail',
    API_UPLOAD_FILE_BETTER: 'GetsBetter/UploadFile',
    API_GET_BOSSBYEMPLOYEE: 'AccidentIncident/GetBossByEmployee',

    API_GET_SEVERITY_TYPE_BY_ID: 'SystemParameter/GetSystemParameterField/',

    API_CREATE_BETTER: "GetsBetter/Create",
    API_UPDATE_BETTER: "GetsBetter/Update",
    API_ALL_BETTER: "GetsBetter/All",
    API_ID_BETTER: "GetsBetter/GetsBetterId/",
    API_UPDATESTATUS_BETTER: "GetsBetter/UpdateStatus",
    API_GET_BETTER_BY_USER: "GetsBetter/GetsBetterIdUser/",
    API_SEND_MAIL_BETTER: "GetsBetter/SendMail",


    API_CREATE_PLANACTION: "ActionPlan/Create",
    API_UPDATE_PLANACTION: "ActionPlan/Update",
    API_ALL_PLANACTION: "ActionPlan/All",
    API_ID_PLANACTION: "ActionPlan/ActionPlanId/",
    API_GET_PLANACTION_BY_USER: "ActionPlan/ActionPlanByIdUser/",
    API_DELETE_PLAN_ACTION: "ActionPlan/ErasePlan",
    API_EXIST_PLAN_ACTION: "ActionPlan/ExistPlanActionById",
    API_CLOSE_PLAN_ACTION: "ActionPlan/CloseActionPlan/",


    API_CREATE_UNSAFECONDITION: "UnsafeCondition/Create",
    API_UPDATE_UNSAFECONDITION: "UnsafeCondition/Update",
    API_ALL_UNSAFECONDITION: "UnsafeCondition/All",
    API_ID_UNSAFECONDITION: "UnsafeCondition/UnsafeConditionId/",
    API_UPDATESTATUS_UNSAFECONDITION: "UnsafeCondition/UpdateStatus",
    API_GET_UNSAFECONDITION_BY_USER: "UnsafeCondition/UnsafeConditionIdUser/",
    API_UPLOAD_FILE_UNSAFECONDITION: 'UnsafeCondition/UploadFile',
    API_UNSAFECONDITION_MAIL: 'UnsafeCondition/SendMail',


    API_UNSAFEACT_FOULS:  'UnsafeAct/ReviewFauls/',
    API_UNSAFE_SEND_MAIL: 'UnsafeAct/SendMail',



    API_PARAM_GENERAL_ALL: 'SystemParameter/AllField',
    API_PARAM_GENERAL_BY_TYPE: 'SystemParameter/ViewField/',
    API_PARAM_GENERAL_UPDATE: 'SystemParameter/Update',
    API_PARAM_GENERAL_CREATE: 'SystemParameter/Create',
    API_PARAM_GENERAL_UPDATE_STATUS: 'SystemParameter/UpdateStatus',
    API_PARAM_GENERAL_UPDATE_GENERAL: 'SystemParameter/UpdateField',


    API_USER_SYSTEM_GET_ALL: 'UserInternal/All',
    API_USER_SYSTEM_UPDATE: 'UserInternal/Update',

    API_GET_ALL_ACTIONS_PER_PLAN: 'ActionPlanDetails/All',
    API_UPDATE_DATES_ACTION_PER_PLAN: 'ActionPlanDetails/UpdateDates',
    API_UPDATE_EVIDENCE_ACTION_PER_PLAN: 'ActionPlanDetails/UpdateEvidence',
    API_GET_BY_USERNAME_ACTION_PER_PLAN:'ActionPlanDetails/ByUsername/',
    API_UPLOAD_EVIDENCE_ACTION_PER_PLAN: 'ActionPlanDetails/UploadFile',
    API_UPDATE_APPROVE_REJECT_ACTION : 'ActionPlanDetails/ApprovedActionByActionId',


    API_CONSULTAS_GENERALES_INCIDENCES_ALL: 'ConsultasGenerales/IncidencesCreatedAll',
    API_CONSULTAS_GENERALES_INCIDENCES_BY_USER: 'ConsultasGenerales/IncidencesCreatedByUser/',




    API_GET_EMPLOYEE_INTERNAL_ALL : 'EmployeeInternal/All',
    API_GET_EMPLOYEE_INTERNAL_BY_NUMBERIDENTIFICATION: 'EmployeeInternal/ByNumberIdentification/',




    API_FORMSQUESTIONS_ALL : 'FormsQuestions/All',
    API_FORMSQUESTIONS_CREATE : 'FormsQuestions/Create',
    API_FORMSQUESTIONS_UPDATE : 'FormsQuestions/Update',
    API_FORMSQUESTIONS_UPDATESTATUS : 'FormsQuestions/UpdateStatus',
    API_FORMSQUESTIONS_BYTYPEFORM : 'FormsQuestions/ByTypeForm/',


    API_GET_QUESTIONSBYGROUP : 'Questions/GetQuestionsByGroup',
    API_GET_QUESTION_EVAL_ID: 'Questions/GetQByEvaluationId/',
    API_CREATE_EVALUATION: 'Myevaluation/Create',
    API_SEARCH_REPETITIONS: 'Myevaluation/RepetitionsByEvaluation/',


    API_GET_HSE_BY_USER: 'Hse/GetHseByUser/',
    API_GET_HSE_BY_ALL: 'Hse/All/',
    API_UPDATE_HSE_BY_EMPLOYEE: 'Hse/UpdateHseValues',
    API_GET_ALL_HSE_WITH_NOAPPLYQUESTION: 'Hse/GetAllByAll',
    API_GET_ALL_HSE_WITH_NOAPPLYQUESTION_USER: 'Hse/GetAllByUser/',


    API_GET_CHARLAS: "Charlas/All",
    API_CREATE_CHARLAS: "Charlas/Create",
    API_UPDATESTATUS_CHARLAS: "Charlas/UpdateStatus",
    API_UPDATE_CHARLAS: "Charlas/Update",
    API_UPLOADFILE_CHARLAS: "Charlas/UploadFile",
    API_GET_FILE_CHARLAS: "Charlas/GetFile/",
    API_GET_CHARLAS_BY_WEEK: "Charlas/CharlaByWeek",

    API_GET_CHARLAS_EVIDENCIAS_ALL: "CharlasAudits/All",
    API_CREATE_CHARLAS_EVIDENCIAS: "CharlasAudits/Create",
    API_GET_CHARLAS_EVIDENCIAS_BY_USER: "CharlasAudits/GetCharlasByUser/",
    API_UPLOADFILE_CHARLAS_EVIDENCIAS: "CharlasAudits/UploadFile",
    API_GET_FILE_CHARLAS_EVIDENCIAS: "CharlasAudits/GetFile/",
    API_ERASED_CHARLAS_EVIDENCIAS: "CharlasAudits/UpdateStatus",
    API_UPDATE_CHARLAS_EVIDENCIAS: "CharlasAudits/Update",

    API_GET_EMPLOYEE_WITH_USER: "EmployeeInternal/GetEmployeeWithUser",



    GET_SCHEDULE_PER_YEAR: "Schedule/GetByYear/",
    API_POST_CREATE_SCHEDULE: "Schedule/Create",


    API_GET_ALL_HSE_BY_STATUS: "Hse/AllComplete",
    API_UPDATE_HSE_PERMISSION: 'Hse/UpdatePermission',
    API_APPROVE_OR_REJECT_HSE_PERMISSION: "Hse/ApproveOrReject",
    API_GET_ALL_USER_BY_EMPLOYEE: "UserInternal/AllUserByEMployee",


    API_CREATE_PLANACTION_PLANAUDIT: "PlanAudit/Create",
    API_UPDATE_PLANACTION_PLANAUDIT: "PlanAudit/Update",
    API_ALL_PLANACTION_PLANAUDIT: "PlanAudit/All",
    API_ID_PLANACTION_PLANAUDIT: "PlanAudit/ActionPlanId/",
    API_GET_PLANACTION_BY_USER_PLANAUDIT: "PlanAudit/ActionPlanByIdUser/",
    API_DELETE_PLAN_ACTION_PLANAUDIT: "PlanAudit/ErasePlan",
    API_EXIST_PLAN_ACTION_PLANAUDIT: "PlanAudit/ExistPlanActionById",
    API_CLOSE_PLAN_ACTION_PLANAUDIT: "PlanAudit/CloseActionPlan/",
    API_GET_HSE_IFNOTEXIST: "PlanAudit/GetPlanAuditsByHse",
    API_GET_HSE_IFNOTEXIST_PLAN_BY_USER: "PlanAudit/GetPlanAuditsByHseAndUser/",
    API_GET_HSE_IFNOTEXIST_PLAN_BY_ALL: "PlanAudit/GetPlanAuditsByHseAndAll",

    API_GET_ALL_ACTIONS_PER_PLAN_AUDIT: 'ActionPlanAuditDetails/All',
    API_UPDATE_DATES_ACTION_PER_PLAN_AUDIT: 'ActionPlanAuditDetails/UpdateDates',
    API_UPDATE_EVIDENCE_ACTION_PER_PLAN_AUDIT: 'ActionPlanAuditDetails/UpdateEvidence',
    API_GET_BY_USERNAME_ACTION_PER_PLAN_AUDIT:'ActionPlanAuditDetails/ByUsername/',
    API_UPLOAD_EVIDENCE_ACTION_PER_PLAN_AUDIT: 'ActionPlanAuditDetails/UploadFile',
    API_UPDATE_APPROVE_REJECT_ACTION_AUDIT: 'ActionPlanAuditDetails/ApprovedActionByActionId',


    API_CREATE_AREA_GODFATHER: "SeccionAssign/Create",
    API_GET_SECCION_GODFATHER: "SeccionAssign/AreaByEmployeeAll/",
    API_GET_EMPLOYEE_BY_AREA_ASSIGNED: "SeccionAssign/AreaByEmployeeId/",

    API_GET_AUDITORS_FOR_QUALIFICATION: 'Calificacion/GetEmployeeAudit',

    API_GET_SCORE_PER_MONTH_AND_WEEK: 'ScoreEmployee/GetScorePerMonthAndWeek/',
    API_GET_WEEKS_PER_YEAR: 'ScoreEmployee/GetWeeksPerYear/',
    API_GET_YEARS: 'ScoreEmployee/GetYears',
    API_SET_BONUS: 'ScoreEmployee/UpdateScoreEmployeeMonth',

    API_EXIST_EMPLOYEE : 'Employee/ExistEmployee/',
    API_SEND_CHANGE_REQUEST : 'Employee/WorkerChangeRequest/',
    API_ALL_CHANGE_REQUEST : 'Employee/AllEmployeeChangeCompany/',

    API_EXISTS_GF_BY_PLANT: 'ConsultasGenerales/GetGodfatherByPlant',
    API_EXIST_AUDIT_IN_WEEK: 'Schedule/SearchAuditByWeekAndEmployee',

    ordinal_numbers: [
        'ERA',
        'DA',
        'ERA',
        'TA',
        'TA',
        'TA',
        'TA',
        'TA',
        'NA',
        'MA'
    ],






    //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    //Datos que se usaban en HA
    pdi_filters: [
    		'PA_ANTE',
    		'PA_CONTE',
    		'PA_DEFO',
    		'PA_DEND',
    		'PA_ECTO',
    		'PA_GAME',
    		'PA_GREG',
    		'PA_NECR',
    		'PA_NEMA',
    		'PA_NIVE',
    		'PA_TEXT',
    		'PA_UROP'
		],
    sapproval_filters: ['SAM_01'],
    sampling_status: [
        { id:1, name:'PENDIENTE', approv_actions:['take'], samp_actions:['edit', 'delete'], origid: 75 },
        { id:2, name:'RECIBIDA', approv_actions:['assign', 'reject', 'overdue'], samp_actions:[], origid: 466 },
        { id:3, name:'ASIGNADO', approv_actions:['reset', 'reject', 'overdue'], samp_actions:[], origid: 76 },
        { id:4, name:'EN PROGRESO', approv_actions:['overdue'], samp_actions:['download'], origid: 77 },
        { id:5, name:'TERMINADO', approv_actions:['approve', 'reject', 'download', 'overdue'], samp_actions:['download'], origid: 89 },
        { id:6, name:'RECHAZADA', approv_actions:[], samp_actions:[], origid: 78 },
        { id:7, name:'APROBADO', approv_actions:['download', 'overdue'], samp_actions:['download', 'followup'], origid: 90 },
        { id:8, name:'OFFLINE', approv_actions:[], samp_actions:['offline'], origid: 0 }
    ],

    ID_COMPANY_SKRETTING : 30,
};
