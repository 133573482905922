export class Newsubcomjob {
    SubCompanyID: string;
    JobTypeID: string;
    CreatorUser: string;
    LastModifierUser: string;
    CompanyID: number;
    Name: string;
    Ruc: string;
    Email: string;
    LegalName: string;
    LegalCI: string;
    legalEmail: string;
    SecurityName: string;
    SecurityCI: string;
    SecurityEmail: string;
    DeleteUser: string;
    NameJobType: string;

    constructor(){}
}
