import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../../base-crud/base-crud.component';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/services/main/global.service';
import Swal from 'sweetalert2';
import { LoginService } from 'src/app/services/authentication/login.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

const API_GET_SEVERITY_TYPE_BY_ID = environment.Global.API_GET_SEVERITY_TYPE_BY_ID;
const GET_ALL_SITES_ACTIVE = environment.Global.API_GET_ALL_SITES_ACTIVE;
const GET_ALL_AREA_BY_ID = environment.Global.API_GET_ALL_AREA_BY_ID;
const API_UPLOAD_FILE_BETTER = environment.Global.API_UPLOAD_FILE_BETTER;
const API_CREATE_BETTER = environment.Global.API_CREATE_BETTER;
const API_UPDATESTATUS_BETTER = environment.Global.API_UPDATESTATUS_BETTER;
const API_GET_BETTER_BY_USER = environment.Global.API_GET_BETTER_BY_USER;
const API_USER_SYSTEM_GET_ALL = environment.Global.API_USER_SYSTEM_GET_ALL;
const API_SEND_MAIL_BETTER = environment.Global.API_SEND_MAIL_BETTER;
const API_ALL_BETTER = environment.Global.API_ALL_BETTER;
const API_EXIST_PLAN_ACTION = environment.Global.API_EXIST_PLAN_ACTION;
const API_UPDATE_BETTER = environment.Global.API_UPDATE_BETTER;
@Component({
  selector: 'app-oportunitymejor',
  templateUrl: './oportunitymejor.component.html',
  styleUrls: ['./oportunitymejor.component.css']
})
export class OportunitymejorComponent extends BaseCrudComponent implements OnInit {
  fechaActual = new Date();
  archivos=[];
  list_incidence = [];
  userinfo;
  row_plantas = [];
  row_plantas_area = [];
  modalCreate = false;
  loadingList = false;
  userSystemList = [];
  permissions;
  existePlan = true;
  isEditable = false;

  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    public auth: LoginService,
    private globalService: GlobalService,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
  }

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
      .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
        });
      
        // this.getTypeFouls();
    this.search_fields = ['GetsBetterId','IncidentTypeName'];
    this.storage.get('userinfo').then(x => {
      this.userinfo = x;
    });
    this.auth.getPermissions().then(x => {
      this.permissions = x;
      this.getList(); 
    });
    this.getUserSystem();
    
  }

  public getUserSystem() {
    this.userSystemList = [];
    this.srv.getCall(API_USER_SYSTEM_GET_ALL).subscribe(x => {
      if(x.success){
        if(x.val != null){
          x.val.forEach(element => {
            element.Permissions = JSON.parse(element.Permissions);
            if (element.Permissions != null) {
              element.Permissions.Modules.forEach(permission => {
                if(permission.Name == "SI_PLANACTION") {
                  this.userSystemList.push(element)
                }
              });
            }
          });
        }else {
          Swal.fire(this.translations.empty, this.translations.not_user_registers, 'success');
          this.userSystemList = [];
          return;
        }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public validateCreateInfo(fields:any): boolean {
    let oport:any = {};
    oport = new FormGroup({
        'level': new FormControl(fields.level, [Validators.required]),
        'area': new FormControl(fields.area, [Validators.required]),
        'incidence': new FormControl(fields.incidence, [Validators.required]),
        'observation': new FormControl(fields.observation, [Validators.required]),
        'ResponsableUser': new FormControl(fields.ResponsableUser, [Validators.required]),
    });
    return oport.valid;
  }


  public handleInputChange(e){
    // console.log(e)
    this.archivos=[];
    var file = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    // console.log(file)
    let files = Object.values(file);
    // console.log(files)
    files.forEach(element => {
      if (this.archivos.length < 2) {
        this.archivos.push(element);
      } else {
        return Swal.fire(this.translations.only_two_imgs,'','error');
      }
    });
    
  }


  public getList() {
    this.loadingList = true; 
    // Swal.fire(
    //   {
    //     title: this.translations['loading'],
    //     html: '',
    //     icon: 'info',
    //   }
    // );
    // Swal.showLoading();
    let url = '';
    // SE VALIDA SI EL USUARIO ES ADMINISTRADOR
    if (this.permissions.includes('SI_ADMIN')) {
      url = API_ALL_BETTER;
    } else {
      url = API_GET_BETTER_BY_USER + this.userinfo['logon'];
    }
    // console.log(url)
    this.srv.getCall(url).subscribe(x => {
      this.loadingList = false; 
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          this.rows = this.rawdata;
          this.page = 1;
          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          Swal.close()
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });

    this.GetSeverityTypes()
  }

  public openCreateModal(content: any) {
    
    this.c_obj['date'] = this.globalService.formatOnlyDate(this.fechaActual);
    this.c_obj['reportby'] = this.userinfo['name'];
    this.c_obj['level'] = null;
    this.c_obj['area'] = null;
    this.c_obj['incidence'] = null;
    this.c_obj['ResponsableUser'] = null;
    this.modalCreate = true;
    this.modalService.open(content,  { backdrop: 'static',  keyboard : false, size: 'lg' });
    this.GetPlantas();
  }

  public GetSeverityTypes() {
    var qst = API_GET_SEVERITY_TYPE_BY_ID + 'INCIDENT_TYPE';
    this.srv.getCall(qst).subscribe(x => {
      this.list_incidence = [];
      if(x.success){
        if(x.val != null){
          // console.log(x.val)
          x.val.forEach(element => {
            this.list_incidence.push(
              {
                id: element.ID,
                type: element.value
              }
            );
          })
        }else {
          return;
        }
      }else {
        return;
          }
    });
  }

  public GetPlantas() {
    var qst = GET_ALL_SITES_ACTIVE;
    this.srv.getCall(qst).subscribe(x => {
      this.row_plantas = [];
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.row_plantas = rs;
          //  console.log(this.row_plantas)
        }else {
          this.row_plantas = [];
          return;
        }
      }else {
        return;
          }
    });
  }

  public searchArea() {

    if (this.modalCreate) {
      if (this.c_obj['level'] !== null) {
        var qst = GET_ALL_AREA_BY_ID + this.c_obj['level'];
        this.srv.getCall(qst).subscribe(x => {
          if(x.success){
            if(x.val != null){
              var rs = x.val;
              this.row_plantas_area = rs;
              // console.log(this.row_plantas_area)
            }else {
              this.row_plantas_area = [];
              return;
            }
          }else {
            return;
              }
        });

      } else {
        this.c_obj['area'] = null;
      }
    } else {
      if (this.u_obj['level'] !== null) {

        var qst = GET_ALL_AREA_BY_ID + this.u_obj['level'];
        this.srv.getCall(qst).subscribe(x => {
          if(x.success){
            if(x.val != null){
              var rs = x.val;
              this.row_plantas_area = rs;
              // console.log(this.row_plantas_area)
            }else {
              this.row_plantas_area = [];
              return;
            }
          }else {
            return;
              }
        });
      }else {
        this.u_obj['area'] = null;
      }
    }
   
  }

  public async createItem(modal, object) {
    this.isCreating = true;
    let validated = this.validateCreateInfo(object);
    if( !validated ){
      this.isCreating = false;
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }

    if (this.archivos.length == 0) {
      this.isCreating = false;
      return Swal.fire(this.translations.should_upload_imgs, '','error');
    }

    let new_obj = {
      IncidentId: object['incidence'],
      PlantaID: object['level'],
      SeccionID: object['area'],
      Date: object['date'],
      Photos: '',
      Observation: object['observation'],
      CreatorUser: this.userinfo['logon'],
      ResponsableUser: object['ResponsableUser']
    }
    // console.log(new_obj)
    this.srv.postCall(API_CREATE_BETTER, new_obj).toPromise().then(  async (data)=> {
      if(data.success){
        await this.sendPhotos(data); 
        await this.sendMail(data.val['GetsBetterId']);
        this.getList();
        this.isCreating = false;        
        this.c_obj = {};
        this.closeModal(modal);
      } else {
        this.isCreating = false;
        Swal.fire(data.msg, '', 'error');
      }      
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
      this.isCreating = false;
    });

  }

  public async sendPhotos(data?) {
    let formData = new FormData();
    for(let file of this.archivos) {
      formData.append(file.name, file);
    }
    formData.append('user', this.userinfo['logon']);
    formData.append('GetsBetterId', data.val.GetsBetterId);
    await this.srv.AddFiles(API_UPLOAD_FILE_BETTER, formData).toPromise().then(result => {
      if (result=="OK") {
        this.archivos=[];
      }
      else{
        Swal.fire(this.translations.evidence, result, 'error');
        this.archivos=[];
      }
    });
  }

  public async sendMail(GetsBetterId) {
    var to:any;    
    to = {};
    to['_id'] = GetsBetterId;
    to['_pdfbase64'] = null;
    let qst = API_SEND_MAIL_BETTER;
    // console.log('etro')
    await this.srv.postCall(qst,to).toPromise().then(x => {
      // console.log(x)
      Swal.fire(this.translations.msg_create,'', 'success');
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });

  }




  public edit(modal, object) {
    // console.log(object)
    this.GetPlantas();
    this.u_obj['evidencia'] = [];
    this.modalCreate = false
    this.modalService.open(modal,  { backdrop: 'static',  keyboard : false, size: 'lg' });
    this.u_obj['GetsBetterId'] = object['GetsBetterId'];
    this.u_obj['incidence'] = object['IncidentId'];
    this.u_obj['date'] = object['Date'].split('T')[0];
    this.u_obj['level'] = object['PlantaID'];
    this.u_obj['_Evidences'] = object['_Evidences'];
    object._Evidences.forEach(element => {
      this.u_obj['evidencia'].push(element.imagen);
    });
    this.searchArea();
    this.u_obj['area'] = object['SeccionID'];
    this.u_obj['observation'] = object['Observation'];
    this.u_obj['observationOld'] = object['Observation'];
    this.u_obj['reportby'] = object['Reportby'];
    this.u_obj['areaOld'] = object['SeccionID'];
    this.u_obj['levelOld'] = object['PlantaID'];
    this.u_obj['ResponsableUser'] = object['ResponsableUser'];
    this.u_obj['ResponsableUserNew'] = object['ResponsableUser'];
    this.userSystemList.forEach(element => {
      element.Username == object.ResponsableUser && (this.u_obj['ResponsableName'] = element.FirstName + ' ' + element.LastName);
    })
    this.verifyExistPlan(object);
    // console.log(this.isEditable)
  }

  public updateItem(modal, object) {
    this.isUpdating = true;
    let x = Object.values(object);
    if (x.includes(null)) {
      this.isUpdating = false;
      return Swal.fire(this.translations['msg_all_required'], '', 'error');
    }

    let new_obj = {
      GetsBetterId: object['GetsBetterId'],
      IncidentId:  object['incidence'],
      PlantaID: this.u_obj['level'] !=  object['levelOld'] ? object['level'] : this.u_obj['levelOld'],
      SeccionID:  this.u_obj['area'] !=  object['areaOld'] ? object['area'] : this.u_obj['areaOld'],
      Observation: this.u_obj['observationOld'] != object['observation'] ? object['observation'] : this.u_obj['observationOld'] ,
      LastModifierUser:  this.userinfo['logon'],
      ResponsableUser : this.u_obj['ResponsableUser'] != object['ResponsableUserNew'] ? object['ResponsableUserNew'] : this.u_obj['ResponsableUser'] 
    }
    this.srv.postCall(API_UPDATE_BETTER, new_obj).toPromise().then(  (data)=> {
      if(data.success){
        this.u_obj = {};
        Swal.fire(this.translations.msg_update,'', 'success');
        this.getList();
        this.isUpdating = false;
        this.closeModal(modal);
      } else {
        this.isUpdating = false;
        Swal.fire(data.msg, '', 'error');
      }      
    },catchError =>{
      this.u_obj = {};
      Swal.fire(catchError.message, '', 'error');
      this.isUpdating = false;
    });
  }

  public async verifyExistPlan(row) {
    // console.log(row)
    let obj = {
      idInspection : row.GetsBetterId,
      justification : "Mejora u Oportunidad" 
    }
    await this.srv.postCall(API_EXIST_PLAN_ACTION, obj).toPromise().then( data =>{
      if(data.success){
        if (data.val != null) {
          if (data.val.length > 0 || row['StatusInsp'] == 1) {
            this.isEditable = true;
            this.existePlan = true;
          } else {
            this.isEditable= false;
            this.existePlan = false;
          }
        } else {
          this.isEditable= false;
          this.existePlan = false;
        }
      } else {
        Swal.fire(data.msg, '', 'error');
      }       
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
    });
  }

 
  public async deleteBetter(row) {
    await this.verifyExistPlan(row);
    if (this.existePlan) {
      return Swal.fire( this.translations.have_actionplan , this.translations.you_have_action_plan,'error');
    } else {
      let obj = {
        GetsBetterId : row.GetsBetterId,
        LastModifierUser: this.userinfo['logon'],
        Status : 3
      }
      // console.log(obj)
      // console.log('no existe un plan')
      this.srv.postCall(API_UPDATESTATUS_BETTER, obj).toPromise().then( data =>{
        if(data.success){
          Swal.fire(this.translations.msg_update, '', 'success');
          this.getList();
        }else {
          Swal.fire(data.msg, '', 'error');
        }    
      },catchError =>{
        Swal.fire(catchError.message, '', 'error');
      });
    }
  }

}
