import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import * as fileSaver from 'file-saver';
import * as moment from 'moment-timezone';
import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { isNumeric } from 'rxjs/util/isNumeric';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
import { saveAs as importedSaveAs } from "file-saver";
import { ConditionalExpr, ReturnStatement } from '@angular/compiler';
//import { Companieadmin } from '../../../interfaces/companieadmin';
//import * as CryptoJS from 'crypto-js';
//import { format } from 'd3-format';

const API_SUBCOMPANIEBYCOMPIE = environment.API_SUBCOMPANIEBYCOMPIE;
const API_CONSDOCUMENEMP = Global.API_CONSPARAMDOCUMENT_1;//Global.API_CONSPARAMDOCUMENTYPE;
const API_GETCOMPANYID = Global.API_CONSULTACOMPANIESID;
const API_UPDATEEMP = Global.API_UPDATECOMPANIE;
const API_UPDATESTATUSEMPLOYEE = Global.API_STATUSEMPLOYEE_INSURANCE;
const API_DELETE_DOCUMET = environment.Global.API_DELETE_DOCUMET;

@Component({
  selector: 'app-regcompaniesemp',
  templateUrl: './regcompaniesemp.component.html',
  styleUrls: ['./regcompaniesemp.component.css']
})
export class RegcompaniesempComponent extends BaseCrudComponent {

  loadingRequired = false;
  translations: any;
  available_languages: any;
  mostarselectemp;
  currlang: any;
  version: any;
  userinfo: any;
  company: any;
  date_actually = new Date();
  //u_obj:any;
  date;
  id;
  permissions = [];
  mostargridvacio = false;
  mostargrid = true;
  NewCompanie: FormGroup;
  directiontemp;
  phonetemp;
  namefile: any;
  cargados = false;
  archivos = [];
  tipotrabajo = [];
  mensaje = "";
  size_max = 3145728;//3mb
  date_doc: any;
  curr_parameter: any;
  public rowEmployee: any;
  alertMsj: any

  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
    this.id = route.snapshot.params.id;

    this.read_url = API_SUBCOMPANIEBYCOMPIE;
    this.select_url = API_GETCOMPANYID;
    // this.create_url = API_REGISTEREMP;
    // this.select_url = API_CONSULTAEMP;
    this.update_url = API_UPDATEEMP;
    // this.delete_url = API_DESATEEMP;
    this.active_url = API_UPDATESTATUSEMPLOYEE;
    this.documentemp_url = API_CONSDOCUMENEMP;
    this.delete_url_details = API_DELETE_DOCUMET;
    this.date_doc = new Date();
    this.curr_parameter = 0;
    this.search_fields = ['Name', 'Ruc'];

    this.c_obj['Company'] = [];

    this.reload.subscribe(val => {
      if (val) {
        super.clearData();
        this.getList();
      }
    });
    // this.crearFormEmp();
  }


  async ngOnInit() {
    try {
      this.date = new Date();
      this.company = [];
      //this.u_obj=[];

      // debugger;
      console.log(this.translate);
      await this.translate.getTranslation(this.translate.currentLang)
        .toPromise().then((translations) => {
          this.translations = translations;
          console.log(this.translations);
          super.ngOnInit();
          //   this.getList();
        });
      this.userinfo = [];
      this.userinfo['name'] = '';
      this.userinfo['emailAddress'] = '';

      await this.storage.get('userinfo').then(x => {
        this.userinfo = x;
        console.log('UserInfo',this.userinfo);

        console.log(this.userinfo.logon);

        console.log(typeof(this.userinfo.logon));
        // console.log(Number(this.userinfo.logon));
        console.log(isNumeric(this.userinfo.logon));
        if (isNumeric(this.userinfo.logon)) {
          this.mostarselectemp = false;
          this.getList();
        } else {
          this.getList();
          //this.mostarselectemp = true;
        }
      });

      console.log('Linea 141: ',this.userinfo);
    }
    catch(error){
      console.log('Ha entrado al catch, el erro: ',error);
    }
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  isNullUndefinedOrEmpty(value: any):boolean{
    return value === null || value === undefined || value === '';
  }

  public getList() {
    //debugger;
    var qst = this.read_url;
    this.loadingList = true;

    var id: any;
    id = {};
    id.CompanyID = this.userinfo.selectedCompanyId;

    this.GetCompanyData2();
    this.getListDocument();
    this.GetDocumentUpload();
    this.getTypeJob();
    Swal.fire(
      {
        title: this.translations['loading'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    //console.log("qst==>",qst,"id==>",id);
    this.srv.postCall(qst, id).subscribe(x => {
      //console.log("entrooooooooo:",x);
      this.loadingList = false;
      if (x.success) {
        if (x.val != null && x.val.length > 0) {
          var rs = x.val;
          this.rows = rs;
          Swal.close()
        } else {
          // Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          Swal.close();
          this.rows = [];
          return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        this.loadingList = false;
        Swal.close();
        return;
      }
    });


  }

  public getListDocument() {
    // debugger;
    var qst = this.documentemp_url;

    var to: any;
    to = {};
    to._IdComp = this.userinfo.selectedCompanyId;
    to._Id = 25;
    this.srv.postCall(qst, to).subscribe(x => {
      this.loadingList = false;
      if (x.success) {
        if (x.val.length != 0) {
          var rs = x.val;
          this.rowdocument = rs;
        } else {
          // Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rowdocument = [];
          return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public updateItem(content: any, form: any) {
    try{
      // console.log('1')
      console.log('form==>',form);
      this.isUpdating = true;
      var qst = this.update_url;
      if (form == undefined) {
        form = {};
        throw new Error(this.translations.msg_all_required);
      }
      // console.log('2')

      console.log("form==>", form, "com_Securitymail==>", form.com_Securitymail, "com_Securitymail===>", form['com_Securitymail']);
      let mailskComplete = form['com_Securitymail'];
      let representative = form['com_Securityrepresentative'];

      if(!this.isNullUndefinedOrEmpty(mailskComplete) || !this.isNullUndefinedOrEmpty(representative)){
        form['com_Securitymail'] = mailskComplete.toLowerCase();
        let mailsk = form['com_Securitymail'].split('@');
        // console.log("mailsk==>",mailsk);
        if (mailsk[1] != 'skretting.com') {
          form = {};
          let messageError = this.translations.mail_si + ' ' + this.translations.msg_mail_security_required;
          throw new Error(messageError);
        }
      }
      else{
        form['com_Securitymail']  = null;
        form['com_Securityrepresentative'] = null;
      }
      console.log('3')

      var to: any;
      to = {};
      to = form;
      to.CompanyId = this.userinfo.selectedCompanyId;

      this.srv.postCall(qst, to).subscribe(x => {
        console.log('to==> ', to);
        console.log('qst==>', qst);
        this.isUpdating = false;
        Swal.close();
        if (x.success) {
          this.reload.next(true);
          this.closeModal('update5');
          Swal.fire('¡Actualizado!', x.msg, 'success');
          this.curr_parameter = 0;
          this.getList();

        }
      }, err => {
        Swal.close();
        Swal.fire('Error');
        this.isUpdating = false;
      });
    }
    catch(error){
      console.log(error);
      if (error instanceof Error) {
        console.log(error.message);
        Swal.fire(error.message, '', 'error');
      } else {
        Swal.fire('Se produjo un error inesperado!','','error');
      }
    }
    finally{
      this.isUpdating = false;
    }
  }

  public GetCompanyData() {
    var par_url = this.select_url;
    var id = this.userinfo.selectedCompanyId;

    par_url = par_url + "/" + id;
    return this.srv.getCall(par_url).pipe(
      mergeMap(data => {
        let mydata: any = { success: false, msg: '', val: [] };
        mydata = data;

        if (mydata.success == true) {
          return of(mydata.val);
        }
        else {
          return of(false);
        }
      }),
      catchError(data => of(false))
    );
  }

  public GetCompanyData2() {
    var par_url = this.select_url;
    var to: any;
    to = {};
    to.CompanyId = this.userinfo.selectedCompanyId;
    this.srv.postCall(par_url, to).subscribe(x => {
      this.loadingList = false;
      if (x.success) {
        if (x.val.length != 0) {
          var rs = x.val;
          this.u_obj = rs[0];
          //console.log(this.u_obj)
        } else {
          // Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.u_obj = [];
          return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  async onExpSubmit(row: any) {
    if (this.archivos.length == 0) {
      Swal.fire(this.translations.document, this.translations.document_error_minqty, 'error');
      return;
    }
    var size_document = this.archivos[0].size;
    if (size_document > this.size_max) {
      Swal.fire(this.translations.document, this.translations.pdi_img_toobig, 'error');
      this.archivos = [];
      return;
    }
    debugger
    if (row.date_doc == undefined || row.date_doc.trim() == "") {
      Swal.fire(this.translations.enter_date, '', 'error');
      return;
    }
    let formData = new FormData();
    formData.append('FileUpload', this.archivos[0]);
    formData.append('CompanyID', this.userinfo.selectedCompanyId);
    formData.append('ParamDocumentID', row.ParamDocumentID);
    formData.append('CreatorUser', this.userinfo.logon);
    await this.srv.AddFileDetails(formData).toPromise().then(async result => {
      if (result == "OK") {
        this.closeModal('update5');
        await this.uploadDataBase(row);
        this.archivos = [];
        Swal.fire(this.translations.msg_update, '', 'success');

      }
      else {
        Swal.fire('¡Documento!', result, 'success');
        this.archivos = [];
        this.getList();
      }
    });
  }

  public async uploadDataBase(row: any) {
    var par_url = "CompanyDocument/Update";
    var to: any;
    to = {};
    to.ParamDocumentID = row.ParamDocumentID;
    to.CompanyID = this.userinfo.selectedCompanyId;
    to.LastModifierUser = this.userinfo.logon;
    to.CreatorUser = this.userinfo.logon;
    to.ImageFileName = this.namefile;
    to.DateDocument = row.date_doc;


    await this.srv.postCall(par_url, to).toPromise().then(x => {
      this.isCreating = false;
      Swal.close();
      if (x.success) {
        // this.reload.next(true);
        this.closeModal('update5');
        this.getList();

      }

    }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }

  handleInputChange(e) {
    this.archivos = [];
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

    const archivocapture = e.target.files[0];
    this.archivos.push(archivocapture);
    this.namefile = file.name;
  }

  public GetDocumentUpload() {
    var par_url = "CompanyDocument/GetDocumentsId/" + this.userinfo.selectedCompanyId;
    var to: any;

    to = {};
    to.CompanyId = this.userinfo.selectedCompanyId;
    this.srv.postCall(par_url, to).subscribe(x => {
      this.loadingList = false;
      if (x.success) {
        if (x.val.length != 0 && x.val.length > 0) {
          var rs = x.val;
          this.rowdocumentupload = rs;
          if (rs[0].status_doc == 'A' || rs[0].status_doc == 'R') {
            this.cargados = true;
          }
          else {
            this.cargados = false;
          }
        } else {
          // Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rowdocumentupload = [];
          this.cargados = false;
          this.mensaje = "";
          return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  downloadDocFile(data) {
    const DocFileName = data.CompDocumentId;
    const name = data.ImageFileName;
    //var DocFile = DocFileName.slice(0, -5);
    this.srv.downloadFile(DocFileName).subscribe((data) => {
      importedSaveAs(data, name)//DocFileName)
    });
  }



  public showAlertDocuments(content: any) {

    // Consult Api
    var ID = 144 // Set ID Message, #SystemParameter
    var to: any;
    to = {};
    var par_url = "ParameterDocument/getAlertMessage" + '/' + ID;
    this.isUpdating = true;
    this.srv.getCall(par_url).subscribe(x => {
      this.isUpdating = false;
      Swal.close();
      if (x.success) {
        if (x.val != null) {
          var msj: any
          msj = x.val
          this.alertMsj = msj.value

          // Open Modal
          this.modalService.open(content, { backdrop: 'static', keyboard: false, size: 'lg', centered: true });
        }

      }
      else {
        Swal.fire(x.msg, '', 'error');
      }
    });

  }

  public UpdateStatus() {

    this.closeModal('alert'); // Close Modal Alert


    var par_url = "CompanyDocument/UpdateStatus";
    var to: any;
    to = {};
    to.CompanyID = this.userinfo.selectedCompanyId;
    to.Status = "P";
    to.StatusId = 38;
    if (this.u_obj.com_Securitymail == "" || this.u_obj.com_Securitymail == " " || this.u_obj.com_Securitymail == null) {
      Swal.fire('¡Importante!', this.translations['msg_mail'], 'warning');
      return;
    }

    let trans = this.translations;
    Swal.fire({
      title: trans.sampapp_refmethodswarn,
      text: '',
      icon: 'warning',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      cancelButtonText: trans.cancel,
      confirmButtonText: trans.ok,
      preConfirm: () => {
        this.saveVerificationAPI(to);
      }
    }).then((result) => {
    });


  }

  saveVerificationAPI(to: any) {
    var par_url = "CompanyDocument/UpdateStatus";
    this.isUpdating = true;
    this.srv.postCall(par_url, to).subscribe(x => {
      this.isUpdating = false;
      Swal.close();
      if (x.success) {
        // this.reload.next(true);
        this.closeModal('update5');
        Swal.fire(this.translations.msg_update, x.msg, 'success');
        this.getList();
      }
      else {
        Swal.fire(x.msg, '', 'error');
      }
    });
  }
  public getTypeJob() {
    let url = 'ConsultasGenerales/ConsultaJobType';
    var to: any;
    to = {};
    to.CompanyID = this.userinfo['selectedCompanyId'];
    this.srv.getCall(url).subscribe(x => {
      this.loadingList = false;
      if (x.success) {
        if (x.val != null) {
          this.tipotrabajo = x.val;
        } else {
          this.tipotrabajo = [];
          return;
        }
      } else {
        return;
      }
    });
  }
  public addParameter(obj: any) {
    if (this.curr_parameter == 0) {
      return;
    }
    else {
      let parm = this.tipotrabajo.filter(f => {
        return f.JobTypeID == this.curr_parameter;
      });
      let checker = obj['CompanyByJob'].filter(f => {
        return f.CompanyID == this.curr_parameter;
      });
      if (parm.length > 0) {
        let parameter = parm[0];
        obj['CompanyByJob'].push({ JobTypeID: parameter['JobTypeID'], CompanyId: obj['CompanyId'], fullname: parameter['Name'] });
      }
    }

  }

  public delParameter(obj: any, position: number) {
    obj['CompanyByJob'].splice(position, 1);
  }

  openUpdateModal(content: any, obj1: any) {
    let obj = JSON.parse(JSON.stringify(obj1));
    this.u_objSub = obj
    //console.log("objeto de llegada=>", this.u_objSub, "ubj=>", this.u_obj);
    this.getEmployeeList();
    //this.curr_parameter= [];
    //this.curr_parameterl=[];

    this.modalService.open(content, { backdrop: 'static', keyboard: false, size: 'lg', centered: true });


  }

  public getEmployeeList() {
    var qst = Global.API_ALLEMPLOYEECOMPANYONLYID + "/" + this.userinfo['selectedCompanyId'];
    this.loadingList = true;

    Swal.fire(
      {
        title: this.translations['loading'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;

      if (x.success) {
        if (x.val != null) {
          this.rawdata = x.val;
          var rs = x.val;
          this.rowEmployee = rs;
          this.page = 1;
          if (Array.isArray(this.rowEmployee)) {
            this.pageTotal = this.rowEmployee.length;
          }
          this.filterAll();
          Swal.close()

        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rowEmployee = [];
          //return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }
  toggleEditable(event, id) {
    if (event.target.checked) {
      this.Activar(id, 1);
    } else {
      this.Activar(id, 2);
    }
  }

  Activar(obj: any, valor: any) {
    var to: any;
    to = {};
    to.EmployeeId = obj;
    to.Status = valor;
    var qst = this.active_url;
    this.srv.postCall(qst, to).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if (x.success) {
        this.reload.next(true);
        if (valor == "1") {
          Swal.fire('¡Activado!', x.msg, 'success');
        }
        else {
          Swal.fire('¡Desactivado!', x.msg, 'success');
        }

        this.getList();
      }
      else {
        Swal.fire(x.msg, '', 'error');
      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }

  public deleteFile(position, modal, docName, CompDocumentId) {
    //this.position = position
    //this.docName = docName
    //this.CompDocumentId = CompDocumentId
    //this.modalService.open(modal, { backdrop: 'static', keyboard: false,  size: 'lg', centered:true });

    let trans = this.translations;
    Swal.fire({
      title: '¿Está seguro que desea eliminar el archivo ' + docName + ' ?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      cancelButtonText: trans.cancel,
      confirmButtonText: trans.ok,
      allowOutsideClick: false,
      preConfirm: () => {
        this.confirmDeleteFile(CompDocumentId);
      }
    }).then((result) => {
    });
  }
  confirmDeleteFile(CompDocumentId) {

    var qst = this.delete_url_details;
    var to: any;

    to = {};
    to.CompDocumentId = CompDocumentId
    to.DeleteUser = this.userinfo.logon;


    this.isUpdating = true;
    this.srv.postCall(qst, to).subscribe(x => {
      this.isUpdating = false;
      if (x.success) {
        this.getList();
      }
      else {
        Swal.fire(x.msg, '', 'error');
      }
    });
  }
  validarStatus(row: any): boolean {
    console.log(row)
    let dateEnd = new Date(row.DateEnd)
    // row.DateEnd = new Date('2024-01-22')
    //debugger;

    if (row.status_name === 'ACTIVO' || (row.status_name === 'REVISIÓN' || row.status_name === 'RENOVAR' || row.status_name === 'VALIDADO') && this.isRenewalStatus(dateEnd)) {//( fechaLimite >= new Date(date_comparation)) ||
      row.status_name = "RENOVAR";//fechaLimite >=  new Date(date_comparation)
      return true;
    }else if(row.status_name === 'RECHAZADO'){
      return true;
    }

    return false;
  }
  // Método para determinar el estado de renovación del documento
  isRenewalStatus(expirationDate: Date): boolean {
    // Obtener la fecha actual
    const currentDate = new Date();

    // Calcular la fecha límite para la renovación (10 días antes de la fecha de caducidad)
    const renewalLimit = new Date(expirationDate);
    renewalLimit.setDate(renewalLimit.getDate() - 10);

    // Comparar la fecha actual con la fecha de caducidad y la fecha límite para la renovación
    if (currentDate > expirationDate || currentDate >= renewalLimit) {
      return true; // Documento vencido o Renovación necesaria (faltan menos de 10 días para la caducidad)
    } else {
      return false; // Documento válido
    }
  }

}
