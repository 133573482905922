import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../services/authentication/login.service';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/main/api.service';
import {saveAs as importedSaveAs  } from "file-saver";
import { isNumeric } from 'rxjs/internal-compatibility';

const URL_TO_DOWNLOAD_QRCODE = environment.Global.API_DOWNLOAD_QR;

@Component({
  selector: 'app-excelenciahome',
  templateUrl: './excelenciahome.component.html',
  styleUrls: ['./excelenciahome.component.css']
})
export class ExcelenciahomeComponent implements OnInit {

  translations:any;
  available_languages:any;
  currlang:any;
  version:any;
  items:any;
  permissions = [];
  userinfo = [];
  isSkretting = false;

  constructor(
    public _router: Router,
    public translate: TranslateService,
    public auth: LoginService,
    private storage: Storage,
    private srv: ApiService,
  ) { }

  ngOnInit() {
    this.items = [
      {title: 'menu_myevaluation', url: '/myevaluation', icon: 'mdi mdi-adjust', perm: 'SI_CONSOLINDUCCION'}, // modulo de evaluaciones por trabajador
      {title: 'menu_inspeccion', url: '/incidents', icon: 'mdi mdi-adjust', perm: 'SI_REGINSPECCION'}, // modulo de incidencias
      {title: 'menu_audits', url: '/audits', icon: 'mdi mdi-adjust', perm: 'SI_OPORTUNITEVALEXT'}, // modulo de auditorías
      // {title: 'menu_reporting', url: '/reporting', icon: 'mdi mdi-adjust', perm: 'SI_CONCOMPANIES'}, //'modulo de reporteria
      {title: 'menu_workers_plant', url: '/workersPlant', icon: 'mdi mdi-adjust', perm: 'SI_PERMISO'}, // modulo de trabajadores en planta
      {title: 'my_qrcode', url: '/qrRequest', icon: 'mdi mdi-adjust', perm: 'SI_QR_REQUEST'}, // modulo de qr por trabajador
    ];

    this.auth.getPermissions().then(x => {
        this.permissions = x;
    });

    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';
    this.storage.get('userinfo').then(x => {
      this.userinfo = x;
      // console.log(this.userinfo)
      if (isNumeric(this.userinfo['logon'])) {
        this.isSkretting = false;
      } else {
        this.isSkretting = true;
      }
    });
  }

  hasPermission(str:string){
    if( this.permissions.indexOf(str) > -1 ){
        return true;
    }
    else{
        return false;
    }
  }

  // downloadQR () {
  //   // console.log('Se descargara el codigo qr');
  //   // console.log(this.userinfo)
  //   let qst = URL_TO_DOWNLOAD_QRCODE + `${this.userinfo['userCode']}`;
  //   this.srv.downloadFileQR(qst).subscribe(data => {
  //     // console.log(data);
  //     importedSaveAs(data, 'QR - INGRESO PLANTA SKRETTING');
  //   });
  // }

}
