import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { FullComponent } from './layouts/full/full.component';

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { setTheme } from 'ngx-bootstrap/utils';
setTheme('bs4');

import { Ic2routes } from './ic2-routing';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Ic2Component } from '../ic2/ic2.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { QuillModule } from 'ngx-quill';
import {NgxImageCompressService} from 'ngx-image-compress';

import { BaseCrudComponent } from './base-crud/base-crud.component';
import { CompaniesComponent } from './companies/companies.component';

import { LaddaModule } from 'angular2-ladda';
import { LightboxModule } from 'ngx-lightbox';
import { OrderModule } from 'ngx-order-pipe';

import { SettingsComponent } from './settings/settings.component';
//CRUDS
import { CrudComponent } from './maint/crudhome/crud.component';
import { ParameterComponent } from './maint/parameter/parameter.component';
import { CompanyComponent } from './maint/company/company.component';
import { CountryComponent } from './maint/country/country.component';
import { ProductionGroupComponent } from './maint/production-group/production-group.component';
import { UserComponent } from './maint/user/user.component';
import { FormshomeComponent } from './forms/formshome/formshome.component';
import { ProchomeComponent } from './processes/prochome/prochome.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ParamjobtypeComponent } from './maint/paramjobtype/paramjobtype.component';
import { ParamplantaComponent } from './maint/paramplanta/paramplanta.component';
import { ParamareaComponent } from './maint/paramarea/paramarea.component';
import { ParamgeneralesComponent } from './maint/paramgenerales/paramgenerales.component';
import { ParamgenedetailComponent } from './maint/paramgenedetail/paramgenedetail.component';
import { ParamdocujobtypeComponent } from './maint/paramdocujobtype/paramdocujobtype.component';
import { ParamdocuComponent } from './maint/paramdocu/paramdocu.component';
import { ParamtypefoulComponent } from './maint/paramtypefoul/paramtypefoul.component';
import { ParamfoulsComponent } from './maint/paramfouls/paramfouls.component';
import { ParamsancionComponent } from './maint/paramsancion/paramsancion.component';
import { RegsubcontracComponent } from './forms/regsubcontrac/regsubcontrac.component';
import { RegsubcontractypejobComponent } from './forms/regsubcontractypejob/regsubcontractypejob.component';
import { RegsubcontracdocuComponent } from './forms/regsubcontracdocu/regsubcontracdocu.component';
import { RegcompaniesComponent } from './forms/regcompanies/regcompanies.component';
import { RegcompaniesdocuComponent } from './forms/regcompaniesdocu/regcompaniesdocu.component';
import { ExcelenciahomeComponent } from './excelencia/excelenciahome/excelenciahome.component';
import { ParamcomunicadoComponent } from './maint/paramcomunicado/paramcomunicado.component';
import { ComunicadosComponent } from './maint/comunicados/comunicados.component';
import { ParamtipopreguntaComponent } from './maint/paramtipopregunta/paramtipopregunta.component';
//import { ParampreguntaComponent } from './maint/parampregunta/parampregunta.component';
import { ParampreguntadetalleComponent } from './maint/parampreguntadetalle/parampreguntadetalle.component';
import { ParamevaluacionComponent } from './maint/paramevaluacion/paramevaluacion.component';
import { RegcompaniesempComponent } from './forms/regcompaniesemp/regcompaniesemp.component';
import { QuestionsComponent } from './maint/questions/questions.component';
import { QuestiondetailComponent } from './maint/questiondetail/questiondetail.component';
import { RegemployeeComponent } from './forms/regemployee/regemployee.component';
import { RegemployeedocComponent } from './forms/regemployeedoc/regemployeedoc.component';
import { ConsdochistoryComponent } from './forms/consdochistory/consdochistory.component';
import { ConsdochistorycompaniesComponent } from './forms/consdochistorycompanies/consdochistorycompanies.component';
import { ConsdochistorysubcompaniesComponent } from './forms/consdochistorysubcompanies/consdochistorysubcompanies.component';
import { ConsdochistoryemployeesComponent } from './forms/consdochistoryemployees/consdochistoryemployees.component';
import { ProapprovedocumentsComponent } from './processes/proapprovedocuments/proapprovedocuments.component';
import { ProapprovedocumentscompanyComponent } from './processes/proapprovedocumentscompany/proapprovedocumentscompany.component';
import { ProapprovedocumentssubcompanyComponent } from './processes/proapprovedocumentssubcompany/proapprovedocumentssubcompany.component';
import { ProapprovedocumentsemployeesComponent } from './processes/proapprovedocumentsemployees/proapprovedocumentsemployees.component';
import { ProrequestComponent } from './processes/prorequest/prorequest.component';
import { MyevaluationComponent } from './excelencia/myevaluation/myevaluation.component';
import { MyevaluationepecificComponent } from './excelencia/myevaluationepecific/myevaluationepecific.component';
import { OpportunitiesComponent } from './maint/opportunities/opportunities.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ModalFormComponent } from '../components/modal-form/modal-form.component';

import { EvaluationinternalComponent } from './processes/evaluationinternal/evaluationinternal.component';
import { RegtoolsequipmentComponent } from './forms/regtoolsequipment/regtoolsequipment.component';
import { ProadmissionappComponent } from './processes/proadmissionapp/proadmissionapp.component';
import { RepocreatorComponent } from './processes/repocreator/repocreator.component';

import { SafePipeModule } from 'safe-pipe';
import {YouTubePlayerModule} from '@angular/youtube-player';
import { ReportingComponent } from './processes/reporting/reporting.component';
import { ProadmissemergappComponent } from './processes/proadmissemergapp/proadmissemergapp.component';
import { WorkersPlantComponent } from './excelencia/workers-plant/workers-plant.component';
import { ReportformsComponent } from './forms/reportforms/reportforms.component';
import { ActnosecureComponent } from './excelencia/incidents/actnosecure/actnosecure.component';
import { CondinosecureComponent } from './excelencia/incidents/condinosecure/condinosecure.component';
import { IncidentComponent } from './excelencia/incidents/incidentaccident/incident.component';
import { OportunitymejorComponent } from './excelencia/incidents/oportunitymejor/oportunitymejor.component';
import { PlanComponent } from './excelencia/plan/plan.component';
import { HseComponent } from './excelencia/audits/hse/hse.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgSelectConfig } from '@ng-select/ng-select';
import { ɵs } from '@ng-select/ng-select';
import { IncidentsComponent } from './excelencia/incidents/incidents.component';
import { AuditsComponent } from './excelencia/audits/audits.component';
import { ActionperplanComponent } from './excelencia/actionperplan/actionperplan.component';
import { FormsQuestionsComponent } from './maint/forms-questions/forms-questions.component';
import { PlanAuditsComponent } from './excelencia/audits/plan-audits/plan-audits.component';
import { CronogramaComponent } from './excelencia/audits/cronograma/cronograma.component';
import { CharlasComponent } from './excelencia/audits/charlas/charlas.component';
import { CharlasAuditsComponent } from './excelencia/audits/charlas-audits/charlas-audits.component';
import { MaintuserComponent } from './maint/maintuser/maintuser.component';
import { ActionplanAuditsComponent } from './excelencia/audits/actionplan-audits/actionplan-audits.component';
import { MaintareabygodfatherComponent } from './maint/maintareabygodfather/maintareabygodfather.component';
import { CalificacionesComponent } from './excelencia/audits/calificaciones/calificaciones.component';
import { QremployeeComponent } from './excelencia/qremployee/qremployee.component';
import { ProaddratingComponent } from './processes/proaddrating/proaddrating.component';
import { ProIndicatorDetailComponent } from './processes/pro-indicator-detail/pro-indicator-detail.component';
import {AuthCallbackComponent} from "./redirectS/auth-callback/auth-callback.component";
import { ProapprovechangeemployeesComponent } from './processes/proapprovechangeemployees/proapprovechangeemployees.component';
import { SilentRefreshComponent } from './redirectS/silent-refresh/silent-refresh.component';
import { ReportingPersonalComponent } from './processes/reporting-personal/reporting-personal.component';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
  minScrollbarLength: 20
};

@NgModule({
  declarations: [
    FullComponent,
    ModalFormComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    AuthCallbackComponent,
    Ic2Component,
    BaseCrudComponent,
    CrudComponent,
    CompaniesComponent,
    SettingsComponent,
    ParameterComponent,
    CompanyComponent,
    CountryComponent,
    ProductionGroupComponent,
    UserComponent,
    FormshomeComponent,
    ProchomeComponent,
    DashboardComponent,
    ParamjobtypeComponent,
    ParamplantaComponent,
    ParamareaComponent,
    ParamgeneralesComponent,
    ParamgenedetailComponent,
    ParamdocujobtypeComponent,
    ParamdocuComponent,
    ParamtypefoulComponent,
    ParamfoulsComponent,
    ParamsancionComponent,
    RegsubcontracComponent,
    RegsubcontractypejobComponent,
    RegsubcontracdocuComponent,
    RegcompaniesComponent,
    RegcompaniesdocuComponent,
    ExcelenciahomeComponent,
    ParamcomunicadoComponent,
    ComunicadosComponent,
    ParamtipopreguntaComponent,
    ParampreguntadetalleComponent,
    ParamevaluacionComponent,
    RegcompaniesempComponent,
    QuestionsComponent,
    QuestiondetailComponent,
    RegemployeeComponent,
    RegemployeedocComponent,
    ConsdochistoryComponent,
    ConsdochistorycompaniesComponent,
    ConsdochistorysubcompaniesComponent,
    ConsdochistoryemployeesComponent,
    ProapprovedocumentsComponent,
    ProapprovedocumentscompanyComponent,
    ProapprovedocumentssubcompanyComponent,
    ProapprovedocumentsemployeesComponent,
    ProrequestComponent,
    MyevaluationComponent,
    MyevaluationepecificComponent,
    OpportunitiesComponent,
    EvaluationinternalComponent,
    RegtoolsequipmentComponent,
    ProadmissionappComponent,
    RepocreatorComponent,
    ReportingComponent,
    ProadmissemergappComponent,
    WorkersPlantComponent,
    ReportformsComponent,
    ActnosecureComponent,
    CondinosecureComponent,
    IncidentComponent,
    OportunitymejorComponent,
    PlanComponent,
    PlanAuditsComponent,
    HseComponent,
    IncidentsComponent,
    AuditsComponent,
    ActionperplanComponent,
    FormsQuestionsComponent,
    CronogramaComponent,
    CharlasComponent,
    CharlasAuditsComponent,
    MaintuserComponent,
    ActionplanAuditsComponent,
    MaintareabygodfatherComponent,
    CalificacionesComponent,
    QremployeeComponent,
    ProaddratingComponent,
    ProIndicatorDetailComponent,
    ProapprovechangeemployeesComponent,
    SilentRefreshComponent,
    ReportingPersonalComponent
      ],
  imports: [
    YouTubePlayerModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    HttpClientModule,
    NgbModule,
    NgxSpinnerModule,
    NgSelectModule,
    RouterModule.forRoot(Ic2routes),
    PerfectScrollbarModule,
    NgMultiSelectDropDownModule.forRoot(),
    AgmCoreModule,
    AgmJsMarkerClustererModule,
    BsDatepickerModule.forRoot(),
    LaddaModule,
    TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
    LightboxModule,
    OrderModule,
    SafePipeModule,
    QuillModule.forRoot()
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    NgSelectConfig,
    ɵs,
    DatePipe,
    NgxImageCompressService
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class Ic2Module {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
