import { Component, OnInit } from '@angular/core';
import { BaseCrudComponent } from 'src/app/ic2/base-crud/base-crud.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/services/main/global.service';
import Swal from 'sweetalert2';

const API_GET_BY_USERNAME_ACTION_PER_PLAN_AUDIT = environment.Global.API_GET_BY_USERNAME_ACTION_PER_PLAN_AUDIT;
const API_UPDATE_DATES_ACTION_PER_PLAN_AUDIT = environment.Global.API_UPDATE_DATES_ACTION_PER_PLAN_AUDIT;
const API_UPLOAD_EVIDENCE_ACTION_PER_PLAN_AUDIT = environment.Global.API_UPLOAD_EVIDENCE_ACTION_PER_PLAN_AUDIT;


@Component({
  selector: 'app-actionplan-audits',
  templateUrl: './actionplan-audits.component.html',
  styleUrls: ['./actionplan-audits.component.css']
})
export class ActionplanAuditsComponent extends BaseCrudComponent implements OnInit {
  userinfo;
  files = [];
  fileswithimages = [];
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private globalService: GlobalService,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
  }

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
      .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
        });
      
        // this.getTypeFouls();
    this.search_fields = ['PlanActionAuditID','Action'];
    this.storage.get('userinfo').then(x => {
      this.userinfo = x;
      this.getList();
    });
  }

  public getList() {
    this.loadingList = true;
    // console.log(API_GET_BY_USERNAME_ACTION_PER_PLAN_AUDIT + this.userinfo['logon'])
    this.srv.getCall(API_GET_BY_USERNAME_ACTION_PER_PLAN_AUDIT + this.userinfo['logon']).subscribe(x => {
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          this.rows = this.rawdata;
          // console.log(this.rows)
          this.page = 1;
          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          this.loadingList = false;
        }else {
          this.loadingList = false;
          Swal.fire(this.translations.empty, this.translations.not_user_registers, 'success');
          return;
        }
      }else {
        this.rows = [];
        this.loadingList = false;
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }


  public openUpdateDates(modal, row) {
    let obj = JSON.parse(JSON.stringify(row))
    this.files = [];
    this.fileswithimages = [];
    this.u_obj = {};
    this.u_obj['PlanActionAuditID'] = obj.PlanActionAuditID;
    this.u_obj['Action'] = obj.Action;
    this.u_obj['Details'] = obj.Details;
    this.u_obj['Observation'] = obj.Observation;
    this.u_obj['StatusEvidence'] = obj.StatusEvidence;
    this.u_obj['InitDate'] = obj.InitDate !== null ? obj.InitDate.split('T')[0] : null;
    this.u_obj['EndDate'] = obj.EndDate !== null ? obj.EndDate.split('T')[0] : null;
    this.u_obj['PlanProgDate'] = obj.PlanProgDate !== null ? obj.PlanProgDate.split('T')[0] : null;
    this.u_obj['PlanProg2Date'] = obj.PlanProg2Date !== null ? obj.PlanProg2Date.split('T')[0] : null;
    this.u_obj['PlanEndDate'] = obj.PlanEndDate !== null ? obj.PlanEndDate.split('T')[0] : null;
    this.u_obj['prog1status'] = obj.prog1status;
    this.u_obj['prog2status'] = obj.prog2status;
    // console.log(this.u_obj)
    this.modalService.open(modal, { backdrop: 'static',  keyboard : false, size: 'lg' });
  }

  public changeDate(date) {
    this.u_obj['EndDate'] = date
  }


  public updateDates(modal, object) {
    // console.log(object)
    this.isUpdating = true;

    if (object['prog1status'] == 0) {
      if (object['PlanProgDate'] == null) {
        this.isUpdating = false;
        Swal.fire(this.translations['msg_all_required'], '', 'error');
        return;
      }
    }

    if (object['prog2status'] == 0) {
      if (object['PlanProgDate'] == null) {
        this.isUpdating = false;
        Swal.fire(this.translations['msg_all_required'], '', 'error');
        return;
      }
    }

    let new_dates = {
      PlanActionAuditID : object['PlanActionAuditID'],
      PlanProgDate : object['PlanProgDate'],
      PlanProg2Date: object['PlanProg2Date'],
      EndDate : object['EndDate']
    }

    // console.log(new_dates)

    this.srv.postCall(API_UPDATE_DATES_ACTION_PER_PLAN_AUDIT, new_dates).toPromise().then(  (data)=> {
      if(data.success){
        this.c_obj = {};
        Swal.fire(this.translations.msg_update,'', 'success');
        this.getList();
        this.isUpdating = false;
        this.closeModal(modal);
      } else {
        this.isUpdating = false;
        Swal.fire(data.msg, '', 'error');
      }      
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
      this.isUpdating = false;
    });
    
  }

  public openUpdateEvidence(modal, row) {
    // console.log(row)
    this.files = [];
    this.fileswithimages = [];
    this.u_obj = {};
    this.u_obj['PlanActionAuditID'] = row.PlanActionAuditID;
    this.u_obj['Action'] = row.Action;
    this.u_obj['Details'] = row.Details;
    this.u_obj['Observation'] = row.Observation;
    this.u_obj['StatusEvidence'] = row.StatusEvidence;
    this.u_obj['InitDate'] = row.InitDate !== null ? row.InitDate.split('T')[0] : null;
    this.u_obj['EndDate'] = row.EndDate !== null ? row.InitDate.split('T')[0] : null;
    this.u_obj['Evidence'] =  row.Evidence !== null ? row.Evidence.split(';'): null;
    this.u_obj['_Evidences'] = row._Evidences ? row._Evidences : null;
    this.modalService.open(modal, { backdrop: 'static',  keyboard : false, size: 'lg' });
  }

  public uploadImages(event) {
    if (this.u_obj['Evidence'] !== null) {
      Swal.fire({
        title: this.translations.modify_evidence,
        text: this.translations.evidence_replaced,
        icon: "info"
      })
    }
    const file = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    const fileImg = event.target.files;
    this.files = Object.values(file);
    this.fileswithimages = Object.values(file);
    if (this.files.length > 2) {
      Swal.fire(this.translations.only_two_imgs,this.translations.only_consider_3imagesfirst,'warning');
      while(this.files.length > 2) {
        this.files.splice(this.files.length -1, 1)
        this.fileswithimages.splice(this.files.length -1, 1)
      }
    }
    this.fileswithimages.forEach(element => {
      const reader = new FileReader();
      reader.readAsDataURL(element);
      reader.onload = () => {
        element.src = reader.result;
      };
    });
    

  }

  public updateEvidence(modal) {
    this.isUpdating = true;
    let formData = new FormData();
    for(let file of this.files) {
      formData.append(file.name, file);
    }
    formData.append('UserName', this.userinfo['logon']);
    formData.append('PlanActionAuditID', this.u_obj['PlanActionAuditID']);
    this.srv.AddFiles(API_UPLOAD_EVIDENCE_ACTION_PER_PLAN_AUDIT, formData).toPromise().then(result => {
      // console.log(result)
      if (result=="OK") {  
        this.isUpdating = false;
        Swal.fire(this.translations.evidencia_uploaded, this.translations.evidencia_en_revision, 'success');
        this.getList();
        this.closeModal(modal);
        this.files = [];
        this.fileswithimages = [];
      }
      else{   
        this.isUpdating = false;
        Swal.fire(this.translations.evidencia_error_cargax, result, 'error');
        this.getList();
        this.closeModal(modal);        
        this.files = [];
        this.fileswithimages = [];
      }
    });
  }

  public openEvidenceUploaded (modal) {
    // console.log(this.u_obj)
    this.modalService.open(modal, { backdrop: 'static',  keyboard : false, size: 'lg' });
  }


}
