import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import * as fileSaver from 'file-saver';
import * as moment from 'moment-timezone';
import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { isNumeric } from 'rxjs/util/isNumeric';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
import { Newsubcomjob } from '../../../interfaces/newsubcomjob';
import { saveAs as importedSaveAs } from "file-saver";
import { ConditionalExpr, ReturnStatement } from '@angular/compiler';
import { Newinfo } from '../../../interfaces/newinfo';
import * as CryptoJS from 'crypto-js';
//import { ConsoleReporter } from 'jasmine';
//import { Console } from 'console';

const KEY = environment.KEY;
const API_REGEMPLOYEE = Global.API_REGISTERINDICATOR;
const API_ALLEMPLOYEE = Global.API_ALLINDICATORCOMPANYID;
const API_UPDATEEMPLOYEE = Global.API_UPDATEINDICATOR;
const API_UPDATESTATUSEMPLOYEE = Global.API_STATUSINDICATOR;
const API_CONSDOCUMENEMP = Global.API_CONSPARAMDOCUMENT_1;
const API_EXIST_EMPLOYEE = environment.Global.API_EXIST_EMPLOYEE;
@Component({
  selector: 'app-pro-indicator-detail',
  templateUrl: './pro-indicator-detail.component.html',
  styleUrls: ['./pro-indicator-detail.component.css']
})
export class ProIndicatorDetailComponent extends BaseCrudComponent {
  userinfo: any;
  groupselect: any;
  plantas: any;
  tipotrabajo: any;
  loadingRequired = false;
  translations: any;
  momentjs: any = moment;
  fileName: any;
  foto64;
  imageSrc: any;
  fields: any;
  list_doc: any;
  curr_parameter: any;
  curr_parameterl: any;
  passuno: string;
  passdos: string;
  passnew: string;
  conversionEncryptOutput: string;
  conversionDecryptOutput: string;
  resultado: any;
  form: FormGroup;
  default = '../../../assets/images/users/1.jpg';
  monthList = [
    { Value: 1, Text: 'Enero' },
    { Value: 2, Text: 'Febrero' },
    { Value: 3, Text: 'Marzo' },
    { Value: 4, Text: 'Abril' },
    { Value: 5, Text: 'Mayo' },
    { Value: 6, Text: 'Junio' },
    { Value: 7, Text: 'Julio' },
    { Value: 8, Text: 'Agosto' },
    { Value: 9, Text: 'Septiembre' },
    { Value: 10, Text: 'Octubre' },
    { Value: 11, Text: 'Noviembre' },
    { Value: 12, Text: 'Diciembre' }
  ];
  yearList = [
  ];
  constructor(srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private FormBuilder: FormBuilder
  ) {
    super(srv, modalService, storage, router);

    this.read_url = API_ALLEMPLOYEE;
    this.create_url = API_REGEMPLOYEE;
    this.update_url = API_UPDATEEMPLOYEE;
    this.active_url = API_UPDATESTATUSEMPLOYEE;
    this.list_doc = API_CONSDOCUMENEMP;
    this.groupselect = [];
    this.plantas = [];
    this.tipotrabajo = [];
    this.curr_parameter = 0;
    this.curr_parameterl = 0;

    this.search_fields = ['EmployeeId', 'NumberIdentification', 'Name', 'NameSubCompany'];

    this.c_obj['Employee'] = [];

    this.reload.subscribe(val => {
      if (val) {
        super.clearData();
        this.getList();
      }
    });
  }

  async ngOnInit() {

    await this.translate.getTranslation(this.translate.currentLang)
      .toPromise().then((translations) => {
        this.translations = translations;
        super.ngOnInit();
        //this.getList();
      });
    await this.generateYear();
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    await this.storage.get('userinfo').then(x => {
      this.userinfo = x;
      this.getList();
    });
  }

  public getList() {
    var qst = this.read_url + "/" + this.userinfo['selectedCompanyId'];
    this.loadingList = true;

    this.getCompany();
    this.getPlantas();
    this.getTypeJob();
    Swal.fire(
      {
        title: this.translations['loading'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;

      if (x.success) {
        if (x.val != null) {
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          this.page = 1;
          if (Array.isArray(this.rows)) {
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          Swal.close()

        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          //return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  private buildform() {
    this.form = this.FormBuilder.group({

    });

  }

  public getCompany() {
    let url = 'CompaniesConsulta/SubCompanyByIDCompany';

    var to: any;
    to = {};
    to.CompanyID = this.userinfo['selectedCompanyId'];

    //console.log("to:",to,"url:",url);

    this.srv.postCall(url, to).subscribe(x => {
      Swal.close();
      //this.groupselect=[];
      if (x.success) {
        this.groupselect = x.val;
        //console.log("x:",x);
      } else {
        this.groupselect = [];
        return;
      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }

  public getPlantas() {
    let url = 'ConsultasGenerales/ConsultaPlantas';

    var to: any;
    to = {};
    to.CompanyID = this.userinfo['selectedCompanyId'];

    //console.log("to:",to,"url:",url);

    this.srv.getCall(url).subscribe(x => {
      this.loadingList = false;
      if (x.success) {
        if (x.val != null) {
          this.plantas = x.val;
        } else {
          this.plantas = [];
          return;
        }
      } else {
        return;
      }
    });
  }

  public getTypeJob() {
    let url = 'ConsultasGenerales/ConsultaJobType';

    var to: any;
    to = {};
    to.CompanyID = this.userinfo['selectedCompanyId'];

    //console.log("to:",to,"url:",url);

    this.srv.getCall(url).subscribe(x => {
      this.loadingList = false;
      if (x.success) {
        if (x.val != null) {
          this.tipotrabajo = x.val;
        } else {
          this.tipotrabajo = [];
          return;
        }
      } else {
        return;
      }
    });
  }

  public openCreateModal(content: any) {
    this.c_obj = {};
    this.default = '../../../assets/images/users/1.jpg';
    let curr_date = this.momentjs().tz('America/Guayaquil').format('YYYY-MM-DD');
    this.c_obj['Birth_date'] = curr_date;
    this.c_obj['EmployeeByJob'] = [];
    this.c_obj['EmployeeByPlanta'] = [];

    this.c_obj['YearNumber'] = new Date().getFullYear();
    this.c_obj['MonthNumber'] = new Date().getMonth();

    this.imageSrc = null;
    this.curr_parameter = 0;
    this.curr_parameterl = 0;
    //this.modalService.open(content, { size: 'lg', backdrop: 'static', keyboard: false });
    this.modalService.open(content, { backdrop: 'static', keyboard: false, size: 'lg', centered: true });
  }

  onFileChanged(event) {
    // debugger;
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.fileName = file.name + ' ' + file.type;
      const str = reader.result.toString();
      this.foto64 = str;
      if (this.u_obj['Picture'] == null) {
        this.u_obj['Picture'] = this.foto64;
      }
      this.imageSrc = str;
      this.default = null;
    };
  }

  validateCreateInfo(fields: any) {
    let mygroup: any = {};
    mygroup = new FormGroup({
      'IncidentsNumber': new FormControl(fields.IncidentsNumber, [Validators.required]),
      'AccidentsNumber': new FormControl(fields.AccidentsNumber, [Validators.required]),
      //'Birth_date': new FormControl(fields.Birth_date, [Validators.required]),
      'PlannedARTNumber': new FormControl(fields.PlannedARTNumber, [Validators.required]),
      'ExecutedARTNumber': new FormControl(fields.ExecutedARTNumber, [Validators.required]),
      'PermitsWorkNumber': new FormControl(fields.PermitsWorkNumber, [Validators.required]),
      'EnteredPeopleNumber': new FormControl(fields.EnteredPeopleNumber, [Validators.required]),
      'MHWorked': new FormControl(fields.MHWorked, [Validators.required]),
    });

    return mygroup.valid;
  }

  public async createItem(content: any, obj: any) {
    this.isCreating = true;
    var validated = false;

    if (!isNumeric(obj.AccidentsNumber)) {
      this.isCreating = false;
      Swal.fire(this.translations.msg_all_required, '', 'error')
      return;
    }

    validated = this.validateCreateInfo(obj);
    if (!validated) {
      this.isCreating = false;
      Swal.fire(this.translations.msg_all_required, '', 'error');
      return;
    }

    obj.Picture = this.foto64;
    obj.CompanyID = this.userinfo.selectedCompanyId;
    obj.CreatorUser = this.userinfo['logon'];
    obj.Status = "A";

    // console.log("obj:",obj);

    var qst = this.create_url;

    await this.srv.postCall(qst, obj).toPromise().then(async x => {
      this.isCreating = false;
      if (x.success) {
        await this.CreateUserAndSenmail(x.val);
        if (this.resultado == "success") {
          Swal.fire(this.translations.msg_create, x.msg, 'success');
        }
        else {
          Swal.fire(this.translations.msg_create, this.translations['msg_mail_employee'], 'success');
        }
        this.closeModal(content);
        this.imageSrc = "";
        this.getList();
      }//end success
      else {
        //Swal.fire(this.translations[x.msg], '', 'error');
        Swal.fire(x.msg, '', 'error');
      }
    });
  }

  openUpdateModal(content: any, obj1: any) {
    let obj = JSON.parse(JSON.stringify(obj1));
    this.u_obj = obj
    // console.log(this.u_obj['Picture'])

    this.curr_parameter = [];
    this.curr_parameterl = [];

    this.modalService.open(content, { backdrop: 'static', keyboard: false, size: 'lg', centered: true });


  }

  public updateItem(content: any, form: any) {
    this.isUpdating = true;
    var qst = this.update_url;

    if (form == undefined) {
      form = {};
    }

    var to: any;

    if ((form.IncidentsNumber == undefined) || (form.IncidentsNumber == '')) {
      this.isUpdating = false;
      Swal.fire(this.translations.msg_all_required, '', 'warning');
      return;
    }

    to = {};
    to = form;

    //to.nationality = form.nationality;
    to.ModifierUser = this.userinfo['userId'];
    to.CompanyID = this.userinfo['selectedCompanyId'];
    //to.SubCompanyID = form.SubCompanyID;
    to.Status = "A"
    //to.JobTypeID=form.JobTypeID;



    this.srv.postCall(qst, to).subscribe(x => {
      this.isUpdating = false;
      if (x.success) {
        this.reload.next(true);
        this.closeModal('update1');
        Swal.fire(this.translations.msg_update, x.msg, 'success');
        this.curr_parameter = 0;
        this.curr_parameterl = 0;
        this.getList();

      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });

  }

  toggleEditable(event, id) {
    if (event.target.checked) {
      this.Activar(id, 1);
    } else {
      this.Activar(id, 2);
    }
  }

  Activar(obj: any, valor: any) {
    var to: any;
    to = {};
    to.EmployeeId = obj;
    to.Status = valor;
    var qst = this.active_url;
    this.srv.postCall(qst, to).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if (x.success) {
        this.reload.next(true);
        if (valor == "1") {
          Swal.fire('¡Activado!', x.msg, 'success');
        }
        else {
          Swal.fire('¡Desactivado!', x.msg, 'success');
        }

        this.getList();
      }
      else {
        Swal.fire(x.msg, '', 'error');
      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }

  public addJob() {
    this.c_obj.EmployeeByJob = [];
    this.curr_parameter.forEach(element => {
      this.tipotrabajo.forEach(trabajo => {
        if (element == trabajo.JobTypeID) {
          this.c_obj.EmployeeByJob.push({
            JobTypeID: trabajo['JobTypeID'],
            EmployeeId: this.c_obj['EmployeeId'],
            FullName: trabajo['Name']
          })
        }
      });
    });
  }

  public addPlanta() {
    this.c_obj.EmployeeByPlanta = [];
    this.curr_parameterl.forEach(element => {
      this.plantas.forEach(planta => {
        if (element == planta.PlantaID) {
          this.c_obj.EmployeeByPlanta.push({
            PlantaID: planta['PlantaID'],
            EmployeeId: this.c_obj['EmployeeId'],
            FullName: planta['Name']
          })
        }
      });
    });
  }

  public addJobUpdate() {
    this.curr_parameter.forEach(element => {
      this.tipotrabajo.forEach(trabajo => {
        if (element == trabajo.JobTypeID) {
          this.u_obj.EmployeeByJob.push({
            JobTypeID: trabajo['JobTypeID'],
            EmployeeId: this.u_obj['EmployeeId'],
            FullName: trabajo['Name']
          })
        }
      });
    });
  }

  public addPlantaUpdate() {
    this.curr_parameterl.forEach(element => {
      this.plantas.forEach(planta => {
        if (element == planta.PlantaID) {
          this.u_obj.EmployeeByPlanta.push({
            PlantaID: planta['PlantaID'],
            EmployeeId: this.u_obj['EmployeeId'],
            FullName: planta['Name']
          })
        }
      });
    });
  }

  searchEmployee() {
    this.srv.getCall(API_EXIST_EMPLOYEE + this.c_obj['NumberIdentification']).subscribe(x => {
      // console.log(x)
      if (x.success) {
        if (x.val != null) {
          Swal.fire(this.translations.employee_exist, '', 'warning')
          this.c_obj['NumberIdentification'] = null;
        }
      }
    }, error => {
      // console.log(error)
    });
  }

  generapass(numero: any, names: any) {
    if (numero != "" && numero != null) {
      this.passuno = names.substr(0, 3);
      this.passdos = numero.substr(4, 6);
      this.passnew = this.passuno.concat(this.passdos);
      this.conversionEncryptOutput = "";
      this.conversionEncryptOutput = CryptoJS.AES.encrypt(this.passnew.trim(), KEY.trim()).toString();
    }
  }

  async CreateUserAndSenmail(obj: any) {
    var qst = "Employee/CreateUser";

    this.generapass(obj.NumberIdentification, obj.Name);

    var to = obj;
    to.address = this.conversionEncryptOutput;
    to.position = this.passnew;
    if (this.conversionEncryptOutput == "") {
      this.resultado = "error";
      return;
    }

    await this.srv.postCall(qst, to).toPromise().then(x => {
      this.isCreating = false;
      if (x.success) {
        this.resultado = "success";
      }
      else {
        this.resultado = "error";
      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }

  public delParameter(obj: any, position: number) {
    obj['EmployeeByJob'].splice(position, 1);
  }
  public delParameter2(obj: any, position: number) {
    obj['EmployeeByPlanta'].splice(position, 1);
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }
  public generateYear() {
    var year = 2019;//(new Date()).getFullYear();
    for (var i = 0; i < 27; i++) {
      this.yearList.push({
        Text: String(year + i),
        Value: parseInt(String(year + i))
      });
    }
  }
}
