import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-silent-refresh',
  templateUrl: './silent-refresh.component.html',
  styleUrls: ['./silent-refresh.component.css']
})
export class SilentRefreshComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
