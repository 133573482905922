import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

const API_CONSPREGUNTADETALLE = Global.API_CONSRESPUESTAESPECIFICAPORPREGUNTA;//API_CONSRESPUESTAS;
const API_REGIPREGUNTADETALLE = Global.API_REGRESPUESTA;
const API_UPDATEPREGUNTADETALLE = Global.API_UPDATARESPUESTA;
const API_UPDATESTATUS = Global.API_UPDATESTATUSRESPUESTA;
const API_CONSPREGUNTAESPECIFICA = environment.Global.API_CONSPREGUNTAESPECIFICA;
//const API_ACTIVEPREGUNTADETALLE = environment.API_ACTIVEPREGUNTADETALLE;


@Component({
  selector: 'app-questiondetail',
  templateUrl: './questiondetail.component.html',
  styleUrls: ['./questiondetail.component.css']
})
export class QuestiondetailComponent extends BaseCrudComponent {

  id:any;
  loadingRequired = false;
  listAgr:any;
  translations:any;
  userinfo:any;
  Opciontemp :any;
  QuestionSpecific = null;
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        this.read_url = API_CONSPREGUNTADETALLE;
        this.create_url = API_REGIPREGUNTADETALLE;
        this.update_url = API_UPDATEPREGUNTADETALLE;
        //this.delete_url = API_DESACTIVEPREGUNTADETALLE;
        this.active_url = API_UPDATESTATUS;

        this.search_fields = ['Respuestas'];

        this.c_obj['ParamPreguntaDetalle'] = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
   }

   ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
          this.translations = translations;
          super.ngOnInit();
          this.getList();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    this.storage.get('userinfo').then(x => {
        this.userinfo = x;
    });
  }

  public async getList(){
    var qst = this.read_url+"/"+this.id;
    this.loadingList = true;
  
    var id:any;
    id = {};
    id.PreguntaID = this.id;
    Swal.fire(
      {
        title: this.translations['loading'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    await this.srv.postCall(qst, id).toPromise().then(x => {
      this.loadingList = false;
      console.log(x.val)
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          this.page = 1;
          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          Swal.close()
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
    await this.getQuestionSpecific();
    // console.log(this.QuestionSpecific)
  }

  public async getQuestionSpecific() {
    const url = API_CONSPREGUNTAESPECIFICA + "/" + this.id;
    await this.srv.getCall(url).toPromise().then(x => {
      if(x.success){
        if(x.val != null){
          this.QuestionSpecific = x.val[0];
        }
      }
    });
  }

  public openCreateModal(content: any) {
    let isClosedQuestion = false;
    if (this.QuestionSpecific.TipoPreguntaID == 3) {
      if ( this.rows.length == 2 ) {
        isClosedQuestion = true;
        Swal.fire(this.translations.question_type_close, this.translations.you_dont_create_more_questions,'error');
      }
    }
    console.log(isClosedQuestion)
    if (!isClosedQuestion) {
      this.c_obj = {};
      this.openUpdateModalStatic(content, this.u_obj);
    }

  }

  
  public createItem(content:any, form:any){
    ////debugger;
    var validated = true;
  
    if( form == undefined ){
      form = {};
    }
  
    validated = this.validateCreateInfo(form);
    if( !validated ){
        Swal.fire(this.translations['msg_all_required'], '', 'error');
        return;
    }
  
    var to:any;
   form.CreatorUser = this.userinfo['logon'];
   form.LastModifierUser = this.userinfo['logon'];
   form.PreguntaID = this.id;
   form.Opcion = this.Opciontemp;
   to = form;
  // console.log("to:",to);
    var qst = this.create_url;
    this.isCreating = true;
  
    this.srv.postCall(qst, to).subscribe(x => {
        this.isCreating = false;
        if(x.success){
            this.getList();
            this.closeModal(content);
            Swal.fire(this.translations.msg_create, x.msg, 'success');
        }//end success
        else{
            //Swal.fire(this.translations[x.msg], '', 'error');
            Swal.fire(x.msg, '', 'error');
        }
    });
  }

  validateCreateInfo(fields:any){
    let mygroup:any = {};
    mygroup = new FormGroup({
        'Respuestas': new FormControl(fields.Respuestas, [Validators.required]),
    });
  
    return mygroup.valid;
  }

  changeupdate(event){
    ////debugger;
    if ( event.target.checked ) {
     this.Opciontemp = 1;
    }else {
      this.Opciontemp = 0;
    }
  }
  
  public updateItem(content:any, form:any)
  {     
      this.isUpdating = true;
      var qst = this.update_url;
      
      if( form == undefined ){
        form = {};
      }
    
      var to:any;
    
      if( (form.Respuestas == undefined) || (form.Respuestas == '') ){
          Swal.fire(this.translations.msg_all_required, '', 'warning');
          return;
      }
    
      to = {};
      to.Respuestas = form.Respuestas;
      to.PreguntaID =  this.id;
      to.LastModifierUser = this.userinfo['userId'];
      to.DetallePreguntaID = form.DetallePreguntaID;
      to.Opcion = this.Opciontemp;
      // console.log("to:",to);
    
      this.srv.postCall(qst, to).subscribe(x => {
        this.isUpdating = false;
        Swal.close();
        if (x.success) {
          this.reload.next(true);
          this.closeModal('update1');
          Swal.fire(this.translations.msg_update, x.msg, 'success');
          this.getList();
          
        } 
     }, err => {
        Swal.close();
        Swal.fire('Error');
       });
    
    }

    toggleEditable(event, id) {
    
      if ( event.target.checked ) {
         this.ActivarItem(id);
     }else {
       this.deleteItem(id);
    
    }
    }
    
    deleteItem(obj: any){
      var id:any;
      id = {};
      id.DetallePreguntaID = obj;
      id.DeleteUser = this.userinfo['logon'];
      id.LastModifierUser = this.userinfo['userId'];
      id.Status=0;
      var qst = this.active_url;
      this.srv.postCall(qst, id).subscribe(x => {
        this.isCreating = false;
        Swal.close();
        if(x.success){
          this.reload.next(true);
          Swal.fire('Desactivado!', x.msg, 'success');
          this.getList();
      }
      else{
          Swal.fire(x.msg, '', 'error');
      }
     }, err => {
        Swal.close();
        Swal.fire('Error');
       });
    }
    
    ActivarItem(obj: any){
      var id:any;
      id = {};
      id.DetallePreguntaID = obj;
      id.LastModifierUser = this.userinfo['userId'];
      id.Status=1;
      var qst = this.active_url;
      this.srv.postCall(qst, id).subscribe(x => {
        this.isCreating = false;
        Swal.close();
        if(x.success){
          this.reload.next(true);
          Swal.fire('¡Activado!', x.msg, 'success');
          this.getList();
      }
      else{
          Swal.fire(x.msg, '', 'error');
      }
     }, err => {
        Swal.close();
        Swal.fire('Error');
       });
    }

}
