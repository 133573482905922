import { Component, OnInit } from '@angular/core';
import { BaseCrudComponent } from 'src/app/ic2/base-crud/base-crud.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/services/main/global.service';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';

const API_FORMSQUESTIONS_ALL = environment.Global.API_FORMSQUESTIONS_ALL;
const API_FORMSQUESTIONS_CREATE = environment.Global.API_FORMSQUESTIONS_CREATE;
const API_FORMSQUESTIONS_UPDATE = environment.Global.API_FORMSQUESTIONS_UPDATE;
const API_FORMSQUESTIONS_UPDATESTATUS = environment.Global.API_FORMSQUESTIONS_UPDATESTATUS;
const API_FORMSQUESTIONS_BYTYPEFORM = environment.Global.API_FORMSQUESTIONS_BYTYPEFORM;
const API_GET_SEVERITY_TYPE_BY_ID = environment.Global.API_GET_SEVERITY_TYPE_BY_ID; 

@Component({
  selector: 'app-forms-questions',
  templateUrl: './forms-questions.component.html',
  styleUrls: ['./forms-questions.component.css']
})
export class FormsQuestionsComponent extends BaseCrudComponent implements OnInit {
  userinfo: any;
  listGroup = [];
  listSubGroup = [];
  listForm = [{id: 1, name:'HSE'},] //  {id: 2, name: "5'S"}
  typeForm = 1;
  pageSize = 5;
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private globalService: GlobalService,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) { 
    super(srv, modalService, storage, router);
  }

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
      .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
      });
    this.search_fields = ['PreguntaId','Description'];
    this.storage.get('userinfo').then(x => {
      this.userinfo = x;
      this.getList();
    });
    
  }


  public getList(){
    this.loadingList = true;
    this.srv.getCall(API_FORMSQUESTIONS_ALL).subscribe(x => {
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          this.rows = this.rawdata;
          // console.log(this.rows)
          this.page = 1;
          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          this.loadingList = false;
        }else {
          this.loadingList = false;
          Swal.fire(this.translations.empty, this.translations.not_user_registers, 'success');
          return;
        }
      }else {
        this.rows = [];
        this.loadingList = false;
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public openCreateModal(modal) {
    if (this.typeForm == 1) {
      this.getGroup_SubGroup();
    }
    this.modalService.open(modal, { backdrop: 'static',  keyboard : false, windowClass: 'myCustomModalClass' });
  }

  public getGroup_SubGroup() {
    var urlg = API_GET_SEVERITY_TYPE_BY_ID + 'HSE_GROUP';
    var urlsg = API_GET_SEVERITY_TYPE_BY_ID + 'HSE_SUBGROUP';
    this.srv.getCall(urlg).subscribe(x => {
      this.listGroup = [];
      if(x.success){
        if(x.val != null){
          x.val.forEach(element => {
            this.listGroup.push(
              {
                id: element.ID,
                type: element.value
              }
            );
          })
          // console.log(this.listGroup)
        }else {
          return;
        }
      }else {
        return;
      }
    });

    this.srv.getCall(urlsg).subscribe(x => {
      this.listSubGroup = [];
      if(x.success){
        if(x.val != null){
          x.val.forEach(element => {
            this.listSubGroup.push(
              {
                id: element.ID,
                type: element.value
              }
            );
          })
          // console.log(this.listSubGroup)
        }else {
          return;
        }
      }else {
        return;
      }
    });
  }

  public validateForm(fields: any): boolean {
    let form:any = {};
    form = new FormGroup({
      'Description': new FormControl(fields.Description, [Validators.required]),
      'GroupId': new FormControl(fields.GroupId, [Validators.required]),
      'SubgroupId': new FormControl(fields.SubgroupId, [Validators.required])
    });
    return form.valid;
  }

  public createItem(modal, object) {
    this.isCreating = true;
    let validated = this.validateForm(object);
    if( !validated ){
      this.isCreating = false;
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }
    if (this.typeForm == null) {
      this.isCreating = false;
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }

    let new_Object = {
      Description: object['Description'],
      GroupId: Number(object['GroupId']),
      SubgroupId: Number(object['SubgroupId']),
      TypeForm: this.typeForm,
      CreatorUser: this.userinfo['logon']
    }

    // console.log(new_Object)
    this.srv.postCall(API_FORMSQUESTIONS_CREATE, new_Object).subscribe( data => {
      this.isCreating = true;
      if (data.success) {
        this.c_obj ={};
        this.typeForm = null;
        Swal.fire(this.translations.msg_create,'', 'success');
        this.getList();
        this.isCreating = false;
        this.closeModal(modal);
      } else {
        this.isCreating = false;
        Swal.fire(data.msg, '', 'error');
      }
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
      this.isCreating = false;
    });
  }

  public habilitar(event, id) {
    if ( event.target.checked ) {
      this.changeStatus(id, 1);
    }else {
      this.changeStatus(id, 2);
    }
  }

  public deleteItem(id) {
    this.changeStatus(id, 3);
  }

  public changeStatus(obj: any, valor: Number){
    var qst = API_FORMSQUESTIONS_UPDATESTATUS;
    var id:any;
    id = {};
    id.PreguntaId = obj;
    id.Status = valor;
    id.LastModifierUser = this.userinfo['logon'];
    this.srv.postCall(qst, id).subscribe(x => {
      if(x.success){
        Swal.fire(this.translations.msg_update, "", 'success');
        this.getList();
      }else{
        this.getList();
        Swal.fire(x.msg, '', 'error');
      }
    }, catchError => {
      Swal.fire(catchError.message, '', 'error');
    });
  }


  public openUpdateModal(modal, row) {
    // console.log({row})
    this.u_obj['PreguntaId'] = row.PreguntaId;
    this.u_obj['Description'] = row.Description;
    this.u_obj['GroupId'] = row.GroupId;
    this.u_obj['SubgroupId'] = row.SubgroupId;
    this.typeForm = Number(row.TypeForm);
    this.getGroup_SubGroup();
    this.modalService.open(modal, { backdrop: 'static',  keyboard : false, windowClass: 'myCustomModalClass' });
  }

  public updateItem(modal, object) {
    this.isUpdating = true;
    let obj = {
      PreguntaId: object.PreguntaId,
      Description: object.Description,
      GroupId: object.GroupId,
      SubgroupId: object.SubgroupId,
      LastModifierUser: this.userinfo['logon'],
      TypeForm: this.typeForm
    }
    // console.log(obj)
    this.srv.postCall(API_FORMSQUESTIONS_UPDATE, obj).subscribe(x => {
      if(x.success){
        this.u_obj = {};
        this.typeForm = 1;
        Swal.fire(this.translations.msg_update, "", 'success');
        this.getList();
        this.closeModal(modal);
        this.isUpdating = false;
      }else{
        this.isUpdating = false;
        this.getList();
        Swal.fire(x.msg, '', 'error');
      }
    }, catchError => {
      this.isUpdating = false;
      Swal.fire(catchError.message, '', 'error');
    });
  }


}
