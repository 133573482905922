import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

const API_CONPARAM = environment.API_CONPARAM;
const API_REGISTROPARAM = environment.API_REGISTROPARAM;
const API_UPDATEPARAMGENERIC = environment.API_UPDATEPARAMGENERIC;
const API_DELETEPARAMGENERIC = environment.API_DELETEPARAMGENERIC;
const API_ACTIVEPARAMGENERIC = environment.API_ACTIVEPARAMGENERIC;
const API_PARAM_GENERAL_ALL = environment.Global.API_PARAM_GENERAL_ALL;
const API_PARAM_GENERAL_BY_TYPE = environment.Global.API_PARAM_GENERAL_BY_TYPE;
const API_PARAM_GENERAL_UPDATE = environment.Global.API_PARAM_GENERAL_UPDATE;
const API_PARAM_GENERAL_CREATE = environment.Global.API_PARAM_GENERAL_CREATE;
const API_PARAM_GENERAL_UPDATE_STATUS = environment.Global.API_PARAM_GENERAL_UPDATE_STATUS;
const API_PARAM_GENERAL_UPDATE_GENERAL = environment.Global.API_PARAM_GENERAL_UPDATE_GENERAL;

@Component({
  selector: 'app-paramgenerales',
  templateUrl: './paramgenerales.component.html',
  styleUrls: ['./paramgenerales.component.css']
})
export class ParamgeneralesComponent extends BaseCrudComponent {

  
  id:any;
  loadingRequired = false;
  listAgr:any;
  translations:any;
  userinfo:any;

  constructor(
    srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        // this.read_url = API_CONPARAM;
        // this.create_url = API_REGISTROPARAM;
        // this.update_url = API_UPDATEPARAMGENERIC;
        // this.delete_url = API_DELETEPARAMGENERIC;
        // this.active_url = API_ACTIVEPARAMGENERIC;

        this.read_url = API_PARAM_GENERAL_ALL;
        this.create_url = API_PARAM_GENERAL_CREATE;
        this.update_url = API_PARAM_GENERAL_UPDATE_GENERAL;
        this.delete_url = API_DELETEPARAMGENERIC;
        this.active_url = API_PARAM_GENERAL_UPDATE_STATUS;
        this.pageSize = 10;
        this.search_fields = ['field_txt'];

        this.c_obj['AnalysisFeature'] = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
   }

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
          this.translations = translations;
          super.ngOnInit();
          this.getList();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    this.storage.get('userinfo').then(x => {
        this.userinfo = x;
    });
  }

  
  public getList(){
    ////debugger;
    var qst = this.read_url;
    this.loadingList = true;
    Swal.fire(
      {
        title: this.translations['loading'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    this.srv.getCall(qst).subscribe(x => {
      ////debugger;
      this.loadingList = false;
      
      if(x.success){
        if(x.val != null){
          // console.log(x.val);
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          this.page = 1;
          if( Array.isArray(this.rows) ){
             this.pageTotal = this.rows.length;
          }
          this.filterAll();
          Swal.close()
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
          }
  });
}

public createItem(content:any, form:any){
  // //debugger;
  var validated = true;

  if( form == undefined ){
    form = {};
  }

  validated = this.validateCreateInfo(form);
  if( !validated ){
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
  }

  var to:any;
  // form.CreatorUser = this.userinfo['logon'];
  // form.LastModifierUser = this.userinfo['logon'];
  to = form;


  var qst = this.create_url;
  this.isCreating = true;

  this.srv.postCall(qst, to).subscribe(x => {
      this.isCreating = false;
      if(x.success){
          this.getList();
          this.closeModal(content);
          Swal.fire('¡Creado!', x.msg, 'success');
      }//end success
      else{
          //Swal.fire(this.translations[x.msg], '', 'error');
          Swal.fire(x.msg, '', 'error');
      }
  });
}

validateCreateInfo(fields:any){
  let mygroup:any = {};
  mygroup = new FormGroup({
      'field_txt': new FormControl(fields.field_txt, [Validators.required]),
      // 'Descripction': new FormControl(fields.Descripction, [])
  });

  return mygroup.valid;
}

public openUpdate(content: any, obj: any, size?:any) {
  let newobj = JSON.parse(JSON.stringify(obj));
  this.u_obj = newobj;
  // console.log(this.u_obj)
  if( size == undefined ){
      this.modalService.open(content, { centered: true });
  }
  else{
      this.modalService.open(content, { centered: true, size: size });
  }

  this.u_obj['nombreViejo'] = obj['field_txt'];

  
}

 
public updateItem(content:any, form:any){
  
  this.isUpdating = true;
  var qst = this.update_url;

    if( form == undefined ){
      form = {};
    }
  
    var to:any;
  
    if( (form.nombreNuevo == undefined) || (form.nombreNuevo == '') ){
        Swal.fire('Ingrese todos los campos requeridos', 'msg', 'warning');
        return;
    }
  
    to = {};
    to.field_txt = form.nombreNuevo;
    to.field =  this.u_obj['nombreViejo'];

    // console.log(to)
  
    this.srv.postCall(qst, to).subscribe(x => {
      this.isUpdating = false;
    Swal.close();
    if (x.success) {
      this.reload.next(true);
      this.closeModal('update1');
      Swal.fire('¡Actualizado!', x.msg, 'success');
      this.getList();
      
    } 
   }, err => {
      Swal.close();
      Swal.fire('Error');
     });
  
  }

  toggleEditable(event, id) {
    //debugger;
    if ( event.target.checked ) {
       this.Activar(id);
   }else {
     this.deleteItem(id);
  
  }
  }

  Activar(obj: any){
    //debugger;
    var qst = this.active_url;
    this.isCreating = true;

    var id:any;
    id = {};
    id.ParId = obj;
    id.LastModifierUser = this.userinfo['logon'];

    this.srv.postCall(qst, id).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if(x.success){
        this.reload.next(true);
        Swal.fire('¡Activado!', x.msg, 'success');
        this.getList();
    }
    else{
        Swal.fire(x.msg, '', 'error');
    }
   }, err => {
      Swal.close();
      Swal.fire('Error');
     });
  }

  deleteItem(obj: any){
    //debugger;
    var qst = this.delete_url;
    this.loadingList = true;

    var id:any;
    id = {};
    id.ParId = obj;
    id.LastModifierUser = this.userinfo['logon'];
    id.DeleteUser = this.userinfo['logon'];

    this.srv.postCall(qst, id).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if(x.success){
        this.reload.next(true);
        Swal.fire('Desactivado!', x.msg, 'success');
        this.getList();
    }
    else{
        Swal.fire(x.msg, '', 'error');
    }
   }, err => {
      Swal.close();
      Swal.fire('Error');
     });
  }

}
