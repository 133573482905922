import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

const API_CONSTIPOCOMUNICADO = environment.API_CONSTIPOCOMUNICADO;
const API_REGISTERTYPEINFO = environment.API_REGISTERTYPEINFO;
const API_UPDATETYPEINFO = environment.API_UPDATETYPEINFO;
const API_DESACTIVETYPEINFO = environment.API_DESACTIVETYPEINFO;
const API_ACTIVETYPEINFO = environment.API_ACTIVETYPEINFO;


@Component({
  selector: 'app-paramcomunicado',
  templateUrl: './paramcomunicado.component.html',
  styleUrls: ['./paramcomunicado.component.css']
})
export class ParamcomunicadoComponent extends BaseCrudComponent {

  id:any;
  loadingRequired = false;
  listAgr:any;
  translations:any;
  userinfo:any;

  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

    this.read_url = API_CONSTIPOCOMUNICADO;
    this.create_url = API_REGISTERTYPEINFO;
    this.update_url = API_UPDATETYPEINFO;
    this.delete_url = API_DESACTIVETYPEINFO;
    this.active_url = API_ACTIVETYPEINFO;

        this.search_fields = ['Name', 'Direction', 'Description'];

        this.c_obj['AnalysisFeature'] = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
   }

   ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
          this.translations = translations;
          super.ngOnInit();
          this.getList();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    this.storage.get('userinfo').then(x => {
        this.userinfo = x;
    });
  }

  public getList(){
    // //debugger;
    var qst = this.read_url;
    this.loadingList = true;
    Swal.fire(
      {
        title: this.translations['loading'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    this.srv.getCall(qst).subscribe(x => {
      // //debugger;
      this.loadingList = false;
      
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;

          this.page = 1;
          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          Swal.close()
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
          }
  });


}

public createItem(content:any, form:any){
  //debugger;
  var validated = true;

  if( form == undefined ){
    form = {};
  }

  validated = this.validateCreateInfo(form);
  if( !validated ){
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
  }

  var to:any;
 form.CreatorUser = this.userinfo['logon'];
 form.LastModifierUser = this.userinfo['logon'];
  to = form;


  var qst = this.create_url;
  this.isCreating = true;

  this.srv.postCall(qst, to).subscribe(x => {
      this.isCreating = false;
      if(x.success){
          this.getList();
          this.closeModal(content);
          Swal.fire('¡Creado!', x.msg, 'success');
      }//end success
      else{
          //Swal.fire(this.translations[x.msg], '', 'error');
          Swal.fire(x.msg, '', 'error');
      }
  });
}

validateCreateInfo(fields:any){
  let mygroup:any = {};
  mygroup = new FormGroup({
      'NameType': new FormControl(fields.NameType, [Validators.required]),
      'Description': new FormControl(fields.Description, [])
  });

  return mygroup.valid;
}

public updateItem(content:any, form:any){

  var qst = this.update_url;
    this.isCreating = true;

  if( form == undefined ){
    form = {};
  }

  var to:any;

  if( (form.NameType == undefined) || (form.NameType == '') ){
      Swal.fire('Ingrese todos los campos requeridos', 'msg', 'warning');
      return;
  }

  to = {};
  to.NameType = form.NameType;
  to.Description = form.Description;
  to.TypeID = form.TypeID
  to.LastModifierUser = this.userinfo['logon'];
  

  this.srv.postCall(qst, to).subscribe(x => {
    this.isCreating = false;
    Swal.close();
    if (x.success) {
      this.reload.next(true);
      this.closeModal('update1');
      Swal.fire('¡Actualizado!', x.msg, 'success');
      this.getList();
      
    } 
 }, err => {
    Swal.close();
    Swal.fire('Error');
   });

}

toggleEditable(event, id) {
  //debugger;
  if ( event.target.checked ) {
     this.Activar(id);
 }else {
   this.deleteItem(id);

}
}

Activar(obj: any){
  //debugger;
  var id:any;
  id = {};
  id.TypeID = obj;
  id.LastModifierUser = this.userinfo['logon'];
  var qst = this.active_url;

  this.srv.postCall(qst, id).subscribe(x => {
    this.isCreating = false;
        Swal.close();

        if(x.success){
          this.reload.next(true);
          Swal.fire('¡Activado!', x.msg, 'success');
          this.getList();
      }
      else{
          Swal.fire(x.msg, '', 'error');
      }
 }, err => {
    Swal.close();
    Swal.fire('Error');
   });
}

deleteItem(obj: any){

  var id:any;
  id = {};
  id.TypeID = obj;
  id.LastModifierUser = this.userinfo['logon'];
  id.DeleteUser = this.userinfo['logon'];
  var qst = this.delete_url;

  this.srv.postCall(qst, id).subscribe(x => {
    this.isCreating = false;
    Swal.close();
    this.isCreating = false;
        Swal.close();
        if(x.success){
          this.reload.next(true);
          Swal.fire('Desactivado!', x.msg, 'success');
          this.getList();
      }
      else{
          Swal.fire(x.msg, '', 'error');
      }
 }, err => {
    Swal.close();
    Swal.fire('Error');
   });
}




}
