import { Component, OnInit } from '@angular/core';
import { BaseCrudComponent } from '../../../base-crud/base-crud.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/services/main/global.service';
import Swal from 'sweetalert2';
import {saveAs as importedSaveAs  } from "file-saver";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/authentication/login.service';

const GET_ALL_SITES_ACTIVE = environment.Global.API_GET_ALL_SITES_ACTIVE;
const API_GET_EMPLOYEES = environment.Global.API_GET_EMPLOYEE_INTERNAL_ALL;
const API_GET_CHARLAS_BY_WEEK = environment.Global.API_GET_CHARLAS_BY_WEEK;
const GET_ALL_AREA_BY_ID = environment.Global.API_GET_ALL_AREA_BY_ID;
const API_GET_CHARLAS_EVIDENCIAS_ALL = environment.Global.API_GET_CHARLAS_EVIDENCIAS_ALL;
const API_CREATE_CHARLAS_EVIDENCIAS = environment.Global.API_CREATE_CHARLAS_EVIDENCIAS;
const API_GET_CHARLAS_EVIDENCIAS_BY_USER = environment.Global.API_GET_CHARLAS_EVIDENCIAS_BY_USER;
const API_UPLOADFILE_CHARLAS_EVIDENCIAS = environment.Global.API_UPLOADFILE_CHARLAS_EVIDENCIAS;
const API_GET_FILE_CHARLAS_EVIDENCIAS = environment.Global.API_GET_FILE_CHARLAS_EVIDENCIAS;
const API_GET_EMPLOYEE_WITH_USER = environment.Global.API_GET_EMPLOYEE_WITH_USER;
const API_ERASED_CHARLAS_EVIDENCIAS = environment.Global.API_ERASED_CHARLAS_EVIDENCIAS;
const API_UPDATE_CHARLAS_EVIDENCIAS = environment.Global.API_UPDATE_CHARLAS_EVIDENCIAS;

@Component({
  selector: 'app-charlas-audits',
  templateUrl: './charlas-audits.component.html',
  styleUrls: ['./charlas-audits.component.css']
})
export class CharlasAuditsComponent extends BaseCrudComponent implements OnInit {
  fechaActual = new Date();
  semana: any = null;
  listEmployees = [];
  listEmployees2 = [];
  listaSemanas = [];
  listPlantas = [];
  listAreas = [];
  archivos = [];
  isLoading = false;
  userinfo: any;
  pageSize = 10;
  loadingList
  fileName = "";
  permissions;
  isAdmin = false;
  plantaid;
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private globalService: GlobalService,
    private route: ActivatedRoute,
    public auth: LoginService,
    public translate: TranslateService,
  ) {
    super(srv, modalService, storage, router);
  }

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
      .subscribe((translations) => {
        this.translations = translations;
        super.ngOnInit();
      });
  this.search_fields = ['CharlaAuditorID','Week','employeeName'];
    this.storage.get('userinfo').then(x => {
      this.userinfo = x;
      // console.log(x)
    });
    this.auth.getPermissions().then(x => {
      this.permissions = x;
      this.getList();
    });
    for( let i = 0; i < this.globalService.getISOWeeks(this.fechaActual.getFullYear()); i++) {
      let rangoFechas = this.globalService.getDateRangeOfWeekP(i,this.fechaActual);
      let fechaFinal = rangoFechas.split('-')[1].trim().split('/');
      let fd = new Date(fechaFinal[2] + "-" + fechaFinal[1] + "-" + fechaFinal[0]);
      if (this.fechaActual.getFullYear() == fd.getFullYear()) {
        this.listaSemanas.push( 
          {
            week:  "Semana " + `${(i + 1).toString()}`,
            idWeek : i + 1,
            rangeDates : this.globalService.getDateRangeOfWeekP(i,this.fechaActual)
          }  
        )
      }
    }
  }

  public getList() {
    this.loadingList = true;
    let url = '';
    if (this.permissions.includes('SI_ADMIN')) {
      url = API_GET_CHARLAS_EVIDENCIAS_ALL;
      // this.isAdmin = true;
    } else {
      url =  API_GET_CHARLAS_EVIDENCIAS_BY_USER + this.userinfo['logon'] 
    }
    this.srv.getCall(url).subscribe(x => {
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          this.page = 1;
          // console.log(x.val)
          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
        }else {
          this.rows = [];
          return;
        }
      }else {
        this.rows = [];
        return;
      }
    });
  }

  public openCreateModal(modal) {
    this.isCreating = false;
    this.c_obj = {};
    this.c_obj['asisten_number'] = 1;
    this.modalService.open(modal, { backdrop: 'static',  keyboard : false, size: "lg" });
  }



  public async getEmployees() {
    let url = API_GET_EMPLOYEES;
    await this.srv.getCall(url).toPromise().then(x => {
      if(x.success){
        if(x.val != null){
          this.listEmployees = x.val
          this.listEmployees2 = x.val;
        }else {
          this.listEmployees = [];
          this.listEmployees2 = [];
          return;
        }
      }else {
        this.listEmployees = [];
        this.listEmployees2 = [];
        return;
      }
    });
  }



  public handleInputChange(e){
    this.archivos=[];
    var file = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    let files = Object.values(file);
    this.archivos = files;
    this.fileName = file[0].name;
  }

  public setEmail() {
    if (this.u_obj.padrin != undefined || this.u_obj.padrin != null) {
      this.listEmployees.forEach(employee => {
        if (employee.EmployeeId == this.u_obj['padrin']) {
          this.u_obj['email'] = employee.Email;
        }
      });
    } else {
      this.u_obj['email'] = null;
    }
  }

  public async searchWeek() {
    let url = API_GET_CHARLAS_BY_WEEK;
    let obj = {
      week : "Semana " + this.u_obj['Week'],
      rangesDates: this.u_obj['IntervalWeek']
    }
    // console.log(obj)
    this.srv.postCall(url, obj).toPromise().then(x => {
      // console.log(x)
      if(x.success){
        if(x.val != null){
          // console.log(x.val)
          this.u_obj['title'] = x.val['Title'];
        }else {
          this.u_obj['title'] = null;
          return;
        }
      }else {
        this.u_obj['title'] = null;
        return;
      }
    });
  }

  public deleteItem(row) {
    let url = API_ERASED_CHARLAS_EVIDENCIAS;
    let obj = {
      CharlaAuditorID :  row['CharlaAuditorID'],
      Status : "3",
      LastModifierUser: this.userinfo['logon']
    }

    this.srv.postCall(url, obj).toPromise().then(  data => {
      if(data.success){
        Swal.fire(this.translations.msg_delete, '', 'success');
        this.getList();
      }else {
        Swal.fire(data.msg, '', 'error');
      }    
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
    });


  }


  public validateCreateInfo(fields:any): boolean {
    let form:any = {};
    form = new FormGroup({
        // 'semana': new FormControl(fields.semana, [Validators.required]),
        'date_ejecution': new FormControl(fields.date_ejecution, [Validators.required]),
        'hour_init': new FormControl(fields.hour_init, [Validators.required]),
        'hour_end': new FormControl(fields.hour_end, [Validators.required]),
        'audit': new FormControl(fields.audit, [Validators.required]),
        'asisten_number': new FormControl(fields.asisten_number, [Validators.required]),
        'PlantaID': new FormControl(fields.PlantaID, [Validators.required]),
        'SeccionID': new FormControl(fields.SeccionID, [Validators.required]),
    });
    return form.valid;
  }


  public downloadDocFile(data) {
    let url = API_GET_FILE_CHARLAS_EVIDENCIAS;
    const idCharla = data.CharlaAuditorID;
    const name = data.FileNames;
    this.srv.downloadFileAny(idCharla, url).subscribe((data) => {
      importedSaveAs(data,name )
    });
  }

  

  public async openUpdateModal (content, row) {
    let obj = JSON.parse(JSON.stringify(row))
    this.GetPlantas();
    this.modalService.open(content, { backdrop: 'static',  keyboard : false, size: "lg" });
    this.u_obj = obj;
    this.u_obj['weekString'] = "Semana " + this.u_obj['Week'];
    this.u_obj['date_ejecution'] =  row.EjecutionDate != null ? row.EjecutionDate.split('T')[0] :  row.EjecutionDate;
    this.u_obj['hour_init'] = row.HourInit!= null ? row.HourInit.replace(' ','T'): row.HourInit;
    this.u_obj['hour_end'] = row.HourEnd != null ? row.HourEnd.replace(' ','T') : row.HourEnd;
    this.u_obj['audit'] = row.EmployeeIdNR != 0 ? row.EmployeeIdNR : null;
    this.u_obj['padrin'] = row.EmployeeIdGF != 0 ? row.EmployeeIdGF : null;
    this.u_obj['asisten_number'] = row.NumberAssist;
    this.u_obj['Username'] = this.u_obj['Username'] == null ? this.userinfo['logon'] : this.u_obj['Username'];
    this.u_obj['Email'] = this.u_obj['Email'] == null ? this.userinfo['emailAddress'] : this.u_obj['Email'];
    this.u_obj['PlantaID'] = this.u_obj['PlantaID'] == null ? null : this.u_obj['PlantaID'];
    this.u_obj['SeccionID'] = this.u_obj['SeccionID'] == null ? null : this.u_obj['SeccionID'];
    // console.log(this.u_obj)
    await this.searchWeek();
    await this.getEmployees();
  }

  public  async updateItem(modal) {
    this.isUpdating = true;
    let url = API_UPDATE_CHARLAS_EVIDENCIAS;
    let validated = this.validateCreateInfo(this.u_obj);
    if( !validated ){
      this.isUpdating = false;
      Swal.fire(this.translations['msg_all_required'], '', 'error');      
      return;
    }
    if (this.archivos.length == 0){
      this.isUpdating = false;
      Swal.fire(this.translations.msg_all_required, '', 'error');
      return;
    }
    
    let obj = {
      CharlaAuditorID: this.u_obj['CharlaAuditorID'],
      EjecutionDate: this.u_obj['date_ejecution'],
      HourInit: this.u_obj['hour_init'].replace('T',' '),
      HourEnd: this.u_obj['hour_end'].replace('T',' '),
      SeccionID: this.u_obj['SeccionID'],
      Username: this.u_obj['Username'],
      Email: this.u_obj['Email'],
      PlantaID: this.u_obj['PlantaID'],
      EmployeeIdGF: this.u_obj['audit'],
      NumberAssist: this.u_obj['asisten_number'],
      LastModifierUser: this.userinfo['logon']
    }



    this.srv.postCall(url, obj).subscribe(async data => {
      if(data.success){
        // console.log(data.val);
        await this.UploadFile(data)
        this.isUpdating = false;
        Swal.fire(this.translations.msg_update, '', 'success');
        this.closeModal(modal);
      }else {
        this.isUpdating = false;
        Swal.fire(data.msg, '', 'error');
      }    
    },catchError =>{
      this.isUpdating = false;
      Swal.fire(catchError.message, '', 'error');
    });
    
  }

  public async UploadFile(data) {
    let formData = new FormData();
    for(let file of this.archivos) {
      formData.append(file.name, file);
    }

    formData.append('CharlaAuditorID', data.val.CharlaAuditorID.toString());

    await this.srv.AddFiles(API_UPLOADFILE_CHARLAS_EVIDENCIAS, formData).toPromise().then(result => {
      if (result=="OK") {
        this.archivos=[];
        this.u_obj = {};
        this.semana = null;
        Swal.fire(this.translations.msg_create, '', 'success');
        this.getList()
      }
      else{
        Swal.fire('', result, 'error');
        this.u_obj = {};
        this.semana = null;
        this.archivos=[];
      }
    });
  }

  public setLevelArea() {
    if (this.u_obj['PlantaID'] !== null) {
      var qst = GET_ALL_AREA_BY_ID + this.u_obj['PlantaID'];
      this.srv.getCall(qst).subscribe(x => {
        if(x.success){
          if(x.val != null){
            var rs = x.val;
            this.listAreas = rs;
            // console.log(rs)
          }else {
            this.listAreas = [];
            return;
          }
        }else {
          return;
        }
      });
    } else {
      this.listAreas = [];
    }
  }

  public GetPlantas() {
    var qst = GET_ALL_SITES_ACTIVE;
    this.srv.getCall(qst).subscribe(x => {
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.listPlantas = rs;
        }else {
          this.listPlantas = [];
          return;
        }
      }else {
        return;
          }
    });
  }


}
