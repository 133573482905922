import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from 'src/app/services/authentication/login.service';

@Component({
  selector: 'app-incidents',
  templateUrl: './incidents.component.html',
  styleUrls: ['./incidents.component.css']
})
export class IncidentsComponent implements OnInit {
  translations:any;
  available_languages:any;
  currlang:any;
  version:any;
  items:any;
  permissions = [];
  userinfo = [];
  isSkretting = false;
  constructor(
    public _router: Router,
    public translate: TranslateService,
    public auth: LoginService,
  ) { }

  ngOnInit() {
    this.items = [
      {title: 'menu_act_nosecure', url: '/actnosecure', icon: 'mdi mdi-adjust', perm: 'SI_ACTINSECURE'},
      {title: 'menu_condi_nosecure', url: '/condinosecure', icon: 'mdi mdi-adjust', perm: 'SI_CONDINSECURE'},
      {title: 'menu_incident_accident', url: '/inciacci', icon: 'mdi mdi-adjust', perm: 'SI_ACCIDEINCIDENT'},
      {title: 'menu_oportunity_mejor', url: '/oportunitymejor', icon: 'mdi mdi-adjust', perm: 'SI_MEJOROPORTU'},
      {title: 'menu_plan_action', url: '/planaction', icon: 'mdi mdi-adjust', perm: 'SI_PLANACTION'},
      {title: 'menu_action_per_plan', url: '/actionperplan', icon: 'mdi mdi-adjust', perm: 'SI_REGPLANACCION'}
    ];
    
    this.auth.getPermissions().then(x => {
      this.permissions = x;
    });
    
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';
  }
  
  hasPermission(str:string){
    if( this.permissions.indexOf(str) > -1 ){
      return true;
    }
    else{
      return false;
    }
  }

}
