import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseCrudComponent } from 'src/app/ic2/base-crud/base-crud.component';
import { LoginService } from 'src/app/services/authentication/login.service';
import { ApiService } from 'src/app/services/main/api.service';
import { GlobalService } from 'src/app/services/main/global.service';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

const GET_ALL_SITES_ACTIVE = environment.Global.API_GET_ALL_SITES_ACTIVE;
const GET_ALL_AREA_BY_ID = environment.Global.API_GET_ALL_AREA_BY_ID;
const GET_SCHEDULE_PER_YEAR = environment.Global.GET_SCHEDULE_PER_YEAR;
const API_POST_CREATE_SCHEDULE = environment.Global.API_POST_CREATE_SCHEDULE;
const API_GET_EMPLOYEE_WITH_USER = environment.Global.API_GET_EMPLOYEE_WITH_USER;
const API_GET_EMPLOYEE_BY_AREA_ASSIGNED = environment.Global.API_GET_EMPLOYEE_BY_AREA_ASSIGNED;
const API_APPROVE_OR_REJECT_HSE_PERMISSION = environment.Global.API_APPROVE_OR_REJECT_HSE_PERMISSION;
const API_EXISTS_GF_BY_PLANT = environment.Global.API_EXISTS_GF_BY_PLANT;
const API_EXIST_AUDIT_IN_WEEK = environment.Global.API_EXIST_AUDIT_IN_WEEK;

@Component({
  selector: 'app-cronograma',
  templateUrl: './cronograma.component.html',
  styleUrls: ['./cronograma.component.css']
})
export class CronogramaComponent extends BaseCrudComponent {
  listEmployees = [];
  listaSemanas = [];
  listPlantas = [];
  listaMeses = [];
  listAreas = [];
  listaSchd = [];
  listaFinal = [];
  anio = new Date();
  options = [{name:"Aprobar", id: 1}, {name:"Rechazar", id: 2}]
  userinfo : any;
  schedule:any;
  plantaIDAssigned = null;
  seccionIDAssigned = null;
  isAprrove = false;
  optionSelected = null;
  comment = false;
  commentary = ""
  reason = "";
  employee = "";
  auditoria = 0;
  hasGF = false;
  existAuditInWeek = false;
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    public auth: LoginService,
    private globalService: GlobalService,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
  }

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
      .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
    });
    this.storage.get('userinfo').then(x => {
      this.userinfo = x;
      // console.log(this.userinfo)
    });
    this.getEmployees();
    this.getScheduleYear();
    this.GetPlantas();
    this.listaSemanas = this.globalService.getWeeks(this.anio);
  }

  public getScheduleYear() {
    this.loadingList = true;
    this.listaSchd = [];
    let url = GET_SCHEDULE_PER_YEAR + this.anio.getFullYear().toString();
    this.srv.getCall(url).subscribe(x => {
      // console.log(x)
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          this.schedule = x.val
          // console.log(x.val['ScheduleDetail'])
          x.val['ScheduleDetail'].forEach(detail => {
            // console.log(detail)
            let obj = {};
            obj['employee'] = detail.employeeName;
            obj['EmployeeId'] = detail.EmployeeId;
            obj['weeks'] = [];
            obj['plantadepadrino'] = detail.plantaPadrino;
            obj['areadepadrino'] = detail.seccionPadrino;
            obj['plantaidpadrino'] = detail.PlantaAssignid;
            obj['seccionidpadrino'] = detail.SeccionAssingid;
            for( let  j = 0; j < this.globalService.getISOWeeks(this.anio.getFullYear()); j++ ) {
              let w = {
                auditoriaid : '',
                status : '',
                planta : '--------',
                area : '--------',
                semana: 'Semana ' + (j + 1).toString(),
                commentary: '',
                styles: {
                  'background-color': 'rgb(255, 255, 255)',
                  'height': '50px',
                  'width': '50px',
                  'border': '1px rgba(0, 0, 0, 0.250)',
                  'border-radius': '50%',
                  'border-style' :'solid',
                  'display': 'inline-block',
                  'cursor': 'pointer'
                }
              }
              w[`${(j + 1).toString()}`] = '';
              obj['weeks'].push(w);
            }
            this.constructList(obj);
          });
          x.val['ScheduleDetail'].forEach(auditoria => {
            this.listaSchd.forEach(empleado => {
              if (empleado.employee == auditoria.employeeName && empleado.plantaidpadrino == auditoria.PlantaAssignid 
                && empleado.seccionidpadrino == auditoria.SeccionAssingid) {
                  let ScheduleWeek = auditoria.ScheduleWeek[0];
                  empleado.weeks.forEach(semana =>{
                    if (semana[ScheduleWeek.Week] != null) {
                      semana['status'] = auditoria.ScheduleWeek[0].hse.StatusHSE;
                      semana[ScheduleWeek.Week] = 'Auditoría';
                      semana['planta'] = auditoria.plantaName;
                      semana['area'] = auditoria.areaName;
                      semana['numero'] = ScheduleWeek.Week;
                      semana['commentary'] = auditoria.ScheduleWeek[0].hse.Commentary;
                      semana['auditoriaid'] = auditoria.ScheduleWeek[0].hse.HseID;
                      if (semana['status'] == 'Programado') {
                        semana.styles['background-color'] = '#bbb';
                      } else if (semana['status'] == 'No realizado') {
                        semana.styles['background-color'] = '#BE0202';
                      } else if (semana['status'] == 'Realizado con Atraso') {
                        semana.styles['background-color'] = '#60D2E8';
                      } else if (semana['status'] == 'Realizado') {
                        semana.styles['background-color'] = '#026EBE';
                      } else if (semana['status'] == 'Justificado') {
                        semana.styles['background-color'] = '#000000';
                      } else if (semana['status'] == 'Pendiente') {
                        semana.styles['background-color'] = '#F7F330';
                      }
                      if (semana['commentary'] != null) {
                        semana.styles['border-style'] ='dashed';
                        semana.styles['border-color'] ='black';
                        semana.styles['border'] = '2.5px rgba(0, 0, 0, 0.250)';
                      }
                    }
                  })
                }
            })
          });
          // console.log(this.listaSchd)
        }else {
          this.schedule = null;
          this.listaSchd = [];
          return;
        }
      }else {
        return;
      }
    });
  }

  public constructList(obj:any) {
    if (this.listaSchd.length == 0) {
      this.listaSchd.push(obj);
    } else {
      let flag = false;
      this.listaSchd.forEach(element =>{
        if (element.employee == obj['employee'] && 
        element.plantaidpadrino == obj['plantaidpadrino'] && 
        element.seccionidpadrino == obj['seccionidpadrino']) {
          flag = true;
        }
      })
      if (!flag) {
        this.listaSchd.push(obj);
      }
    }
  }

  public getEmployees() {
    let url = API_GET_EMPLOYEE_WITH_USER;
    this.srv.getCall(url).subscribe(x => {
      if(x.success){
        if(x.val != null){
          // console.log(x.val)
          this.listEmployees = x.val;
        }else {
          this.listEmployees = [];
          return;
        }
      }else {
        return;
      }
    });
  }

  public GetPlantas() {
    var qst = GET_ALL_SITES_ACTIVE;
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false; 
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.listPlantas = rs;
        }else {
          this.listPlantas = [];
          return;
        }
      }else {
        return;
      }
    });
  }

  public findAreaGodFather() {
    if (this.c_obj['employee'] != null) {
      let id = this.c_obj['employee'];
      this.srv.getCall(API_GET_EMPLOYEE_BY_AREA_ASSIGNED + id).subscribe(x => {
        if (x.success) {
          this.plantaIDAssigned = x.val[0]['PlantaAssign'];
          this.seccionIDAssigned = x.val[0]['SeccionAssign'];
        }
      })
    }   
  }

  public findWeekGodFather(): boolean {
    let flag = 0;
    this.listaSchd.forEach(empleado => {
      if (empleado.EmployeeId == this.c_obj['employee']) {
        empleado.weeks.forEach(semana => {
          if (semana.semana == this.c_obj['week'].week && semana.area != "--------") {
            flag = 1;
            // console.log(semana)
          }
        });
      }
    });
    return flag == 0 ? false: true;
  }

  public setLevelArea() {
    if (this.c_obj['level'] !== null) {
      var qst = GET_ALL_AREA_BY_ID + this.c_obj['level'].split('-')[0];
      this.srv.getCall(qst).subscribe(x => {
        if(x.success){
          if(x.val != null){
            var rs = x.val;
            this.listAreas = rs;
          }else {
            this.listAreas = [];
            return;
          }
        }else {
          return;
        }
      });
    } else {
      this.c_obj['job_area'] = null;
    }
  }

  public async createSchedule() {
    this.isCreating = true;
    let _week = null;
    
    this.listaSemanas.forEach(element => {
      if (element.idWeek == this.c_obj['week'].idWeek) {
        _week = element;
      }
    })
   
    if (
      this.c_obj['level'] == undefined || 
      this.c_obj['job_area'] == undefined || 
      this.c_obj['employee'] == undefined ||
      this.c_obj['week'] == undefined) {
        this.isCreating = false;
      Swal.fire(this.translations.msg_all_required, '','error')
      return;
    }
    
    let schedulweek = [
      {
        Week: this.c_obj['week'].idWeek,
        StatusWeek: "A",
        IntervalWeek: _week.rangeDates
      }
    ];

    let scheduleDetal = [
      {
        EmployeeId: this.c_obj['employee'],
        PlantaID: this.c_obj['level'].split('-')[0],
        SeccionID: this.c_obj['job_area'].split('-')[0],
        ScheduleWeek: schedulweek,
        PlantaAssignid: this.plantaIDAssigned,
        SeccionAssingid: this.seccionIDAssigned
      }
    ]
    
    let obj = {
      Year : this.schedule.Year,
      CreatorUser: this.userinfo['logon'],
      ScheduleDetail: scheduleDetal
    }

    let cont = 0;
    this.listaSchd.forEach( trabajador => {
      if (trabajador.plantaid == scheduleDetal[0].PlantaID &&
        trabajador.seccionid == scheduleDetal[0].SeccionID  ) {
          trabajador.weeks.forEach(semanas => {
            if (semanas.numero == schedulweek[0].Week) {
              cont = 1;
            }
          });
      }
    });

    // console.log(obj)
    
    if (cont == 1) {
      this.isCreating = false;
      Swal.fire(this.translations.exist_schedule_Week,'','error');
      return;
    }
    await this.searchPlant();
    if (!this.hasGF) {
      this.isCreating = false;
      Swal.fire(this.translations.plant_dh_gf, '','error');
      return;
    }

    let existarea = this.findWeekGodFather();
    if (existarea) {
      this.isCreating = false;
      Swal.fire(this.translations.area_exist_in_week, '','error')
      return;
    }

    

    await this.searchAuditInWeek(this.c_obj['week'].idWeek,_week.rangeDates);
    
    if (!this.existAuditInWeek) {
      this.isCreating = false;
      Swal.fire(this.translations.msg_week_data, '','error');
      return;
    }
    // // console.log(obj)
    // let url = API_POST_CREATE_SCHEDULE;
    // this.srv.postCall(url, obj).subscribe(data => {
    //   this.isCreating = false;
    //   if (data.success) {
    //     Swal.fire(this.translations.msg_create, data.msg, 'success');
    //     this.getScheduleYear();
    //     this.c_obj = {};
    //   }
    // }, error => {
    //   Swal.close();
    //   Swal.fire('Error');
    //   this.c_obj= {};
    // })


  }

  public async searchPlant () {
    let obj = {
      plantaID: this.c_obj['level'].split('-')[0],
      seccionID: this.c_obj['job_area'].split('-')[0],
    }
    let url = API_EXISTS_GF_BY_PLANT;
    await this.srv.postCall(url,obj).toPromise().then(data => {
      if(data.val != null) {
        this.hasGF = true;
      } else {
        this.hasGF = false;
      }
    })
  }

  public async searchAuditInWeek (week, intervalWeek) {
    let obj = {
      EmployeeID: this.c_obj['employee'],
      IntervalWeek: intervalWeek,
      Week: week,
      PlantaID: this.c_obj['level'].split('-')[0],
      SeccionID: this.c_obj['job_area'].split('-')[0],
    }
    // console.log(obj)
    let url = API_EXIST_AUDIT_IN_WEEK;
    await this.srv.postCall(url,obj).toPromise().then(data => {
      // console.log(data)
      if(data.val != null) {
        this.existAuditInWeek = true;
      } else {
        this.existAuditInWeek = false;
      }
    })
  }

  public openStatusAudit(item, week, modal) {
    // console.log(week)
    if (week.commentary != null && (week.status == "Programado" || week.status == "Pendiente")) {
      this.modalService.open(modal, { backdrop: 'static',  keyboard : false, size:"lg" });
      this.reason = week.commentary;
      this.employee = item.employee;
      this.auditoria = week.auditoriaid;
    }

  }

  public openComment() {
    if (this.optionSelected == 2) {
      this.comment = true;
    } else {
      this.comment = false;
      this.commentary = "";
    }
  }

  public approveorreject (modal) {
    this.isAprrove = true;
    if (this.optionSelected == 2 && this.commentary == "") {
      this.isAprrove = false;
      Swal.fire(this.translations.msg_all_required, '' ,'error');
      return;
    }

    let obj = {
      LastModifierUser : this.userinfo['logon'],
      Commentary: this.commentary,
      HseID: this.auditoria
    }

    this.srv.postCall(API_APPROVE_OR_REJECT_HSE_PERMISSION, obj).subscribe(x => {
      // console.log(x)
      if (x.success) {
        this.isAprrove = false;
        Swal.fire(this.translations.msg_update, '','success');
        this.optionSelected = null;
        this.getScheduleYear();
        this.closeModal(modal);
        this.comment = false;
      } else {
        this.isAprrove = false;
        Swal.fire(x.msg, '','error')
      }
    })
    
  } 

  getMonthNumber(w, y) {
    var d = (1 + (w - 1) * 7); // calcula el numero de dias a partir del 1 de enero
     var fecha = new Date(y, 0, d);
     return fecha.getMonth() + 1;
  };

}
