import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { isNumeric } from 'rxjs/util/isNumeric';
import { Newsubcomjob } from '../../../interfaces/newsubcomjob';


const API_CONSULTASUBCOMPANIEJOB = environment.API_CONSULTASUBCOMPANIEJOB;
const API_REGISTERJOBSUBCOM = environment.API_REGISTERJOBSUBCOM;
const API_UPDATESUBJOBCOM = environment.API_UPDATESUBJOBCOM;
const API_DESACTIVESUBJOBCOM = environment.API_DESACTIVESUBJOBCOM;
const API_ACTIVESUBJOBCOM = environment.API_ACTIVESUBJOBCOM;
const API_CONSULTAJOBTYPEACTIVE = environment.API_CONSULTAJOBTYPEACTIVE;


@Component({
  selector: 'app-regsubcontractypejob',
  templateUrl: './regsubcontractypejob.component.html',
  styleUrls: ['./regsubcontractypejob.component.css']
})
export class RegsubcontractypejobComponent extends BaseCrudComponent {
  id:any;
  loadingRequired = false;
  listAgr:any;
  translations:any;
  userinfo:any;
  RegSubJobCo: FormGroup;
  jobtypeID:any;
  seleccionados=[];

  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
    this.id = route.snapshot.params.id;

    
        this.read_url = API_CONSULTASUBCOMPANIEJOB;
        this.create_url = API_REGISTERJOBSUBCOM;
        this.update_url = API_UPDATESUBJOBCOM;
        this.active_url = API_ACTIVESUBJOBCOM;
        this.delete_url = API_DESACTIVESUBJOBCOM;
        this.urlJob = API_CONSULTAJOBTYPEACTIVE;
        

    this.search_fields = ['SubJobTypeID', 'NameJobType'];

    this.c_obj['AnalysisFeature'] = [];

    this.reload.subscribe(val => {
        if( val ){
            super.clearData();
            this.getList();
        }
    });
    this.getListJobType();
    this.crearFormSubCom();
   }

   ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
          this.translations = translations;
          super.ngOnInit();
          this.getList();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    this.storage.get('userinfo').then(x => {
        this.userinfo = x;
    });
  }

  public getList(){
    ////debugger;
  
    var qst = this.read_url;
    this.loadingList = true;
    var id:any;
    id = {};
    id.SubCompanyID = this.id;
    // Swal.fire(
    //   {
    //     title: this.translations['loading'],
    //     html: '',
    //     icon: 'info',
    //   }
    // );
    // Swal.showLoading();
    this.srv.postCall(qst, id).subscribe(x => {
      this.loadingList = false;
      ////debugger;
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;

          this.page = 1;
          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          Swal.close()
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
        }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public getListJobType(){

    var qst = this.urlJob;
    this.loadingList = true;
  
    this.srv.getCall(qst).subscribe(x => {
      // //debugger;
      this.loadingList = false;
      
      if(x.success){
          var rs = x.val;
          this.rowJob = rs;
      }else {
          Swal.fire(x.msg, '', 'error');
          return;
            }
              });
  }

  crearFormSubCom() {
    this.RegSubJobCo = new FormGroup({
      selectjob: new FormControl('', Validators.compose([])),
      });
  }

      
    public createItem(row){
      // //debugger;
      if (this.RegSubJobCo.valid) {
    
        var qst = this.create_url;
        this.isCreating = true;
    
          this.seleccionados.forEach(element => { 
            
            const newsubcomjob: Newsubcomjob = new Newsubcomjob();
            newsubcomjob.SubCompanyID =  this.id;
            newsubcomjob.JobTypeID = element.JobTypeID;
            newsubcomjob.CreatorUser = this.userinfo['logon'];
            newsubcomjob.LastModifierUser = this.userinfo['logon'];
    
              this.srv.postCall(qst, newsubcomjob).subscribe(x => {
                this.isCreating = false;
                    Swal.close();
                    if (x.success) {
                      this.RegSubJobCo.reset();
                      this.closeModal('create1');
                      this.reload.next(true);
                      Swal.fire('¡Creado!', x.msg, 'success');
                      this.seleccionados =[]; 
                      this.getList();
                  //    if( isNumeric( this.userinfo.logon) ) { 
                  //      this.getListbyuser(this.userinfo.selectedCompanyId);
                  //    } else {
                  //    this.getList();
                  //  } 
                    
                  } else{
                    Swal.fire(x.msg, '', 'error');
                    this.seleccionados =[]; 
                }
            }, err => {
                Swal.close();
                Swal.fire('Error');
              });
            
          });
            
      
      }
      else {
        Swal.fire('Ingrese todos los campos requeridos', '', 'error');
        return;
        }
    }

    public filterAnalysisGroup(id:any){
    // //debugger;
    this.jobtypeID = id.split(':')[1].trim();
  
  }
    public updateItem(content:any, form:any){
    // //debugger;
  
    var qst = this.update_url;
    this.isCreating = true;
  
    if( form == undefined ){
      form = {};
    }
  
    var to:any;
  

    to = {};
    to.SubJobTypeID = form.SubJobTypeID;
    to.SubCompanyID = form.SubCompanyID;
    to.JobTypeID =  this.jobtypeID;
    to.LastModifierUser = this.userinfo['logon'];
    
  
    this.srv.postCall(qst, to).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if (x.success) {
        this.reload.next(true);
        this.closeModal('update2');
        Swal.fire('¡Actualizado!', x.msg, 'success');
        this.getList();
      } 
    }, err => {
      Swal.close();
      Swal.fire('Error');
      });
  
  }

  toggleEditable(event, id) {
    // //debugger;
    if ( event.target.checked ) {
        this.Activar(id);
    }else {
      this.deleteItem(id);
  
  }
  }
  
  Activar(obj: any){
    // //debugger;
    var id:any;
    id = {};
    id.SubJobTypeID = obj;
    id.LastModifierUser = this.userinfo['logon'];
    var qst = this.active_url;
  
    this.srv.postCall(qst, id).subscribe(x => {
      this.isCreating = false;
          Swal.close();
  
          if(x.success){
            this.reload.next(true);
            Swal.fire('¡Activado!', x.msg, 'success');
            this.getList();
        }
        else{
            Swal.fire(x.msg, '', 'error');
        }
    }, err => {
      Swal.close();
      Swal.fire('Error');
      });
  }
  
  deleteItem(obj: any){
  
    var id:any;
    id = {};
    id.SubJobTypeID = obj;
    id.LastModifierUser = this.userinfo['logon'];
    id.DeleteUser = this.userinfo['logon'];
    var qst = this.delete_url;
  
    this.srv.postCall(qst, id).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      this.isCreating = false;
          Swal.close();
          if(x.success){
            this.reload.next(true);
            Swal.fire('Desactivado!', x.msg, 'success');
            this.getList();
        }
        else{
            Swal.fire(x.msg, '', 'error');
        }
    }, err => {
      Swal.close();
      Swal.fire('Error');
      });
  }
      

      


}
