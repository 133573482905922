import { Component, Input, OnInit } from '@angular/core';
import { BaseCrudComponent } from '../../../base-crud/base-crud.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/services/main/global.service';
import Swal from 'sweetalert2';
import {saveAs as importedSaveAs  } from "file-saver";

const API_GET_CHARLAS = environment.Global.API_GET_CHARLAS;
const API_CREATE_CHARLAS = environment.Global.API_CREATE_CHARLAS;
const API_UPDATESTATUS_CHARLAS = environment.Global.API_UPDATESTATUS_CHARLAS;
const API_UPDATE_CHARLAS = environment.Global.API_UPDATE_CHARLAS;
const API_UPLOADFILE_CHARLAS = environment.Global.API_UPLOADFILE_CHARLAS;
const API_GET_FILE_CHARLAS = environment.Global.API_GET_FILE_CHARLAS;


@Component({
  selector: 'app-charlas',
  templateUrl: './charlas.component.html',
  styleUrls: ['./charlas.component.css']
})



export class CharlasComponent extends BaseCrudComponent implements OnInit {
  fechaActual = new Date();
  semana: any = null;
  listaSemanas = [];
  archivos = [];
  userinfo: any;
  pageSize = 3;
  isLoading = false;
  fileName = "";
  exist = 0;
  @Input() isAdmin: boolean = true;
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private globalService: GlobalService,
    private route: ActivatedRoute,
    public translate: TranslateService,
  ) {
    super(srv, modalService, storage, router);
  }

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
      .subscribe((translations) => {
        this.translations = translations;
        super.ngOnInit();
      });
    this.search_fields = ['CharlaID','Title'];
    this.storage.get('userinfo').then(x => {
      this.userinfo = x;
      // console.log(x)
    });

    this.listaSemanas = this.globalService.getWeeks(this.fechaActual);
    // console.log(this.listaSemanas)
    this.getList();
  }

  public getList() {
    this.loadingList = true;
    let url = API_GET_CHARLAS;
    this.srv.getCall(url).subscribe(x => {
      this.loadingList = false;
      if (x.success) {
        if (x.val != null) {
          this.rawdata = x.val;
          this.rows = this.rawdata;
          this.page = 1;
          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          // console.log(x.val)
        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
        }
      } else {
        this.rows = [];
        Swal.fire(x.msg, '', 'error');
        return;
      }
    })
  }

  public verifyexistweek() {
    this.exist = 0;
    this.rows.forEach(element => {
      if (this.semana.week == element.Week && this.semana.rangeDates == element.RangesDates) {
        this.exist = 1;
      }
    });
    

  }

  public openCreateModal(content: any): void {
    this.c_obj = {};
    this.modalService.open(content, { backdrop: 'static',  keyboard : false, size: "lg" });
  }

  public handleInputChange(e){
    this.archivos=[];
    var file = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    let files = Object.values(file);
    this.archivos = files;
    this.fileName = file[0].name;
  }

  public async createItem(modal) {
    this.isCreating = true;
    let url = API_CREATE_CHARLAS;
    // console.log(this.semana)
    if (this.semana == null){
      // console.log('entra')
      this.isCreating = false;
      Swal.fire(this.translations.msg_all_required, '', 'error');
      return;
    }
    if (this.c_obj['title'] == null){
      this.isCreating = false;
      Swal.fire(this.translations.msg_all_required, '', 'error');
      return;
    }
    if (this.exist == 1) {
      this.semana = null;
      this.isCreating = false;
      Swal.fire(this.translations.existe_chat_week,'','error');
      return;
    }
    if (this.archivos.length == 0){
      this.isCreating = false;
      return Swal.fire(this.translations.msg_all_required, '', 'error');
    }

    let obj = {
      Year: this.fechaActual.getFullYear(),
      Week: this.semana.week,
      RangesDates: this.semana.rangeDates,
      CreatorUser: this.userinfo['logon'],
      Title: this.c_obj['title']
    }
    this.srv.postCall(url, obj).toPromise().then( async data => {
      if(data.success){
        await this.UploadFile(data)
        this.isCreating = false;
        this.closeModal(modal)
      }else {
        this.isCreating = true;
        Swal.fire(data.msg, '', 'error');
      }    
    },catchError =>{
      this.isCreating = true;
      Swal.fire(catchError.message, '', 'error');
    });

  }

  public deleteItem(row) {
    let url = API_UPDATESTATUS_CHARLAS;
    let obj = row;
    obj['Status'] = "3"
    // console.log(obj)
    this.srv.postCall(url, obj).subscribe(data => {
      if(data.success){
        Swal.fire(this.translations.msg_delete, '', 'success');
        this.getList();
      }else {
        Swal.fire(data.msg, '', 'error');
      }    
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
    });

  }


  public downloadDocFile(data) {
    let url = API_GET_FILE_CHARLAS;
    const idCharla = data.CharlaID;
    const name = data.FileName;
    this.srv.downloadFileAny(idCharla, url).subscribe((data) => {
      importedSaveAs(data,name )
    });
  }

  public async UploadFile(data) {
    let formData = new FormData();
    for(let file of this.archivos) {
      formData.append(file.name, file);
    }
    formData.append('Semana', this.semana.week);
    formData.append('Year', this.fechaActual.getFullYear().toString());
    formData.append('CharlaID', data.val.CharlaID.toString());

    await this.srv.AddFiles(API_UPLOADFILE_CHARLAS, formData).toPromise().then(result => {
      if (result=="OK") {
        this.archivos=[];
        this.c_obj = {};
        this.semana = null;
        Swal.fire(this.translations.msg_create, '', 'success');
        this.getList()
      }
      else{
        Swal.fire('', result, 'error');
        this.c_obj = {};
        this.semana = null;
        this.archivos=[];
      }
    });
  }


  public openUpdateModal (content, row) {
    let obj = JSON.parse(JSON.stringify(row))
    // console.log(obj)
    this.modalService.open(content, { backdrop: 'static',  keyboard : false, size: "lg" });
    this.u_obj = obj;
  }

  public updateItem(modal) {
    this.isUpdating = true;
    let url = API_UPDATE_CHARLAS;
    let obj = {
      Title: this.u_obj['Title'],
      LastModifierUser: this.userinfo['logon'],
      FileName: this.fileName,
      Year: this.u_obj['Year'],
      Week: this.u_obj['Week'],
      CharlaID: this.u_obj['CharlaID']
    }
    this.srv.postCall(url, obj).toPromise().then( async data => {
      if(data.success){
        await this.UploadFileUpdate(data);
        this.isUpdating = false;
        this.u_obj = {};
        this.archivos=[];
        this.getList();
        this.closeModal(modal)
      }else {
        this.u_obj = {};
        this.isUpdating = false;
        this.archivos=[];
        this.getList();
        Swal.fire(data.msg, '', 'error');
      }    
    },catchError =>{
      this.isUpdating = false;
      Swal.fire(catchError.message, '', 'error');
    });
  }

  public async UploadFileUpdate(data) {
    // console.log(data)
    let formData = new FormData();
    for(let file of this.archivos) {
      formData.append(file.name, file);
    }
    formData.append('Semana', data.val.Week);
    formData.append('Year', data.val.Year.toString());
    formData.append('CharlaID', data.val.CharlaID.toString());

    await this.srv.AddFiles(API_UPLOADFILE_CHARLAS, formData).toPromise().then(result => {
      if (result=="OK") {
        Swal.fire(this.translations.msg_update, '', 'success');
      }
      else{
        Swal.fire('', result, 'error');
        this.u_obj = {};
        this.archivos=[];
      }
    });
  }

}
