import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';


const API_CONSULTAFALTADETAIL = environment.API_CONSULTAFALTADETAIL;
const API_REGISTERFOUL = environment.API_REGISTERFOUL;
const API_UPDATEFOUL = environment.API_UPDATEFOUL;
const API_DESACTIVEFOUL = environment.API_DESACTIVEFOUL;
const API_ACTIVEFOUL = environment.API_ACTIVEFOUL;


@Component({
  selector: 'app-paramfouls',
  templateUrl: './paramfouls.component.html',
  styleUrls: ['./paramfouls.component.css']
})
export class ParamfoulsComponent extends BaseCrudComponent {
  id:any;
  loadingRequired = false;
  listAgr:any;
  translations:any;
  userinfo:any;
  jobtypeID:any;

  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
    this.id = route.snapshot.params.id;

    this.read_url = API_CONSULTAFALTADETAIL;
    this.create_url = API_REGISTERFOUL;
    this.update_url = API_UPDATEFOUL;
    this.delete_url = API_DESACTIVEFOUL;
    this.active_url = API_ACTIVEFOUL;

    this.search_fields = ['Name', 'Descripcion'];

    this.c_obj['AnalysisFeature'] = [];

    this.reload.subscribe(val => {
        if( val ){
            super.clearData();
            this.getList();
        }
    });
   }

   ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
          this.translations = translations;
          super.ngOnInit();
          this.getList();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    this.storage.get('userinfo').then(x => {
        this.userinfo = x;
    });
  }

  public getList(){
    // gger;
  
    var qst = this.read_url;
    this.loadingList = true;

          
          var id:any;
          id = {};
          id.FoulTypeID = this.id;
          Swal.fire(
            {
              title: this.translations['loading'],
              html: '',
              icon: 'info',
            }
          );
          Swal.showLoading();
          this.srv.postCall(qst, id).subscribe(x => {
                this.loadingList = false;
               // //debugger;
                if(x.success){
                  if(x.val != null){
                    this.rawdata = x.val;
                    var rs = x.val;
                    this.rows = rs;
                    this.page = 1;
                    if( Array.isArray(this.rows) ){
                      this.pageTotal = this.rows.length;
                    }
                    this.filterAll();
                    Swal.close()
                  }else {
                    Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
                    this.rows = [];
                    return;
                      }
                }else {
                  Swal.fire(x.msg, '', 'error');
                  return;
                    }
            });
        }

        public createItem(content:any, form:any){
          ////debugger;
          var validated = true;
        
          if( form == undefined ){
            form = {};
          }
        
          validated = this.validateCreateInfo(form);
          if( !validated ){
              Swal.fire(this.translations['msg_all_required'], '', 'error');
              return;
          }
        
          var to:any;
         form.CreatorUser = this.userinfo['logon'];
         form.LastModifierUser = this.userinfo['logon'];
         form.FoulTypeID = this.id;
          to = form;
        
        
          var qst = this.create_url;
          this.isCreating = true;
        
          this.srv.postCall(qst, to).subscribe(x => {
              this.isCreating = false;
              if(x.success){
                  this.getList();
                  this.closeModal(content);
                  Swal.fire('¡Creado!', x.msg, 'success');
              }//end success
              else{
                  //Swal.fire(this.translations[x.msg], '', 'error');
                  Swal.fire(x.msg, '', 'error');
              }
          });
        }

        validateCreateInfo(fields:any){
          let mygroup:any = {};
          mygroup = new FormGroup({
              'Name': new FormControl(fields.Name, [Validators.required]),
              'Descripcion': new FormControl(fields.Descripcion, []),
          });
        
          return mygroup.valid;
        }
      
        public updateItem(content:any, form:any){

          var qst = this.update_url;
          this.isCreating = true;
        
          if( form == undefined ){
            form = {};
          }
        
          var to:any;
        
          if( (form.Name == undefined) || (form.Name == '') ){
              Swal.fire('Ingrese todos los campos requeridos', 'msg', 'warning');
              return;
          }
        
        
          to = {};
          to.Name = form.Name;
          to.Descripcion = form.Descripcion;
          to.LastModifierUser = this.userinfo['logon'];
          to.ParaDetaFoulID = form.ParaDetaFoulID;
          
        
          this.srv.postCall(qst, to).subscribe(x => {
            this.isCreating = false;
            Swal.close();
            if (x.success) {
              this.reload.next(true);
              this.closeModal('update2');
              Swal.fire('¡Actualizado!', x.msg, 'success');
              this.getList();
            } 
         }, err => {
            Swal.close();
            Swal.fire('Error');
           });
        
        }
        toggleEditable(event, id) {
          ////debugger;
          if ( event.target.checked ) {
             this.Activar(id);
         }else {
           this.deleteItem(id);
        
        }
        }
        
        Activar(obj: any){
        
        
          var id:any;
          id = {};
          id.ParaDetaFoulID = obj;
          id.LastModifierUser = this.userinfo['logon'];
          var qst = this.active_url;
        
          this.srv.postCall(qst, id).subscribe(x => {
            this.isCreating = false;
                  Swal.close();
                  if(x.success){
                    this.reload.next(true);
                    Swal.fire('¡Activado!', x.msg, 'success');
                    this.getList();
                }
                else{
                    Swal.fire(x.msg, '', 'error');
                }
         }, err => {
            Swal.close();
            Swal.fire('Error');
           });
        }
        
        deleteItem(obj: any){
        
          var id:any;
          id = {};
          id.ParaDetaFoulID = obj;
          id.LastModifierUser = this.userinfo['logon'];
          id.DeleteUser = this.userinfo['logon'];
          var qst = this.delete_url;
        
          this.srv.postCall(qst, id).subscribe(x => {
            this.isCreating = false;
            Swal.close();
            if(x.success){
              this.reload.next(true);
              Swal.fire('Desactivado!', x.msg, 'success');
              this.getList();
          }
          else{
              Swal.fire(x.msg, '', 'error');
          }
         }, err => {
            Swal.close();
            Swal.fire('Error');
           });
        }

}
