import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../../base-crud/base-crud.component';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/services/main/global.service';
import Swal from 'sweetalert2';
import { LoginService } from 'src/app/services/authentication/login.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';


const API_CREATE_ACCIDENTEINCIDENT = environment.Global.API_CREATE_ACCIDENTEINCIDENT;
const API_UPDATE_ACCIDENTEINCIDENT = environment.Global.API_UPDATE_ACCIDENTEINCIDENT;
const API_UPDATESTATUS_ACCIDENTEINCIDENT = environment.Global.API_UPDATESTATUS_ACCIDENTEINCIDENT;
const API_GET_ACCIDENTEINCIDENT = environment.Global.API_GET_ACCIDENTEINCIDENT;
const API_GET_ACCIDENTEINCIDENTBYID = environment.Global.API_GET_ACCIDENTEINCIDENTBYID;
const API_UPLOAD_FILE_ACCIDENTEINCIDENT = environment.Global.API_UPLOAD_FILE_ACCIDENTEINCIDENT;
const GET_EMPLOYEE_BY_IDENTIFICATION = environment.Global.API_GET_EMPLOYEE_BY_IDENTIFICATION;
const API_GET_SEVERITY_TYPE_BY_ID = environment.Global.API_GET_SEVERITY_TYPE_BY_ID; 
const GET_ALL_SITES_ACTIVE = environment.Global.API_GET_ALL_SITES_ACTIVE;
const GET_ALL_AREA_BY_ID = environment.Global.API_GET_ALL_AREA_BY_ID;
const API_GET_BOSSBYEMPLOYEE = environment.Global.API_GET_BOSSBYEMPLOYEE;
const API_GET_EMPLOYEE_INTERNAL_BY_NUMBERIDENTIFICATION = environment.Global.API_GET_EMPLOYEE_INTERNAL_BY_NUMBERIDENTIFICATION;
const API_GET_ACCIDENTINCIDENTBYUSER = environment.Global.API_GET_ACCIDENTINCIDENTBYUSER;
const API_ACCIDENTINCIDENT_MAIL = environment.Global.API_ACCIDENTINCIDENT_MAIL;

@Component({
  selector: 'app-incident',
  templateUrl: './incident.component.html',
  styleUrls: ['./incident.component.css']
})
export class IncidentComponent extends BaseCrudComponent implements OnInit {
  fechaActual = new Date();
  list_injury =  [];
  list_workers = [{id: 1, type: 'Interno'},{id: 2, type:'Externo'}];
  list_option_fault = [{id: 1, type: 'Sí'},{id: 2, type:'No'}];
  archivos=[];
  list_incad = [{id: 1, type: 'Incidente'},{id: 2, type:'Accidente'}];
  workerId = 1;
  plantaId = 0;
  row_plantas = [];
  row_plantas_area = [];
  employeesbyarea;
  permissions;
  isCreating = false;
  isUpdating = false;
  loadingList = false;
  userinfo: any;
  modalCreate = false;
  mailbossExternal;
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private globalService: GlobalService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    public auth: LoginService,
  ) {
    super(srv, modalService, storage, router);
  }

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
      this.translations = translations;
      super.ngOnInit();
    });
    this.search_fields = ['AccIncId','Name'];
    this.c_obj['typeinac'] = 1;
    this.c_obj['date'] = this.globalService.formatOnlyDate(this.fechaActual);

    this.GetSeverityTypes();
    this.storage.get('userinfo').then(x => {
      this.userinfo = x;
    });

    this.auth.getPermissions().then(x => {
      this.permissions = x;
      this.getList(); 
    });
    
  }

  public onlyNumberKey(event: any): boolean {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  public getList() {
    this.loadingList = true; 
    // Swal.fire(
    //   {
    //     title: this.translations['loading'],
    //     html: '',
    //     icon: 'info',
    //   }
    // );
    // Swal.showLoading();
    let url = '';
    if (this.permissions.includes('SI_ADMIN')) {
      url = API_GET_ACCIDENTEINCIDENT;
    } else {
      url = API_GET_ACCIDENTINCIDENTBYUSER + this.userinfo['logon'];
    }
    this.srv.getCall(url).subscribe(x => {
      this.loadingList = false; 
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          this.rows = this.rawdata;
          // console.log(this.rows)
          this.page = 1;
          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          Swal.close()
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
    this.GetPlantas();
  }

  public async GetEmployeeforIdentification(obj:any) {
    if (obj === "" || obj == " ")
    {
      return;
    }
    var qst = "";
    if (this.workerId == 1) { // trabajador interno
      qst = API_GET_EMPLOYEE_INTERNAL_BY_NUMBERIDENTIFICATION + obj;
    } else { // trabajador externo
      qst =  GET_EMPLOYEE_BY_IDENTIFICATION + obj;
    }
    await this.srv.getCall(qst).toPromise().then(x => {
      this.loadingList = false;
      // console.log(x)
      if(x.success){
        if(x.val != null){
          this.c_obj['EmployeeId'] = x.val['EmployeeId'];
          if (this.workerId == 1) {
            this.c_obj['NameEmployee'] = x.val['Name'] + " " + x.val['LastName'];
          } else {
            this.c_obj['NameEmployee'] = x.val['Name'];
            this.c_obj['CompanyID'] = x.val['CompanyID'];
          }
        } else {
          this.c_obj['NameEmployee'] = null;
          Swal.fire( x.msg , '' ,'error');
          return;
        }
      }else {
        this.c_obj['NameEmployee'] = null;
        Swal.fire( x.msg , '' ,'error');
        return;
      }
    });
    this.searchBoss();
  }
  
  public handleInputChange(e){
    // console.log(e)
    this.archivos=[];
    var file = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    let files = Object.values(file);
    files.forEach(element => {
      if (this.archivos.length < 2) {
        this.archivos.push(element);
      } else {
        return Swal.fire(this.translations.only_two_imgs,'','error');
      }
    });
    
  }

  public setTypeWorker() {
    this.c_obj['NameEmployee'] = null;
    this.c_obj['NumberIdentification'] = null;   
    this.c_obj['bossInternal'] = null;
    this.c_obj['witness'] = null;
    this.c_obj['medt_take'] = null;
    this.mailbossExternal = null;
    this.employeesbyarea = null;
  }


  public openCreateModal(content: any) {
    this.c_obj = {};
    this.c_obj['typeinac'] = 1;
    this.c_obj['reportby'] = this.userinfo['name'];
    this.c_obj['PlantaId'] = null;
    this.c_obj['SeccionId'] = null;
    this.c_obj['severity_of_injury'] = null;
    this.c_obj['bossInternal'] = null;
    this.c_obj['date'] = this.globalService.formatOnlyDate(new Date());
    this.modalCreate = true;
    this.employeesbyarea = null;
    this.modalService.open(content,  { backdrop: 'static',  keyboard : false, size: 'lg' });
  }

  public GetPlantas() {
    var qst = GET_ALL_SITES_ACTIVE;
    this.srv.getCall(qst).subscribe(x => {
      this.row_plantas = [];
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.row_plantas = rs;
        }else {
          this.row_plantas = [];
          return;
        }
      }else {
        return;
      }
    });
  }

  public GetSeverityTypes() {
    var qst = API_GET_SEVERITY_TYPE_BY_ID + 'SEVERITY_TYPE';
    this.srv.getCall(qst).subscribe(x => {
      this.list_injury = [];
      if(x.success){
        if(x.val != null){
          // console.log(x.val)
          x.val.forEach(element => {
            this.list_injury.push(
              {
                id: element.ID,
                type: element.value
              }
            );
          })
        }else {
          return;
        }
      }else {
        return;
          }
    });
  }

  public searchArea() {
    let codigoPlanta = this.c_obj['PlantaId'] || this.u_obj['PlantaID'];
    var qst = GET_ALL_AREA_BY_ID + codigoPlanta;
    this.srv.getCall(qst).subscribe(x => {
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.row_plantas_area = rs;
          // console.log(this.row_plantas_area)
        }else {
          this.row_plantas_area = [];
          return;
        }
      }else {
        return;
          }
    });
    
  }
  
  public validateCreateInfo(fields:any): boolean {
    let inciacci:any = {};
    inciacci = new FormGroup({
        'NumberIdentification': new FormControl(fields.NumberIdentification, [Validators.required]),
        'NameEmployee': new FormControl(fields.NameEmployee, [Validators.required]),
        'severity_of_injury': new FormControl(fields.severity_of_injury, [Validators.required]),
        'PlantaId': new FormControl(fields.PlantaId, [Validators.required]),
        // 'boss_inme': new FormControl(fields.boss_inme, [Validators.required]),
        // 'mail_boss': new FormControl(fields.mail_boss, [Validators.required]),
        'SeccionId': new FormControl(fields.SeccionId, [Validators.required]),
        'medt_take': new FormControl(fields.medt_take, [Validators.required]),
        'witness': new FormControl(fields.witness, [Validators.required])

    });
    // console.log(inciacci)
    return inciacci.valid;
  }

  public searchBoss() {
    let obj = {
      employeeId: this.u_obj['EmployeeId'] || this.c_obj['EmployeeId'],
      typeWorker: this.workerId
    }
    // console.log(obj)
    this.srv.postCall(API_GET_BOSSBYEMPLOYEE, obj).subscribe(x => {
      if(x.success) {
        if (x.val != null) {
          // console.log(x.val)
          if (this.workerId == 1) {
            this.employeesbyarea = x.val;
          } else {
            this.employeesbyarea = x.val['name'];
            this.mailbossExternal = x.val['email'];
          }
          // console.log(x.val)
        }
      }
    });
  }


  public createItem(modal, object) {
    this.isCreating = true;
    let validated = this.validateCreateInfo(object);
    if( !validated ){
      this.isCreating = false;
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }

    if (this.archivos.length == 0) {
      this.isCreating = false;
      return Swal.fire(this.translations.should_upload_imgs, '','error');
    }
    let new_obj = {
      AccIncType: object['typeinac'],
      BossInmediatly: this.workerId == 1 ? object['bossInternal'][0] : this.employeesbyarea,
      CompanyID: this.workerId == 2 ? object['CompanyID'] : null,
      CreatorUser: this.userinfo['logon'],
      Date: object['date'],
      EmployeeId: object['EmployeeId'],
      MailBoss: this.workerId == 1 ? object['bossInternal'][1] : this.mailbossExternal,
      Measures: object['medt_take'],
      Photos: '',
      PlantaID: object['PlantaId'],
      Reportby: this.userinfo['logon'],
      SeccionID: object['SeccionId'],
      SeverityType: object['severity_of_injury'],
      Witnesses: object['witness'],
      WorkerTypeId: this.workerId,
    }
    this.srv.postCall(API_CREATE_ACCIDENTEINCIDENT, new_obj).toPromise().then( async (data)=> {
      if(data.success){
        this.isCreating = false;
        this.c_obj = {};
        await this.sendPhotos(data.val['AccIncId']);
        Swal.fire(this.translations.msg_create,'', 'success');
        this.closeModal(modal);
        await this.sendMail(data.val['AccIncId']);
        this.getList();
      } else {
        this.isCreating = false;
        Swal.fire(data.msg, '', 'error');
      }      
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
      this.isCreating = false;
    });
  }

  public async sendPhotos(data?) {
    let formData = new FormData();
    for(let file of this.archivos) {
      formData.append(file.name, file);
    }
    formData.append('AccIncId', data);
    formData.append('TypeWorker', this.workerId == 1 ? 'Interno': 'Externo');
    await this.srv.AddFiles(API_UPLOAD_FILE_ACCIDENTEINCIDENT, formData).toPromise().then(result => {
      if (result=="OK") {
        this.archivos=[];
      }
      else{
        Swal.fire(this.translations.evidence, result, 'error');
        this.archivos=[];
      }
    });
  }

  public async sendMail(data?) {
    var to:any;    
    to = {};
    to['AccIncId'] = data;
    let qst = API_ACCIDENTINCIDENT_MAIL;
    await this.srv.postCall(qst ,to).toPromise().then(x => {
      Swal.fire(x.msg, '','success');
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }

  public openUpdateModal(content, obj) {
    this.modalService.open(content,  { backdrop: 'static',  keyboard : false, size: 'lg' });
    this.u_obj = obj;
    // console.log(this.u_obj)
    this.employeesbyarea = null;
    this.searchArea();
    // if (this.u_obj.WorkerTypeId == 1) {
    //   this.searchBoss();
    // }
  }

  public deleteAccInc(row) {
    this.ActivarItem(row.AccIncId,3);
  }

  public ActivarItem(id:any, accion:any): void {
    var to:any;
    to = {};
    to.AccIncId = id;
    to.Status = accion;
    to.LastModifierUser = this.userinfo['logon'];
    this.srv.postCall(API_UPDATESTATUS_ACCIDENTEINCIDENT, to).subscribe(x => {
      Swal.close();
      if(x.success){
        Swal.fire(this.translations['MENSAJE'], x.msg, 'success');
        this.getList();
      }
      else{
        Swal.fire(x.msg, '', 'error');
      }
    }, err => {
        Swal.close();
        Swal.fire('Error');
    });
  }



  public updateItem(modal, u_obj) {
    this.isUpdating = true;
    let new_obj = {
      AccIncId: u_obj['AccIncId'],
      EmployeeId: u_obj['EmployeeId'],
      PlantaID: u_obj['PlantaID'],
      SeccionID: u_obj['SeccionID'],
      LastModifierUser: this.userinfo['logon'],
    }
    this.srv.postCall(API_UPDATE_ACCIDENTEINCIDENT, new_obj).toPromise().then(  (data)=> {
      this.isUpdating = false;
      if(data.success){
        this.c_obj = {};
        Swal.fire(this.translations.msg_update,'', 'success');
        this.getList();
        this.isUpdating = false;
        this.closeModal(modal);
      } else {
        this.isUpdating = false;
        Swal.fire(data.msg, '', 'error');
      }      
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
      this.isUpdating = false;
    });
  }





}
