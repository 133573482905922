import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { SettingsComponent } from './settings/settings.component';
import { ParameterComponent } from './maint/parameter/parameter.component';
import { CrudComponent } from './maint/crudhome/crud.component';
import { CompanyComponent } from './maint/company/company.component';
import { CountryComponent } from './maint/country/country.component';
import { ProductionGroupComponent } from './maint/production-group/production-group.component';
import { UserComponent } from './maint/user/user.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormshomeComponent } from './forms/formshome/formshome.component';
import { ProchomeComponent } from './processes/prochome/prochome.component';
import { LoginService } from '../services/authentication/login.service';
import { ParamjobtypeComponent } from './maint/paramjobtype/paramjobtype.component';
import { ParamplantaComponent } from './maint/paramplanta/paramplanta.component';
import { ParamareaComponent } from './maint/paramarea/paramarea.component';
import { ParamgeneralesComponent } from './maint/paramgenerales/paramgenerales.component';
import { ParamgenedetailComponent } from './maint/paramgenedetail/paramgenedetail.component';
import { ParamdocujobtypeComponent } from './maint/paramdocujobtype/paramdocujobtype.component';
import { ParamdocuComponent } from './maint/paramdocu/paramdocu.component';
import { ParamtypefoulComponent } from './maint/paramtypefoul/paramtypefoul.component';
import { ParamfoulsComponent } from './maint/paramfouls/paramfouls.component';
import { ParamsancionComponent } from './maint/paramsancion/paramsancion.component';
import { RegsubcontracComponent } from './forms/regsubcontrac/regsubcontrac.component';
import { RegsubcontractypejobComponent } from './forms/regsubcontractypejob/regsubcontractypejob.component';
import { RegsubcontracdocuComponent } from './forms/regsubcontracdocu/regsubcontracdocu.component';
import { RegcompaniesComponent } from './forms/regcompanies/regcompanies.component';
import { RegcompaniesdocuComponent } from './forms/regcompaniesdocu/regcompaniesdocu.component';
import { ExcelenciahomeComponent } from './excelencia/excelenciahome/excelenciahome.component';
import { ParamcomunicadoComponent } from './maint/paramcomunicado/paramcomunicado.component';
import { ComunicadosComponent } from './maint/comunicados/comunicados.component';
import { ParamtipopreguntaComponent } from './maint/paramtipopregunta/paramtipopregunta.component';
import { ParampreguntaComponent } from './maint/parampregunta/parampregunta.component';
import { ParampreguntadetalleComponent } from './maint/parampreguntadetalle/parampreguntadetalle.component';
import { ParamevaluacionComponent } from './maint/paramevaluacion/paramevaluacion.component';
import { RegcompaniesempComponent } from './forms/regcompaniesemp/regcompaniesemp.component';
import { QuestionsComponent } from './maint/questions/questions.component';
import { QuestiondetailComponent } from './maint/questiondetail/questiondetail.component';
import { RegemployeeComponent } from './forms/regemployee/regemployee.component';
import { RegemployeedocComponent} from './forms/regemployeedoc/regemployeedoc.component';
import { ConsdochistoryComponent } from './forms/consdochistory/consdochistory.component';
import { ConsdochistorycompaniesComponent } from './forms/consdochistorycompanies/consdochistorycompanies.component';
import { ConsdochistorysubcompaniesComponent } from './forms/consdochistorysubcompanies/consdochistorysubcompanies.component';
import { ConsdochistoryemployeesComponent } from './forms/consdochistoryemployees/consdochistoryemployees.component';
import { ProapprovedocumentsComponent } from './processes/proapprovedocuments/proapprovedocuments.component';
import { ProapprovedocumentscompanyComponent } from './processes/proapprovedocumentscompany/proapprovedocumentscompany.component';
import { ProapprovedocumentssubcompanyComponent } from './processes/proapprovedocumentssubcompany/proapprovedocumentssubcompany.component';
import { ProapprovedocumentsemployeesComponent } from './processes/proapprovedocumentsemployees/proapprovedocumentsemployees.component';
import { ProrequestComponent } from './processes/prorequest/prorequest.component';
import { MyevaluationComponent } from './excelencia/myevaluation/myevaluation.component';
import { MyevaluationepecificComponent } from './excelencia/myevaluationepecific/myevaluationepecific.component';
import { OpportunitiesComponent } from './maint/opportunities/opportunities.component';

import { EvaluationinternalComponent } from './processes/evaluationinternal/evaluationinternal.component';
import { RegtoolsequipmentComponent } from './forms/regtoolsequipment/regtoolsequipment.component';
import { ProadmissionappComponent } from './processes/proadmissionapp/proadmissionapp.component';
import { RepocreatorComponent } from './processes/repocreator/repocreator.component';
import { ReportingComponent } from './processes/reporting/reporting.component';
import { ProadmissemergappComponent } from './processes/proadmissemergapp/proadmissemergapp.component';
import { WorkersPlantComponent } from './excelencia/workers-plant/workers-plant.component';
import { ReportformsComponent } from './forms/reportforms/reportforms.component';
import { ActnosecureComponent } from './excelencia/incidents/actnosecure/actnosecure.component';
import { CondinosecureComponent } from './excelencia/incidents/condinosecure/condinosecure.component';
import { IncidentComponent } from './excelencia/incidents/incidentaccident/incident.component';
import { OportunitymejorComponent } from './excelencia/incidents/oportunitymejor/oportunitymejor.component';
import { PlanComponent } from './excelencia/plan/plan.component';
import { PlanAuditsComponent } from './excelencia/audits/plan-audits/plan-audits.component';
import { HseComponent } from './excelencia/audits/hse/hse.component';
import { IncidentsComponent } from './excelencia/incidents/incidents.component';
import { AuditsComponent } from './excelencia/audits/audits.component';
import { ActionperplanComponent } from './excelencia/actionperplan/actionperplan.component';
import { FormsQuestionsComponent } from './maint/forms-questions/forms-questions.component';
import { CronogramaComponent } from './excelencia/audits/cronograma/cronograma.component';
import { CharlasComponent } from './excelencia/audits/charlas/charlas.component';
import { CharlasAuditsComponent } from './excelencia/audits/charlas-audits/charlas-audits.component';
import { MaintuserComponent } from './maint/maintuser/maintuser.component';
import { ActionplanAuditsComponent } from './excelencia/audits/actionplan-audits/actionplan-audits.component';
import { MaintareabygodfatherComponent } from './maint/maintareabygodfather/maintareabygodfather.component';
import { CalificacionesComponent } from './excelencia/audits/calificaciones/calificaciones.component';
import { QremployeeComponent } from './excelencia/qremployee/qremployee.component';
import {ProaddratingComponent} from "./processes/proaddrating/proaddrating.component";
import {ProIndicatorDetailComponent} from "./processes/pro-indicator-detail/pro-indicator-detail.component";
import { ProapprovechangeemployeesComponent } from './processes/proapprovechangeemployees/proapprovechangeemployees.component';
import {QrcodeValidationComponent} from "./qrcode-validation/qrcode-validation.component";
import {ReportingPersonalComponent} from "./processes/reporting-personal/reporting-personal.component";


export const Ic2routes: Routes = [
  {
    path: '',
    component: FullComponent,
    //canActivate: [LoginService],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_home',
          urls: []
        }
      },
      {
        path: 'formshome',
        component: FormshomeComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_forms',
          urls: []
        }
      },
      {
        path: 'prochome',
        component: ProchomeComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_processes',
          urls: []
        }
      },
      {
        path: 'workersPlant',
        component: WorkersPlantComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_workers_plant',
          urls: [
            { title: 'menu_operacional', url: '/excelenciahome' },
            { title: 'menu_workers_plant'}
          ]
        }
      },
      // =========================================================================================
      // MODULO DE EXCELENCIA OPERACIONAL
      // =========================================================================================
      {
        path: 'excelenciahome',
        component: ExcelenciahomeComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_operacional',
          urls: []
        }
      },
      {
        path: 'incidents',
        component: IncidentsComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_inspeccion',
          urls: [
            { title: 'menu_operacional', url: '/excelenciahome' },
            { title: 'menu_inspeccion'}
          ]
        }
      },
      {
        path: 'actnosecure',
        component: ActnosecureComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_act_nosecure',
          urls: [
            { title: 'menu_inspeccion', url: '/incidents' },
            { title: 'menu_act_nosecure'}
          ]
        }
      },
      {
        path: 'condinosecure',
        component: CondinosecureComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_condi_nosecure',
          urls: [
            { title: 'menu_inspeccion', url: '/incidents' },
            { title: 'menu_condi_nosecure'}
          ]
        }
      },
      {
        path: 'inciacci',
        component: IncidentComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_incident_accident',
          urls: [
            { title: 'menu_inspeccion', url: '/incidents' },
            { title: 'menu_incident_accident'}
          ]
        }
      },
      {
        path: 'oportunitymejor',
        component: OportunitymejorComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_oportunity_mejor',
          urls: [
            { title: 'menu_inspeccion', url: '/incidents' },
            { title: 'menu_oportunity_mejor'}
          ]
        }
      },
      {
        path: 'planaction',
        component: PlanComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_plan_action',
          urls: [
            { title: 'menu_inspeccion', url: '/incidents' },
            { title: 'menu_plan_action'}
          ]
        }
      },
      {
        path: 'actionperplan',
        component: ActionperplanComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_action_per_plan',
          urls: [
            { title: 'menu_inspeccion', url: '/incidents' },
            { title: 'menu_action_per_plan'}
          ]
        }
      },
      {
        path: 'audits',
        component: AuditsComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_audits',
          urls: [
            { title: 'menu_operacional', url: '/excelenciahome' },
            { title: 'menu_audits'}
          ]
        }
      },
      {
        path: 'hse',
        component: HseComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_hse',
          urls: [
            { title: 'menu_audits', url: '/audits' },
            { title: 'menu_hse'}
          ]
        }
      },
      {
        path: 'cronograma',
        component: CronogramaComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_cronograma',
          urls: [
            { title: 'menu_operacional', url: '/excelenciahome' },
            { title: 'menu_cronograma'}
          ]
        }
      },
      {
        path: 'charla',
        component: CharlasComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_charla',
          urls: [
            { title: 'menu_operacional', url: '/excelenciahome' },
            { title: 'menu_charla'}
          ]
        }
      },
      {
        path: 'charlaAudits',
        component: CharlasAuditsComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_charlaAudits',
          urls: [
            { title: 'menu_operacional', url: '/excelenciahome' },
            { title: 'menu_charlaAudits'}
          ]
        }
      },
      {
        path: 'planActionAudits',
        component: PlanAuditsComponent,
        canActivate: [LoginService],
        data: {
            title: 'menu_plan_action_audits',
          urls: [
            { title: 'menu_audits', url: '/audits' },
            { title: 'menu_hse'}
          ]
        }
      },
      {
        path: 'actionplanAudits',
        component: ActionplanAuditsComponent,
        canActivate: [LoginService],
        data: {
            title: 'menu_action_per_plan',
          urls: [
            { title: 'menu_audits', url: '/audits' },
            { title: 'menu_hse'}
          ]
        }
      },
      {
        path: 'calificaciones',
        component: CalificacionesComponent,
        canActivate: [LoginService],
        data: {
            title: 'menu_calificaciones',
          urls: [
            { title: 'menu_audits', url: '/audits' },
            { title: 'menu_hse'}
          ]
        }
      },
      {
        path: 'qrRequest',
        component: QremployeeComponent,
        canActivate: [LoginService],
        data: {
            title: 'my_qrcode',
          urls: [
            { title: 'menu_operacional', url: '/excelenciahome' },
            { title: 'menu_inspeccion'}
          ]
        }
      },
      {
        path: 'qrcodeValidationComponent',
        component: QrcodeValidationComponent,
        canActivate: [LoginService],
        data: {
          title: 'detail_qrcode',
          urls: [
            { title: 'menu_operacional', url: '/excelenciahome' },
            { title: 'menu_inspeccion'}
          ]
        }
      },
      // =========================================================================================
      // FIN
      // =========================================================================================







      // {
      //   path: 'actionplan',
      //   component: ActionplanComponent,
      //   canActivate: [LoginService],
      //   data: {
      //     title: 'menu_audits',
      //     urls: [
      //       { title: 'menu_operacional', url: '/excelenciahome' },
      //       { title: 'menu_audits'}
      //     ]
      //   }
      // },
      // {
      //   path: 'plan',
      //   component: PlanComponent,
      //   // canActivate: [LoginService],
      //   data: {
      //     title: 'menu_plan',
      //     urls: [
      //       { title: 'menu_planaccion', url: '/actionplan' },
      //       { title: 'menu_plan'}
      //     ]
      //   }
      // },
      // {
      //   path: 'hse',
      //   component: HseComponent,
      //   // canActivate: [LoginService],
      //   data: {
      //     title: 'menu_hse',
      //     urls: [
      //       { title: 'menu_planaccion', url: '/actionplan' },
      //       { title: 'menu_hse'}
      //     ]
      //   }
      // },
      // {
      //   path: 'sapproval',
      //   component: SapprovalComponent,
      //   canActivate: [LoginService],
      //   data: {
      //     title: 'menu_incidents',
      //     urls: [
      //       { title: 'menu_operacional', url: '/excelenciahome' },
      //       { title: 'menu_incidents'}
      //     ]
      //   }
      // },















































      {
        path: 'regsubcontract',
        component: RegsubcontracComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_subcontract',
          urls: [
            { title: 'menu_forms', url: '/formshome' },
            { title: 'menu_subcontract'}
          ]
        }
      },
      {
        path: 'regsubcontracttypejob/:id',
        component: RegsubcontractypejobComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_subcontracttypejob',
          urls: [
            { title: 'menu_forms', url: '/formshome' },
            { title: 'menu_subcontract', url: '/regsubcontract' },
            { title: 'menu_subcontracttypejob' }
          ]
        }
      },
      {

        path: 'regsubcontracdocu/:id',
        component: RegsubcontracdocuComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_subcontractdocu',
          urls: [
            { title: 'menu_forms', url: '/formshome' },
            { title: 'menu_subcontract', url: '/regsubcontract' },
            { title: 'menu_subcontractdocu' }
          ]
        }
      },
      {
        path: 'regcompanie',
        component: RegcompaniesComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_contract',
          urls: [
            { title: 'menu_forms', url: '/formshome' },
            { title: 'menu_contract'}
          ]
        }
      },
      {
        path: 'regcompanieemp',
        component: RegcompaniesempComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_contract',
          urls: [
            { title: 'menu_forms', url: '/formshome' },
            { title: 'menu_contract'}
          ]
        }
      },
      {
        path: 'regcontracdocu/:id',
        component: RegcompaniesdocuComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_contractdocu',
          urls: [
            { title: 'menu_forms', url: '/formshome' },
            { title: 'menu_contract', url: '/regcompanie' },
            { title: 'menu_contractdocu' }
          ]
        }
      },
      {
        path: 'regemployee',
        component: RegemployeeComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_trabajadores',
          urls: [
            { title: 'menu_forms', url: '/formshome' },
            { title: 'menu_trabajadores'}
          ]
        }
      },
      {
        path: 'regemployeedoc/:id',
        component: RegemployeedocComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_trabajadoresdoc',
          urls: [
            { title: 'menu_forms', url: '/formshome' },
            { title: 'menu_trabajadores', url: '/regemployee'},
            { title: 'menu_trabajadoresdoc' }
          ]
        }
      },
      {
        path: 'reportforms',
        component: ReportformsComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_reporte',
          urls: [
            { title: 'menu_forms', url: '/formshome'},
            { title: 'menu_reporte'}
          ]
        }
      },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_general',
          urls: []
        }
      },
      {
        path: 'companies',
        component: CompanyComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_company',
          urls: [
              { title: 'menu_crud', url: '/cruds' },
              { title: 'menu_company'}
          ]
        }
      },
      {
        path: 'countries',
        component: CountryComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_countries',
          urls: [
              { title: 'menu_crud', url: '/cruds' },
              { title: 'menu_countries'}
          ]
        }
      },
      {
        path: 'prodgroups',
        component: ProductionGroupComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_prodgroups',
          urls: [
              { title: 'menu_crud', url: '/cruds' },
              { title: 'menu_prodgroups'}
          ]
        }
      },
      {
        path: 'maintuser',
        component: MaintuserComponent,
        canActivate: [LoginService],
        data: {
          title: 'maint_user',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'maint_user' }
          ]
        }
      },
      {
        path: 'maintareagf',
        component: MaintareabygodfatherComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_area_godfather',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_area_godfather' }
          ]
        }
      },
      {
        path: 'params/:id',
        component: ParameterComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_mant_params',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_mant_analysis', url: '/analysis' },
            { title: 'menu_title_mant_params' }
          ]
        }
      },
      {
        path: 'paramjobtype',
        component: ParamjobtypeComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_tipotrabajo',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_tipotrabajo'}
          ]
        }
      },
      {
        path: 'paramdocujobtype/:id',
        component: ParamdocujobtypeComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_docujobtype',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_tipotrabajo', url: '/paramjobtype' },
            { title: 'menu_title_man_docujobtype'}
          ]
        }
      },
      {
        path: 'consdochistory',
        component: ConsdochistoryComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_form_documenthistory',
          urls: [
            { title: 'menu_forms', url: '/formshome' },
            { title: 'menu_title_form_documenthistory'}
          ]
        }
      },
      {
        path: 'consdochistorycompanies/:id',
        component: ConsdochistorycompaniesComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_historydocument_company',
          urls: [
            { title: 'menu_forms', url: '/formshome' },
            { title: 'menu_title_form_documenthistory', url: '/consdochistory' },
            { title: 'menu_historydocument_company'}
          ]
        }
      },
      {
        path: 'consdochistorysubcompanies/:id',
        component: ConsdochistorysubcompaniesComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_historydocument_subcompany',
          urls: [
            { title: 'menu_forms', url: '/formshome' },
            { title: 'menu_title_form_documenthistory', url: '/consdochistory' },
            { title: 'menu_historydocument_subcompany'}
          ]
        }
      },
      {
        path: 'consdochistoryemployees/:id',
        component: ConsdochistoryemployeesComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_historydocument_employees',
          urls: [
            { title: 'menu_forms', url: '/formshome' },
            { title: 'menu_title_form_documenthistory', url: '/consdochistory' },
            { title: 'menu_historydocument_employees'}
          ]
        }
      },
      {
        path: 'proapprovedocuments',
        component: ProapprovedocumentsComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_docapproval',
          urls: [
            { title: 'menu_processes', url: '/prochome' },
            { title: 'menu_docapproval'}
          ]
        }
      },
      {
        path: 'proapprovedocumentscompany/:id',
        component: ProapprovedocumentscompanyComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_docapproval_company',
          urls: [
            { title: 'menu_processes', url: '/prochome' },
            { title: 'menu_docapproval', url: '/proapprovedocuments' },
            { title: 'menu_docapproval_company'}
          ]
        }
      },
      {
        path: 'proapprovedocumentssubcompany/:id',
        component: ProapprovedocumentssubcompanyComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_docapproval_subcompany',
          urls: [
            { title: 'menu_processes', url: '/prochome' },
            { title: 'menu_docapproval', url: '/proapprovedocuments' },
            { title: 'menu_docapproval_subcompany'}
          ]
        }
      },
      {
        path: 'proapprovedocumentsemployees/:id',
        component: ProapprovedocumentsemployeesComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_docapproval_employees',
          urls: [
            { title: 'menu_processes', url: '/prochome' },
            { title: 'menu_docapproval', url: '/proapprovedocuments' },
            { title: 'menu_docapproval_employees'}
          ]
        }
      },
      {
        path: 'prorequest',
        component: ProrequestComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_soinduccion',
          urls: [
            { title: 'menu_processes', url: '/prochome' },
            { title: 'menu_soinduccion'}
          ]
        }
      },
      {
        path: 'proaddrating',
        component: ProaddratingComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_docaddrating',
          urls: [
            { title: 'menu_processes', url: '/prochome' },
            { title: 'menu_docaddrating'}
          ]
        }
      },
      {
        path: 'proindicator',
        component: ProIndicatorDetailComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_indicators',
          urls: [
            { title: 'menu_processes', url: '/prochome' },
            { title: 'menu_indicators'}
          ]
        }
      },
      {
        path: 'myevaluation',
        component: MyevaluationComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_myevaluation',
          urls: [
            { title: 'menu_operacional', url: '/excelenciahome' },
            { title: 'menu_myevaluation'}
          ]
        }
      },
      {
        path: 'myevaluationepecific/:id',
        component: MyevaluationepecificComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_myevaluationespecific',
          urls: [
            { title: 'menu_operacional', url: '/excelenciahome' },
            { title: 'menu_myevaluation', url: '/myevaluation' },
            { title: 'menu_myevaluationespecific'}
          ]
        }
      },
      {
        path: 'opportunities',
        component: OpportunitiesComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_opportunities',
          urls: [
            { title:  'menu_crud', url: '/cruds' },
            { title: 'menu_opportunities'}
          ]
        }
      },
      {
        path: 'paramplanta',
        component: ParamplantaComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_planta',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_planta'}
          ]
        }
      },
      {
        path: 'paramarea/:id',
        component: ParamareaComponent,
        canActivate: [LoginService],
        data: {
          title: 'areas',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_planta', url: '/paramplanta' },
            { title: 'areas' }
          ]
        }
      },
      {
        path: 'paramgenerales',
        component: ParamgeneralesComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_generales',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_generales'}
          ]
        }
      },
      {
        path: 'paramgenedetail/:id',
        component: ParamgenedetailComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_generadetail',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_generales', url: '/paramgenerales' },
            { title: 'menu_title_man_generadetail' }
          ]
        }
      },
      {
        //path: 'paramdocudetail/:id',
        path: 'paramdocu',
        component: ParamdocuComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_docu',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            //{ title: 'menu_title_man_docujobtype', url: '/paramdocujobtype' },
            { title: 'menu_title_man_docu' }
          ]
        }
      },
      {
        path: 'paramtypefouls',
        component: ParamtypefoulComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_foultype',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_foultype'}
          ]
        }
      },
      {
        path: 'paramfouls/:id',
        component: ParamfoulsComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_foul',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_foultype', url: '/paramtypefouls' },
            { title: 'menu_title_man_foul' }
          ]
        }
      },
      {
        path: 'paramsancion/:id',
        component: ParamsancionComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_sancion',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_foultype', url: '/paramtypefouls' },
            { title: 'menu_title_man_sancion' }
          ]
        }
      },
      {
        path: 'paramcomunicados',
        component: ParamcomunicadoComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_comunicados',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_comunicados'}
          ]
        }
      },
      {
        path: 'comunicados/:id',
        component: ComunicadosComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_comunicadosg',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_comunicados', url: '/paramcomunicados' },
            { title: 'menu_title_man_comunicadosg' }
          ]
        }
      },
      {
        path: 'paramtipopregunta',
        component: ParamtipopreguntaComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_tipo_preguntas',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_tipo_preguntas'}
          ]
        }
      },
      {
        path: 'questions',
        component: QuestionsComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_pregunta',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_pregunta' }
          ]
        }
      },
      {
        path: 'questiondetail/:id',
        component: QuestiondetailComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_preguntasdetalle',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_pregunta', url: '/questions' },
            { title: 'menu_title_man_preguntasdetalle' }
          ]
        }
      },
      {
        path: 'paramevaluacion',
        component: ParamevaluacionComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_man_evaluacion',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_man_evaluacion'}
          ]
        }
      },
      {
        path: 'cruds',
        component: CrudComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_crud',
          urls: [
            /*{ title: 'Mapas', url: '' },
            { title: 'Mapas' }*/
          ]
        }
      },
      {
        path: 'users',
        component: UserComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_users',
          urls: [
              { title: 'menu_crud', url: '/cruds' },
              { title: 'menu_users'}
          ]
        }
      },

      {
        path: 'formsQuestions',
        component: FormsQuestionsComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_form_questions',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_form_questions' }
          ]
        }
      },






      {
        path: 'evaluationinternal',
        component: EvaluationinternalComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_assignment_evaluation_internal',
          urls: [
            { title: 'menu_processes', url: '/prochome' },
            { title: 'menu_assignment_evaluation_internal'}
          ]
        }
      },
      {
        path: 'reporting',
        component: ReportingComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_reporting',
          urls: [
            { title: 'menu_processes', url: '/prochome' },
            { title: 'menu_reporting'}
          ]
        }
      },
      {
        path: 'reporting-personal',
        component: ReportingPersonalComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_reporting_personal',
          urls: [
            { title: 'menu_processes', url: '/prochome' },
            { title: 'menu_reporting_personal'}
          ]
        }
      },
      {
        path: 'repocreator',
        component: RepocreatorComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_permiso',
          urls: [
            { title: 'menu_processes', url: '/prochome' },
            { title: 'menu_permiso'}
          ]
        }
      },
      {
        path: 'changeworker',
        component: ProapprovechangeemployeesComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_change_worker',
          urls: [
            { title: 'menu_processes', url: '/prochome' },
            { title: 'menu_change_worker'}
          ]
        }
      },
      {
        path: 'regtoolsequipment',
        component: RegtoolsequipmentComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_list_of_tools_and_equipment',
          urls: [
            { title: 'menu_processes', url: '/prochome' },
            { title: 'menu_list_of_tools_and_equipment'}
          ]
        }
      },
      {
        path: 'proadmissionapp',
        component: ProadmissionappComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_soingreso',
          urls: [
            { title: 'menu_processes', url: '/prochome' },
            { title: 'menu_soingreso'}
          ]
        }
      },
      {
        path: 'proadmissemergapp',
        component: ProadmissemergappComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_soingreso_emergencia',
          urls: [
            { title: 'menu_processes', url: '/prochome' },
            { title: 'menu_soingreso_emergencia'}
          ]
        }
      }
      //end
    ]
  }
];
