import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../services/authentication/login.service';
import { ApiService } from '../../services/main/api.service';
import { BaseCrudComponent } from '../base-crud/base-crud.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Storage } from '@ionic/storage';

import * as moment from 'moment-timezone';
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { isNumeric } from 'rxjs/util/isNumeric';

const API_CONSULINFOALL = environment.API_CONSULINFOALL;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent extends BaseCrudComponent {
  date_actually = new Date();
  typeUser = 0;
  translations:any;
  available_languages:any;
  currlang:any;
  version:any;
  items:any;
  datenow;
  permissions = [];
  datearrays = [];
  new_comunicates = [];
  userinfo;
  constructor(
    private spinner: NgxSpinnerService,
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    public auth: LoginService,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);


  }

  ngOnInit() {
    // this.spinner.show();
    this.items = [
      {title: 'menu_forms', url: '/formshome', icon: 'icon icon-note', perm: 'SI_ADMREGISTRO'},
      {title: 'menu_processes', url: '/prochome', icon: 'icon icon-chemistry', perm: 'SI_ADMSOLICITUD'},
      {title: 'menu_operacional', url: '/cruds', icon: 'icon icon-globe', perm: 'SI_ADMOPERACIONAL'},
      {title: 'menu_crud', url: '/cruds', icon: 'icon icon-globe', perm: 'SI_ADMCONSULTA'},
      {title: 'menu_general', url: '/settings', icon: 'icon icon-settings', perm: 'sett'}
    ];
    this.read_url = API_CONSULINFOALL;
    this.translate.getTranslation(this.translate.currentLang)
      .subscribe((translations) => {
        this.translations = translations;
        // // this.rows = [];
        // super.ngOnInit();
        this.getList();
      });




    this.search_fields = ['Title', 'SubTitle', 'Description'];

    this.c_obj['Info'] = [];

    // this.reload.subscribe(val => {
    //   if( val ){
    //     super.clearData();
    //     this.getList();
    //   }
    // });

    moment.locale('es');
    this.auth.getPermissions().then(x => {
      this.permissions = x;
      this.permissions.push('sett');
    });
    this.storage.get('userinfo').then(x => {
      this.userinfo = x;
      // console.log(x)
      if ( this.userinfo.ProfileID === undefined) {
        this.typeUser = 0;
      } else if ( this.userinfo.ProfileID === 1) {
        this.typeUser = 1;
      } else if (this.userinfo.ProfileID === 2) {
        this.typeUser = 2;
      }
      // this.getList();
    });

  }

  hasPermission(str:string){
    if( this.permissions.indexOf(str) > -1 ){
      return true;
    }
    else{
      return false;
    }
  }


  public getList(){
    var qst = this.read_url;

    this.loadingList = true;
    Swal.fire(
      {
        title: this.translations['loading'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;
      this.rows = [];
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.rowsTemp = rs;

          this.rowsTemp.forEach(element => {
            var day = moment(element.CreationTime, "YYYYMMDD");
            var days = moment(element.CreationTime).format('MMMM Do YYYY, h:mm:ss a');
            var xs = moment(day).startOf('day').fromNow();
            element.dateago = xs;
            element.dateformat = days;
          });

          this.rowsTemp.forEach(elements => {
            if(elements.Status == 'A'){
              this.rows.push(elements);
            }
          });
          let date_comparation = this.formatOnlyDate(this.date_actually);
          // console.log(date_comparation)
          this.rows.sort(function (a, b){ return (b.InfoID - a.InfoID) })
          this.rows.forEach(element => {
            // console.log(element);
            if (element.DateEnd.split('T')[0] > date_comparation) {
              if ((this.typeUser == 0) && (element.NameType == "Globales" || element.NameType == "Skretting")) {
                this.new_comunicates.push(element);
              } else if ((this.typeUser == 1) && (element.NameType == "Globales" || element.NameType == "Contratista")) {
                this.new_comunicates.push(element);
              } else if ((this.typeUser == 2) && (element.NameType == "Globales" || element.NameType == "Trabajador")) {
                this.new_comunicates.push(element);
              }
            }
          });
          // console.log(this.new_comunicates)
          // console.log(this.rows);
          // this.spinner.hide();
          Swal.close();
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
        }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }

    });

  }

  formatOnlyDate(date: Date) {
    return date.getFullYear().toString() + '-' + (date.getMonth() <= 9 ? `0${(date.getMonth() + 1).toString()}`: (date.getMonth() + 1).toString()) + '-' + (date.getDate() <= 9 ? `0${date.getDate().toString()}`: date.getDate().toString());
  }

  formatDate(date: Date) {
    return date.getFullYear().toString() + '-' + (date.getMonth() <= 9 ? `0${(date.getMonth() + 1).toString()}`: (date.getMonth() + 1).toString())
      + '-' + (date.getDate() <= 9 ? `0${date.getDate().toString()}`: date.getDate().toString()) + ' ' +
      (date.getHours() <= 9 ? `0${date.getHours().toString()}`: date.getHours().toString()) + ':' +
      (date.getMinutes() <= 9 ? `0${date.getMinutes().toString()}`: date.getMinutes().toString()) + ':' +
      (date.getSeconds() <= 9 ? `0${date.getSeconds().toString()}`: date.getSeconds().toString());
  }

}
