import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from '../global';
import { Router } from '@angular/router';
import { Observable, from, of, BehaviorSubject } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { OfflineContainer } from '../offlinecontainer';
import { environment } from '../../../environments/environment';
import {Response} from "../../interfaces/Response";

const API_GENERAL_URL = environment.Global.url;

const AQ_BASE_URL = 'https://aquasim.skretting.com/';
const IC2_BASE_URL = 'http://13.90.229.9/api/';
//max time for api requests
const MAX_TIME = 180000;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
	url:any;
	public offline = new BehaviorSubject(false);

  	constructor(
  		private _router: Router,
  		private http: HttpClient,
  		public storage: Storage
  	) {
  		//this.url = IC2_BASE_URL;
  		// this.url = Global.url;
		  this.url = API_GENERAL_URL;
  	}

  	public getCall(qst) {
		//console.log('get call',qst);
		////debugger;
		var resp = {success:false, msg:'', val:[]};
		return from(this.storage.get('token')).pipe(
				mergeMap(token => {
					// console.log(token)
					const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`});
					//console.log("url completa",`${this.url}`+ qst)
	    			return this.http.get(`${this.url}`+ qst, { headers })
	    				.pipe(
					  		mergeMap(data => {
		        				let mydata:any = {STATUS: '', OBJETO: [], MENSAJE: ''};
					      		mydata = data;

					      		if( mydata.STATUS == 'success' ){
					      			//return of(mydata.OBJETO);
					      			resp.success = true;
					      		}

					      		resp.msg = mydata.MENSAJE;
					      		resp.val = mydata.OBJETO;

					      		return of(resp);
		        			}),
		        			timeout(MAX_TIME),
		        			catchError(err => {
		        				resp['msg'] = 'system error';
					            return of(resp);
					        })
					  );
				})
			);
	}

	public postCall(qst, obj) {
		//console.log('cool post call');
		var resp = {success:false, msg:'', val:[]};
		return from(this.storage.get('token')).pipe(
				mergeMap(token => {
					const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`});
					//console.log('before post',"header:",headers,"qst:",qst,"obj:",obj);
	    			return this.http.post(`${this.url}`+qst, obj, { headers })
	    				.pipe(
					  		mergeMap(data => {
		        				let mydata:any = {STATUS: '', OBJETO: [], MENSAJE: ''};
					      		mydata = data;

					      		if( mydata.STATUS == 'success' ){
					      			//return of(mydata.OBJETO);
					      			resp.success = true;
					      		}

					      		resp.msg = mydata.MENSAJE;
					      		resp.val = mydata.OBJETO;

					      		return of(resp);
		        			}),
		        			timeout(MAX_TIME),
		        			catchError(err => {
		        				resp['msg'] = 'system error';
					            return of(resp);
					        })
					  );
				})
			);
	}

	public persistData(key:string, val:any){
		return this.storage.set(key, val);
	}

	public getPeristedData(key:string){
		return this.storage.get(key);
	}

	public getBlobCall(qst) {
		return from(this.storage.get('token')).pipe(
				mergeMap(token => {
					const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}`});
	    			return this.http.get(`${this.url}`+ qst, { headers, responseType: 'blob' })
	    				.pipe(
					  		mergeMap(data => {
					  			//console.log(data);
					      		return of(data);
					      		//return of(true);
		        			}),
		        			timeout(MAX_TIME),
		        			catchError(err => {
					            return of(false);
					        })
					  );
				})
			);
	}

	public postBlobCall(qst, to) {
		return from(this.storage.get('token')).pipe(
				mergeMap(token => {
					const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}`});
	    			return this.http.post(`${this.url}`+ qst, to, { headers, responseType: 'blob' })
	    				.pipe(
					  		mergeMap(data => {
					  			//console.log(data);
					      		return of(data);
					      		//return of(true);
		        			}),
		        			timeout(MAX_TIME),
		        			catchError(err => {
					            return of(false);
					        })
					  );
				})
			);
	}

	public saveOfflineData(key:string, obj:any){
		let tmp = new OfflineContainer();
		tmp.setData(obj);
		return this.persistData(key, tmp);
	}

	public getOfflineData(key:string){
		return this.getPeristedData(key).then(x => {
			if( (x == undefined) || (x == null) ){
				return null;
			}
			else{
				let tmp = new OfflineContainer();
				tmp.data = x.data;
				tmp.last_update = x.last_update;
				return tmp;
			}

		});
	}

	public isReachable() {
		/**
		   * Note: fetch() still "succeeds" for 404s on subdirectories,
		   * which is ok when only testing for domain reachability.
		   *
		   * Example:
		   *   https://google.com/noexist does not throw
		   *   https://noexist.com/noexist does throw
		*/
		//var url = 'https://google.com';
		var url = 'https://ha.skretting360.com/';
		return fetch(url, { method: 'HEAD', mode: 'no-cors' })
		    .then(function(resp) {
		      return resp && (resp.ok || resp.type === 'opaque');
		    })
		    .catch(function(err) {
		      console.warn('[conn test failure]:', err);
		    });
	}

	public downloadFile(docFile: any): Observable <Blob>
	{
		 return this.http.get(this.url+"CompanyDocument/GetFile/" + docFile, {responseType: 'blob'});

	}

	public downloadFileSub(docFile: any): Observable <Blob>
	{
		 return this.http.get(this.url+"SubCompanyDocument/GetFile/" + docFile, {responseType: 'blob'});

	}

	public downloadFileEmp(docFile: any, DocFileId:AnalyserNode): Observable <Blob>
	{
		 return this.http.get(this.url+"EmployeeDocument/GetFile/" + docFile + "/" + DocFileId, {responseType: 'blob'});

	}

	public downloadFileAny(docFile: any, qst): Observable <Blob>
	{
		return this.http.get(this.url + qst + docFile, {responseType: 'blob'});

	}

	public downloadFileQR(qst): Observable <Blob>
	{
		return this.http.get(this.url + qst , {responseType: 'blob'});

	}

	public downloadFiles(qst): Observable <Blob>
	{
		return this.http.get(this.url + qst , {responseType: 'blob'});

	}

	AddFileDetails(data: FormData): Observable < string >
	{
			  let headers = new HttpHeaders();
			  headers.append('Content-Type', 'application/json');
			  const httpOptions = {              headers: headers          };
			   return this.http.post < string > (this.url + 'CompanyDocument/UploadFile/', data, httpOptions);
	}
	AddFiles(url2,data: FormData): Observable < string >
	{
			  let headers = new HttpHeaders();
			  headers.append('Content-Type', 'application/json');
			  const httpOptions = {              headers: headers          };
			   return this.http.post < string > (this.url + url2, data, httpOptions);
	}

	AddFileSubCompany(data: FormData): Observable < string >
	{
			  let headers = new HttpHeaders();
			  headers.append('Content-Type', 'application/json');
			  const httpOptions = {              headers: headers          };
			   return this.http.post < string > (this.url + 'SubCompanyDocument/UploadFile/', data, httpOptions);
	 }

	 AddFileEmployee(data: FormData): Observable < string >
	{
			  let headers = new HttpHeaders();
			  headers.append('Content-Type', 'application/json');
			  const httpOptions = {              headers: headers          };
			   return this.http.post < string > (this.url + 'EmployeeDocument/UploadFile/', data, httpOptions);
	 }

	AddFileWorkerAuth(data: FormData): Observable < string >
	{
			let headers = new HttpHeaders();
			headers.append('Content-Type', 'application/json');
			const httpOptions = {              headers: headers          };
			return this.http.post < string > (this.url + 'WorkAuth/UploadFile', data, httpOptions);
	}

  public post(qst, obj) {
    let resp: Response = {
      val:'',
      success:'',
      Status: false,
      Object: null,
      Message: '',
      Code: ''
    };
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${this.url}` + qst, obj, { headers })
      .pipe(
        mergeMap(data => {
          let mydata: any = {STATUS: '', OBJETO: [], MENSAJE: ''};
          mydata = data;
          if (mydata.STATUS == 'success') {
            resp.Status = true;
          }
          resp.Message = mydata.MENSAJE;
          resp.Object = mydata.OBJETO;
          resp.Code = mydata.STATUS;
          return of(resp);
        }),
        timeout(MAX_TIME),
        catchError(err => {
            resp.Message = 'system error, ' + err;
            return of(resp);
          }
        )
      );
  }

  public get(qst) {
    let resp: Response = {
      val:'',
      success:'',
      Status: false,
      Object: null,
      Message: '',
      Code: ''
    };
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(`${this.url}` + qst, { headers })
      .pipe(
        mergeMap(data => {
          let mydata: any = null;
          mydata = data;
          if (mydata.Status == 'success') {
            resp.Status = true;
          }
          resp.Message = mydata.Message;
          resp.Object = mydata.Object;
          resp.Code = mydata.Code;
          return of(resp);
        }),
        timeout(MAX_TIME),
        catchError(err => {
            resp.Message = 'system error, ' + err;
            return of(resp);
          }
        )
      );
  }
}
