import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

  const API_CONSEVALUACION = environment.Global.API_CONSEVALUACION; 
  const API_REGEVALUACION = environment.Global.API_REGEVALUACION;
  const API_UPDATEEVALUACION = environment.Global.API_UPDATEEVALUACION;
  const API_DESACTEVALUACION = environment.Global.API_DESACTEVALUACION;
  const API_ACTIVEEVALUACION = environment.Global.API_ACTIVEEVALUACION;
  const API_CONSULTAJOBTYPE = environment.API_CONSULTAJOBTYPE;
  const API_CONSPREGUNTA = environment.Global.API_CONSPREGUNTA;
  const API_GET_QUESTIONSBYGROUP = environment.Global.API_GET_QUESTIONSBYGROUP;

@Component({
  selector: 'app-paramevaluacion',
  templateUrl: './paramevaluacion.component.html',
  styleUrls: ['./paramevaluacion.component.css']
})
export class ParamevaluacionComponent extends BaseCrudComponent {

  id:any;
  listParameter:any;
  curr_parameter:any = [];
  loadingRequired = false;
  listAgr:any;
  translations:any;
  userinfo:any;
  listQuestion:any;
  curr_question:any;
  lista = [];
  listaTrabajos = [];
  jobs:any = [];
  contadorgeneral = 0;

  constructor(
    srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
  ) { 
    super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        this.read_url = API_CONSEVALUACION;
        this.create_url = API_REGEVALUACION;
        this.update_url = API_UPDATEEVALUACION;
        this.delete_url = API_DESACTEVALUACION;
        this.active_url = API_ACTIVEEVALUACION;


        this.search_fields = ['ParameEvaID', 'Nombre', 'NombreVideo'];

        //this.c_obj['ParamEvaluacion'] = [];

        this.c_obj['ParameterEvaluation'] = [];
        this.curr_parameter = 0;

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
  }

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
          this.translations = translations;
          super.ngOnInit();
          this.getList();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    this.storage.get('userinfo').then(x => {
        this.userinfo = x;
    });
  }

  public getList(){
    ////debugger;
    var qst = this.read_url;
    this.loadingList = true;
    Swal.fire(
      {
        title: this.translations['loading'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    this.srv.getCall(qst).subscribe(x => {
      ////debugger;
      this.loadingList = false;
      
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          this.page = 1;
            if( Array.isArray(this.rows) ){
              this.pageTotal = this.rows.length;
            }
            this.filterAll();
            Swal.close()
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
          }
    });
  }

  async openCreateModal(content:any){
    this.c_obj['ParameterEvaluation'] = [];
    this.c_obj['NumeroPregunta'] = 10;
    this.c_obj['durationtime'] = 15;
    this.curr_parameter = 0;
    this.modalService.open(content, { backdrop: 'static', size: 'lg' });
    await this.getTypeParameterData().toPromise().then(x => {
      if(x != false){
        this.listParameter = x;
      }
    });
    // console.log(this.listParameter)
        this.jobs = [];
    // await this.getQuestions();
    await this.getTrabajo();
  }
  
  public createItem(content:any, form:any){
    this.contadorgeneral = 0;
    this.isCreating = true;
    var validated = true;
    if( form == undefined ){
      form = {};
    }
    validated = this.validateCreateInfo(form);
    if( !validated ){
      this.isCreating = false;
        Swal.fire(this.translations['msg_all_required'], '', 'error');
        return;
    }
    // console.log(this.curr_parameter)
    if (this.curr_parameter.length == 0 || this.curr_parameter == 0) {
      this.isCreating = false;
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }

    if (this.contadorgeneral < this.c_obj['NumeroPregunta']) {
      this.isCreating = false;
      return Swal.fire(this.translations.minusquestions,this.translations.description_minusquestions,'error');
    }

    var to:any;

    form.CreatorUser = this.userinfo['logon'];
    form.Status ="A";
    to = form;

    var qst = this.create_url;
    // console.log(to);
    this.srv.postCall(qst, to).subscribe(x => {
        this.isCreating = false;
        if(x.success){
          this.c_obj = {};
          this.c_obj['ParameterEvaluation'] = [];
          this.curr_parameter = 0;
          this.getList();
          this.closeModal(content);
          Swal.fire(this.translations['MENSAJE'], x.msg, 'success');
        }
        else{
            Swal.fire(x.msg, '', 'error');
        }
    });
  }

  validateCreateInfo(fields:any){
    let mygroup:any = {};
    mygroup = new FormGroup({
        'Nombre': new FormControl(fields.Nombre, [Validators.required]),
        'NombreVideo': new FormControl(fields.NombreVideo, []),
        'UrlVIdeo': new FormControl(fields.UrlVIdeo, []),
        'NumeroPregunta': new FormControl(fields.NumeroPregunta, []),
        'PorcentajeCalificacion': new FormControl(fields.PorcentajeCalificacion, []),
        'PorcentajeCalificacionMinima': new FormControl(fields.PorcentajeCalificacionMinima, []),
        'durationtime': new FormControl(fields.durationtime, []),
        'ValidateTime': new FormControl(fields.ValidateTime, [])
    });

    return mygroup.valid;
  }

  async openUpdateModal(content:any, obj:any){
    this.curr_parameter = 0;
    this.contadorgeneral = 0;
    let newobj = JSON.parse(JSON.stringify(obj));
    this.u_obj = newobj;
    // console.log(obj)
    this.modalService.open(content, { backdrop: 'static', size: 'lg' });
    await this.getTypeParameterData().toPromise().then(x => {
        this.loadingRequired = false;
        if(x != false){
            this.listParameter = x;
        }
    });
    // await this.getQuestions();
    await this.getTrabajo();
    this.jobs = [];
    this.listaTrabajos.forEach(trabajo => {
      this.u_obj['ParamEvalByJob'].forEach(job => {
        if (job.JobTypeID == trabajo.JobTypeID) {
          this.jobs.push(job.JobTypeID)
        }
      });
    })
    this.curr_parameter = [];
    this.listParameter.forEach(parameter => {
      this.u_obj['ParameterEvaluation'].forEach(parametereval => {
        if (parameter.ID == parametereval.ID) {
          this.curr_parameter.push(parametereval.ID)
          // console.log(parametereval)
        }
      });
    });
    this.verifyQuestions();
    // console.log(this.curr_parameter)
  }

  public updateItem(content:any, form:any){
    this.isUpdating = true;
    var qst = this.update_url;

    if( form == undefined ){
      form = {};
    }

    var to:any;

    if( (form.Nombre == undefined) || (form.Nombre == '') ){
      this.isUpdating = false;
        Swal.fire('Ingrese todos los campos requeridos', 'msg', 'warning');
        return;
    }

    if (this.contadorgeneral < this.u_obj['NumeroPregunta']) {
      this.isUpdating = false;
      return Swal.fire(this.translations.minusquestions,this.translations.description_minusquestions,'error');
    }
    // console.log(this.contadorgeneral)
    to = {};
    form.LastModifierUserID = this.userinfo['logon'];
    to = form;
    // console.log(to)
    this.srv.postCall(qst, to).subscribe(x => {
      this.isUpdating = false;
      Swal.close();
      if (x.success) {
        this.reload.next(true);
        this.closeModal('update1');
        Swal.fire(this.translations.msg_all_required, '', 'error');
        Swal.fire(this.translations['MENSAJE'], x.msg, 'success');
        this.getList();
        
      } 
    }, err => {
      this.isUpdating = false;
      Swal.close();
      Swal.fire('Error');
    });

  }


  deleteItem(obj: any){
    var id:any;
    id = {};
    id.ParameEvaID = obj;
    id.Status=2;
    id.LastModifierUser = this.userinfo['userId'];
    var qst = this.delete_url;
    this.srv.postCall(qst, id).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if(x.success){
        this.reload.next(true);
        Swal.fire(this.translations['MENSAJE'], x.msg, 'success');
        this.getList();
    }
    else{
        Swal.fire(x.msg, '', 'error');
    }
  }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }


  Activar(obj: any){
      var id:any;
      id = {};
      id.ParameEvaID = obj;
      id.Status=1;
      id.LastModifierUser = this.userinfo['userId'];
      var qst = this.active_url;
      this.srv.postCall(qst, id).subscribe(x => {
        this.isCreating = false;
        Swal.close();
        if(x.success){
          this.reload.next(true);
          Swal.fire(this.translations['MENSAJE'], x.msg, 'success');
          this.getList();
      }
      else{
          Swal.fire(x.msg, '', 'error');
        }
      }, err => {
          Swal.close();
          Swal.fire('Error');
        });
  }

  toggleEditable(event, id) {
      if ( event.target.checked ) {
        this.Activar(id);
    }else {
      this.deleteItem(id);

    }
  }

  DeletedItem(obj: any){
    var id:any;
    id = {};
    id.ParameEvaID = obj;
    id.Status=3;
    id.LastModifierUser = this.userinfo['userId'];
    var qst = "Evaluation/UpdateStatus";
    this.srv.postCall(qst, id).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if(x.success){
        this.reload.next(true);
        Swal.fire(this.translations['MENSAJE'], x.msg, 'success');
        this.getList();
    }
    else{
        Swal.fire(x.msg, '', 'error');
    }
  }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }


  public getTypeParameterData(){
    var par_url = "SystemParameter/GetSystemParameterField";
    var to:any;
    to = {};
    to._field = "PARMTYPEQUESTION";
    par_url=par_url+"/"+"PARMTYPEQUESTION";
    return this.srv.getCall(par_url).pipe( 
      mergeMap(data => { 
          let mydata:any = {success: false, msg:'', val:[]};
          mydata = data;            
          if(mydata.success == true ){
            return of(mydata.val);
          }
          else{
            return of(false);
          }
        }),
        catchError(data => of(false))
      );
    }


  public async getTrabajo() {
    await this.srv.getCall(API_CONSULTAJOBTYPE).toPromise().then(x => {
      if(x.success){
        if(x.val != null){
          // console.log(x.val)
          this.listaTrabajos = x.val;
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          return;
        }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public setJobs(type:Number) {
    if(type == 1) {
      this.c_obj['ParamEvalByJob'] = [];
      this.jobs.forEach(job => {
        this.c_obj['ParamEvalByJob'].push(
          {
            JobTypeID: job
          }
        )
      });
    } else {
      this.u_obj['ParamEvalByJob'] = [];
      this.jobs.forEach(job => {
        this.u_obj['ParamEvalByJob'].push(
          {
            JobTypeID: job
          }
        )
      });
    }
  }

  public setGroups(type:Number) {
    if(type == 1) {
      this.c_obj['ParameterEvaluation'] = [];
      this.curr_parameter.forEach(job => {
        this.c_obj['ParameterEvaluation'].push(
          {
            ID: job
          }
        )
      });
    } else {
      this.u_obj['ParameterEvaluation'] = [];
      this.curr_parameter.forEach(job => {
        this.u_obj['ParameterEvaluation'].push(
          {
            ID: job
          }
        )
      });
    }
    this.verifyQuestions(); 
  }

  public async verifyQuestions() {
    let preguntas = [];
    let to = {
      groups : this.curr_parameter
    }
    let url = API_GET_QUESTIONSBYGROUP;
    await this.srv.postCall(url, to).toPromise().then(x => {
      if(x.success){
        if(x.val != null && x.val.length > 0){
          preguntas = x.val;
        }
      }
    });
    this.contadorgeneral  = 0;
    if(preguntas.length > 0){
      preguntas.forEach(pregunta => {
        this.contadorgeneral = this.contadorgeneral + pregunta.cantidad;
      }) 
    }      
  }


 
  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

}
