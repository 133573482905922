import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { isNumeric } from 'rxjs/util/isNumeric';
import { Newsubcomjob } from '../../../interfaces/newsubcomjob';
import {saveAs as importedSaveAs  } from "file-saver";

@Component({
  selector: 'app-consdochistoryemployees',
  templateUrl: './consdochistoryemployees.component.html',
  styleUrls: ['./consdochistoryemployees.component.css']
})
export class ConsdochistoryemployeesComponent extends BaseCrudComponent{
  id:any;
  userinfo:any;
  constructor(srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService) {
      super(srv, modalService, storage, router); 
      this.id = route.snapshot.params.id;

      this.read_url="EmployeeDocument/GetDocumentshistoryId";
      this.search_fields = ['NameDoc','NameSubcomapny','identification','EmployeeName','status_name','VerificateUser'];

      this.c_obj['EmployeeDocument'] = [];

      this.reload.subscribe(val => {
        if( val ){
            super.clearData();
            this.getList();
        }
      });

  }

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
          this.translations = translations;
          super.ngOnInit();
          this.getList();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    this.storage.get('userinfo').then(x => {
        this.userinfo = x;
    });
  }

  public getList()
  {
    var qst = this.read_url+"/"+this.id;
    console.log(qst)
    this.loadingList = true;

    var to:any;
    to = {};
    Swal.fire(
      {
        title: this.translations['loading'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    //this.srv.postCall(qst, to).subscribe(x => {
      this.srv.getCall(qst).subscribe(x=>{
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          // console.log("rs:",rs);
          
          this.page = 1;
          if( Array.isArray(this.rows) ){
             this.pageTotal = this.rows.length;
          }
          this.filterAll();
          Swal.close()
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
          }
     });
  }

  downloadDocFile(data) {
    //const DocFileName = data.EmployeeDocumentId;
    const DocFileName = data.EmployeeId;    
    const DocFileId = data.EmployeeDocumentId;

    const name=data.FileName;
    //var DocFile = DocFileName.slice(0, -5);
    this.srv.downloadFileEmp(DocFileName, DocFileId).subscribe((data) => {
       importedSaveAs(data,name )//DocFileName)
   });
  }


}
