import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { isNumeric } from 'rxjs/util/isNumeric';
import { Newsubcomjob } from '../../../interfaces/newsubcomjob';
import { saveAs as importedSaveAs } from "file-saver";

//const API_CONSDOCUMENEMP = environment.API_CONSDOCUMENEMP;
const API_CONSDOCUMENEMP = Global.API_CONSPARAMDOCUMENT_1;
const API_DELETE_DOCUMENT = Global.API_DELETE_DOCUMENT_SUBCOMPANY;

@Component({
  selector: 'app-regsubcontracdocu',
  templateUrl: './regsubcontracdocu.component.html',
  styleUrls: ['./regsubcontracdocu.component.css']
})
export class RegsubcontracdocuComponent extends BaseCrudComponent {
  id: any;
  loadingRequired = false;
  listAgr: any;
  translations: any;
  userinfo: any;
  RegSubJobCo: FormGroup;
  jobtypeID: any;
  seleccionados = [];
  namefile: any;
  cargados = false;
  archivos = [];
  mensaje = "";
  size_max = 3145728;//3mb
  cargavacia = false;
  date_doc: any;
  isuploading = false;
  public rowEmployee: any;
  alertMsj: any
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
    this.id = route.snapshot.params.id;


    this.read_url = API_CONSDOCUMENEMP;
    this.delete_url = API_DELETE_DOCUMENT;
    this.date_doc = new Date();

    // this.create_url = API_REGISTERJOBSUBCOM;
    // this.update_url = API_UPDATESUBJOBCOM;
    // this.active_url = API_ACTIVESUBJOBCOM;
    // this.urlJob = API_CONSULTAJOBTYPEACTIVE;

    this.search_fields = ['DocumentName'];

    this.c_obj['AnalysisFeature'] = [];

    this.reload.subscribe(val => {
      if (val) {
        super.clearData();
        this.getList();
      }
    });
  }

  async ngOnInit() {
    await this.translate.getTranslation(this.translate.currentLang)
      .toPromise().then((translations) => {
        this.translations = translations;
        super.ngOnInit();
        this.getList();
      });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    await this.storage.get('userinfo').then(x => {
      this.userinfo = x;
    });
  }

  public getList() {
    //debugger;

    var qst = this.read_url;
    this.loadingList = true;

    var to: any;
    to = {};
    to._IdComp = this.id;
    to._Id = 26;

    this.GetCompanyData2();
    this.GetDocumentUpload();
    Swal.fire(
      {
        title: this.translations['loading'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    this.srv.postCall(qst, to).subscribe(x => {
      this.loadingList = false;
      //debugger;
      if (x.success) {
        if (x.val != null) {
          var rs = x.val;
          this.rows = rs;
          Swal.close()
        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  handleInputChange(e) {//, obj:any) {
    //debugger;
    this.archivos = [];
    //console.log("e:",e);
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    //var pattern = /image-*/;
    //var reader = new FileReader();

    const archivocapture = e.target.files[0];
    this.archivos.push(archivocapture);
    this.namefile = file.name;
    //console.log("archivo capturado:" ,archivocapture);
    //console.log("archivo array:" ,this.archivos);
  }

  async onExpSubmit(row: any) {
    this.isuploading = true;
    if (this.archivos.length == 0) {
      this.isuploading = false;
      Swal.fire(this.translations.document, this.translations.document_error_minqty, 'error');
      return;
    }
    var size_document = this.archivos[0].size;
    if (size_document > this.size_max) {
      this.isuploading = false;
      Swal.fire(this.translations.document, this.translations.pdi_img_toobig, 'error');
      this.archivos = [];
      return;
    }
    if (row.date_doc == undefined) {
      this.isuploading = false;
      Swal.fire(this.translations.enter_date, '', 'error');
      return;
    }
    let formData = new FormData();
    formData.append('FileUpload', this.archivos[0]);
    formData.append('CompanyID', this.userinfo.selectedCompanyId);
    formData.append('SubCompanyId', this.id);
    formData.append('CreatorUser', this.userinfo.logon);
    await this.srv.AddFileSubCompany(formData).toPromise().then(async result => {
      if (result == "OK") {
        // this.reload.next(true);
        await this.uploadDataBase(row);
      }
      else {
        Swal.fire('¡Documento!', result, 'success');
        this.isuploading = false;
        this.archivos = [];
        this.getList();
      }
    });
  }

  public async uploadDataBase(row: any) {
    var par_url = "SubCompanyDocument/Update";
    var to: any;
    to = {};
    to.ParamDocumentID = row.ParamDocumentID;
    to.SubCompanyID = this.id;
    to.LastModifierUser = this.userinfo.logon;
    to.CreatorUser = this.userinfo.logon;
    to.FileName = this.namefile;
    to.DateDocument = row.date_doc;
    await this.srv.postCall(par_url, to).toPromise().then(x => {
      if (x.success) {
        this.reload.next(true);
        this.closeModal('update5');
        Swal.fire(this.translations.msg_update, x.msg, 'success');
        this.archivos = [];
        this.getList();
        this.isuploading = false;
      } else {
        this.isuploading = false;
        Swal.fire(x.msg, '', 'error');
      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }

  public GetDocumentUpload() {
    var par_url = "SubCompanyDocument/GetDocumentsId/" + this.id;
    this.loadingList = true;
    var to: any;
    to = {};
    to.SubCompanyId = this.id;
    this.srv.postCall(par_url, to).subscribe(x => {
      this.loadingList = false;
      if (x.success) {
        if (x.val.length != 0) {
          var rs = x.val;
          this.rowdocumentupload = rs;
          if ((rs[0].status_doc == "A" || rs[0].status_doc == "R")) {
            this.cargados = true;
          }
          else { this.cargados = false; }
        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rowdocumentupload = [];
          if (this.u_obj["Status_doc"] == null) {
            this.cargavacia = true;
          }
          else { this.cargavacia = false; }

          this.mensaje = "";
          return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }
  public GetCompanyData2() {
    var par_url = "SubCompanyDocument/SubCompanySelect/" + this.id;
    var to: any;
    to = {};
    to.SubCompanyId = this.id;
    //console.log("api:",par_url,"CompanyId:",to);

    //debugger;
    this.srv.postCall(par_url, to).subscribe(x => {
      this.loadingList = false;

      if (x.success) {
        if (x.val.length != 0) {
          var rs = x.val;
          this.u_obj = rs[0];
        } else {
          this.u_obj = [];
          return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  downloadDocFile(data) {
    const DocFileName = data.SubCompDocumentId;
    const name = data.FileName;
    //var DocFile = DocFileName.slice(0, -5);
    this.srv.downloadFileSub(DocFileName).subscribe((data) => {
      importedSaveAs(data, name)//DocFileName)
    });
  }
  public showAlertDocuments(content: any) {

    // Consult Api
    var ID = 144 // Set ID Message, #SystemParameter
    var to: any;
    to = {};
    var par_url = "ParameterDocument/getAlertMessage" + '/' + ID;
    this.isUpdating = true;
    this.srv.getCall(par_url).subscribe(x => {
      this.isUpdating = false;
      Swal.close();
      if (x.success) {
        if (x.val != null) {
          var msj: any
          msj = x.val
          this.alertMsj = msj.value

          // Open Modal
          this.modalService.open(content, { backdrop: 'static', keyboard: false, size: 'lg', centered: true });
        }

      }
      else {
        Swal.fire(x.msg, '', 'error');
      }
    });

  }
  public UpdateStatus() {

    this.closeModal('alert'); // Close Modal Alert

    var par_url = "SubCompanyDocument/UpdateStatus";
    var to: any;
    to = {};
    to.SubCompanyID = this.id;
    to.Status = "P";
    to.StatusId = 38;
    to.FileName = this.userinfo['name'];

    let trans = this.translations;
    Swal.fire({
      title: trans.sampapp_refmethodswarn,
      text: '',
      icon: 'warning',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      cancelButtonText: trans.cancel,
      confirmButtonText: trans.ok,
      preConfirm: () => {
        this.isUpdating = true;
        this.saveVerificationAPI(to);
      }
    }).then((result) => {
    });
  }

  saveVerificationAPI(to: any) {
    var par_url = "SubCompanyDocument/UpdateStatus";
    this.isUpdating = true;
    this.loadingList = true;

    this.srv.postCall(par_url, to).subscribe(x => {
      this.isUpdating = false;
      this.loadingList = false;
      Swal.close();
      if (x.success) {
        this.reload.next(true);
        this.closeModal('update5');
        Swal.fire(this.translations.msg_update, x.msg, 'success');
        this.getList();
      }
      else {
        Swal.fire(x.msg, '', 'error');
      }
    });
  }

  openUpdateModal(content: any, obj1: any) {
    let obj = JSON.parse(JSON.stringify(obj1));
    this.u_objSub = obj
    //console.log("objeto de llegada=>", this.u_objSub, "ubj=>", this.u_obj);
    this.getEmployeeList();
    //this.curr_parameter= [];
    //this.curr_parameterl=[];

    this.modalService.open(content, { backdrop: 'static', keyboard: false, size: 'lg', centered: true });


  }

  public getEmployeeList() {
    var qst = Global.API_ALLEMPLOYEESUBCOMPANYID + "/" + this.id;
    this.loadingList = true;

    Swal.fire(
      {
        title: this.translations['loading'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;

      if (x.success) {
        if (x.val != null) {
          this.rawdata = x.val;
          var rs = x.val;
          this.rowEmployee = rs;
          this.page = 1;
          if (Array.isArray(this.rowEmployee)) {
            this.pageTotal = this.rowEmployee.length;
          }
          this.filterAll();
          Swal.close()

        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rowEmployee = [];
          //return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  toggleEditable(event, id) {
    if (event.target.checked) {
      this.Activar(id, 1);
    } else {
      this.Activar(id, 2);
    }
  }
  Activar(obj: any, valor: any) {
    var to: any;
    to = {};
    to.EmployeeId = obj;
    to.Status = valor;
    var qst = this.active_url;
    this.srv.postCall(qst, to).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if (x.success) {
        this.reload.next(true);
        if (valor == "1") {
          Swal.fire('¡Activado!', x.msg, 'success');
        }
        else {
          Swal.fire('¡Desactivado!', x.msg, 'success');
        }

        this.getList();
      }
      else {
        Swal.fire(x.msg, '', 'error');
      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }

  public deleteFile(position, modal, docName, SubCompDocumentId) {
    //this.position = position
    //this.docName = docName
    //this.CompDocumentId = CompDocumentId
    //this.modalService.open(modal, { backdrop: 'static', keyboard: false,  size: 'lg', centered:true });

    console.log('row1 =>', position);
    console.log("CompDocumentId=>", SubCompDocumentId);
    let trans = this.translations;
    Swal.fire({
      title: '¿Está seguro que desea eliminar el archivo ' + docName + ' ?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      cancelButtonText: trans.cancel,
      confirmButtonText: trans.ok,
      allowOutsideClick: false,
      preConfirm: () => {
        this.confirmDeleteFile(SubCompDocumentId);
      }
    }).then((result) => {
    });
  }
  confirmDeleteFile(SubCompDocumentId) {

    var qst = this.delete_url + "/" + SubCompDocumentId;
    // console.log("qst=>", qst);
    var to: any;

    to = {};
    to.CompDocumentId = SubCompDocumentId
    to.DeleteUser = this.userinfo.logon;


    this.isUpdating = true;
    this.srv.postCall(qst, to).subscribe(x => {
      console.log(x);
      this.isUpdating = false;
      if (x.success) {
        this.getList();
      }
      else {
        Swal.fire(x.msg, '', 'error');
      }
    });
  }

  validarStatus(row: any): boolean {
    let dateEnd = new Date(row.DateEnd);
    // Documentos que estén en revisión, validados, activos o renovar y validación de fecha de caducidad
    const validStatusNames = ['ACTIVO', 'REVISIÓN', 'RENOVAR', 'VALIDADO'];
    const isRenewalStatusValid = this.isRenewalStatus(dateEnd);
  
    // Condición para documentos en revisión, validados, activos o renovar, y validación de fecha de caducidad
    if (validStatusNames.includes(row.status_name) && isRenewalStatusValid) {
      // Excluir documentos tipo 107 que estén validados
      if (row.ParamDocumentID !== 107 || row.status_name !== 'VALIDADO') {
        row.status_name = "RENOVAR";
        return true; // Habilitar botón de eliminar para poder renovar
      }
    } else if (row.status_name === 'RECHAZADO') {
      return true; // Habilitar botón de eliminar para poder renovar
    }
  
    return false; // Inhabilitar botón de eliminar
  }  
  
  isRenewalStatus(expirationDate: Date): boolean {
    // Obtener la fecha actual
    const currentDate = new Date();

    // Calcular la fecha límite para la renovación (10 días antes de la fecha de caducidad)
    const renewalLimit = new Date(expirationDate);
    renewalLimit.setDate(renewalLimit.getDate() - 10);

    // Comparar la fecha actual con la fecha de caducidad y la fecha límite para la renovación
    if (currentDate > expirationDate || currentDate >= renewalLimit) {
      return true; // Documento vencido o Renovación necesaria (faltan menos de 10 días para la caducidad)
    } else {
      return false; // Documento válido
    }
  }
}
