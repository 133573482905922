import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

const API_CONSUDOCU = Global.API_CONSPARAMDOCUMENT;
const API_REGISTERDOCU = Global.API_REGPARAMDOCUMEN;
const API_UPDATEDOCU = Global.API_UPDATEPARAMDOCUMEN;
const API_DELETEDOCU = Global.API_UPDATESTATUSPARAMDOCUMEN;
const API_ACTIVEDOCU = Global.API_UPDATESTATUSPARAMDOCUMEN;

@Component({
  selector: 'app-paramdocu',
  templateUrl: './paramdocu.component.html',
  styleUrls: ['./paramdocu.component.css']
})
export class ParamdocuComponent extends BaseCrudComponent {
  id:any;
  loadingRequired = false;
  listAgr:any;
  translations:any;
  userinfo:any;
  sugeridotemp:any;
  listTypeParameter:any;

  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        this.read_url = API_CONSUDOCU;
        this.create_url = API_REGISTERDOCU;
        this.update_url = API_UPDATEDOCU;
        this.delete_url = API_DELETEDOCU;
        this.active_url = API_ACTIVEDOCU;

        this.search_fields = ['DocumentName', 'DocumentDescription'];

        this.c_obj['ParameterDocument'] = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });

   }

  async ngOnInit() {
    await this.translate.getTranslation(this.translate.currentLang)
    .toPromise().then((translations) => {
          this.translations = translations;
          super.ngOnInit();
          this.getList();
    });
    this.loadingRequired = false;
    await this.translate.getTranslation(this.translate.currentLang)
    .toPromise().then((translations) => {
          this.translations = translations;
          super.ngOnInit();
          this.loadingRequired = true;
              this.getTypeParameterData().subscribe(j => {
                  //console.log(j);
                  if(j != false){
                      this.loadingRequired = false;
                      this.listTypeParameter = j;
                  }
              });
          this.getList();

    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    await this.storage.get('userinfo').then(x => {
        this.userinfo = x;
    });
  }

  public getList(){

    var qst = this.read_url;
    this.loadingList = true;


          var id:any;
          id = {};
          id.docuTypeID = this.id;
          Swal.fire(
            {
              title: this.translations['loading'],
              html: '',
              icon: 'info',
            }
          );
          Swal.showLoading();
          this.srv.postCall(qst, id).subscribe(x => {
                this.loadingList = false;
                // debugger;
                if(x.success){
                  if(x.val != null){
                    this.rawdata = x.val;
                    var rs = x.val;
                    this.rows = rs;

                    this.page = 1;
                    if( Array.isArray(this.rows) ){
                      this.pageTotal = this.rows.length;
                    }
                    this.filterAll();
                    Swal.close()
                  }else {
                    Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
                    this.rows = [];
                    return;
                      }
                }else {
                  Swal.fire(x.msg, '', 'error');
                  return;
                    }
            });
        }

        public createItem(content:any, form:any){
          // debugger;
          var validated = true;

          if( form == undefined ){
            form = {};
          }

          validated = this.validateCreateInfo(form);
          if( !validated ){
              Swal.fire(this.translations['msg_all_required'], '', 'error');
              return;
          }

          var to:any;
         form.CreatorUser = this.userinfo['logon'];
         form.LastModifierUser = this.userinfo['userId'];
         //form.docuTypeID = this.id;
         form.sugeridotemp = this.sugeridotemp;
         to = form;

          var qst = this.create_url;
          this.isCreating = true;

          this.srv.postCall(qst, to).subscribe(x => {
              this.isCreating = false;
              if(x.success){
                  this.getList();
                  this.closeModal(content);
                  Swal.fire('¡Creado!', x.msg, 'success');
              }//end success
              else{
                  //Swal.fire(this.translations[x.msg], '', 'error');
                  Swal.fire(x.msg, '', 'error');
              }
          });
        }


  validateCreateInfo(fields:any){
    let mygroup:any = {};
    mygroup = new FormGroup({
        'DocumentName': new FormControl(fields.DocumentName, [Validators.required]),
        'DocumentDescription': new FormControl(fields.DocumentDescription, []),
        'DiasVigencia': new FormControl(fields.DiasVigencia, [Validators.required])
    });

    return mygroup.valid;
  }

  changeupdate(event){
    // debugger;
    if ( event.target.checked ) {
     this.sugeridotemp = true;
    }else {
      this.sugeridotemp = false;
    }
    }

    public updateItem(content:any, form:any){
      var qst = this.update_url;
      this.isCreating = true;

      if( form == undefined ){
        form = {};
      }

      var to:any;

      if( (form.DocumentName == undefined) || (form.DetailName == '') ){
          Swal.fire('Ingrese todos los campos requeridos', 'msg', 'warning');
          return;
      }

      if(this.sugeridotemp == undefined){
        this.sugeridotemp = true;
      }

      to = {};
      to.DocumentName = form.DocumentName;
      to.DocumentDescription = form.DocumentDescription;
      to.DiasVigencia = form.DiasVigencia;
      to.docuTypeID = form.docuTypeID;
      to.LastModifierUser = this.userinfo['userId'];
      to.ParamDocumentID = form.ParamDocumentID;
      to.Sugerido = this.sugeridotemp;
      to.IsRecurrent = form.IsRecurrent;

      this.srv.postCall(qst, to).subscribe(x => {
        this.isCreating = false;
        Swal.close();
        if (x.success) {
          this.reload.next(true);
          this.closeModal('update2');
          Swal.fire('¡Actualizado!', x.msg, 'success');
          this.getList();
        }
     }, err => {
        Swal.close();
        Swal.fire('Error');
       });

    }

    toggleEditable(event, id) {
      // debugger;
      if ( event.target.checked ) {
         this.Activar(id);
     }else {
       this.deleteItem(id);

    }
    }

    Activar(obj: any){


      var id:any;
      id = {};
      id.ParamDocumentID = obj;
      id.LastModifierUser = this.userinfo['userId'];
      id.Status=1;
      id.DeleteUser = this.userinfo['userId'];
      var qst = this.active_url;

      this.srv.postCall(qst, id).subscribe(x => {
        this.isCreating = false;
              Swal.close();
              if(x.success){
                this.reload.next(true);
                Swal.fire('¡Activado!', x.msg, 'success');
                this.getList();
            }
            else{
                Swal.fire(x.msg, '', 'error');
            }
     }, err => {
        Swal.close();
        Swal.fire('Error');
       });
    }

    deleteItem(obj: any){

      var id:any;
      id = {};
      id.ParamDocumentID = obj;
      id.LastModifierUser = this.userinfo['userId'];
      id.Status=2;
      id.DeleteUser = this.userinfo['userId'];
      var qst = this.delete_url;

      this.srv.postCall(qst, id).subscribe(x => {
        this.isCreating = false;
        Swal.close();
        if(x.success){
          this.reload.next(true);
          Swal.fire('Desactivado!', x.msg, 'success');
          this.getList();
      }
      else{
          Swal.fire(x.msg, '', 'error');
      }
     }, err => {
        Swal.close();
        Swal.fire('Error');
       });
    }
    public getTypeParameterData(){
      var par_url = "SystemParameter/GetSystemParameterField";
      this.loadingList = true;

      par_url=par_url+"/"+"PARMDOCUMENT";
      // console.log("url:",par_url);
      //debugger;
      return this.srv.getCall(par_url).pipe(
        mergeMap(data => {
            let mydata:any = {success: false, msg:'', val:[]};
            mydata = data;
            // console.log("data:",data);
            // console.log("mydata:",mydata);

            if(mydata.success == true ){
              return of(mydata.val);
            }
            else{
              return of(false);
            }
          }),
          //timeout(MAX_TIME),
          catchError(data => of(false))
      );
    }

    public DeletedItem(id:any){
      //debugger;
      var to:any;
      to = {};
      to.ParamDocumentID = id;
      to.LastModifierUser =  this.userinfo['userId'];
      to.DeleteUser=this.userinfo['userId'];
      to.Status=3;
      var qst = this.active_url;

      // console.log("to::",to);
      this.srv.postCall(qst, to).subscribe(x => {
        this.isCreating = false;
        Swal.close();
        if(x.success){
          this.reload.next(true);
          Swal.fire(this.translations['MENSAJE'], x.msg, 'success');
          this.getList();
      }
      else{
          Swal.fire(x.msg, '', 'error');
      }
     }, err => {
        Swal.close();
        Swal.fire('Error');
       });

    }

}
