import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { isNumeric } from 'rxjs/util/isNumeric';

const API_CONSULTASUBCOMPANIE = environment.API_CONSULTASUBCOMPANIE;
const API_DESACTIVESUBCOM = environment.API_DESACTIVESUBCOM;
const API_ACTIVESUBCOM = environment.API_ACTIVESUBCOM;
const API_CREATESUBCOM = environment.API_CREATESUBCOM;
const API_CONSULTASUBCOMPANIECI = environment.API_CONSULTASUBCOMPANIECI;
const API_UPDATESUBCOMPANY = environment.API_UPDATESUBCOMPANY;
const API_SUBCOMPANIEBYCOMPIE = environment.API_SUBCOMPANIEBYCOMPIE;
const API_CONSULTACOMPANIES = environment.API_CONSULTACOMPANIES;
const API_CONSDOCUMENEMP = environment.API_CONSDOCUMENEMP;

@Component({
  selector: 'app-regsubcontrac',
  templateUrl: './regsubcontrac.component.html',
  styleUrls: ['./regsubcontrac.component.css']
})
export class RegsubcontracComponent extends BaseCrudComponent {
  id:any;
  loadingRequired = false;
  listAgr:any;
  translations:any;
  userinfo:any;
  jobtypeID:any;
  mostarselectemp = true;
  date;
  CompanyID;
  seleccionados=[];
  SubContratista = "SubContratista";
  curr_cur:any;
  listcomp:any;

  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        this.read_url = API_CONSULTASUBCOMPANIE;
        this.delete_url = API_DESACTIVESUBCOM;
        this.active_url = API_ACTIVESUBCOM;
        this.create_url = API_CREATESUBCOM;
        this.select_url = API_CONSULTASUBCOMPANIECI;
        this.update_url = API_UPDATESUBCOMPANY;
        this.read_url_byID = API_SUBCOMPANIEBYCOMPIE;
        this.urlemp = API_CONSULTACOMPANIES;
        this.documentemp_url = API_CONSDOCUMENEMP;

        this.search_fields = ['Name', 'Ruc', 'NameCompany'];

        this.c_obj['SubCompany'] = [];

        this.reload.subscribe(val => {
          if( val ){
            super.clearData();
          }
        });
   }

   ngOnInit() {
    this.date = new Date();
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
          this.translations = translations;
          super.ngOnInit();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';
    this.storage.get('userinfo').then(x => {
      this.userinfo = x;
      if( isNumeric( this.userinfo.logon) ) { 
        this.mostarselectemp = false;
        this.getList();
      } else {
        this.mostarselectemp = true;
      } 
    });
  }



  public getList() {
    ////debugger;
    var qst = this.read_url+"/"+this.userinfo['logon'];
    this.loadingList = true;
    // Swal.fire(
    //   {
    //     title: this.translations['loading'],
    //     html: '',
    //     icon: 'info',
    //   }
    // );
    // Swal.showLoading();
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          this.page = 1;
          if( Array.isArray(this.rows) ){
             this.pageTotal = this.rows.length;
          }
          this.filterAll();
          Swal.close()
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
        }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
  });
}

  public getListEmp(){
    var qst = "CompaniesConsulta/CompanyById";
    qst=qst+"/"+this.userinfo['selectedCompanyId'];
    this.srv.getCall(qst).subscribe(x => {
      // //debugger;
      this.loadingList = false;
      
      if(x.success){
          var rs = x.val;
          this.rowemp = rs;
      }else {
          Swal.fire(x.msg, '', 'success');
        return;
      }
    });
  }

  consultaSubEmp (form:any){
    if ((form.Ruc != undefined) || (form.Ruc != '')) {
      var qst = this.select_url;
      var id:any;
      id = {};
      id.Ruc = form.Ruc;
      this.srv.postCall(qst, id).subscribe(x => {
        this.isCreating = false;
        Swal.close();
        if (x.success) {
           Swal.fire({
            title: this.translations.ruc_exist,
            text: x.msg ,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#005c3c',
            confirmButtonText: this.translations.accept,
            allowOutsideClick: () => Swal.isLoading(),
          }).then((result) => {
              if (result.value) {
                this.getList();
                this.closeModal('create1');
              }
          })
        } else{
          return;
        }
     }, err => {
        Swal.close();
        Swal.fire('Error');
       });
     
      }
   }

   public createItem(content:any, form:any){
    ////debugger;
    var validated = true;
  
    if( form == undefined ){
      form = {};
    }
  
    validated = this.validateCreateInfo(form);
    if( !validated ){
        Swal.fire(this.translations['msg_all_required'], '', 'error');
        return;
    }
  
    var to:any;
    //to.CompanyID=this.userinfo.selectedCompanyId;
    form.CompanyID = this.userinfo.selectedCompanyId;//form.empselect.CompanyID;
    form.CreatorUser = this.userinfo['logon'];
    form.LastModifierUser = this.userinfo['userId'];
    to = form;
  
  
    var qst = this.create_url;
    this.isCreating = true;
  
    this.srv.postCall(qst, to).subscribe(x => {
        this.isCreating = false;
        if(x.success){
            this.getList();
            this.closeModal(content);
            Swal.fire(this.translations.msg_create, x.msg, 'success');
        }//end success
        else{
            Swal.fire(x.msg, '', 'error');
        }
    });
  }


   validateCreateInfo(fields:any){
    let mygroup:any = {};
    mygroup = new FormGroup({
        'Name': new FormControl(fields.Name, [Validators.required]),
        'Ruc': new FormControl(fields.Ruc, [Validators.required]),
        'Email': new FormControl(fields.Email, [Validators.required]),
        'LegalName': new FormControl(fields.LegalName, [Validators.required]),
        'LegalCI': new FormControl(fields.LegalCI, [Validators.required]),
        'legalEmail': new FormControl(fields.legalEmail, [Validators.required]),
        'SecurityName': new FormControl(fields.SecurityName, [Validators.required]),
        'SecurityCI': new FormControl(fields.SecurityCI, [Validators.required]),
        'SecurityEmail': new FormControl(fields.SecurityEmail, [Validators.required]),
    });
  
    return mygroup.valid;
  }

  public filterAnalysisGroup(id:any){
    ////debugger;
    this.CompanyID = id.split(':')[1].trim();
  
  }



  public updateItem(content:any, form:any){
  // //debugger;
   this.isCreating = true;
   var qst = this.update_url;
 
   if( form == undefined ){
     form = {};
   }
 
   var to:any;
 
   if( (form.Name == undefined) || (form.Name == '') ){
       Swal.fire(this.translations.msg_all_required, 'msg', 'warning');
       return;
   }
 
   to = {};
   to.Ruc = form.Ruc;
   to.Name = form.Name;
   to.CompanyID =  this.CompanyID;
   to.Email = form.Email;
   to.LegalName = form.LegalName;
   to.LegalCI = form.LegalCI;
   to.legalEmail = form.legalEmail;
   to.SecurityName = form.SecurityName;
   to.SecurityCI = form.SecurityCI;
   to.SecurityEmail = form.SecurityEmail;
   to.LastModifierUserID = this.userinfo['userId'];
   to.CompanyID=this.userinfo['selectedCompanyId'];
   to.SubCompanyID = form.SubCompanyID;
   
 
   this.srv.postCall(qst, to).subscribe(x => {
     this.isCreating = false;
     Swal.close();
     if (x.success) {
       this.reload.next(true);
       this.closeModal('update1');
       Swal.fire(this.translations.msg_update, x.msg, 'success');
       this.getList();
       
     } 
  }, err => {
     Swal.close();
     Swal.fire('Error');
    });
 
 }

 deleteItem(obj: any){
  var id:any;
  id = {};
  id.SubCompanyID = obj;
  var qst = this.delete_url;
  this.srv.postCall(qst, id).subscribe(x => {
    this.isCreating = false;
    Swal.close();
    if(x.success){
      this.reload.next(true);
      Swal.fire('Desactivado!', x.msg, 'success');
      this.getList();
  }
  else{
      Swal.fire(x.msg, '', 'error');
  }
 }, err => {
    Swal.close();
    Swal.fire('Error');
   });
}


Activar(obj: any){
  var id:any;
  id = {};
  id.SubCompanyID = obj;
  var qst = this.active_url;
  this.srv.postCall(qst, id).subscribe(x => {
    this.isCreating = false;
    Swal.close();
    if(x.success){
      this.reload.next(true);
      Swal.fire('¡Activado!', x.msg, 'success');
      this.getList();
  }
  else{
      Swal.fire(x.msg, '', 'error');
  }
 }, err => {
    Swal.close();
    Swal.fire('Error');
   });
}

toggleEditable(event, id) {
  if ( event.target.checked ) {
     this.Activar(id);
 }else {
   this.deleteItem(id);

}
}
  
onlyNumberKey(event) {
  return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
}


public openCreateModal(content: any) {
  this.c_obj = {};
  
  //this.modalService.open(content, { size: 'lg' });
  this.modalService.open(content, { backdrop: 'static', keyboard: false ,size:'lg'});
}


}
