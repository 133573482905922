import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

const API_CONSULTAPLANTA = environment.API_CONSULTAPLANTA;
const API_REGISTROPLANTA = environment.API_REGISTROPLANTA;
const API_UPDATEPLANTA = environment.API_UPDATEPLANTA;
const API_DELETEPLANTA = environment.API_DELETEPLANTA;
const API_ACTIVARPLANTA = environment.API_ACTIVARPLANTA;


@Component({
  selector: 'app-paramplanta',
  templateUrl: './paramplanta.component.html',
  styleUrls: ['./paramplanta.component.css']
})
export class ParamplantaComponent extends BaseCrudComponent {

  id:any;
  loadingRequired = false;
  listAgr:any;
  translations:any;
  userinfo:any;

  constructor(
    srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        this.read_url = API_CONSULTAPLANTA;
        this.create_url = API_REGISTROPLANTA;
        this.update_url = API_UPDATEPLANTA;
        this.delete_url = API_DELETEPLANTA;
        this.active_url = API_ACTIVARPLANTA;

        this.search_fields = ['Name', 'Direction', 'Description'];

        this.c_obj['AnalysisFeature'] = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
   }

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
          this.translations = translations;
          super.ngOnInit();
          this.getList();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    this.storage.get('userinfo').then(x => {
        this.userinfo = x;
    });
  }

  public getList(){
    ////debugger;
    var qst = this.read_url;
    this.loadingList = true;
    Swal.fire(
      {
        title: this.translations['loading'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    this.srv.getCall(qst).subscribe(x => {
      ////debugger;
      this.loadingList = false;
      
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          this.page = 1;
          if( Array.isArray(this.rows) ){
             this.pageTotal = this.rows.length;
          }
          this.filterAll();
          Swal.close()
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
          }
  });
}


public createItem(content:any, form:any){
  //debugger;
  var validated = true;

  if( form == undefined ){
    form = {};
  }

  validated = this.validateCreateInfo(form);
  if( !validated ){
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
  }

  var to:any;
 form.CreatorUser = this.userinfo['logon'];
 form.LastModifierUser = this.userinfo['logon'];
  to = form;


  var qst = this.create_url;
  this.isCreating = true;

  this.srv.postCall(qst, to).subscribe(x => {
      this.isCreating = false;
      if(x.success){
          this.getList();
          this.closeModal(content);
          Swal.fire('¡Creado!', x.msg, 'success');
      }//end success
      else{
          //Swal.fire(this.translations[x.msg], '', 'error');
          Swal.fire(x.msg, '', 'error');
      }
  });
}

validateCreateInfo(fields:any){
  let mygroup:any = {};
  mygroup = new FormGroup({
      'Name': new FormControl(fields.Name, [Validators.required]),
      'Direction': new FormControl(fields.Direction, []),
      'Description': new FormControl(fields.Description, [])
  });

  return mygroup.valid;
}

public updateItem(content:any, form:any){
  this.isCreating = true;
  var qst = this.update_url;

  if( form == undefined ){
    form = {};
  }

  var to:any;

  if( (form.Name == undefined) || (form.Name == '') ){
      Swal.fire('Ingrese todos los campos requeridos', 'msg', 'warning');
      return;
  }

  to = {};
  to.Name = form.Name;
  to.Direction = form.Direction;
  to.Description = form.Description;
  to.CreatorUserId = form.CreatorUserId;
  to.LastModifierUserID = this.userinfo['userId'];
  to.PlantaID = form.PlantaID;
  

  this.srv.postCall(qst, to).subscribe(x => {
    this.isCreating = false;
    Swal.close();
    if (x.success) {
      this.reload.next(true);
      this.closeModal('update1');
      Swal.fire('¡Actualizado!', x.msg, 'success');
      this.getList();
      
    } 
 }, err => {
    Swal.close();
    Swal.fire('Error');
   });

}


deleteItem(obj: any){
  var id:any;
  id = {};
  id.PlantaID = obj;
  var qst = this.delete_url;
  this.srv.postCall(qst, id).subscribe(x => {
    this.isCreating = false;
    Swal.close();
    if(x.success){
      this.reload.next(true);
      Swal.fire('Desactivado!', x.msg, 'success');
      this.getList();
  }
  else{
      Swal.fire(x.msg, '', 'error');
  }
 }, err => {
    Swal.close();
    Swal.fire('Error');
   });
}


Activar(obj: any){
  var id:any;
  id = {};
  id.PlantaID = obj;
  var qst = this.active_url;
  this.srv.postCall(qst, id).subscribe(x => {
    this.isCreating = false;
    Swal.close();
    if(x.success){
      this.reload.next(true);
      Swal.fire('¡Activado!', x.msg, 'success');
      this.getList();
  }
  else{
      Swal.fire(x.msg, '', 'error');
  }
 }, err => {
    Swal.close();
    Swal.fire('Error');
   });
}

toggleEditable(event, id) {
  if ( event.target.checked ) {
     this.Activar(id);
 }else {
   this.deleteItem(id);

}
}

}
