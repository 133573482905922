import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment-timezone';


const API_GET_QUESTION_EVAL_ID = environment.Global.API_GET_QUESTION_EVAL_ID;
const API_CREATE_EVALUATION = environment.Global.API_CREATE_EVALUATION;
const API_SEARCH_REPETITIONS = environment.Global.API_SEARCH_REPETITIONS;

@Component({
  selector: 'app-myevaluationepecific',
  templateUrl: './myevaluationepecific.component.html',
  styleUrls: ['./myevaluationepecific.component.css']
})
export class MyevaluationepecificComponent extends BaseCrudComponent  {
  id:any;
  userinfo:any;
  band = null;
  texto:any;
  ind:any;
  respuestas:any;
  sec:any;
  seleccion=false;
  total_preguntas:any;
  finalizar=false;
  data_respuesta:any;
  time: number = 0;
  interval;
  play:any;
  duracion_evaluacion:any;
  duracion_evaluacion_t:number=0;
  oculta_div_evaluacion=false;
  calificacion_evaluacion:any;
  puntaje:any;
  nameevaluation:any;
  mensaje:any;
  momentjs: any = moment;
  max_repeticiones:any;
  veces_repetidas:any;
  loadingRequired = false;
  rowsveces:any;
  minutos:number=0;
  hora:number=0;
  band_minutos:false;


  numeroPregunta = 0;
  preguntas = [];
  preguntaAMostrar= {};
  opcionEscogida = null;
  puntajeMinimo = 0;
  puntajeMaximo = 0;
  constructor(srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService) {
      super(srv, modalService, storage, router); 
      this.id = route.snapshot.params.id;
      //this.RegCompanie.value.ruc.substr(3, 4);
      this.requestID=this.id.substr(this.id.indexOf("-")+1,99999999);
      this.id=this.id.substr(0,this.id.indexOf("-"));
      

      this.read_url="Questions/GetQuestionsByEvaluationId";
      this.search_fields = ['CompDocumentId','NameDoc','plazo'];
      this.respuestas=[];
      this.sec=0;
      this.data_respuesta=[];
      this.mensaje="";
      this.max_repeticiones=2;
      this.veces_repetidas=0;
      this.rowsveces=[];
      this.minutos=0;
      this.hora=0;

      this.c_obj['Respuesta'] = [];

      this.reload.subscribe(val => {
        if( val ){
            super.clearData();
            this.getList();
        }
      });
    }

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
          this.translations = translations;
          super.ngOnInit();
          this.getList();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    this.storage.get('userinfo').then(x => {
      this.userinfo = x;
      this.repeticiones();
    });

    
  }

  ngOnDestroy () {
    this.time = 0;
    this.minutos = 0;
    this.hora = 0;
    this.pauseTimer();
  }

  startTimer() {
    this.interval = setInterval(() => {
      if(this.time >= 0 && this.time<59) {
        this.time++;
      } else {
        this.time = 0;
        this.minutos=this.minutos+1;
      }
      if (this.minutos > this.duracion_evaluacion)
      {
        this.pauseTimer()
        this.finalizaevaluacion();
      }
    },1000)
  }
  
  pauseTimer() {
    clearInterval(this.interval);
  }

  public async getList()
  {
    var qst = API_GET_QUESTION_EVAL_ID + this.id;
    await this.srv.getCall(qst).toPromise().then(x => {
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.rows = rs;
          // console.log(this.rows);
          this.total_preguntas = this.rows['total_preguntas'];
          this.duracion_evaluacion = this.rows['duracion'];
          this.duracion_evaluacion_t = this.duracion_evaluacion * 60;
          this.puntajeMinimo = this.rows["calificacion_minima"];
          this.puntajeMaximo = this.rows["calificacion_maxima"];
          this.nameevaluation = this.rows["nameevaluation"];
          this.preguntas = this.rows['preguntas']
          this.preguntas.forEach(pregunta => {
            pregunta['opcionEscogida'] = null;
          })
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
        }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
    this.band = true;
  }

  iniciaevaluacion(){
    if(this.veces_repetidas >= this.max_repeticiones)
    {
      Swal.fire(
        {
          title: 'Error',
          text: this.translations['msj_'],
          icon:'error',
          confirmButtonColor: '#005c3c',
          allowOutsideClick: false,
          confirmButtonText: this.translations.come_back
        }
      ).then((result) => {
        if (result.value) {
          this.router.navigateByUrl('/myevaluation')
        }
      });
    }
    else{
      this.startTimer();
      this.band=false;
      this.mostrarpregunta();
    }
  }

  siguiente(){
    this.opcionEscogida == null;
    this.mostrarpregunta();
  }

  mostrarpregunta()
  {
    if (this.band==false)
    {
      this.numeroPregunta ++;
      if (this.numeroPregunta < this.total_preguntas) {
        this.preguntaAMostrar = this.preguntas[this.numeroPregunta - 1];
      } else if (this.numeroPregunta == this.total_preguntas) {
        this.preguntaAMostrar = this.preguntas[this.numeroPregunta - 1];
        this.finalizar = true;
      }
    }
  }

  GuardarRespuesta(pregunta) {
    let resp = null;
    let desc = null;
    if (pregunta['TipoPreguntaID'] == 3) {
      if (pregunta.opcionEscogida == null) {
        return Swal.fire(this.translations.you_need_select_answer,'','error')
      } else {
        pregunta.RespuestasList.forEach(element => {
          if (element.respuesta == true) {
            if (element.respuesta) {
              resp = true;
              desc = element.descripcion;
            } else {
              resp = false;
              desc = element.descripcion;
            }
          }
        });
        this.c_obj['Respuesta'].push(
          {
            PreguntaID: pregunta.PreguntaID,
            DetallePreguntaID: pregunta.Pregunta,
            OpcionUsuario: pregunta.opcionEscogida,
            OpcionValida: resp,
            TipoPregunta: pregunta['TipoPreguntaID']
          }
        )
      } 
    } else if (pregunta['TipoPreguntaID'] == 2) {
      let contador = 0;
      pregunta.RespuestasList.forEach(element => {
        if (element.opcionEscogida != undefined) {
          contador ++;
        }
      });
      if (contador == 0) {
        return Swal.fire(this.translations.you_need_select_answer,'','error')
      } else {
        let obj = {
          PreguntaID: pregunta.PreguntaID,
          DetallePreguntaID: pregunta.Pregunta,
          OpcionUsuario: [],
          OpcionValida: [],
          TipoPregunta: pregunta['TipoPreguntaID']
        };
        pregunta.RespuestasList.forEach(element => {
          if (element.respuesta == true) {
            resp = true;
          } else {
            resp = false;
          }
          obj.OpcionUsuario.push(element.opcionEscogida != undefined ? element.opcionEscogida : false)
          obj.OpcionValida.push(resp)
        });
        this.c_obj['Respuesta'].push(obj)
      }
    }
    
    if (!this.finalizar) {
      this.siguiente();
    } else {
      this.finalizaevaluacion();
    }
  }

  finalizaevaluacion()
  {
    let calificacion = 0;
    let puntaje = 0;
    this.c_obj['Respuesta'].forEach(respuesta => {
      if (respuesta.TipoPregunta == 3) {
        if (respuesta.OpcionUsuario == respuesta.OpcionValida) {
            puntaje ++;
        }
      } else if (respuesta.TipoPregunta == 2) {
        let contadorRespuestasV = 0;
        let contadorRespuestasU = 0;
        for (let index = 0; index < respuesta.OpcionUsuario.length; index++) {
          if (respuesta.OpcionValida[index]) {
            contadorRespuestasV ++;
          }
          if (respuesta.OpcionValida[index] && respuesta.OpcionUsuario[index]) {
            contadorRespuestasU ++;
          }
        }
        // console.log({contadorRespuestasU, contadorRespuestasV});
        if (contadorRespuestasU == contadorRespuestasV) {
          puntaje++;
        }
      }
    })
    if (this.c_obj['Respuesta'].length > 0) {
      calificacion = Number(((puntaje * this.puntajeMaximo) / this.total_preguntas).toFixed(1));
    } else {
      calificacion = 0;
    }
    let titulo = '';
    let cuerpo = '';
    let status = '';
    if (calificacion < this.puntajeMinimo) {
      titulo =  '<strong>' + `${this.translations.msj_evaluation2}` + '</strong>';
      status = 'RP';
    } else {
      titulo = '<strong>' + `${this.translations.msj_evaluation1}` + '</strong>';
      status = 'AP';
    }
    this.pauseTimer();
    cuerpo = 
      `${this.translations.result_eval}` + ' ' + (`${calificacion.toString()}` + '/' + `${this.puntajeMaximo.toString()}`);

    Swal.fire({
      title: titulo,
      text: cuerpo,
      icon: status == 'RP' ? 'error' : 'success',
      showCancelButton: false,
      confirmButtonColor: status == 'AP' ? '#005c3c' : '#C8102E' ,
      allowOutsideClick: false,
      confirmButtonText: this.translations.accept
    }).then((result) => {
      if (result.value) {
        let curr_date = this.momentjs().tz('America/Guayaquil').format('YYYY-MM-DD H:mm:ss');
        this.u_obj["EmployeeId"] = this.userinfo.userCode; // codigo de empelado externo
        this.u_obj["ParameEvaID"] = this.id; // evaluacion realizada
        this.u_obj["RequestId"] = this.requestID; // solicitud de induccion
        this.u_obj["max_repetition"] = 2;
        this.u_obj["score_end"] = Number(calificacion.toFixed(0));
        this.u_obj["number_questions"] = this.total_preguntas;
        this.u_obj["score_evaluation"] = this.puntajeMaximo;
        this.u_obj["status_end"] = status;
        this.u_obj['DateModification'] = curr_date;
        this.u_obj['MyevaluationDetail'] = [];
        this.u_obj['MyevaluationDetail'].push({score: Number(calificacion.toFixed(0)), status:status,  creationtime:curr_date});
        this.savescore(this.u_obj);
      }
    })
  }

  savescore(obj:any)  {
    // console.log({obj})
    Swal.fire(
      {
        title: this.translations.save_data,
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    this.srv.postCall(API_CREATE_EVALUATION, obj).subscribe(x => {
      if(x.success){
        Swal.close()
        this.router.navigateByUrl('/myevaluation');
      }
      else{
        Swal.fire(x.msg, '', 'error');
      }
    });
  }

  public repeticiones()
  {
    var qst = API_SEARCH_REPETITIONS +
      this.id+"/" + 
      this.userinfo.userCode + "/" +
      this.requestID;
    this.srv.getCall(qst).subscribe(x => {
      if(x.success){
        var rs = x.val;
        this.rowsveces=rs;
        this.max_repeticiones=this.rowsveces.max_repetition;
        this.veces_repetidas=this.rowsveces.vecesrepetidas;
      }
      else{
        this.max_repeticiones=2;
        this.veces_repetidas=0;
      }
    });
  }

}
