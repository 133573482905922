import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

	constructor(private storage: Storage, private router: Router,){}

  	canActivate(
  	  	next: ActivatedRouteSnapshot,
    	state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  		
  		//console.log('test login guard');
  		
  		return this.storage.get('token').then(x => {
  			if( (x == undefined) || (x == null) || (x=='') ){
  				return true;
  			}
  			else{
  				this.router.navigate(['dashboard']);
  				return false;
  			}
  		});
  	}
  
}
