import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import {saveAs as importedSaveAs  } from "file-saver";
import {Global} from "../../../services/global";

@Component({
  selector: 'app-proapprovedocumentssubcompany',
  templateUrl: './proapprovedocumentssubcompany.component.html',
  styleUrls: ['./proapprovedocumentssubcompany.component.css']
})
export class ProapprovedocumentssubcompanyComponent extends BaseCrudComponent  {
  id:any;
  userinfo:any;
  name_company:any;
  flag:any;

  public rowEmployee: any;

  constructor(srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService) {
      super(srv, modalService, storage, router);
      this.id = route.snapshot.params.id;
      this.flag = 0;

      this.read_url="SubCompanyDocument/GetDocumentstoApprove";

      this.active_url = Global.API_STATUSEMPLOYEE_INSURANCE;
      this.search_fields = ['SubCompDocumentId','NameDoc','identification','SubCompanyName','plazo'];

      this.c_obj['SubCompanyDocument'] = [];

      this.reload.subscribe(val => {
        if( val ){
            super.clearData();
            this.getList();
        }
      });
    }


  async ngOnInit() {
    await this.translate.getTranslation(this.translate.currentLang)
    .toPromise().then((translations) => {
          this.translations = translations;
          super.ngOnInit();
          this.getList();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';
    this.route.queryParams.subscribe(params => {
      this.name_company = params.state
    });
    await this.storage.get('userinfo').then(x => {
        this.userinfo = x;
    });
  }

  public getList()
  {
    var qst = this.read_url+"/"+this.id;
    this.loadingList = true;

    //console.log("userinfo:",this.userinfo);
    Swal.fire(
      {
        title: this.translations['loading'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    //this.srv.postCall(qst, to).subscribe(x => {
      this.srv.getCall(qst).subscribe(x=>{
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          this.rows.forEach(element => {
            element.valor = null;
          });
          // console.log(this.rows)
          this.page = 1;
          if( Array.isArray(this.rows) ){
             this.pageTotal = this.rows.length;
          }
          this.filterAll();
          Swal.close()
          // if(x.val != null){
          //   if(x.val.length == 0) {
          //     Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          //   } else {
          //     this.rawdata = x.val;
          //     var rs = x.val;
          //     this.rows = rs;
          //     this.name_company=this.rows[0].name_company;

          //     this.page = 1;
          //     if( Array.isArray(this.rows) ){
          //        this.pageTotal = this.rows.length;
          //     }
          //     this.filterAll();
          //     Swal.close()
          //   }
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
          }
     });
  }

  downloadDocFile(data) {
    const DocFileName = data.SubCompDocumentId;
    const name=data.FileName;
    //var DocFile = DocFileName.slice(0, -5);
    this.srv.downloadFileSub(DocFileName).subscribe((data) => {
       importedSaveAs(data,name )//DocFileName)
   });
  }


  verificateprocesardoc(row:any){
    var qst = "SubCompanyDocument/ProApproveToDoc"
    var mensaje:any;

    //debugger;
    var to:any;
    let trans = this.translations;
    to={};
    to.SubCompDocumentId=row.SubCompDocumentId;
    to.SubCompanyID=row.SubCompanyID;
    //to.VerificateUser=this.userinfo.logon;
    to.VerificateUser=this.userinfo.userId;
    if (this.flag == undefined) {
      return Swal.fire('', this.translations.aprroveorrevoce , 'error');
    }

    //if (row.VerificateComment==" " || row.VerificateComment==null || row.VerificateComment=="")
    if(this.flag==0)
    {
      to.Status="V";
      to.StatusId=39;
      to.VerificateComment="";
      mensaje=trans.show_message_approve;
    }
    else{
      if (row.VerificateComment==" " || row.VerificateComment==null || row.VerificateComment=="")
      {
        Swal.fire('¡Warning!', trans.docapprove_error_missingcomment, 'success');
        return;
      }

      to.Status="R";
      to.StatusId=40;
      to.VerificateComment=row.VerificateComment;
      mensaje=trans.show_message_reject;
    }


    Swal.fire({
        title: mensaje,//trans.sampapp_refmethodswarn,
        text: '',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: trans.cancel,
        confirmButtonText: trans.ok,
        preConfirm: () => {
            this.procesardoc(qst,to);

        }
    }).then((result) => {
    });

  }

  procesardoc(qst:any,to:any){
    this.loadingList = true;

    this.srv.postCall(qst, to).subscribe(x => {
      this.loadingList = false;
      if(x.success)
      {
        Swal.fire('¡Actualizado!', x.msg, 'success');
        this.getList();
      }else {
        Swal.fire( x.msg,'', 'error');
      }
     });

  }

  changeSelect(row) {
    //  console.log(row)
    if(row.valor == 0) {
      this.flag = 0;
    } else {
      this.flag = 1;
    }
  }

  //PARA MODAL DE DETALLE DE EMPLEADOS POR DOCUMENTO DE IESS
  openUpdateModal(content: any, obj1: any) {
    console.log("objeto::",obj1)
    let obj = JSON.parse(JSON.stringify(obj1));
    this.u_objSub = obj
    //console.log("objeto de llegada=>", this.u_objSub, "ubj=>", this.u_obj);
    this.getEmployeeList();

    this.modalService.open(content, { backdrop: 'static', keyboard: false, size: 'lg', centered: true });
  }

  public getEmployeeList() {
    var qst = Global.API_ALLEMPLOYEESUBCOMPANYID + "/" + this.u_objSub.SubCompanyID;//this.userinfo['selectedCompanyId'];
    console.log("qst:",qst)
    this.loadingList = true;

    Swal.fire(
      {
        title: this.translations['loading'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;

      if (x.success) {
        if (x.val != null) {
          //this.rowsTemp = x.val;
          var rs = x.val;
          this.rowEmployee = rs;
          this.page = 1;
          console.log("objEmployee:",this.rowEmployee)
          if (Array.isArray(this.rowEmployee)) {
            this.pageTotal = this.rowEmployee.length;
          }
          this.filterAll();
          Swal.close()

        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rowEmployee = [];
          //return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  toggleEditable(event, id) {
    if (event.target.checked) {
      this.Activar(id, 1);
    } else {
      this.Activar(id, 2);
    }
  }

  Activar(obj: any, valor: any) {
    var to: any;
    to = {};
    to.EmployeeId = obj;
    to.Status = valor;
    var qst = this.active_url;
    // console.log("qst:", qst);
    // console.log("to:", to);
    this.srv.postCall(qst, to).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if (x.success) {
        //this.reload.next(true);
        if (valor == "1") {
          Swal.fire('¡Activado!', x.msg, 'success');
        }
        else {
          Swal.fire('¡Desactivado!', x.msg, 'success');
        }
        //this.getList();
      }
      else {
        Swal.fire(x.msg, '', 'error');
      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }

}
