import { Component, OnInit } from '@angular/core';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/services/main/global.service';
import {saveAs as importedSaveAs  } from "file-saver";
import Swal from 'sweetalert2';
import * as moment from 'moment-timezone';

const URL_TO_DOWNLOAD_QRCODE = environment.Global.API_DOWNLOAD_QR;
const API_GET_ENTRY_REQUEST_BY_EMPLOYEE = environment.Global.API_GET_ENTRY_REQUEST_BY_EMPLOYEE;

@Component({
  selector: 'app-qremployee',
  templateUrl: './qremployee.component.html',
  styleUrls: ['./qremployee.component.css']
})
export class QremployeeComponent extends BaseCrudComponent implements OnInit {
  userinfo: any;

  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private globalService: GlobalService,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
  }


  async ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
      .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
        });

        // this.getTypeFouls();
    this.search_fields = ['EntryRequestId','Requiredby'];
    await this.storage.get('userinfo').then(x => {
      this.userinfo = x;
    });
    this.getList();
  }

  public getList() {
    this.loadingList = true;
    let url = API_GET_ENTRY_REQUEST_BY_EMPLOYEE + this.userinfo['userCode'];
    this.srv.getCall(url).subscribe( data => {
      this.loadingList = false;
      if (data.success) {
        if (data.val.length > 0) {
          this.rawdata = data.val;
          this.rows = data.val;
          this.page = 1;
          // console.log(data.val)
          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          this.loadingList = false;
        }
      }
    })
  }

  public downloadQr(row) {
    let fechaActual = moment().startOf('day');//moment().startOf('day');//new Date();
    let fecha = moment(row.StartDate.split('T')[0]);//new Date(row.StartDate.split('T')[0]);
    let fechaSalida = moment(row.EndDte.split('T')[0]);//new Date(row.EndDte.split('T')[0]);debugger;
    //fecha.setDate(fecha.getDate() - 2);
    debugger;
    if (fechaActual.isBetween(fecha, fechaSalida, null, '[]')){//(fechaActual.getTime() >= fecha.getTime() && fechaActual.getTime() <= fechaSalida.getTime() ) {
      let entryRequestId = row.EntryRequestId;
      let dato = this.userinfo['userCode'] + '-' + entryRequestId;
      // console.log(dato)
      let qst = URL_TO_DOWNLOAD_QRCODE + dato.toString();
      this.srv.downloadFileQR(qst).subscribe(data => {
        importedSaveAs(data, 'QR - INGRESO PLANTA SKRETTING');
      });
    } else {
      Swal.fire(this.translations.download_not_valid,this.translations.only_download_in_date,'error');
    }



  }

}
