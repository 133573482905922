import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';


import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { isNumeric } from 'rxjs/util/isNumeric';
import { Newsubcomjob } from '../../../interfaces/newsubcomjob';
import {saveAs as importedSaveAs  } from "file-saver";

@Component({
  selector: 'app-myevaluation',
  templateUrl: './myevaluation.component.html',
  styleUrls: ['./myevaluation.component.css']
})
export class MyevaluationComponent extends BaseCrudComponent  {
  urlvideo: SafeUrl;
  userinfo:any;
  videoPlayer: HTMLVideoElement;
  apiLoaded = false;
  constructor(
    private _sanitizer: DomSanitizer,
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService) {
      super(srv, modalService, storage, router);

      //this.read_url = ALLCOMPANIES;
      this.search_fields = ['EvaluationId','EvaluationName'];

      this.c_obj['Company'] = [];

      this.reload.subscribe(val => {
          if( val ){
              super.clearData();
              this.getList();
          }
      });
    }

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
          this.translations = translations;
          super.ngOnInit();
          //this.getList();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    this.storage.get('userinfo').then(x => {
        this.userinfo = x;
        this.getList();
    });
  }

  public getList()
  {
    var qst = "Request/AllByUser/"+this.userinfo.userCode;//this.read_url;
    this.loadingList = true;
    // console.log(qst)
    this.srv.getCall(qst).subscribe(x => {
        this.loadingList = false;     
        if(x.success){
          if(x.val != null){
            this.rawdata = x.val;
            var rs = x.val;
            this.rows = rs;
            // console.log("rs:",rs);
            this.page = 1;
            if( Array.isArray(this.rows) ){
               this.pageTotal = this.rows.length;
            }
            Swal.close();
            this.filterAll();
          }else {
            Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
            this.rows = [];
            return;
          }
        }else {
          Swal.fire(x.msg, '', 'error');
          return;
            }
       });
  }

  openVideo (modal, obj) {
    this.modalService.open(modal, { backdrop: 'static',  keyboard : false, size: 'lg' });
  }

  GoEvaluation(content:any,obj:any)
  {
    this.closeModal(content);
    this.requestID=obj.RequestId;
    // window.location.href = '/#/myevaluationepecific/'+obj.EvaluationId+"-"+obj.RequestId;
    this.router.navigateByUrl('/myevaluationepecific/'+obj.EvaluationId+"-"+obj.RequestId);

  }

  openModalVideo(content: any, obj: any, size?:any) {
    let newobj = JSON.parse(JSON.stringify(obj));
    this.u_obj = newobj;
    this.urlVideo = this._sanitizer.bypassSecurityTrustResourceUrl(this.u_obj.UrlVIdeo);
    if( size == undefined ){
        this.modalService.open(content, { centered: true ,keyboard : false,});
    }
    else{
        this.modalService.open(content, { centered: true, keyboard : false, size: size });
    }
    
  }



}
