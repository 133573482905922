import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-ic2',
  templateUrl: './ic2.component.html',
  styleUrls: ['./ic2.component.css']
})
export class Ic2Component implements OnInit {

  constructor(private translate: TranslateService) {
        /*var userLang = 'es';
      	translate.setDefaultLang(userLang);
      	translate.use(userLang);*/
    }

  ngOnInit() {
  }

}
