import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Newinfo } from '../../../interfaces/newinfo';
import { NgxImageCompressService } from 'ngx-image-compress';

const API_CONSINFO = environment.API_CONSINFO;
const API_REGISTERINFO = environment.API_REGISTERINFO;
const API_UPDATEINFO = environment.API_UPDATEINFO;
const API_DESACTIVEINFO = environment.API_DESACTIVEINFO;
const API_ACTIVEINFO = environment.API_ACTIVEINFO;
const API_INFOUPLOAD = environment.API_INFO_UPLOAD;


@Component({
  selector: 'app-comunicados',
  templateUrl: './comunicados.component.html',
  styleUrls: ['./comunicados.component.css']
})
export class ComunicadosComponent extends BaseCrudComponent {

  id:any;
  loadingRequired = false;
  listAgr:any;
  translations:any;
  userinfo:any;
  RegNewInfo:FormGroup;
  fileName;
  foto64;
  imageSrc = null;
  // Propiedad para almacenar la URL del archivo subido
  uploadedFileUrl: string;
  // Propiedad para rastrear la selección del usuario
  isUpload: boolean = true; // Por defecto se selecciona "Subir archivo"

  file: any;
  localUrl: any;
  localCompressedURl:any;
  sizeOfOriginalImage:number;
  sizeOFCompressedImage:number;
  imgResultBeforeCompress:string;
  imgResultAfterCompress:string;

  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private imageCompress: NgxImageCompressService
  ) {
    super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        this.create_url = API_REGISTERINFO;
        this.read_url = API_CONSINFO;
        this.update_url = API_UPDATEINFO;
        this.delete_url = API_DESACTIVEINFO;
        this.active_url = API_ACTIVEINFO;

        this.search_fields = ['Name', 'Direction', 'Description'];

        this.c_obj['AnalysisFeature'] = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
        this.crearFormInfo();
   }

   ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
          this.translations = translations;
          super.ngOnInit();
          this.getList();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    this.storage.get('userinfo').then(x => {
        this.userinfo = x;
    });
  }

  public getList(){
    var qst = this.read_url;
    this.loadingList = true;

    var id:any;
    id = {};
    id.TypeID = this.id;
    // Swal.fire(
    //   {
    //     title: this.translations['loading'],
    //     html: '',
    //     icon: 'info',
    //   }
    // );
    // Swal.showLoading();

  this.srv.postCall(qst, id).subscribe(x => {
      this.loadingList = false;
          if(x.success){
            if(x.val.length != 0){
              this.rawdata = x.val;
              var rs = x.val;
              // console.log(rs)
              rs.forEach(element => {
                element.CreationTime = element.CreationTime !== null ? element.CreationTime.split('T')[0] : null;
                element.DateEnd = element.DateEnd !== null ? element.DateEnd.split('T')[0] : null;
              });
              // console.log(rs)
              this.rows = rs;
              this.page = 1;
              if( Array.isArray(this.rows) ){
                this.pageTotal = this.rows.length;
              }
              this.filterAll();
              Swal.close()
            }else {
              Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
              this.rows = [];
              return;
                }
          }else {
            Swal.fire(x.msg, '', 'error');
            return;
              }
      });
  }

  crearFormInfo() {
    this.RegNewInfo = new FormGroup({
      Title: new FormControl('', Validators.compose([Validators.required])),
      SubTitle: new FormControl('', Validators.compose([Validators.required])),
      Description: new FormControl('', Validators.compose([Validators.required])),
      UrlLinks: new FormControl('', Validators.compose([Validators.required])),
      DateEnd: new FormControl('', Validators.compose([Validators.required])),
      });
  }


  onFileChanged(event) {
    const reader = new FileReader();
    const file = event.target.files[0];
    const max_size = 5000000;
    let fileInput = event.target.files[0].size;
    if (fileInput > max_size) {
      return Swal.fire(this.translations.max_size_img,'<h2><Strong>5MB</Strong></h2>','error');
    }  else {
      reader.readAsDataURL(file);
      reader.onload = () => {
          this.fileName = file.name + ' ' + file.type;
          const str = reader.result.toString();
          this.foto64 = str;
          this.imageSrc = str;
      };
    }
  }



  selectfile(event) {
    let fileName : any;
    this.file = event.target.files[0];
    fileName = this.file['name'];
    // console.log(event.target.files[0].size)
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (event: any) => {
        // console.warn(reader.result)
        this.localUrl = event.target.result;
        // this.compressFile(this.localUrl,fileName)
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }


  compressFile(image,fileName) {
    let orientation = -1;
    this.sizeOfOriginalImage = this.imageCompress.byteCount(image)/(1024*1024);
    // console.warn('Size in bytes is now:',  this.sizeOfOriginalImage);
    this.imageCompress.compressFile(image, orientation, 50, 50).then(
    result => {
      this.imgResultAfterCompress = result;
      this.localCompressedURl = result;
      this.sizeOFCompressedImage = this.imageCompress.byteCount(result)/(1024*1024)
      // console.warn('Size in bytes after compression:',  this.sizeOFCompressedImage);
      // create file from byte
      const imageName = fileName;
      // call method that creates a blob from dataUri
      const imageBlob = this.dataURItoBlob(this.imgResultAfterCompress.split(',')[1]);
      //imageFile created below is the new compressed file which can be send to API in form data
      const imageFile = new File([result], imageName, { type: 'image/jpeg' });
      this.imageSrc = result;
    });
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }




  public createItem(row){
    // //debugger;
    if (this.RegNewInfo.valid) {

      var qst = this.create_url;
      this.isCreating = true;

          const newparam: Newinfo = new Newinfo();
          newparam.TypeID = this.id;
          newparam.Title = this.RegNewInfo.value.Title;
          newparam.SubTitle = this.RegNewInfo.value.SubTitle;
          newparam.Description = this.RegNewInfo.value.Description;
          newparam.UrlPicture = this.foto64;
          //newparam.UrlLinks = this.RegNewInfo.value.UrlLinks;
          newparam.DateEnd = this.RegNewInfo.value.DateEnd;
          newparam.CreatorUser = this.userinfo['logon'];

          // Verifica si se subió un archivo y asigna la URL al campo UrlLinks
          if (this.uploadedFileUrl) {
            newparam.UrlLinks = this.uploadedFileUrl;
          } else {
            // Si no se subió archivo, utiliza el valor ingresado manualmente (si corresponde)
            newparam.UrlLinks = this.RegNewInfo.value.UrlLinks;
          }

             this.srv.postCall(qst, newparam).subscribe(x => {
              this.isCreating = false;
                  Swal.close();
                  if (x.success) {
                    this.RegNewInfo.reset();
                    this.imageSrc = "";
                    this.closeModal('create1');
                    this.getList();
                    this.reload.next(true);
                    Swal.fire('¡Creado!', x.msg, 'success');
                    this.foto64 = null;
                    this.imageSrc = null;
                    this.uploadedFileUrl = null;
                 } else{
                   Swal.fire(x.msg, '', 'error');
                 //this.closeModal(content);
               }
           }, err => {
              Swal.close();
              Swal.fire('Error');
             });

    }
    else {
      Swal.fire(this.translations.msg_all_required, '', 'error');
      return;
      }
  }

  public updateItem(content:any, form:any){
    // //debugger;
      var qst = this.update_url;
      this.isUpdating = true;

      if( form == undefined ){
        form = {};
      }

      var to:any;

      if( (form.Title == undefined) || (form.Title == '') ){
        Swal.fire(this.translations.msg_all_required, '', 'error');
          return;
      }


      to = {};
      to.Title = form.Title;
      to.SubTitle = form.SubTitle;
      to.Description = form.Description;
      to.UrlLinks = form.UrlLinks;
      to.UrlPicture = this.foto64 === undefined ? this.u_obj['UrlPicture'] : this.foto64;
      to.DateEnd = form.DateEnd
      to.InfoID = form.InfoID;
      // console.log(to);

      this.srv.postCall(qst, to).subscribe(x => {
        this.isUpdating = false;
        Swal.close();
        if (x.success) {
          this.reload.next(true);
          this.closeModal('update2');
          Swal.fire(this.translations.msg_update, x.msg, 'success');
          this.getList();
          this.foto64 = null;
          this.imageSrc = null;
        }
      }, err => {
        Swal.close();
        Swal.fire('Error');
      });

    }

    toggleEditable(event, id) {
      // //debugger;
      if ( event.target.checked ) {
         this.Activar(id);
     }else {
       this.deleteItem(id);

    }
    }

    Activar(obj: any){


      var id:any;
      id = {};
      id.InfoID = obj;
      var qst = this.active_url;

      this.srv.postCall(qst, id).subscribe(x => {
        this.isCreating = false;
              Swal.close();
              if(x.success){
                this.reload.next(true);
                Swal.fire('¡Activado!', x.msg, 'success');
                this.getList();
            }
            else{
                Swal.fire(x.msg, '', 'error');
            }
     }, err => {
        Swal.close();
        Swal.fire('Error');
       });
    }

    deleteItem(obj: any){

      var id:any;
      id = {};
      id.InfoID = obj;
      id.DeleteUser = this.userinfo['logon'];
      var qst = this.delete_url;

      this.srv.postCall(qst, id).subscribe(x => {
        this.isCreating = false;
        Swal.close();
        if(x.success){
          this.reload.next(true);
          Swal.fire('Desactivado!', x.msg, 'success');
          this.getList();
      }
      else{
          Swal.fire(x.msg, '', 'error');
      }
     }, err => {
        Swal.close();
        Swal.fire('Error');
       });
    }

    openUpdateModal ( content, row ) {
      this.imageSrc = null;
      this.uploadedFileUrl = "";
      let newobj = JSON.parse(JSON.stringify(row));
      this.u_obj = newobj;
      // console.log(this.u_obj)
      this.modalService.open(content, { centered: true, backdrop: 'static', keyboard: false});
    }
  onFileSelected(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append('file', file, file.name);

      // Llama al servicio para subir el archivo
      //await this.srv.AddFiles(API_UPLOAD_FILE_BETTER, formData).toPromise().then(result => {
      this.srv.AddFiles(API_INFOUPLOAD, formData).subscribe(
        (response) => {
          // Asume que el servidor retorna la URL del archivo subido en 'fileUrl'
          if (response.includes('OK')){
            this.uploadedFileUrl = response.split('|')[1];
          }
          // Opcionalmente, puedes asignar la URL al control del formulario
          this.RegNewInfo.get('UrlLinks').setValue(this.uploadedFileUrl);
        },
        (error) => {
          console.error('Error al subir el archivo:', error);
          // Maneja el error adecuadamente (mostrar mensaje al usuario, etc.)
        }
      );
    }
  }
  // Método para actualizar los validadores
  updateValidators() {
    // if (this.isUpload) {
    //   // Si se selecciona subir archivo, UrlLinks no es requerido
    //   this.RegNewInfo.get('UrlLinks').clearValidators();
    //   this.RegNewInfo.get('UrlLinks').updateValueAndValidity();
    //
    //   // El campo de archivo es requerido
    //   this.RegNewInfo.get('file').setValidators([Validators.required]);
    //   this.RegNewInfo.get('file').updateValueAndValidity();
    // } else {
    //   // Si se selecciona ingresar enlace, UrlLinks es requerido
    //   this.RegNewInfo.get('UrlLinks').setValidators([Validators.required]);
    //   this.RegNewInfo.get('UrlLinks').updateValueAndValidity();
    //
    //   // El campo de archivo no es requerido
    //   this.RegNewInfo.get('file').clearValidators();
    //   this.RegNewInfo.get('file').updateValueAndValidity();
    // }
  }

  // Método que se llama cuando cambia la opción seleccionada
  onOptionChange(value: boolean) {
    this.isUpload = value;
    this.updateValidators();
  }
}
