import { Component, OnInit } from '@angular/core';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { isNumeric } from 'rxjs/util/isNumeric';

import Swal from 'sweetalert2';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { catchError, mergeMap } from 'rxjs/operators';
import { Cell, Columns, Img, Item, Line, PageReference, PdfMakeWrapper, Stack, Table, Toc, Txt, Ul } from 'pdfmake-wrapper';
import * as pdfFonts from "pdfmake/build/vfs_fonts"; // fonts provided for pdfmake
import { of } from 'rxjs';
import { isNull } from 'util';

// Set the fonts to use
PdfMakeWrapper.setFonts(pdfFonts);
const GET_EMPLOYEES_BY_COMPANY = environment.Global.API_GET_EMPLOYEES_BY_COMPANY;
const GET_EMPLOYEES_ALL_COMPANY = environment.Global.API_GET_EMPLOYEES_ALL_COMPANY;
const GET_JOBS_TYPE = environment.Global.API_GET_JOB_TYPE;
const GET_EMPLOYEE_BY_IDENTIFICATION = environment.Global.API_GET_EMPLOYEE_BY_IDENTIFICATION;
const API_gET_EMPLOYEE_BY_IDENTIFICATIONREQUEST = environment.Global.API_gET_EMPLOYEE_BY_IDENTIFICATIONREQUEST;
const GET_ALL_SITES_ACTIVE = environment.Global.API_GET_ALL_SITES_ACTIVE;
const GET_ALL_AREA_BY_ID = environment.Global.API_GET_ALL_AREA_BY_ID;
const UPDATE_ADMISSION_REQUEST = environment.Global.API_UPDATE_ADMISSION_REQUEST;
const CREATE_ADMISSION_REQUEST = environment.Global.API_CREATE_ADMISSION_REQUEST;
const SEND_EMAIL_ADMISSION_REQUEST = environment.Global.API_SEND_EMAIL_ADMISSION_REQUEST;
const GET_ALL_REQUEST = environment.Global.API_GET_ALL_REQUEST;
const GET_ALL_REQUEST_BY_COMPANY_ID = environment.Global.API_GET_ALL_REQUEST_BY_COMPANY_ID;
const UPDATE_STATUS__ADMISSION_REQUEST = environment.Global.API_UPDATE_STATUS__ADMISSION_REQUEST;
const CREATE_QR_AND_SEND_EMAIL = environment.Global.API_CREATE_QR_AND_SEND_EMAIL;
const SEND_MAIL_TO_NOTIFIED_SKRETTING = environment.Global.API_SEND_MAIL_TO_NOTIFIED_SKRETTING;


@Component({
  selector: 'app-proadmissionapp',
  templateUrl: './proadmissionapp.component.html',
  styleUrls: ['./proadmissionapp.component.css']
})
export class ProadmissionappComponent extends BaseCrudComponent implements OnInit {
  emergency_checked = false;

  loadingList = true;
  userinfo:any;
  row_plantas:any;
  row_plantas_area:any;
  mostarselectemp;
  habilitar = false;
  isUpdating = false;
  isEmergency = false;
  loadingRequired = false;
  row_typejob:any;
  isSkretting = false;
  diffDate;
  listaPlantasUpdate = [];
  row_emp:any; // lista que obtiene los trabajadores
  workerList = [];
  worksListId = [];
  pageTotal2 = 0;
  page2 = 0;
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
  }

  ngOnInit() {
    // console.log(environment.Global.API_GET_ALL_TOOLS)
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
          this.translations = translations;
          super.ngOnInit();
          // this.getList();
    });
    this.pageSize = 5;
    this.pageSize2 = 5;
    this.search_fields = ['EntryRequestId','Requiredby'];
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';
    this.storage.get('userinfo').then(x => {
      this.userinfo = x;
      // console.log(x)
      if( isNumeric( this.userinfo.logon) ) {
        this.mostarselectemp = false;
        this.isSkretting = false;
      } else {
        this.isSkretting = true;
      }
      this.getList();
  });
  }

  public openCreateModal(content: any) {
    this.GetTrabajadores();
    this.GetPlantas();
    this.c_obj = {};
    this.c_obj['worker'] = [];
    this.c_obj['emergency'] = false;
    this.c_obj['comment_emergency'] = '';
    this.c_obj['email_applicant'] = '';
    this.c_obj['level'] = null;
    //this.modalService.open(content, { size: 'lg' });  backdrop: 'static', keyboard: false,
    this.modalService.open(content, { backdrop: 'static', size: 'lg' });
    this.GetTypeJob().subscribe(x => {
      this.loadingRequired = false;
      if(x != false){
          this.row_typejob = x;
      }
    });
  }

  public check_risk() {
    console.log(this.isEmergency)

  }

  public getList() {
    var qst = "" ;
    this.loadingList = true;

    if(isNumeric(this.userinfo.logon) )
    {
      qst = GET_ALL_REQUEST_BY_COMPANY_ID + this.userinfo.selectedCompanyId;
    }
    else{
      qst = GET_ALL_REQUEST;
    }
    Swal.fire(
      {
        title: this.translations['loading'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    // console.log(qst);
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          // console.log(this.rows)
          this.page = 1;
          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
          // console.log(this.pageTotal)
          // console.log(this.pageSize)
          this.filterAll();
          Swal.close()
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public validateCreateInfo(fields:any): boolean {
    let mygroup:any = {};
    mygroup = new FormGroup({
        'date_init_work': new FormControl(fields.date_init_work, [Validators.required]),
        'date_final_work': new FormControl(fields.date_final_work, [Validators.required]),
        'job_order': new FormControl(fields.job_order, [Validators.required]),
        'job_applicant': new FormControl(fields.job_applicant, [Validators.required]),
        'level': new FormControl(fields.level, [Validators.required]),
        'work_description': new FormControl(fields.work_description, [Validators.required]),
        'job_area': new FormControl(fields.job_area, [Validators.required]),
        'request_area': new FormControl(fields.request_area, [Validators.required]),
        'jobs': new FormControl(fields.jobs),
        'email_applicant':  new FormControl(fields.email_applicant, [Validators.required]),

    });
    return mygroup.valid;
  }

  public GetTypeJob()
  {
    var qst = GET_JOBS_TYPE;

    return this.srv.getCall(qst).pipe(
      mergeMap(data => {
          let mydata:any = {success: false, msg:'', val:[]};
          mydata = data;
          if(mydata.success == true ){
            return of(mydata.val);
          }
          else{
            return of(false);
          }
        }),
        //timeout(MAX_TIME),
        catchError(data => of(false))
      );
  }

  public verifyDateEnd() {
    if (this.c_obj['date_init_work'] === undefined ) {
      (<HTMLInputElement> document.getElementById("fechaFinal")).value = "";
      return Swal.fire(this.translations.error,this.translations.msg_not_found_date_init, 'error');
    }
    const date_init = new Date(this.c_obj['date_init_work']);
    const date_end = new Date(this.c_obj['date_final_work']);
    this.diffDate = (date_end.getTime() - date_init.getTime()) / 86400000;
    if (date_init > date_end ){
      (<HTMLInputElement> document.getElementById("fechaFinal")).value = "";
      return Swal.fire(this.translations.error,this.translations.msg_date_end_max, 'error');
    }

    // if (this.diffDate > 7 ){
    //   return Swal.fire(this.translations.error,this.translations.msg_max_ranges_dates, 'error');
    // }


  }

  public goToToolList(obj) {
    let entryRequestId = obj.EntryRequestId;
    this.router.navigate(['regtoolsequipment/'],{ queryParams: { id: entryRequestId}});
  }

  public goToWorkRequest(obj) {
    let entryRequestId = obj.EntryRequestId;
    this.router.navigate(['repocreator/'],{ queryParams: { id: entryRequestId}});
  }

  public createItem(content:any, form:any): any{
    this.isCreating = true;
    var validated = true;
    if( form == undefined ){
      form = {};
    }
    form['emergency']=false;
    validated = this.validateCreateInfo(form);
    // if (this.diffDate > 7 ){
    //   this.isCreating = false;
    //   return Swal.fire(this.translations.error,this.translations.msg_max_ranges_dates, 'error');
    // }
    if( !validated ){
      this.isCreating = false;
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }
    if(form['emergency']){
      if(form['comment_emergency'] == "" || (form['who_responsable'] == undefined || form['who_responsable'] == null)) {
        this.isCreating = false;
        Swal.fire(this.translations['msg_all_required'], '', 'error');
        return;
      }
    }
    if (this.c_obj['worker'].length == 0) {
      this.isCreating = false;
      return Swal.fire(this.translations.error, this.translations.msg_worker_required,'error');
    }
    let array_jobs = [];
    for (let obj of form['worker']) {
      let jobs = {
        EmployeeId : obj['employeeId'],
        JobTypeID : obj['id_typejob']
      }
      array_jobs.push(jobs);
    };
    let CompanyID = 0;
    if (this.isSkretting) {
      CompanyID = 30;
    } else {
      CompanyID = this.userinfo.selectedCompanyId;
    }

    let new_request = {
      StartDate: form['date_init_work'],
      EndDte: form['date_final_work'],
      Status: "A",
      CreatorUser: this.userinfo['logon'],
      CompanyID: CompanyID,
      Risk: form['risk'] || false,
      Emergency: form['emergency'] || false,
      Commentary: form['comment_emergency'] || '',
      Responsable: form['who_responsable'] || '',
      Requiredby: form['job_applicant'],
      MailRequiredby: form['email_applicant'],
      WorkOrder: form['job_order'],
      WorkDescription: form['work_description'],
      PlantaID: form['level'].split('-')[0],
      SeccionID: form['job_area'].split('-')[0],
      Requiredbyarea: form['request_area'],
      EntryRequestDetails: array_jobs,
      Description:form['Description']
    }
    // console.log(new_request)
    let url_create = CREATE_ADMISSION_REQUEST;
    this.srv.postCall(url_create, new_request).toPromise().then( data =>{

      if(data.success){
        // console.log(data);
        this.c_obj = {};
        this.closeModal(content);
        if (!new_request.Emergency) {
          this.SendMail(data);
          Swal.fire(this.translations.msg_create, '', 'success');
        } else {
          this.SendMailToNotified(data);
          Swal.fire(this.translations.msg_create, this.translations.entry_request_created_to_notified, 'success');
        }
        this.getList();

        this.isCreating = false;
      } else {
        this.isCreating = false;
        Swal.fire(data.msg, '', 'error');
      }
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
      this.isCreating = false;
    });

  }

  public SendMail(data:any){
    var to:any;
    to = {};
    to = data;
    let qst = CREATE_QR_AND_SEND_EMAIL + to.val.EntryRequestId.toString();
    this.srv.postCall(qst ,to.val).subscribe(x => {
      this.isCreating = false;
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }

  public SendMailToNotified(data:any){
    var to:any;
    to = {};
    to = data;
    let qst = SEND_MAIL_TO_NOTIFIED_SKRETTING;
    this.srv.postCall(qst ,to.val).subscribe(x => {
      this.isCreating = false;
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }

  public onlyNumberKey(event: any): boolean {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }


  public GetTrabajadores()
  {
    // var qst = this.read_url;
    if(isNumeric(this.userinfo.logon) )
    {
      this.read_url = GET_EMPLOYEES_BY_COMPANY + this.userinfo.selectedCompanyId;
    }
    else{
      this.read_url = GET_EMPLOYEES_ALL_COMPANY;
    }
    var qst = this.read_url;
    this.srv.getCall(qst).subscribe(x => {
      this.workerList = [];
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.row_emp = rs;
          console.log(this.row_emp)
          for (let row of rs) {
            if (row.Status_faltas === 'SI') {
              row['status_Doc_Eval'] = 'SUSPENDIDO';
            } else if ((row.Status_doc === 'VALIDADO' && row.Status_evaluation === 'APROBADO')) {
              row['status_Doc_Eval'] = 'HABILITADO';
            } else if ((row.Status_doc != 'VALIDADO' || row.Status_evaluation != 'APROBADO')) {
              row['status_Doc_Eval'] = 'NO HABILITADO';
            }
            this.workerList.push(row);
          }
          this.page2 = 1;
          if( Array.isArray(this.row_emp) ){
            this.pageTotal2 = this.row_emp.length;
          }
        }else {
          //Swal.fire('¡Vacio!', 'La consulta no devolvio registros', 'success');
          this.row_emp = [];
          return;
        }
      }else {
        //Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public setChecked() {
    if (!this.c_obj['emergency']) {
      if (this.c_obj['comment_emergency']! == '' || this.c_obj['comment_emergency']!== null) {
        this.c_obj['comment_emergency'] = null
      }
      if (this.c_obj['who_responsable']! == '' || this.c_obj['who_responsable']!== null) {
        this.c_obj['who_responsable'] = null
      }
    }
  }


  public addParameter(obj){
    var validated = true;
    if( obj == undefined ){
      obj = {};
    }

    if( obj['jobs'] == undefined || obj['jobs'] == null || obj['jobs'] == ''){
        Swal.fire(this.translations['msg_all_required'], '', 'error');
        return;
    }
    let exists = obj['worker'].filter(f => {
      return (f.NumberIdentification ===  obj['NumberIdentification']);
    });

    // SE BUSCA SI EXISTE EL TIPO DE TRABAJO PARA ESE EMPLEADO
    if (obj['listWorksIdEmp'].indexOf(obj['jobs']) === -1) {
      if (obj['listWorksIdEmp'].length === 1) {
        return Swal.fire(this.translations.Error_Type_Job, this.translations.Type_job_habilitate + `: <br> ${obj['listWorksIdEmp'][0].split('-')[1]}`, 'error')
      } else {
        let works = '';
        for (let x of obj['listWorksIdEmp']) {
          works = works + x.split('-')[1] + ',';;
        }
        return Swal.fire(this.translations.Error_Type_Job, this.translations.Type_job_habilitate + `: <br> ${works.slice(0, -1)}`, 'error')
      }

    }
    // console.log('Existe')

    if(exists.length > 0)
    {
      this.c_obj['NumberIdentification'] = null;
      this.c_obj['NameEmployee'] = null;
      this.c_obj['namesubcompany'] = null;
      this.c_obj['position'] = null;
      return Swal.fire(this.translations.error, this.translations.identificaction_repeated,'error');
    }

    let worker_type = obj['jobs'].split('-');
    if (obj['worker'].length === 0) {
      obj['worker'].push(
        {
          NameEmployee: obj['NameEmployee'],
          NumberIdentification: obj['NumberIdentification'],
          jobtitle: obj['position'],
          globalparam_typejob: worker_type[1],
          id_typejob: worker_type[0],
          employeeId: obj['EmployeeId'],
          emergency: this.emergency_checked ? false: true
        }
      );
    } else {
      let count = 0;
      let typeEmergency = '';
      /*for (let element of obj['worker']) {
        if (element.emergency !== this.emergency_checked) {
          count ++;
          typeEmergency = element.emergency ? this.translations.emergency:  this.translations.no_emergency;
          break;
        }
      }*/
      // console.log(count)
      if (count > 0) {
        this.c_obj['NumberIdentification'] = null;
        this.c_obj['NameEmployee'] = null;
        this.c_obj['namesubcompany'] = null;
        this.c_obj['position'] = null;
        return Swal.fire(
          this.translations.error_creation_proadd,
          this.translations.cant_create_distinct_pro_add,
          'error');
      } else {
        obj['worker'].push(
          {
            NameEmployee: obj['NameEmployee'],
            NumberIdentification: obj['NumberIdentification'],
            jobtitle: obj['position'],
            globalparam_typejob: worker_type[1],
            id_typejob: worker_type[0],
            employeeId: obj['EmployeeId'],
            emergency: this.emergency_checked
          }
        );
      }
    }

    this.c_obj['NumberIdentification'] = null;
    this.c_obj['NameEmployee'] = null;
    this.c_obj['namesubcompany'] = null;
    this.c_obj['position'] = null;
  }

  public delParameter(obj:any, position:number): void{
    obj['worker'].splice(position, 1);

  }


  public GetEmployeeforIdentification(obj:any,val:any)
  {
    this.c_obj['listWorksIdEmp'] = [];
    ////debugger;
    var qst =  API_gET_EMPLOYEE_BY_IDENTIFICATIONREQUEST + obj;

    if (obj === "")
    {
      return;
    }



    //console.log("usuario logoneado:",this.userinfo)
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          if (this.c_obj['emergency']) {
            this.c_obj['NumberIdentification'] = x.val['NumberIdentification'];
            this.c_obj['NameEmployee'] = x.val['Name'];
            this.c_obj['namesubcompany'] = x.val['namesubcompany'];
            this.c_obj['position'] = x.val['position'];
            this.c_obj['EmployeeId'] = x.val['EmployeeId'];
            for (let object of x.val['_JobTypeEmployee']) {
              this.c_obj['listWorksIdEmp'].push(object.JobTypeID.toString() + '-'+ object.Name);
            };
            // this.emergency_checked = true;
          } else {
            //  console.log(x.val)
            if (x.val['Status_doc']!='VALIDADO' || x.val['Status_evaluation']!='APROBADO' ||  x.val['Status_faltas']!='NO')
            {
              Swal.fire(this.translations.error, this.translations.msg_hability_request_approval + " " + this.translations.approvals, 'error');
              this.c_obj['NumberIdentification'] = "";
              this.c_obj['NameEmployee'] = "";
              this.c_obj['namesubcompany'] = "";
              this.c_obj['position'] = "";
              this.c_obj['EmployeeId'] = "";
              this.c_obj['listWorksIdEmp'] = [];
              return;
            } else {
              this.c_obj['NumberIdentification'] = x.val['NumberIdentification'];
              this.c_obj['NameEmployee'] = x.val['Name'];
              this.c_obj['namesubcompany'] = x.val['namesubcompany'];
              this.c_obj['position'] = x.val['position'];
              this.c_obj['EmployeeId'] = x.val['EmployeeId'];
              for (let object of x.val['_JobTypeEmployee']) {
                this.c_obj['listWorksIdEmp'].push(object.JobTypeID.toString() + '-'+ object.Name);
              };
              // console.log(this.c_obj['listWorksIdEmp'])
            }
          }
        } else {
          return;
            }
      }else {
        return;
          }
    });


  }

  public GetPlantas() {
    var qst = GET_ALL_SITES_ACTIVE;
    this.srv.getCall(qst).subscribe(x => {
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.row_plantas = rs;
        }else {
          this.row_plantas = [];
          return;
        }
      }else {
        return;
          }
    });
  }

  public setLevelArea() {
    if (this.c_obj['level'] !== null) {
      var qst = GET_ALL_AREA_BY_ID + this.c_obj['level'].split('-')[0];
      this.srv.getCall(qst).subscribe(x => {
        if(x.success){
          if(x.val != null){
            var rs = x.val;
            this.row_plantas_area = rs;
          }else {
            this.row_plantas_area = [];
            return;
          }
        }else {
          return;
            }
      });
    } else {
      this.c_obj['job_area'] = null;
    }
  }

  public toggleEditable( event,EntryRequestId) {

    if ( event.target.checked ) {
      this.ActivarItem(EntryRequestId,1);
    }else {
      this.ActivarItem(EntryRequestId,2);

    }
  }

  public ActivarItem(id:any, accion:any): void
  {
    var to:any;
    to = {};
    to.EntryRequestId = id;
    to.Status = accion;
    to.LastModifierUser = this.userinfo['userId'];
    this.srv.postCall(UPDATE_STATUS__ADMISSION_REQUEST, to).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if(x.success){
        this.reload.next(true);
        Swal.fire(this.translations['MENSAJE'], x.msg, 'success');
        this.getList();
      }
      else{
          Swal.fire(x.msg, '', 'error');
      }
    }, err => {
        Swal.close();
        Swal.fire('Error');
    });
  }

  public deleteItem(obj:any): void {
    // console.log(obj);
    this.ActivarItem(obj.EntryRequestId,3);
  }

  public downloadQR(infoRequest) {
    // console.log(infoRequest);
  }

  public openUpdateModal(content, obj, size?) {
    this.GetTrabajadores();
    this.GetPlantas();
    this.c_obj = {};

    this.c_obj['date_init_work'] = obj.StartDate.split('T')[0];
    this.c_obj['date_final_work'] = obj.EndDte.split('T')[0];
    this.c_obj['job_order'] = obj.WorkOrder;
    this.c_obj['job_applicant'] = obj.Requiredby;
    this.c_obj['level'] = obj.PlantaID + '-' + obj.PlantaName;
    this.c_obj['job_area'] = obj.SeccionID + '-' + obj.SeccionName;
    this.c_obj['request_area'] = obj.Requiredbyarea;
    this.c_obj['work_description'] = obj.WorkDescription;
    this.c_obj['emergency'] = obj.Emergency || null;
    this.c_obj['comment_emergency'] = obj.Commentary || null;
    this.c_obj['who_responsable'] = obj.Responsable || '';
    this.c_obj['email_applicant'] = obj.MailRequiredby || '';
    this.c_obj['risk'] = obj.Risk;
    this.c_obj['worker'] = [];
    this.c_obj['EntryRequestId'] = obj.EntryRequestId;
    this.c_obj['Description'] = obj.Description;
    this.setLevelArea();
    // console.log(obj);

    for (let w of obj.RequestDetails){
      this.c_obj['worker'].push({
        NameEmployee: w['EmployeeName'],
        NumberIdentification: w['NumberIdentification'],
        jobtitle: w['position'],
        globalparam_typejob: w['JobtypeName'],
        id_typejob: w['JobTypeID'],
        employeeId: w['EmployeeId']
      })
    }

    this.modalService.open(content, { backdrop: 'static', size: size });
    this.GetTypeJob().subscribe(x => {
      this.loadingRequired = false;
      if(x != false){
          this.row_typejob = x;
      }
    });
  }

  public updateItem(modal, form) {
    this.isUpdating = true;
    if(form == undefined ){
      form = {};
    }

    if (form['email_applicant'] == '') {
      this.isUpdating = false;
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }

    if(form['emergency']){
      if(form['comment_emergency'] == "" || form['who_responsable'] == ''  ) {
        this.isUpdating = false;
        Swal.fire(this.translations['msg_all_required'], '', 'error');
        return;
      }
    }

    let array_jobs = [];
    for (let obj of form['worker']) {
      let jobs = {
        EmployeeId : obj['employeeId'],
        JobTypeID : obj['id_typejob']
      }
      array_jobs.push(jobs);
    };
    // console.log(form);
    let new_request = {
      StartDate: form['date_init_work'],
      EndDte: form['date_final_work'],
      Status: isNumeric(this.userinfo.logon)? "2":"1",
      CreatorUser: this.userinfo['logon'],
      CompanyID: this.userinfo.selectedCompanyId,
      Risk: form['risk'] || false,
      Emergency: form['emergency'] || false,
      Commentary: form['comment_emergency'] || '',
      Responsable: form['who_responsable'] || '',
      Requiredby: form['job_applicant'],
      WorkOrder: form['job_order'],
      WorkDescription: form['work_description'],
      PlantaID: form['level'].split('-')[0],
      SeccionID: form['job_area'].split('-')[0],
      Requiredbyarea: form['request_area'],
      EntryRequestDetails: array_jobs,
      EntryRequestId: form['EntryRequestId'],
      MailRequiredby: form['email_applicant'],
      Description: form['Description']
    }
    // console.log(new_request)

    if (this.isSkretting) {
      new_request.Status = '2';
      if ((new_request.Emergency) && (isNull(new_request.Commentary))) {
        this.isUpdating = false;
        return Swal.fire(this.translations.error, this.translations.msg_set_comments_person, 'error');
      }
    } else {
      new_request.Status = '1';
    }

    this.srv.postCall(UPDATE_ADMISSION_REQUEST, new_request).toPromise().then( data =>{
      if(data.success){
        this.c_obj = {};
        this.closeModal(modal);
        Swal.fire(this.translations.msg_update, '', 'success');
        this.isUpdating = false;
        this.getList();
      } else {
        this.isUpdating = false;
        Swal.fire(data.msg, '', 'error');
      }
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
      this.isUpdating = false;
    });
  }

  async dowloadRiskEvaluation(row){
    // console.log(row)
    if (row.Emergency && isNull(row.StatusConfirm)) {
      return Swal.fire(this.translations.request_emergency_is_null, '' , 'error');
    }

    let value = false;
    await Swal.fire({title:this.translations.Document_risk,text: this.translations.more_activities, icon: 'info', confirmButtonText: 'OK'})

    //lISTA DE TRABAJADORES
    let lista_trabajadores = this.convertDataToRow(row)
    const pdf = new PdfMakeWrapper();
    // INFORMATION IN TITLE NAV
    pdf.info({
      title: 'Evaluación de Riesgo',
      author: `${row.Lt_Supervisor}`,
      subject: 'Listado',
    });
    //OPTIONS
    pdf.defaultStyle({
      bold: false,
      fontSize: 10,
      alignment:'justify'
    });
    pdf.pageMargins([ 40, 75, 40, 60]);
    //PERMISIONS
    // pdf.permissions('Nutreco2021', {
    //   printing: 'highResolution',
    //   copying: false,
    //   modifying: false,
    //   annotating: true,
    //   fillingForms: true,
    //   documentAssembly: true,
    //   contentAccessibility: true
    // })
    pdf.header(await
      new Img('../../../../assets/images/logo-icon1.png')
      .width('75')
      //.alignment("right")
      .margin(20)
      .build()
    )

    // ENCABEZADO DE DOCUMENTO DE EVALUACION DE RIESGO
    pdf.add(new Txt('ANALISIS DE TAREAS DE RIESGO').fontSize(14).bold().margin([0,0,0,20]).alignment('center')['end']);
    // pdf.add(new Columns([new Columns([ new Txt('Actividad:').bold().end, row.WorkDescription]).end , '' ]).end);
    pdf.add(new Columns([ new Txt('Actividad:').bold().width(60).end, row.WorkDescription]).end);
    pdf.add(new Columns([new Columns([ new Txt('Elaborado por:').width(75).bold().end, row.Requiredby ]).end , new Columns([ new Txt('Area:').width(60).bold().end, row.SeccionName ]).end ]).end);
    pdf.add(new Columns([new Columns([ new Txt('Cargo:').width(60).bold().end, '' ]).end , new Columns([ new Txt('SubArea:').width(60).bold().end, row.Requiredbyarea ]).end ]).end);
    pdf.add(new Columns([new Columns([ new Txt('Compañia:').width(60).bold().end, row.CompanyName ]).end , new Columns([ new Txt('Fecha:').width(60).bold().end, row.StartDate.split('T')[0] ]).end  ]).end);

    // CUERPO DE DOCUMENTO DE EVALUACION DE RIESGO
    pdf.add(
      [
        new Table([
          [
            new Cell( new Stack([ new Txt('Paso a paso').bold().end, new Txt('(¿Qué se va a hacer?, Hace una descripción ordenada de todas las actividades que se requieren para realizar correctamente la tarea)').fontSize(7).end]).end ).end,
            new Cell( new Stack([ new Txt('Peligro - consecuencia').bold().end, new Txt('(Característica inherente al medio que puede generar una lesión y/ó daño ambiental) ').fontSize(7).end]).end ).end,
            new Cell( new Stack([ new Table([[new Cell(new Txt('Evaluación').bold().end).border([false,false,false,true]).end]]).widths([90]).layout({defaultBorder:false}).end ,new Table([[new Cell(new Txt('Pr').end).border([false,false,true,false]).end,new Cell(new Txt('Sv').end).border([false,false,true,false]).end,new Cell(new Txt('Riesgo Pr * Sv').end).end]]).widths([22,22,30]).layout({defaultBorder:false}).end]).end ).end,
            new Cell( new Stack([ new Txt('Controles Aplicables').bold().end, new Txt('(Qué medidas se tomaran para evitar que algo salga mal o falle?)').fontSize(7).end]).end ).end,
            new Cell( new Stack([ new Txt('EPP').bold().end, new Txt('(Descripción de los equipos de protección aplicables para la actividad a realizar) ').fontSize(7).end]).end ).end
          ],
          ...this.getRowsTable(5)

        ])
        .alignment("center")
        .widths([95,95,100,90,90])
        .margin([0,20,0,20]).end,
        new Columns([new Columns([ new Txt('Elaborado por:').bold().end, '____________________' ]).end , new Columns([ new Txt('Aprobado por (Nutreco):').bold().end, '____________________' ]).end ]).margin([0,0,0,15]).end,
        new Columns([new Columns([ new Txt('Fecha:').bold().end, '____________________' ]).end , new Columns([ new Txt('Fecha:').bold().end, '____________________' ]).end ]).end,

        new Table([
          [
            new Cell( new Stack([ new Txt('Paso a paso').bold().end, new Txt('(¿Qué se va a hacer?, Hace una descripción ordenada de todas las actividades que se requieren para realizar correctamente la tarea)').fontSize(7).end]).end ).end,
            new Cell( new Stack([ new Txt('Peligro - consecuencia').bold().end, new Txt('(Característica inherente al medio que puede generar una lesión y/ó daño ambiental) ').fontSize(7).end]).end ).end,
            new Cell( new Stack([ new Table([[new Cell(new Txt('Evaluación').bold().end).border([false,false,false,true]).end]]).widths([90]).layout({defaultBorder:false}).end ,new Table([[new Cell(new Txt('Pr').end).border([false,false,true,false]).end,new Cell(new Txt('Sv').end).border([false,false,true,false]).end,new Cell(new Txt('Riesgo Pr * Sv').end).end]]).widths([22,22,30]).layout({defaultBorder:false}).end]).end ).end,
            new Cell( new Stack([ new Txt('Controles Aplicables').bold().end, new Txt('(Qué medidas se tomaran para evitar que algo salga mal o falle?)').fontSize(7).end]).end ).end,
            new Cell( new Stack([ new Txt('EPP').bold().end, new Txt('(Descripción de los equipos de protección aplicables para la actividad a realizar) ').fontSize(7).end]).end ).end
          ],
          ...this.getRowsTable(6)

        ])
        .pageBreak('before')
        .alignment("center")
        .widths([95,95,100,90,90])
        .margin([0,20,0,20]).end,
        new Columns([new Columns([ new Txt('Elaborado por:').bold().end, '____________________' ]).end , new Columns([ new Txt('Aprobado por (Nutreco):').bold().end, '____________________' ]).end ]).margin([0,0,0,15]).end,
        new Columns([new Columns([ new Txt('Fecha:').bold().end, '____________________' ]).end , new Columns([ new Txt('Fecha:').bold().end, '____________________' ]).end ]).end
      ]
    )



    pdf.add(
      [
        new Txt('ANALISIS DE TAREAS DE RIESGO').fontSize(14).bold().margin([0,0,0,20]).alignment('center').pageBreak('before').end,
        new Txt('*CONFIRMO HABER LEIDO Y ENTENDIDO LOS PELIGROS Y LOS CONTROLES DEL TRABAJO A REALIZAR*').bold().alignment('left').end,
        new Table(
          [
            [
              new Txt('FIRMA DE LOS TRABAJADORES').bold().end, new Txt('CEDULA DE LOS TRABAJADORES').bold().end
            ],
            ...lista_trabajadores,
            // row.Emergency == true ? ['',new Txt('TRABAJADOR POR EMERGENCIA').bold().margin([0,20,0,0]).end]: ['',''],
            // row.Emergency == true ? ['',new Txt(row.Commentary).margin([0,10,0,0]).end]: ['','']
          ]
        )
        .margin([0,15,0,15])
        .layout('noBorders')
        .end,
        new Txt('RECOMENDACIONES PARA DESARROLLAR LA EVALUACIÓN DE RIESGOS LABORALES EN TAREAS NO RUTINARIAS Y TAREAS DE ALTO RIESGO')
        .fontSize(14)
        .bold()
        .margin([0,0,0,20])
        .alignment('center')
        .pageOrientationAndBreak("landscape","before").end,
        new Txt([new Txt('Paso a Paso de la tarea : ').margin([0,0,10,0]).width('auto').bold().end, new Txt('Hace una descripción ordenada de todas las actividades que se requieren para realizar correctamente la tarea').end]).end,
        new Txt([new Txt('Peligro Consecuencia : ').margin([0,0,10,0]).width('auto').bold().end, new Txt('¿Qué energías potencias estan presentes durante la actividad?, ¿Qué situación o fuente podría desencadenar en una consecuencia potencial?, ¿Qué comportamiento de las personas podrían afectar?').end]).end,
        new Txt([new Txt('Grado de Peligro : ').margin([0,0,10,0]).width('auto').bold().end, new Txt('¿Qué resultado (a la persona y/o al Medio ambiente) tenemos si algo sale mal o falla?').end]).end,
        new Txt([new Txt('Controles Aplicables Existente : ').margin([0,0,10,0]).width('auto').bold().end, new Txt('¿Qué podemos hacer para evitar que algo salga mal o falle (controles administrativos, señalización, demarcación, cumplimiento de procedimientos operativos, procedimientos)?').end]).end,
        new Txt([new Txt('EPP : ').margin([0,0,10,0]).width('auto').bold().end, new Txt('Descripción de los equipos de protección aplicables para la actividad a realizar.').end]).end,
        new Table(
          [
            [
              new Cell( new Txt('PROBABILIDAD (Pr)').margin([0,5,0,5]).end).end,
              new Cell( new Txt('SEVERIDAD (Sv)').margin([0,5,0,5]).end).end,
              new Cell( new Txt('GRADO DE PELIGROSIDAD (GP)').margin([0,5,0,5]).end).end,
            ],
            [
              new Txt([new Txt('ALTO: ').margin([0,0,0,5]).bold().end, new Txt('Para eventos que han ocurrido').end]).margin([0,15,0,15]).alignment('left').end,
              new Txt([new Txt('ALTO: ').margin([0,0,0,5]).bold().end, new Txt('Fatalidad o incapacidad permanente, medios de comunicación involucrados y comunidad').end]).margin([0,15,0,15]).alignment('left').end,
              new Cell(
                await new Img('../../../../assets/images/risk_graph.png').fit([300,350]).build()
              )
              .rowSpan(2)
              .end
            ],
            [
              new Txt([new Txt('MEDIO: ').margin([0,0,0,5]).bold().end, new Txt('Para eventos que no han ocurrido pero es posible que sucedan').end]).margin([0,15,0,15]).alignment('left').end,
              new Txt([new Txt('MEDIO: ').margin([0,0,0,5]).bold().end, new Txt('Con incapacidad temporal de un dia o más, casos de tratamiento médico y reubicación laboral.').end]).margin([0,15,0,15]).alignment('left').end
            ],
            [
              new Txt([new Txt('BAJO: ').margin([0,0,0,5]).bold().end, new Txt('Para eventos que nunca han sucedido o que tienen escasa posibilidad de que sucedan').end]).margin([0,15,0,15]).alignment('left').end,
              new Txt([new Txt('BAJO: ').margin([0,0,0,5]).bold().end, new Txt('Casos de primeros auxilios (lesiones leves), incidentes(cuasi accidentes y daños materiales)').end]).margin([0,15,0,15]).alignment('left').end,
              new Stack(
                [
                  new Txt([ new Txt('Grado de peligrosidad (GP) : ').margin([0,0,0,5]).bold().end,  new Txt('Ver tabla de arriba.').margin([0,0,0,5]).end]).end,
                  new Txt([ new Txt('Bajo (B) : ').margin([0,0,0,5]).bold().end,  new Txt('Mantener las medidas de control existentes, se debe hacer comprobaciones periodica para asegurar que el riesgo se mantiene bajo.').margin([0,0,0,5]).end]).end,
                  new Txt([ new Txt('Alto (A) y Medio (M) : ').margin([0,0,0,5]).bold().end,  new Txt('Requiere controles adicionales, caso contrario no se podrá ejecutar la tarea.').margin([0,0,0,5]).end]).end,
              ]).margin([0,15,0,15]).alignment('left').end,
            ]
          ]
        )
        .margin([0,15,0,0])
        .alignment('center')
        .widths(['auto', 'auto','auto'])
        .end
      ]
    );
    pdf.create().open();
  }

  convertDataToRow (data) {
    return data.RequestDetails.map((row, index) => ['', new Txt(row.NumberIdentification).margin([0,10,0,0]).end]);
  }

  getRowsTable (value) {
    let l = [];
    for ( let i = 0; i < value; i++) {
      let li = [
        new Cell(new Txt(' ').margin([0,35,0,35]).end).end,
          new Cell(new Txt(' ').margin([0,35,0,35]).end).end,
          new Cell(new Table([[new Cell(new Txt('').end).border([false,false,true,false]).end,new Cell(new Txt('').end).border([false,false,true,false]).end,new Cell(new Txt('').end).end]]).heights((row: number) => {return 60}).layout({defaultBorder:false}).widths([22,22,25]).end).end,
          new Cell(new Txt(' ').margin([0,35,0,35]).end).end,
          new Cell(new Txt(' ').margin([0,35,0,35]).end).end
      ]
      l.push(li);
    }
    return l;
  }
}


/*

new Txt('RECOMENDACIONES PARA DESARROLLAR LA EVALUACIÓN DE RIESGOS LABORALES EN TAREAS NO RUTINARIAS Y TAREAS DE ALTO RIESGO')
        .fontSize(14)
        .bold()
        .margin([0,0,0,20])
        .alignment('center')
        .pageOrientationAndBreak("portrait","before").end,
        new Stack(
          [
            new Txt('Paso a Paso de la tarea :').margin([0,0,10,0]).width(100).bold().end,
            new Ul(['Hace una descripción ordenada de todas las actividades que se requieren para realizar correctamente la tarea']).margin([20,0,0,0]).end
          ]
        )
        .margin([0,10,0,0])
        .fontSize(10)
        .end,
        new Stack(
          [
            new Txt('Peligro - Consecuencia :').margin([0,0,10,0]).width(100).bold().end,
            new Ul(
              [
                '¿Qué energías potencias estan presentes durante la actividad?',
                '¿Qué situación o fuente podría desencadenar en una consecuencia potencial?',
                '¿Qué comportamiento de las personas podrían afectar?'
              ]
            ).margin([20,0,0,0]).end
          ]
        )
        .margin([0,10,0,0])
        .fontSize(10)
        .end,
        new Stack(
          [
            new Txt('Grado de Peligro :').margin([0,0,10,0]).width(100).bold().end,
            new Ul(
              [
                '¿Qué resultado (a la persona y/o al Medio ambiente) tenemos si algo sale mal o falla?'
              ]
            ).margin([20,0,0,0]).end
          ]
        )
        .margin([0,10,0,0])
        .fontSize(10)
        .end,
        new Stack(
          [
            new Txt('Controles Aplicables Existente :').margin([0,0,10,0]).width(100).bold().end,
            new Ul(
              [
                '¿Qué resultado (a la persona y/o al Medio ambiente) tenemos si algo sale mal o falla?'
              ]
            ).margin([20,0,0,0]).end
          ]
        )
        .margin([0,10,0,0])
        .fontSize(10)
        .end,
        new Stack(
          [
            new Txt('EPP :').margin([0,0,10,0]).width(100).bold().end,
            new Ul(
              [
                '¿Qué podemos hacer para evitar que algo salga mal o falle (controles administrativos, señalización, demarcación, cumplimiento de procedimientos operativos, procedimientos)?'
              ]
            ).margin([20,0,0,0]).end
          ]
        )
        .margin([0,10,0,0])
        .fontSize(10)
        .end,
        new Table(
          [
            [
              new Cell( new Txt('PROBABILIDAD (Pr)').margin([0,5,0,5]).end).end,
              new Cell( new Txt('SEVERIDAD (Sv)').margin([0,5,0,5]).end).end,
              new Cell( new Txt('GRADO DE PELIGROSIDAD (GP)').margin([0,5,0,5]).end).end,
            ],
            [
              new Stack([new Txt('ALTO').margin([0,0,0,5]).bold().end, new Txt('Para eventos que han ocurrido').fontSize(7).alignment('justify').end]).margin([0,35,0,35]).end,
              new Stack([new Txt('ALTO').margin([0,0,0,5]).bold().end, new Txt('Fatalidad o incapacidad permanente, medios de comunicación involucrados y comunidad').fontSize(7).alignment('justify').end]).margin([0,35,0,35]).end,
              new Cell(
                await new Img('../../../../assets/images/risk_graph.png').fit([300,350]).margin([0,35,0,35]).build()
              )
              .rowSpan(2)
              .end
            ],
            [
              new Stack([new Txt('MEDIO').margin([0,0,0,5]).bold().end, new Txt('Para eventos que no han ocurrido pero es posible que sucedan').fontSize(7).alignment('justify').end]).margin([0,35,0,35]).end,
              new Stack([new Txt('MEDIO').margin([0,0,0,5]).bold().end, new Txt('Con incapacidad temporal de un dia o más, casos de tratamiento médico y reubicación laboral.').fontSize(7).alignment('justify').end]).margin([0,35,0,35]).end
            ],
            [
              new Stack([new Txt('BAJO').margin([0,0,0,5]).bold().end, new Txt('Para eventos que nunca han sucedido o que tienen escasa posibilidad de que sucedan').fontSize(7).alignment('justify').end]).margin([0,35,0,35]).end,
              new Stack([new Txt('BAJO').margin([0,0,0,5]).bold().end, new Txt('Casos de primeros auxilios (lesiones leves), incidentes(cuasi accidentes y daños materiales)').fontSize(7).alignment('justify').end]).margin([0,35,0,35]).end,
              new Stack([
                          new Txt('Grado de peligrosidad (GP) : Ver tabla de arriba.').margin([0,0,0,5]).bold().end,
                          new Txt('Bajo (B) : Mantener las medidas de control existentes, se debe hacer comprobaciones periodica para asegurar que el riesgo se mantiene bajo.').fontSize(7).alignment('justify').end,
                          new Txt('Alto (A) y Medio (M) : Requiere controles adicionales, caso contrario no se podrá ejecutar la tarea. ').fontSize(7).alignment('justify').end,
                        ]).margin([0,35,0,35]).end,
            ]
          ]
        )
        .margin([0,15,0,0])
        .alignment('center')
        .widths([95, 95,'*'])
        .end



*/
