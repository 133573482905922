import { Component, OnInit } from '@angular/core';
import { BaseCrudComponent } from 'src/app/ic2/base-crud/base-crud.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/services/main/global.service';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/authentication/login.service';
const GET_ALL_SITES_ACTIVE = environment.Global.API_GET_ALL_SITES_ACTIVE;
const API_CREATE_PLANACTION = environment.Global.API_CREATE_PLANACTION;
const API_UPDATE_PLANACTION= environment.Global.API_UPDATE_PLANACTION;
const API_GET_PLANACTION_BY_USER = environment.Global.API_GET_PLANACTION_BY_USER;
const API_GET_UNSAFEACTBYID = environment.Global.API_GET_UNSAFEACTBYID;
const API_ID_BETTER = environment.Global.API_ID_BETTER;
const API_ID_UNSAFECONDITION = environment.Global.API_ID_UNSAFECONDITION;
const API_USER_SYSTEM_GET_ALL = environment.Global.API_USER_SYSTEM_GET_ALL;
const API_CONSULTAS_GENERALES_INCIDENCES_ALL = environment.Global.API_CONSULTAS_GENERALES_INCIDENCES_ALL;
const API_CONSULTAS_GENERALES_INCIDENCES_BY_USER = environment.Global.API_CONSULTAS_GENERALES_INCIDENCES_BY_USER;
const API_ALL_PLANACTION = environment.Global.API_ALL_PLANACTION;
const API_UPDATE_APPROVE_REJECT_ACTION = environment.Global.API_UPDATE_APPROVE_REJECT_ACTION;
const API_DELETE_PLAN_ACTION = environment.Global.API_DELETE_PLAN_ACTION;
const API_CLOSE_PLAN_ACTION = environment.Global.API_CLOSE_PLAN_ACTION;
@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.css']
})
export class PlanComponent extends BaseCrudComponent implements OnInit {
  userinfo: any;
  modalCreate = false;
  row_plantas_area = [];
  new_Action;
  row_plantas = [];
  evidencias = [];
  id = null;
  typeForm;
  typePlan = null;
  typePlanEsp = null;
  inspectionObject = null;
  listaAcciones = [];
  newActionsList = [];
  pageTotal2 = 0;
  pageTotal3 = 0;
  pageTotal4 = 0;
  page3 = 0;
  page2 = 0;
  page4 = 0;
  pageSize3;
  pageSize4;
  flag = true;
  flagprog = true;
  close_object : any;
  contador = 0;
  cantidadFotos = 0;
  isClosing = false;
  existInspection = false;
  evidenceForActionImage: any;
  habilitarAct = true;
  creoPlan = false;
  userSystemList = [];
  optioncreate = false;
  permissions;
  incidencesList = [];
  accionporplan;
  modalReference: NgbModalRef;
  closeResult;
  isApproved = false;
  isRejected = false;
  contElimiActions = 0;
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    public auth: LoginService,
    private globalService: GlobalService,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
  }

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
      .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
        });
      
        // this.getTypeFouls();
    this.search_fields = ['ActionPlanId','PlantaName'];
    this.storage.get('userinfo').then(x => {
      this.userinfo = x;
      
    });
    this.pageSize2 = 3;
    this.pageSize3 = 3;
    this.pageSize4 = 3;
    this.page2 = 1;
    this.page3 = 1;
    this.page4 = 1;
    this.GetPlantas();
    this.auth.getPermissions().then(x => {
      this.permissions = x;
      this.getList();
    });
  }

  public getUserSystem() {
    this.userSystemList = [];
    this.srv.getCall(API_USER_SYSTEM_GET_ALL).subscribe(x => {
      if(x.success){
        if(x.val != null){
          x.val.forEach(element => {
            element.Permissions = JSON.parse(element.Permissions);
            if (element.Permissions != null) {
              element.Permissions.Modules.forEach(permission => {
                if(permission.Name == "SI_OPORTUNITEVALEXT") {
                  this.userSystemList.push(element)
                }
              });
            }    
          })
        }else {
          Swal.fire(this.translations.empty, this.translations.not_user_registers, 'success');
          this.userSystemList = [];
          return;
        }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public getList() {
    this.loadingList = true;
    if (this.permissions.includes('SI_ADMIN')) {
      this.srv.getCall(API_ALL_PLANACTION).subscribe(x => {
        this.loadingList = false; 
        if(x.success){
          if(x.val != null){
            this.rawdata = x.val;
            this.rows = this.rawdata;
            // console.log(this.rows)
            this.page = 1;
            if( Array.isArray(this.rows) ){
              this.pageTotal = this.rows.length;
            }
            this.filterAll();
            if(this.flag) {
              Swal.close()
            }            
          }else {
            Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
            this.rows = [];
            return;
              }
        }else {
          Swal.fire(x.msg, '', 'error');
          return;
        }
      });
    } else {
      this.srv.getCall(API_GET_PLANACTION_BY_USER + this.userinfo['logon']).subscribe(x => {
        this.loadingList = false; 
        if(x.success){
          if(x.val != null){
            this.rawdata = x.val;
            this.rows = this.rawdata;
            // console.log(this.rows)
            this.page = 1;
            if( Array.isArray(this.rows) ){
              this.pageTotal = this.rows.length;
            }
            this.filterAll();
            if(this.flag) {
              Swal.close()
            }            
          }else {
            Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
            this.rows = [];
            return;
              }
        }else {
          Swal.fire(x.msg, '', 'error');
          return;
        }
      });
    }   
  }


  public openCreateModal(content: any) {
    this.typeForm = '';
    this.getUserSystem();
    this.getIncidencesByTypeUser();
    this.modalCreate = true;
    this.c_obj = {};
    this.modalService.open(content, { backdrop: 'static',  keyboard : false, size: 'lg' });
  }

  public getIncidencesByTypeUser() {
    let url = '';
    if (this.permissions.includes('SI_ADMIN')) {
      url = API_CONSULTAS_GENERALES_INCIDENCES_ALL;
    } else {
      url = API_CONSULTAS_GENERALES_INCIDENCES_BY_USER + this.userinfo['logon']
    }
    this.srv.getCall(url).toPromise().then(x => {
      if(x.success){
        if(x.val != null){
          this.incidencesList = x.val;
        }else {
          this.incidencesList = [];
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public setIncidence() {
    this.id = this.c_obj['incidence'].split('-')[1];
    this.typeForm = this.c_obj['incidence'].split('-')[0];
    // se recorre la lista para saber que usuario tiene asignado la incidencia
    let value = 0;
    this.incidencesList.forEach(element => {
      if (element.incidenceId == this.id && element.typeIncidence == this.typeForm) {
        if (element.userResponsable !== this.userinfo['logon']) {
          value = 1;
        }
      }
    })
    if (value == 1) {
      this.c_obj['level'] = null;
      this.c_obj['levelcode'] = null;
      this.c_obj['areacode'] = null;
      this.c_obj['area'] = null;
      this.c_obj['Reportby'] = null;
      this.c_obj['date_ha'] = null;
      this.c_obj['justificative'] = null;
      this.c_obj['invertion'] = null;
      this.c_obj['detail_plan'] = null;
      this.c_obj['Observation'] = null;
      this.c_obj['paramDetailDescription'] = null;
      return Swal.fire(this.translations.dont_create_plan_user,this.translations.should_modify_user_Responsable_into_incidence,'error');
    } else {
      this.getInformationByType();
    }
  }

  public async getInformationByType() {    
    Swal.fire(
      {
        title: this.translations.loading,
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    switch (this.typeForm) {
      case 'Acto inseguro':
        this.typePlan = 'menu_act_nosecure';
        this.typePlanEsp = 'Acto inseguro';
        await this.getUnsafeAct();
        break;
      case 'Condición insegura':
        this.typePlan = 'menu_condi_nosecure';
        this.typePlanEsp = 'Condición insegura';
        await this.getUnsafeCondition();
        break;
      case 'Mejora u Oportunidad':
        this.typePlan = 'menu_oportunity_mejor';
        this.typePlanEsp = 'Mejora u Oportunidad';
        await this.getOportunity();
        break;
    }
    
    Swal.close();
    this.c_obj['level'] = this.inspectionObject['PlantaName'];
    this.c_obj['levelcode'] = this.inspectionObject['PlantaID'];
    this.c_obj['areacode'] = this.inspectionObject['SeccionID'];
    this.c_obj['area'] = this.inspectionObject['SeccionName'];
    this.c_obj['Reportby'] = this.inspectionObject['Reportby'] == undefined ? this.inspectionObject['CreatorUser'] : this.inspectionObject['Reportby'];
    this.c_obj['date_ha'] = this.inspectionObject['Date'].split('T')[0];
    this.c_obj['justificative'] = this.typePlanEsp ;
    this.c_obj['invertion'] = 0;
    // console.log(this.typeForm)
    if (this.typeForm == 'Acto inseguro' || this.typeForm == 'Condición insegura') {
      this.c_obj['paramDetailDescription'] = this.inspectionObject['paramDetailDescription'];
    } else if (this.typeForm == 'Mejora u Oportunidad' ){
      this.c_obj['Observation'] = this.inspectionObject['Observation'];
    }
  }

  public async getUnsafeAct() {
    await this.srv.getCall(API_GET_UNSAFEACTBYID + this.id).toPromise().then(x => {
      if(x.success){
        if(x.val != null){
          this.inspectionObject = x.val[0];
          // console.log(this.inspectionObject)
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public async getUnsafeCondition() {
    await this.srv.getCall(API_ID_UNSAFECONDITION + this.id).toPromise().then(x => {
      if(x.success){
        if(x.val != null){
          this.inspectionObject = x.val[0];
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public async getOportunity() {
    await this.srv.getCall(API_ID_BETTER + this.id).toPromise().then(x => {
      if(x.success){
        if(x.val != null){
          this.inspectionObject = x.val[0];
          // console.log(this.inspectionObject)
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public addToTable(object) {
    let validated = this.validateActionDetail(object);
    if( !validated ){
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }
    const indice = this.listaAcciones.length + 1;
    let userName = '', responsableNa = ''; 
    this.userSystemList.forEach(element => {  
      if (element.Email == this.c_obj['responsable'] ) {
        // console.log(element)
        userName = element.Username;
        responsableNa = element.FirstName + ' ' + element.LastName;
      }
    })
    if(this.listaAcciones.length > 0) {
      let cont = 0;
      this.listaAcciones.forEach(element => {
        if(element.Action === object['actionCon']) {
          cont = 1;
        }
      })
      if(cont == 0) {
        this.listaAcciones.push(
          {
            Nro: indice,
            Action: this.c_obj['actionCon'],
            Details: this.c_obj['detailEject'],
            Observation: this.c_obj['observation'],
            Responsable: this.c_obj['responsable'],
            InitDate: this.c_obj['date_planea_init'],
            PlanEndDate: this.c_obj['date_planea_end'],
            PlanProgDate: null,
            PlanProgDate2: null,
            EndDate: this.c_obj['date_planea_end'],
            DateApproved: null,
            StatusEvidence: 'P',
            UserName: userName,
            StatusPlanDetail : "A",
            ResponsableName: responsableNa
          }
        );
      } else {
        return Swal.fire(this.translations['action_repeated'], '', 'error');
      }
    } else {
      this.listaAcciones.push(
        {
          Nro: indice,
          Action: this.c_obj['actionCon'],
          Details: this.c_obj['detailEject'],
          Observation: this.c_obj['observation'],
          Responsable: this.c_obj['responsable'],
          InitDate: this.c_obj['date_planea_init'],
          PlanEndDate: this.c_obj['date_planea_end'],
          PlanProgDate: null,
          PlanProgDate2: null,
          EndDate: this.c_obj['date_planea_end'],
          DateApproved: null,
          StatusEvidence: 'P',
          UserName: userName,
          StatusPlanDetail : "A",
          ResponsableName: responsableNa
        }
      );
    }
    this.c_obj['actionCon'] = null;
    this.c_obj['detailEject'] = null;
    // this.c_obj['observation'] = null; La observacion no e borra porque puede ser general
    this.c_obj['date_planea_init'] = null;
    this.c_obj['date_planea_end'] = null;
    this.c_obj['date_fini'] = null;
    this.c_obj['responsable'] = null;

    if( Array.isArray(this.listaAcciones) ){
      this.pageTotal2 = this.listaAcciones.length;
    }
    
  }

  public addNewActionsToTable(action) {
    // console.log(action)
    action['ActionPlanId'] = this.close_object['ActionPlanId'];
    let validated = this.validateActionDetail(action);
    if( !validated ){
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }
    let responsableName = '', userName = ''; 
    this.userSystemList.forEach(element => {     
      if (element.Email == action['responsable'] ) {
        responsableName =  element.FirstName + ' ' + element.LastName;
        userName = element.Username;
      }
    })
    const indice = this.newActionsList.length + 1;
    if(this.newActionsList.length > 0) {
      let cont = 0;
      this.newActionsList.forEach(element => {
        if(element.Action === action['actionCon']) {
          cont = 1;
        }
      })
      if(cont == 0) {
        this.newActionsList.push(
          {
            Nro: indice,
            Action: action['actionCon'],
            ActionPlanId: action['ActionPlanId'],
            Details: action['detailEject'],
            Observation: action['observation'],
            Responsable: action['responsable'],
            InitDate: action['date_planea_init'],
            PlanEndDate: action['date_planea_end'],
            PlanProgDate: null,
            PlanProgDate2: null,
            EndDate: action['date_planea_end'],
            DateApproved: null,
            StatusEvidence: 'P',
            ResponsableName: responsableName,
            UserName: userName
          }
        );
      } else {
        return Swal.fire(this.translations['action_repeated'], '', 'error');
      }
    } else {
      this.newActionsList.push(
        {
          Nro: indice,
          Action: action['actionCon'],
          ActionPlanId: action['ActionPlanId'],
          Details: action['detailEject'],
          Observation: action['observation'],
          Responsable: action['responsable'],
          InitDate: action['date_planea_init'],
          PlanEndDate: action['date_planea_end'],
          PlanProgDate: null,
          PlanProgDate2: null,
          EndDate: action['date_planea_end'],
          DateApproved: null,
          StatusEvidence: 'P',
          ResponsableName: responsableName,
          UserName: userName
        }
      );
    }
    this.new_Action['actionCon'] = null;
    this.new_Action['detailEject'] = null;
    this.new_Action['date_planea_init'] = null;
    this.new_Action['date_planea_end'] = null;
    this.new_Action['date_fini'] = null;
    this.new_Action['responsable'] = null;
    this.new_Action['observation'] = null;
    if( Array.isArray(this.newActionsList) ){
      this.pageTotal3 = this.newActionsList.length;
    }
  }

  public deleteAction ( indice ) {
    if (this.listaAcciones.length > 0 && Array.isArray(this.listaAcciones)) {
      let i = 0;
      for (let index = 0; index < this.listaAcciones.length; index++) {
        const element = this.listaAcciones[index];
        if (element.Nro == indice) {
          i = index;
        }
      }
      this.listaAcciones.splice(i, 1);
      this.pageTotal2 = this.listaAcciones.length;
    }
  }

  public deleteNewActionEditPlan ( indice ) {
    if (this.newActionsList.length > 0 && Array.isArray(this.newActionsList)) {
      let i = 0;
      for (let index = 0; index < this.newActionsList.length; index++) {
        const element = this.newActionsList[index];
        if (element.Nro == indice) {
          i = index;
        }
      }
      this.newActionsList.splice(i, 1);
      this.pageTotal3 = this.newActionsList.length;
    }
  }

  public setEndDate () {
    this.c_obj['date_fini'] = this.c_obj['date_planea_end']
  }

  public setEndDateNewAction () {
    this.new_Action['date_fini'] = this.new_Action['date_planea_end'];
  }

  public GetPlantas() {
    var qst = GET_ALL_SITES_ACTIVE;
    this.srv.getCall(qst).subscribe(x => {
      this.row_plantas = [];
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.row_plantas = rs;
        }else {
          this.row_plantas = [];
          return;
        }
      }else {
        return;
          }
    });
  }

  public validateCreateInfo(fields:any): boolean {
    let form:any = {};
    form = new FormGroup({
        'level': new FormControl(fields.level, [Validators.required]),
        'area': new FormControl(fields.area, [Validators.required]),
        'date_ha': new FormControl(fields.date_ha, [Validators.required]),
        'justificative': new FormControl(fields.justificative, [Validators.required]),
        'detail_plan': new FormControl(fields.detail_plan, [Validators.required]),
    });
    return form.valid;
  }

  public validateActionDetail(fields:any): boolean {
    let form:any = {};
    form = new FormGroup({
        'actionCon': new FormControl(fields.actionCon, [Validators.required]),
        'detailEject': new FormControl(fields.detailEject, [Validators.required]),
        'responsable': new FormControl(fields.responsable, [Validators.required]),
        // 'observation': new FormControl(fields.observation, [Validators.required]),
        'date_planea_init': new FormControl(fields.date_planea_init, [Validators.required]),
        'date_planea_end': new FormControl(fields.date_planea_end, [Validators.required]),
    });
    return form.valid;
  }


  public createItem(modal, object) {
    this.isCreating = true;
    let validated = this.validateCreateInfo(object);
    if( !validated ){
      this.isCreating = false;
      Swal.fire(this.translations['msg_all_required'], '', 'error');
      return;
    }

    let new_obj = {
      PlantaID: object['levelcode'],
      SeccionID: object['areacode'],
      FindingDate: object['date_ha'],
      Justification: object['justificative'],
      Investment: object['invertion'],
      ActionPlanDetails: this.listaAcciones,
      CreatorUser: this.userinfo['logon'],
      InspectionID: this.id,
      Detail: object['detail_plan']
    }
    // console.log(new_obj)
    // console.log(this.listaAcciones);
    this.srv.postCall(API_CREATE_PLANACTION, new_obj).toPromise().then(  (data)=> {
      if(data.success){
        this.c_obj = {};
        this.listaAcciones = [];
        this.flag = false;
        Swal.fire(this.translations.msg_create,'', 'success');
        this.getList();
        this.isCreating = false;
        this.creoPlan = true;
        this.closeModal(modal);
      } else {
        this.isCreating = false;
        Swal.fire(data.msg, '', 'error');
      }      
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
      this.isCreating = false;
    });

  }


  public updateItem(modal, object) {
    this.isUpdating = true;
    let new_obj = {
      ActionPlanId: object['ActionPlanId'],
      ActionPlanDetails: object['ActionPlanDetails'],
      LastModifierUser:  this.userinfo['logon'],
      NewActionsList: this.newActionsList
    }
    // console.log(new_obj);
    this.srv.postCall(API_UPDATE_PLANACTION, new_obj).toPromise().then(  (data)=> {
      if(data.success){
        this.u_obj = {};
        this.newActionsList = [];
        this.flag = false;
        Swal.fire(this.translations.msg_update,'', 'success');
        this.getList();
        this.isUpdating = false;
        this.closeModal(modal);
        this.creoPlan = false;
      } else {
        this.isUpdating = false;
        Swal.fire(data.msg, '', 'error');
      }      
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
      this.isUpdating = false;
    });
  }

  public openCloseModal (content, object) {
    this.new_Action = {};
    this.listaAcciones = [];
    this.getUserSystem();
    // console.log(object)
    this.cantidadFotos = object._ActionPlanDetails.length;
    this.contador = 0;
    object._ActionPlanDetails.forEach(element => {
      if(element.Evidence !== null) {
        this.contador ++;
      }
    });
    this.modalService.open(content, { backdrop: 'static', keyboard : false, windowClass: 'myCustomModalClass' });
    this.close_object = {};
    this.newActionsList = [];
    this.close_object['level'] = object['PlantaName'];
    this.close_object['levelcode'] = object['PlantaID'];
    this.close_object['area'] = object['SeccionName'];
    this.close_object['areacode'] = object['SeccionID'];
    this.close_object['Reportby'] = object['CreatorUser'];
    this.close_object['date_ha'] = object['FindingDate'].split('T')[0];
    this.close_object['justificative'] = object['Justification'];
    this.close_object['ActionPlanDetails'] = [];
    object['_ActionPlanDetails'].forEach(element => {
      if (element.StatusPlanDetail != 'E') {
        this.close_object['ActionPlanDetails'].push(element)
      }
    });
    this.close_object['lengthActions'] = this.close_object['ActionPlanDetails'].length;
    if( Array.isArray(this.close_object['ActionPlanDetails']) ){
      this.pageTotal4 = this.close_object['ActionPlanDetails'].length;
    }
    this.close_object['detail'] = object['Detail'];
    this.close_object['ActionPlanId'] = object['ActionPlanId'];
    this.close_object['StatusPlan'] = object['StatusPlan'] == 'C'? 'is_closed' : 'is_open';

  }

  public deleteActionPerPlan (action) {
    Swal.fire({
      title: this.translations.are_you_sure,
      text: this.translations.text_comment,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#005c3c',
      cancelButtonColor: '#C8102E',
      confirmButtonText: this.translations.remove,
      input: 'text',
      allowOutsideClick: () => Swal.isLoading(),
      preConfirm: (value) => {
        if(value == '') {
          Swal.showValidationMessage(
            this.translations.should_add_comment
          )
        }
      }
    }).then((result) => {
      if (result.value) {
        action.StatusPlanDetail = 'E',
        action.CommentPlanDetail = result.value;
        this.contElimiActions += 1;
      }
    })
  }

  public removeItemFromArr = ( arr, item ) => {
    return arr.filter( e => e !== item );
  };




  public getColorStatusPlan() {
    let color = '';
    this.close_object['StatusPlan'] == 'is_open' ? color = '#C8102E' : color = '#005c3c';
    return color;
  }

  public seeEvidence(modal, objeto) {
    this.accionporplan = objeto;
    this.evidencias = objeto._Evidences;
    // console.log(this.accionporplan)
    this.modalReference = this.modalService.open(modal, { backdrop: 'static',  keyboard : false, size: 'lg' });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
    

  public rejectEvidence() {
    this.isRejected = true;
    let obj = {
      actionPlanDetailsId: this.accionporplan.ActionPlanDetailsId,
      typeUpdate: 0,
      commentRejectAction: null,
      userRejectApproved: this.userinfo['logon']
    }
    Swal.fire({
      title: this.translations.are_you_sure,
      text: this.translations.text_comment,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#005c3c',
      cancelButtonColor: '#C8102E',
      confirmButtonText: this.translations.accept,
      input: 'text',
      allowOutsideClick: () => Swal.isLoading(),
      preConfirm: (value) => {
        if(value == '') {
          Swal.showValidationMessage(
            this.translations.should_add_comment
            )
          }
        }
      }).then((result) => {
        if (result.value) {
          obj.commentRejectAction = result.value;
          this.modalReference.close();
          this.srv.postCall(API_UPDATE_APPROVE_REJECT_ACTION, obj).toPromise().then(  (data)=> {
            if(data.success){
              this.flag = false;
              Swal.fire(this.translations.msg_update,'', 'success');
              this.getList();
              this.isRejected = false;
              this.closeModal(this.modalReference)
            } else {
              this.isApproved = false;
              Swal.fire(data.msg, '', 'error');
            }      
          },catchError =>{
            Swal.fire(catchError.message, '', 'error');
            this.isRejected = false;
          });
        } {
          this.isRejected = false;
        }
    })
    
  }
  public approveEvidence() {
    this.isApproved = true;
    let obj = {
      actionPlanDetailsId: this.accionporplan.ActionPlanDetailsId,
      typeUpdate: 1,
      commentRejectAction: null,
      userRejectApproved: this.userinfo['logon']
    }
    this.srv.postCall(API_UPDATE_APPROVE_REJECT_ACTION, obj).toPromise().then(  (data)=> {
      if(data.success){
        this.flag = false;
        Swal.fire(this.translations.msg_update,'', 'success');
        this.getList();
        this.isApproved = false;
        this.closeModal(this.modalReference)
      } else {
        this.isApproved = false;
        Swal.fire(data.msg, '', 'error');
      }      
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
      this.isApproved = false;
    });
  }

  public closePlan(modal) {
    this.isClosing = true;
    this.srv.postCall(API_CLOSE_PLAN_ACTION + this.close_object['ActionPlanId'], {}).toPromise().then(  (data)=> {
      if(data.success){
        this.isClosing = false;
        Swal.fire(this.translations.msg_plan_action_closed,'', 'success');
        this.getList();
        this.closeModal(modal);
      } else {
        this.isClosing = false;
        Swal.fire(data.msg, '', 'error');
      }      
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
      this.isClosing = false;
    });
  }

  public verifyClose() {
    let contador = 0;
    this.close_object['ActionPlanDetails'].forEach(element => {
      if (element.StatusEvidence == 'AP') {
        contador += 1;
      }
    });
    if (contador == this.close_object['ActionPlanDetails'].length) {
      return true;
    } else {
      return false;
    }
  }

  public verifyDeletePlan(plan) {
    // console.log(plan.ActionPlanId)
    var statusplan = plan.StatusPlan;
    let cont = 0;
    plan._ActionPlanDetails.forEach(accion => {
      if (accion.StatusEvidence == 'AP' || accion.StatusEvidence == 'R'|| accion.StatusEvidence == 'ER') {
        cont += 1;
      }
    });
    if (cont == 0 && statusplan == 'A') {
      return true;
    } else {
      return false;
    }
  }

  public erasePlanAction(row) {
    // console.log(row);
    let obj = {
      id: row.ActionPlanId,
      user: this.userinfo['logon']
    }
    this.srv.postCall(API_DELETE_PLAN_ACTION, obj).toPromise().then( (data)=> {
      if(data.success){
        this.flag = false;
        Swal.fire(this.translations.msg_update,'', 'success');
        this.getList();
      } else {
        Swal.fire(data.msg, '', 'error');
      }      
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
    });
  }
}