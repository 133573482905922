import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { BaseCrudComponent } from '../../../base-crud/base-crud.component';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/services/main/global.service';
import Swal from 'sweetalert2';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/authentication/login.service';
import { Columns,Img,PdfMakeWrapper,Stack,Txt } from 'pdfmake-wrapper';
import * as pdfFonts from "pdfmake/build/vfs_fonts"; // fonts provided for pdfmake

PdfMakeWrapper.setFonts(pdfFonts);

const API_CONSULTATYPEFALTA = environment.API_CONSULTATYPEFALTA;
const API_CONSULTAFALTADETAIL = environment.API_CONSULTAFALTADETAIL;
const API_SANCION = environment.API_SANCION;
const GET_ALL_AREA_BY_ID = environment.Global.API_GET_ALL_AREA_BY_ID;
const GET_ALL_SITES_ACTIVE = environment.Global.API_GET_ALL_SITES_ACTIVE;
const API_GET_SEVERITY_TYPE_BY_ID = environment.Global.API_GET_SEVERITY_TYPE_BY_ID; 

const API_CREATE_UNSAFECONDITION = environment.Global.API_CREATE_UNSAFECONDITION;
const API_UPDATE_UNSAFECONDITION= environment.Global.API_UPDATE_UNSAFECONDITION;
const API_ALL_UNSAFECONDITION= environment.Global.API_ALL_UNSAFECONDITION;
const API_UPDATESTATUS_UNSAFECONDITION= environment.Global.API_UPDATESTATUS_UNSAFECONDITION;
const API_GET_UNSAFECONDITION_BY_USER= environment.Global.API_GET_UNSAFECONDITION_BY_USER;
const API_UPLOAD_FILE_UNSAFECONDITION= environment.Global.API_UPLOAD_FILE_UNSAFECONDITION;
const GET_ALL_COMPANIES = environment.Global.API_GET_ALL_COMPANIES;
const API_UNSAFECONDITION_MAIL = environment.Global.API_UNSAFECONDITION_MAIL;
const API_GET_EMPLOYEE_INTERNAL_BY_NUMBERIDENTIFICATION = environment.Global.API_GET_EMPLOYEE_INTERNAL_BY_NUMBERIDENTIFICATION;
const API_UNSAFEACT_FOULS = environment.Global.API_UNSAFEACT_FOULS;
const API_GET_COMPANYBYID = environment.Global.API_GET_COMPANYBYID;
const API_USER_SYSTEM_GET_ALL = environment.Global.API_USER_SYSTEM_GET_ALL;
const API_GET_BOSSBYEMPLOYEE = environment.Global.API_GET_BOSSBYEMPLOYEE;
const API_EXIST_PLAN_ACTION = environment.Global.API_EXIST_PLAN_ACTION;

@Component({
  selector: 'app-condinosecure',
  templateUrl: './condinosecure.component.html',
  styleUrls: ['./condinosecure.component.css']
})
export class CondinosecureComponent  extends BaseCrudComponent implements OnInit {
  list_type = [];
  fechaActual = new Date();
  list_workers = [{id: 1, type: 'Interno'},{id: 2, type:'Externo'}];
  workerId = 1;
  companyId = null;
  list_option_fault = [{id: 1, type: 'Sí'},{id: 2, type:'No'}];
  archivos=[];
  list_fouls = [];
  list_fouls_detail = [];
  list_sanction = [];
  row_plantas = [];
  row_plantas_area = [];
  userinfo: any;
  loadingList = false;
  isUpdating = false;
  iscreated = false;
  flag = true;
  companies_list = [];
  getCompany = false;
  Company: any;
  getEmployee = false
  userSystemList = [];
  listfouls = [];
  contadorFaltas = 0;
  descriptionFoul = '';
  employeesbyarea;
  permissions;
  isEditable = false;
  existePlan = true;
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    public auth: LoginService,
    private globalService: GlobalService,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
  }

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
    .subscribe((translations) => {
        this.translations = translations;
        super.ngOnInit();
    });
    this.pageSize = 5
    this.search_fields = ['UnsafeConditionId','CategoryName'];
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    this.c_obj['fault_type'] = null;
    this.c_obj['fault_associated'] = null;
    this.c_obj['type_sanction'] = null;
    this.c_obj['close_fault'] = 2;
    this.c_obj['category'] = null;
    this.c_obj['date'] = this.globalService.formatOnlyDate(this.fechaActual);
    this.storage.get('userinfo').then(x => {
      this.userinfo = x;
    });
    this.auth.getPermissions().then(x => {
      this.permissions = x;
      this.getList(); 
    });
    this.getUserSystem();
  }

  public getList() {
    this.loadingList = true;
    let url = '';
    // SE VALIDA SI EL USUARIO ES ADMINISTRADOR
    if (this.permissions.includes('SI_ADMIN')) {
      url = API_ALL_UNSAFECONDITION;
    } else {
      url = API_GET_UNSAFECONDITION_BY_USER + this.userinfo['logon'];
    }
    this.srv.getCall(url).subscribe(x => {
      this.loadingList = false; 
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          this.rows = this.rawdata;
          // console.log(this.rows)
          this.page = 1;
          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          if(this.flag) {
            Swal.close()
          }
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
    this.GetPlantas();
  }

  public getUserSystem() {
    this.userSystemList = [];
    this.srv.getCall(API_USER_SYSTEM_GET_ALL).subscribe(x => {
      if(x.success){
        if(x.val != null){
          x.val.forEach(element => {
            element.Permissions = JSON.parse(element.Permissions);
            if (element.Permissions != null) {
              element.Permissions.Modules.forEach(permission => {
                if(permission.Name == "SI_PLANACTION") {
                  this.userSystemList.push(element)
                }
              });
            }
          });
        }else {
          Swal.fire(this.translations.empty, this.translations.not_user_registers, 'success');
          this.userSystemList = [];
          return;
        }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public setTypeWorker() {
    this.listfouls = [];
    this.getEmployee = false;
    if(this.workerId == 2) {    
      this.getCompanies();
      this.c_obj = {};
      this.c_obj['date'] = this.globalService.formatOnlyDate(new Date());
      this.c_obj['close_fault'] = 2;
      this.c_obj['samp_comment'] = null;
      this.c_obj['reportby'] = this.userinfo['logon'];
    } else {
      this.companies_list = [];
      this.c_obj['companyID'] = null;
      this.Company = null;
    }
  }

  public async setCompany() {
    this.c_obj['companyID'] = this.companyId;  
    await this.searchCompany();
    this.getEmployee = true;
    if (this.getEmployee) {
      this.GetFoulByEmployee(this.Company.CompanyID);
    } 
    
  }

  public async searchCompany(){
    await this.srv.getCall(API_GET_COMPANYBYID + this.c_obj['companyID']).toPromise().then(x => {
      if(x.success){
        if(x.val != null){
          this.Company = x.val[0];
        } else {
          this.Company = [];
        }
      }else {
        this.Company = [];
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }
  
  public getCompanies() {
    let url = GET_ALL_COMPANIES;
    this.srv.getCall(url).subscribe(data => { 
      if(data.success) {
        if(data.val !== null){
          // console.log(data.val)
          let rs = data.val;
          rs.forEach(element => {
            this.companies_list.push(
              {
                id: element.CompanyID, 
                ruc: element.Ruc,
                name: element.Name,
              }
            );
          })
        }
      }
    })
  }

  public GetSeverityTypes() {
    var qst = API_GET_SEVERITY_TYPE_BY_ID + 'CATEGORY_TYPE';
    this.srv.getCall(qst).subscribe(x => {
      this.list_type = [];
      if(x.success){
        if(x.val != null){
          x.val.forEach(element => {
            this.list_type.push(
              {
                id: element.ID,
                type: element.value
              }
            );
          })
        }else {
          return;
        }
      }else {
        return;
          }
    });
  }

  public openCreateModal(content: any) {
    this.GetSeverityTypes();
    this.getTypeFouls();
    this.iscreated = true;
    this.GetPlantas();
    this.c_obj = {};
    this.c_obj['category'] = null;
    this.c_obj['level'] = null;
    this.c_obj['area'] = null;
    this.c_obj['reportby'] = this.userinfo['logon'];
    this.c_obj['close_fault'] = 2;
    this.c_obj['samp_comment'] = null;
    this.c_obj['date'] = this.globalService.formatOnlyDate(new Date());
    this.modalService.open(content, { backdrop: 'static', keyboard : false,  size: 'lg' });
  }
 
  public onlyNumberKey(event: any): boolean {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  public async GetEmployeeforIdentification(obj:any) {
    var qst  = "";
    if (obj === "" || obj == " ")
    {
      return;
    }
    qst = API_GET_EMPLOYEE_INTERNAL_BY_NUMBERIDENTIFICATION + obj;
    await this.srv.getCall(qst).toPromise().then(x => {
      // this.loadingList = false; 0908546328
      if(x.success){
        if(x.val != null){       
          this.c_obj['EmployeeId'] = x.val['EmployeeId'];
          this.c_obj['NameEmployee'] = x.val['Name'] + ' ' +x.val['LastName'];
          this.c_obj['Position'] = x.val['Position'];
          this.c_obj['code'] = x.val['CodeEmployee'];
        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          return;
        }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
    this.getEmployee = true;
    if (this.getEmployee) {
      this.GetFoulByEmployee(obj);
    } 
  }

  public GetFoulByEmployee (ci: string) {
    let new_obj = { //0908546328
      id: ci,
      typeUser : this.workerId == 1? 1:3
    }
    var qst =  API_UNSAFEACT_FOULS;
    this.srv.postCall(qst, new_obj).subscribe(x => {
      if(x.success){
        if(x.val != null){
          this.listfouls = x.val;
        } else {
          return;
        }
      }else {
        return;
      }
    });
  }

  public handleInputChange(e){
    this.archivos=[];
    var file = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    let files = Object.values(file);
    files.forEach(element => {
      if (this.archivos.length < 2) {
        this.archivos.push(element);
      } else {
        return Swal.fire(this.translations.only_two_imgs,'','error');
      }
    });
  }

  public getTypeFouls () {
    let url = API_CONSULTATYPEFALTA;
    this.srv.getCall(url).subscribe(x => {
      if(x.success){
        if(x.val != null){
          // console.log(x.val)
          this.list_fouls = x.val
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          return;
        }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public getFouls(falta_id) {
    if (falta_id !== null) {
      let url = API_CONSULTAFALTADETAIL;
      let id = {FoulTypeID: falta_id};      
      this.c_obj['fault_associated'] = null;
      this.srv.postCall(url, id).subscribe(x => {
        if(x.success){
          if(x.val != null){
            this.list_fouls_detail = x.val;
          }else {
            Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
            return;
              }
        }else {
          Swal.fire(x.msg, '', 'error');
          return;
            }
      });
      this.getSanction(falta_id);
    } else {
      this.c_obj['fault_associated'] = null;
      this.c_obj['type_sanction'] = null;
    }
  }

  public getSanction(falta_id) {
    // console.log({falta_id})
    let url = API_SANCION;
    let id = {FoulTypeID: falta_id};
    this.srv.postCall(url, id).subscribe(x => {
      if(x.success){
        if(x.val != null){
          // console.log(x.val)
          this.list_sanction = x.val;
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
          }
    });
    setTimeout(()=>{
      this.setFoulsAndSanction(falta_id);
    }, 2000);
  }

  public setdescriptionFoul() {
    // console.log(this.c_obj['fault_associated'])
    this.list_fouls_detail.forEach(element => {
      if (element.ParaDetaFoulID == this.c_obj['fault_associated']) {
        this.descriptionFoul = element.Descripcion
      }
    })
  }

  
  public setFoulsAndSanction(falta_id) {
    this.contadorFaltas = 0;
    this.listfouls.forEach(element => {
      if (element.typefouls == falta_id) {
        this.contadorFaltas = element.quantity + 1;
      }
    })
    // en caso de que la falta asociada sea 0
    if (this.contadorFaltas == 0) {
      this.contadorFaltas = 1;
    }

    if (this.contadorFaltas > this.list_sanction.length) {
      this.c_obj['type_sanction'] = this.list_sanction[this.list_sanction.length - 1].SanctionID;
    } else if (this.contadorFaltas <= this.list_sanction.length) {
      this.c_obj['type_sanction'] = this.list_sanction[this.contadorFaltas - 1].SanctionID;
    }
  }

  public GetPlantas() {
    var qst = GET_ALL_SITES_ACTIVE;
    this.srv.getCall(qst).subscribe(x => {
      this.row_plantas = [];
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.row_plantas = rs;
        }else {
          this.row_plantas = [];
          return;
        }
      }else {
        return;
      }
    });
  }
  public searchArea() {
    this.setLevelArea();
  }

  public setLevelArea() {
    if ( this.iscreated) {
      var qst = GET_ALL_AREA_BY_ID + this.c_obj['level'];
      this.srv.getCall(qst).subscribe(x => {
        if(x.success){
          if(x.val != null){
            var rs = x.val;
            this.row_plantas_area = rs;
          }else {
            this.row_plantas_area = [];
            return;
          }
        }else {
          return;
            }
      });
    } else {
      var qst = GET_ALL_AREA_BY_ID + this.u_obj['level'];
    this.srv.getCall(qst).subscribe(x => {
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.row_plantas_area = rs;
        }else {
          this.row_plantas_area = [];
          return;
        }
      }else {
        return;
          }
    });
    }
    
  }

  public changeValueFoult() {
    if(this.c_obj['close_fault'] == 2) {
      this.c_obj['samp_comment'] = null;
    }
  }

  public changeValueFoultUpdate() {
    if(this.u_obj['StatusInsp'] == 2) {
      this.u_obj['Commentary'] = null;
    }
  }

  public validateCreateInfo(fields:any): boolean {
    let internalWorker:any = {};
    internalWorker = new FormGroup({
        'category': new FormControl(fields.category, [Validators.required]),
        'date': new FormControl(fields.date, [Validators.required]),
        'reportby': new FormControl(fields.reportby, [Validators.required]),
        'level': new FormControl(fields.level, [Validators.required]),
        'cmp_area': new FormControl(fields.cmp_area, [Validators.required]),
        'fault_type': new FormControl(fields.fault_type, [Validators.required]),
        'fault_associated': new FormControl(fields.fault_associated, [Validators.required]),
        'type_sanction': new FormControl(fields.type_sanction, [Validators.required]),
        'observation': new FormControl(fields.observation, [Validators.required])
    });
    return internalWorker.valid;
  }
  

  public async createItem(modal, object) {
    // console.log(object)
    this.isCreating = true;
    let validated = this.validateCreateInfo(object);
    if( !validated ){
      this.isCreating = false;
      Swal.fire(this.translations['msg_all_required'], '', 'error');      
      return;
    }


    if (this.workerId == 1) {
      if (this.c_obj['boss_inme'] == undefined) {  
        this.isCreating = false;
        Swal.fire(this.translations['msg_all_required'], '', 'error');      
        return;
      }
      if (this.c_obj['mail_boss'] == undefined) {  
        this.isCreating = false;
        Swal.fire(this.translations['msg_all_required'], '', 'error');      
        return;
      }
    }

    if(object['close_fault'] == 1 && object['samp_comment'] == null) {
      return Swal.fire(this.translations.add_comment_act, '','error');
    }

    let new_obj = {
      CategoryTypeId: object['category'],
      Date: object['date'],
      PlantaID: object['level'],
      SeccionID: object['cmp_area'],
      FoulTypeID: object['fault_type'],
      ParaDetaFoulID: object['fault_associated'],
      SanctionID: object['type_sanction'],
      Photos: '',
      Commentary: object['samp_comment'] == null ? null : object['samp_comment'],
      StatusInsp: object['close_fault'],
      Observation: object['observation'],
      CreatorUser: this.userinfo['logon'],
      WorkerTypeId: this.workerId,
      CompanyID: object['companyID'] == null ? null : object['companyID'],
      CodeEmployee: object['code'] != null ?  object['code']  : null,
      ResponsableUser: object['responsableUser'],
      EmployeeId: object['EmployeeId'] != null ?  object['EmployeeId']  : null,
      Reportby: this.userinfo['logon'],
      BossInmediatly: object['boss_inme'] != null ?  object['boss_inme']  : null,
      MailBoss: object['mail_boss'] != null ?  object['mail_boss']  : null
    }


    // console.log(new_obj)

    this.srv.postCall(API_CREATE_UNSAFECONDITION, new_obj).toPromise().then( async (data)=> {
      if(data.success){
        await this.sendPhotos(data);
        await this.sendMail(data.val['UnsafeConditionId']);
        Swal.fire(this.translations.msg_create,'', 'success');
        this.getList();
        this.isCreating = false;
        this.closeModal(modal);
        this.c_obj = {};
        this.workerId = 1;
        this.companyId = null;
        this.Company = null;
        this.c_obj['close_fault'] = 2;
        this.c_obj['samp_comment'] = null;
      } else {
        this.isCreating = false;
        Swal.fire(data.msg, '', 'error');
      }      
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
      this.isCreating = false;
    });

  }

  public async createReportToSend(id) {
    let foult = '', descriptionFoul = '', elemento = {}, sanction = '';
    this.list_fouls.forEach(element => {
      if (element.FoulTypeID == this.c_obj['fault_type']) {
        foult = element.NameFoul;
        descriptionFoul = element.Descripcion;
        elemento = element;
      }
    })

    this.list_sanction.forEach(element =>{
      if (element.SanctionID == this.c_obj['type_sanction']) {
        sanction = element.NameSan;
      }
    });
    const pdf = new PdfMakeWrapper();
    pdf.info({
      title: `${this.c_obj['company'] + ' - ' + this.c_obj['NameEmployee']}`,
      author: `SKRETTING`,
      subject: 'Notificación de incumplimiento',
    });
    //OPTIONS
    pdf.defaultStyle({
      bold: false,
      fontSize: 10,
      alignment:'justify'
    });
    pdf.pageMargins([ 60, 110, 60, 110]);

    pdf.background(
      [
        await new Img('../../../../assets/images/Skretting-logo.png').absolutePosition(405, 35).width(160).build()
      ]
    )

    pdf.add(
      [
        new Stack([
          new Txt('NOTIFICACION DE INCUMPLIMIENTO').margin([0,10,0,5]).fontSize(13).bold().alignment('center').end,
          new Txt('PERSONAL TERCERO').margin([0,0,0,25]).fontSize(13).alignment('center').bold().end,
        ]).margin([0,25,0,0]).end,
        new Columns([new Txt([new Txt('DE: ').end,new Txt('GISIS S.A.').end]).margin([0,0,0,5]).bold().fontSize(10).end]).end,
        new Columns([new Txt([new Txt('PARA: ').end,
                    new Txt(this.c_obj['boss_inme'] == undefined ? `${this.c_obj['company']}`: `${this.c_obj['boss_inme']}`).end])
                    .margin([0,0,0,5])
                    .bold()
                    .fontSize(10)
                    .end]
                  ).end,
        new Columns([new Txt([new Txt('ASUNTO: ').end,new Txt(`${foult}`.toUpperCase()).end]).margin([0,0,0,5]).bold().fontSize(10).end,]).end,
        new Columns([new Txt([new Txt('FECHA: ').end,new Txt(`${this.globalService.formatOnlyDate(this.fechaActual)}`).end]).bold().fontSize(10).end]).margin([0,0,0,25]).end,
        new Txt(
          [
            new Txt('Por medio de la presente se da conocimiento la sanción al (los) Sr. (a)(s)').end,
            new Txt(this.c_obj['NameEmployee'].toUpperCase()).bold().end,
            new Txt(', con cédula de identidad ').end,
            new Txt(this.c_obj['NumberIdentification']).bold().end,
            new Txt(' como consecuencia de la ').end,
            new Txt(foult.toUpperCase()).bold().end,
            new Txt(' las normas establecidas de HSE en la empresa GISIS S.A., la misma que corresponde a:').end,
          ]
        ).margin([0,0,0,20]).end,
        new Txt(this.descriptionFoul.toUpperCase()).italics().margin([0,0,0,35]).bold().end,
        new Txt(
          [
            new Txt('La cual corresponde a la ').end,
            new Txt(this.contadorFaltas.toString() + '° ' + foult.toUpperCase() + ': ').bold().end,
            new Txt(sanction.toUpperCase()).end,
          ]
        ).margin([0,0,0,35]).end,
        new Txt("Con este documento se deja constancia del incumplimiento y a su vez se espera su compromiso de evitar reincidencias/sanciones en nuestras instalaciones")
        .margin([0,0,0,20])
        .end,
        new Txt("Atentamente").margin([0,0,0,10]).bold().end,
        // new Txt(this.userinfo['name'].toUpperCase()).end,
        new Txt(('Departamento HSE').toUpperCase()).end,
        new Txt(('GISIS S.A.')).end,
      ]
    )

    // pdf.create().open();
    pdf.create().getBase64(base64 => {
      // console.log(base64)
      var to:any;    
      to = {};
      to['_id'] = id;
      to['_pdfbase64'] = base64;
      let qst = '' ;//  API_UNSAFE_SEND_MAIL;
      this.srv.postCall(qst ,to).subscribe(x => {
        Swal.close();
      }, err => {
        Swal.close();
        Swal.fire('Error');
      });
    })
  }

  public async sendMail(id) {
    var to:any;    
    to = {};
    to['_id'] = id;
    to['_pdfbase64'] = null;
    let qst = API_UNSAFECONDITION_MAIL;
    await this.srv.postCall(qst ,to).toPromise().then(x => {
    });
  }

  public searchBoss() {
    let obj = {
      employeeId: this.u_obj['EmployeeId'] || this.c_obj['EmployeeId'],
      typeWorker: this.workerId
    }
    this.srv.postCall(API_GET_BOSSBYEMPLOYEE, obj).subscribe(x => {      
      this.isCreating = false;
      if(x.success) {
        if (x.val != null) {
          if (this.workerId == 1) {
            this.employeesbyarea = x.val;
          } else {
            this.employeesbyarea = x.val;
          }
          // console.log(x.val)
        }
      }
    });
  }

  public async sendPhotos(data?) {
    let formData = new FormData();
    for(let file of this.archivos) {
      formData.append(file.name, file);
    }
    formData.append('UnsafeConditionId', data.val.UnsafeConditionId);
    formData.append('CompanyID', this.companyId == null ? '': this.companyId)
    formData.append('TypeWorker', (this.workerId).toString())
    await this.srv.AddFiles(API_UPLOAD_FILE_UNSAFECONDITION, formData).toPromise().then(result => {
      if (result=="OK") {
        this.archivos=[];
      }
      else{
        Swal.fire(this.translations.evidence, result, 'error');
        this.archivos=[];
      }
    });
  }
  
  public async deleteInsp(row) {
    await this.verifyExistPlan(row);
    if (this.existePlan) {
      return Swal.fire( this.translations.have_actionplan , this.translations.you_have_action_plan,'error');
    } else {
      let obj = {
        UnsafeConditionId : row.UnsafeConditionId,
        LastModifierUser: this.userinfo['logon'],
        Status : 3
      }
      this.srv.postCall(API_UPDATESTATUS_UNSAFECONDITION, obj).toPromise().then( data =>{
        if(data.success){
          this.flag = false;
          Swal.fire(this.translations.msg_update, '', 'success');
          this.getList();
        }else {
          Swal.fire(data.msg, '', 'error');
        }    
      },catchError =>{
        Swal.fire(catchError.message, '', 'error');
      });
    } 
  }

  public async closeIncidence(row) {
    await this.verifyExistPlan(row);
    if (this.existePlan) {
      return Swal.fire( this.translations.have_actionplan , this.translations.you_have_action_plan,'error');
    } else {
      Swal.fire({
        title: this.translations.are_you_sure,
        text: this.translations.text_comment,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#003d6a',
        cancelButtonColor: '#C8102E',
        confirmButtonText: this.translations.close_incidence,
        cancelButtonText: this.translations.cancel,
        input: 'text',
        allowOutsideClick: () => Swal.isLoading(),
        preConfirm: (value) => {
          if(value == '') {
            Swal.showValidationMessage(
              this.translations.should_add_comment
            )
          }
        }
      }).then((result) => {
        if (result.value) {
          let obj = {
            UnsafeConditionId: row['UnsafeConditionId'],
            LastModifierUser: this.userinfo['logon'],
            Commentary: result.value
          }
          this.srv.postCall(API_UPDATE_UNSAFECONDITION, obj).toPromise().then( data =>{
              if(data.success){
                this.flag = false;
                Swal.fire(this.translations.msg_update, '', 'success');
                this.getList();
              } else {
                Swal.fire(data.msg, '', 'error');
              }       
            },catchError =>{
              Swal.fire(catchError.message, '', 'error');
            });
        } 
      })
    }
   
  }

  public async verifyExistPlan(row) {
    let obj = {
      idInspection : row.UnsafeConditionId,
      justification : "Condición insegura" 
    }
    await this.srv.postCall(API_EXIST_PLAN_ACTION, obj).toPromise().then( data =>{
      if(data.success){
        if (data.val == null) {
          this.existePlan = false;
          this.isEditable = true;
        } else {
          if (data.val.length > 0 || row['StatusInsp'] == 1 ) {
            this.existePlan = true;
            this.isEditable = false;
          } else {
            this.isEditable= false;
            this.existePlan = false;
          }
        }
      } else {
        Swal.fire(data.msg, '', 'error');
      }       
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
    });
  }
  
  public openUpdateModal(modal,row) {
    // console.log({object})
    this.u_obj = row;
    this.u_obj['evidencia'] = [];
    this.u_obj['ResponsableUserNew'] = row.ResponsableUser;
    this.u_obj['PlantaIDNew'] = row.PlantaID;
    this.u_obj['SeccionIDNew'] = row.SeccionID;
    this.u_obj['Reportby'] = this.userinfo['name'];
    row._Evidences.forEach(element => {
      this.u_obj['evidencia'].push(element.imagen);
    });
    this.modalService.open(modal, { backdrop: 'static', keyboard : false,  size: 'lg' });
    this.setLevelAreaUpdate(row.PlantaID);
    this.verifyExistPlan(row)
  }

  public searchAreaUpdate() {
    this.setLevelAreaUpdate(this.u_obj['PlantaID']);
  }

  public setLevelAreaUpdate(plantaId) {
    var qst = GET_ALL_AREA_BY_ID + plantaId;
    this.srv.getCall(qst).toPromise().then(x => {
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.row_plantas_area = rs;
        }else {
          this.row_plantas_area = [];
          return;
        }
      }else {
        return;
          }
    });
  }
  
  public updateItem(modal) {
    this.isUpdating = true;
    let obj = {
      UnsafeConditionId: this.u_obj.UnsafeConditionId,
      LastModifierUser: this.userinfo['logon'],
      PlantaID: this.u_obj['PlantaID'] != this.u_obj['PlantaIDNew'] ? this.u_obj['PlantaIDNew'] : this.u_obj['PlantaID'],
      SeccionID: this.u_obj['SeccionID'] != this.u_obj['SeccionIDNew'] ? this.u_obj['SeccionIDNew'] : this.u_obj['SeccionID'],
      ResponsableUser: this.u_obj['ResponsableUser'] != this.u_obj['ResponsableUserNew'] ? this.u_obj['ResponsableUserNew']: this.u_obj['ResponsableUser']
    }
    // console.log(obj);
    this.srv.postCall(API_UPDATE_UNSAFECONDITION, obj).toPromise().then( data =>{
      if(data.success){
        this.u_obj = {};
        this.flag = false;
        Swal.fire(this.translations.msg_update, '', 'success');
        this.isUpdating = false;
        this.getList();
        this.closeModal(modal);
      } else {
        this.isUpdating = false;
        Swal.fire(data.msg, '', 'error');
      }       
    },catchError =>{
      Swal.fire(catchError.message, '', 'error');
      this.isUpdating = false;
    });
  }

}
