import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BlankComponent } from './ic2/layouts/blank/blank.component';
import { QrcodeValidationComponent } from './ic2/qrcode-validation/qrcode-validation.component';
import { LoginGuard } from './services/login.guard';
import {AuthCallbackComponent} from "./ic2/redirectS/auth-callback/auth-callback.component";
import { SilentRefreshComponent } from './ic2/redirectS/silent-refresh/silent-refresh.component';

export const Approutes: Routes = [
  {
    path: '',
    component: BlankComponent,
    children: [
      { path: '', redirectTo: '/authentication/login', pathMatch: 'full' },
      { path: 'callback', component: AuthCallbackComponent },
      { path: 'silent-refresh', component: SilentRefreshComponent},
      {
        path: 'authentication',
        canActivate: [LoginGuard],
        loadChildren:
          () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
      }
    ],
  },
  {
		path: 'qrcodeValidationComponent1',
		component: QrcodeValidationComponent,
	},
];
