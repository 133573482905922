import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';


import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { isNumeric } from 'rxjs/util/isNumeric';
import { Companieadmin } from '../../../interfaces/companieadmin';
import * as CryptoJS from 'crypto-js';
import { format } from 'd3-format';
//mport { Console } from 'console';

const KEY = environment.KEY;
const API_CONSULTACOMPANIES = environment.API_CONSULTACOMPANIES;
const API_REGISTEREMP = environment.API_REGISTEREMP;
const API_CONSULTAEMP = environment.API_CONSULTAEMP;
const API_UPDATEEMP = environment.API_UPDATEEMP;
const API_DESATEEMP = environment.API_DESATEEMP;
const API_ACTIVETEEMP = environment.API_ACTIVETEEMP;
const API_CONSDOCUMENEMP = environment.API_CONSDOCUMENEMP;

@Component({
  selector: 'app-regcompanies',
  templateUrl: './regcompanies.component.html',
  styleUrls: ['./regcompanies.component.css']
})
export class RegcompaniesComponent extends BaseCrudComponent {
  id: any;
  loadingRequired = false;
  listAgr: any;
  translations: any;
  userinfo: any;
  jobtypeID: any;
  mostarselectemp = true;
  date;
  seleccionados = [];
  Empresas = "Empresas";
  available_languages: any;
  currlang: any;
  version: any;
  permissions = [];
  RegCompanie: FormGroup;
  passuno: string;
  passdos: string;
  passnew: string;
  conversionEncryptOutput: string;
  conversionDecryptOutput: string;
  cedulaexiste;
  resetclave: any;

  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
    this.id = route.snapshot.params.id;

    this.read_url = API_CONSULTACOMPANIES;
    this.create_url = API_REGISTEREMP;
    this.select_url = API_CONSULTAEMP;
    this.update_url = API_UPDATEEMP;
    this.delete_url = API_DESATEEMP;
    this.active_url = API_ACTIVETEEMP;
    this.documentemp_url = API_CONSDOCUMENEMP;

    this.search_fields = ['CompanyID', 'Name', 'Ruc'];

    this.c_obj['Company'] = [];

    this.reload.subscribe(val => {
      if (val) {
        super.clearData();
        this.getList();
      }
    });
    this.crearFormEmp();

  }

  async ngOnInit() {
    this.date = new Date();
    await this.translate.getTranslation(this.translate.currentLang)
      .toPromise().then((translations) => {
        this.translations = translations;
        super.ngOnInit();
        this.getList();
      });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';

    await this.storage.get('userinfo').then(x => {
      this.userinfo = x;
      if (isNumeric(this.userinfo.logon)) {
        // this.getListbyuser(this.userinfo.selectedCompanyId);
        this.mostarselectemp = false;
      } else {
        this.getList();
        this.mostarselectemp = true;
      }
    });
  }

  public getList() {
    //debugger;
    var qst = this.read_url;
    this.loadingList = true;
    Swal.fire(
      {
        title: this.translations['loading'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    this.srv.getCall(qst).subscribe(x => {
      //debugger;
      this.loadingList = false;

      if (x.success) {
        if (x.val != null) {
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;

          this.page = 1;
          if (Array.isArray(this.rows)) {
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
          Swal.close()
        } else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
        }
      } else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  public openCreateModal(content: any) {
    this.c_obj = {};
    this.passnew = "";
    //this.modalService.open(content, { size: 'lg' });
    this.modalService.open(content, { backdrop: 'static', keyboard: false });
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  crearFormEmp() {
    this.RegCompanie = new FormGroup({
      ruc: new FormControl('', Validators.compose([Validators.required, Validators.pattern(`^[0-9]{10}([0-9]{3})?$`)])),
      empresa: new FormControl('', [Validators.required]),
      email: new FormControl('', Validators.compose([Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])),
      pass: new FormControl('', []),
    });
  }

  onSubmit() {
    this.isCreating = true;
    var qst = this.create_url;
    if (this.RegCompanie.valid) {
      const companieNew: Companieadmin = new Companieadmin();
      companieNew.Username = this.RegCompanie.value.ruc;
      companieNew.Name = this.RegCompanie.value.empresa;
      companieNew.Email = this.RegCompanie.value.email;
      companieNew.PassWord = this.conversionEncryptOutput;
      companieNew.CreatorUserID = this.userinfo['userId'];
      this.srv.postCall(qst, companieNew).subscribe(x => {
        this.isCreating = false;
        Swal.close();
        if (x.success) {
          Swal.fire({
            title: this.translations.msg_create,
            text: x.msg,
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#005c3c',
            confirmButtonText: this.translations.accept,
            allowOutsideClick: () => Swal.isLoading(),
          }).then((result) => {
            if (result.value) {
              this.RegCompanie.reset();
              this.closeModal('create1');
              this.getList();
            }
          })
        } else {
          Swal.fire(x.msg, '', 'error');
          //this.closeModal(content);
        }
      }, err => {
        Swal.close();
        Swal.fire('Error');
      });



    } else {
      Swal.fire(this.translations.msg_all_required, '', 'error');
      return;
    }



  }

  generapass() {
    if (this.RegCompanie.controls.ruc.status == 'VALID' && this.RegCompanie.controls.empresa.status == 'VALID') {
      this.passnew = this.GeneratePw(this.RegCompanie.value.empresa, this.RegCompanie.value.ruc);
      this.conversionEncryptOutput = CryptoJS.AES.encrypt(this.passnew.trim(), KEY.trim()).toString();
    }
  }

  private GeneratePw(empresa, ruc) {
    if (empresa.length > 3) {
      this.passuno = empresa.substr(0, 4);
    } else {
      this.passuno = empresa;
    }
    this.passdos = ruc.substr(3, 4);
    return this.passuno.concat(this.passdos);
  }

  consultaEmp() {
    //debugger;
    if (this.RegCompanie.controls.ruc.status == 'VALID') {

      var qst = this.select_url;
      const companieNew: Companieadmin = new Companieadmin();
      companieNew.Ruc = this.RegCompanie.value.ruc;

      this.srv.postCall(qst, companieNew).subscribe(x => {
        this.isCreating = false;
        Swal.close();
        if (x.success) {
          Swal.fire({
            title: this.translations.ruc_exist,
            text: x.msg,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#005c3c',
            confirmButtonText: this.translations.accept,
            allowOutsideClick: () => Swal.isLoading(),
          }).then((result) => {
            if (result.value) {
              this.RegCompanie.reset();
              this.getList();
              this.closeModal('create1');
            }
          })
        } else {
          return;
        }
      }, err => {
        Swal.close();
        Swal.fire('Error');
      });

    }
  }

  public ValidaCheck(val: any) {
    // console.log('Valor Cheked:',val.target.checked);
    this.resetclave = "";
    if (val.target.checked == true) {
      this.resetclave = true;
    }
    else {
      this.resetclave = false;
    }
  }

  public updateItem(content: any, form: any) {
    this.isUpdating = true;
    var qst = this.update_url;

    if (form == undefined) {
      form = {};
    }

    var to: any;

    if ((form.Name == undefined) || (form.Name == '')) {
      Swal.fire(this.translations.msg_all_required, '', 'error');
      return;
    }
    to = {};
    to.Ruc = form.Ruc;
    to.Name = form.Name;
    to.Email = form.Email;
    to.LastModifierUserID = this.userinfo['userId'];
    to.CompanyID = form.CompanyID;

    if (this.resetclave == true) {
      to.ResetPass = 1;
      to.PassWord = CryptoJS.AES.encrypt(this.GeneratePw(to.Name, to.Ruc), KEY.trim()).toString();
    }
    else {
      to.ResetPass = 2;
    }

    this.srv.postCall(qst, to).subscribe(x => {
      this.isUpdating = false;
      if (x.success) {
        this.getList();
        this.closeModal(content);
        Swal.fire(this.translations['updated'], '', 'success');

      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });


  }

  deleteItem(obj: any) {
    var id: any;
    id = {};
    id.CompanyID = obj;
    var qst = this.delete_url;
    this.srv.postCall(qst, id).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if (x.success) {
        this.reload.next(true);
        Swal.fire('Desactivado!', x.msg, 'success');
        this.getList();
      }
      else {
        Swal.fire(x.msg, '', 'error');
      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }


  Activar(obj: any) {
    var id: any;
    id = {};
    id.CompanyID = obj;
    var qst = this.active_url;
    this.srv.postCall(qst, id).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if (x.success) {
        this.reload.next(true);
        Swal.fire('¡Activado!', x.msg, 'success');
        this.getList();
      }
      else {
        Swal.fire(x.msg, '', 'error');
      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }

  toggleEditable(event, id) {
    if (event.target.checked) {
      this.Activar(id);
    } else {
      this.deleteItem(id);

    }


  }

  DeletedItem(obj: any) {

    var id: any;
    id = {};
    id.CompanyID = obj;
    id.DeleteUserID = this.userinfo['userId'];
    var qst = "RegisterCompany/delete";
    this.srv.postCall(qst, id).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if (x.success) {
        // this.reload.next(true);
        Swal.fire({
          title: this.translations.msg_updated,
          text: x.msg,
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#005c3c',
          confirmButtonText: this.translations.accept,
          allowOutsideClick: () => Swal.isLoading(),
        }).then((result) => {
          if (result.value) {
            this.getList();
          }
        })
      }
      else {
        Swal.fire(x.msg, '', 'error');
      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });

  }
}
