import { Component, OnInit } from '@angular/core';
import { BaseCrudComponent } from 'src/app/ic2/base-crud/base-crud.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/main/api.service';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/services/main/global.service';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { LoginService } from 'src/app/services/authentication/login.service';

const API_GET_SEVERITY_TYPE_BY_ID = environment.Global.API_GET_SEVERITY_TYPE_BY_ID; 
const API_GET_EMPLOYEE_INTERNAL_ALL = environment.Global.API_GET_EMPLOYEE_INTERNAL_ALL;
const API_FORMSQUESTIONS_BYTYPEFORM = environment.Global.API_FORMSQUESTIONS_BYTYPEFORM;
const API_GET_HSE_BY_ALL = environment.Global.API_GET_HSE_BY_ALL;
const API_UPDATE_HSE_BY_EMPLOYEE = environment.Global.API_UPDATE_HSE_BY_EMPLOYEE;
const API_GET_HSE_BY_USER = environment.Global.API_GET_HSE_BY_USER;
const API_GET_EMPLOYEE_WITH_USER = environment.Global.API_GET_EMPLOYEE_WITH_USER;
const API_UPDATE_HSE_PERMISSION = environment.Global.API_UPDATE_HSE_PERMISSION;

@Component({
  selector: 'app-hse',
  templateUrl: './hse.component.html',
  styleUrls: ['./hse.component.css']
})
export class HseComponent  extends BaseCrudComponent implements OnInit {
  panelNum = 1;
  usersResponsableList = [];
  auditorsList = [];
  row_plantas = [];
  row_plantas_area = [];
  questionsList = [];
  listGroup = [];
  listSubGroup = [];
  optionsList = [{id: 1, value:'Cumple'}, {id: 2, value:'No cumple'}, {id: 3, value:'No aplica'}]
  map;
  mapG;
  optionSelected = 1;
  behaviourPoints = 0;
  conditionsPoints = 0;
  isEvaluated = false;
  semana = this.globalService.getWeek(new Date());
  userinfo;
  employeeId = 0;
  permissions;
  fecha = new Date();
  employee = null;
  canEvaluate = false;
  isAdmin = false;
  constructor(
    srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private globalService: GlobalService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private auth: LoginService
  ) {
    super(srv, modalService, storage, router);
  }

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
      .subscribe((translations) => {
        this.translations = translations;
        super.ngOnInit();
      });
    this.search_fields = ['HseID','PlantaName'];
    this.storage.get('userinfo').then(x => {
      this.userinfo = x;
    });
    this.auth.getPermissions().then(x => {
      this.permissions = x;
      this.getList();
    });
  }


  public getList() {
    this.loadingList = true;
    let url = "";
    if (this.permissions.includes('SI_ADMIN')) {
      url = API_GET_HSE_BY_ALL;
      this.isAdmin = true;
    } else {
      url =  API_GET_HSE_BY_USER + this.userinfo['logon'] 
      this.isAdmin = false;
    }
    // console.log(url)
    this.srv.getCall(url).subscribe(x => {
      this.loadingList = false;
      this.row_plantas = [];
      if(x.success){
        if(x.val != null){
          var rs = x.val;
          this.rawdata = x.val;
          this.rows = rs;
          this.page = 1;
          console.log(this.rows)
          if( Array.isArray(this.rows) ){
            this.pageTotal = this.rows.length;
          }
          this.filterAll();
        }else {
          this.rows = [];
          return;
        }
      }else {
        this.rows = [];
        return;
      }
    });
  }

  
  public async getQuestions() {
    var qst = API_FORMSQUESTIONS_BYTYPEFORM + "1";
    this.srv.getCall(qst).toPromise().then(x => {
      if (x.success){
        if (x.val != null){
          var rs = x.val;
          this.questionsList = rs;
          // console.log(rs)
        } else {
          this.questionsList = [];
          return;
        }
      }else {
        return;
      }
    });
  }

  public async getGroup_SubGroup() {
    var urlg = API_GET_SEVERITY_TYPE_BY_ID + 'HSE_GROUP';
    var urlsg = API_GET_SEVERITY_TYPE_BY_ID + 'HSE_SUBGROUP';
    await this.srv.getCall(urlg).toPromise().then(x => {
      this.listGroup = [];
      if(x.success){
        if(x.val != null){
          x.val.forEach(element => {
            this.listGroup.push(
              {
                id: element.ID,
                type: element.value
              }
            );
          })
        }else {
          return;
        }
      }else {
        return;
      }
    });
    
    await this.srv.getCall(urlsg).toPromise().then(x => {
      this.listSubGroup = [];
      if(x.success){
        if(x.val != null){
          x.val.forEach(element => {
            this.listSubGroup.push(
              {
                id: element.ID,
                type: element.value
              }
              );
            })
        }else {
          return;
        }
      }else {
        return;
      }
    });
  }

  public async getUserResponsable() {
    this.usersResponsableList = [];
    await this.srv.getCall(API_GET_EMPLOYEE_INTERNAL_ALL).toPromise().then(x => {
      if(x.success){
        if(x.val != null){
          this.usersResponsableList = x.val;
          // console.log(this.usersResponsableList)
        }else {
          Swal.fire(this.translations.empty, this.translations.not_user_registers, 'success');
          this.usersResponsableList = [];
          return;
        }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
      }
    });
  }

  
  public async openUpdateModal(content, row) {
    
    this.modalService.open(content, { backdrop: 'static',  keyboard : false, windowClass: 'myCustomModalClass' });
    let obj = JSON.parse(JSON.stringify(row))
    this.u_obj = obj;
    let fecha = this.fecha.toLocaleDateString().split('/')[2] + '/' + this.fecha.toLocaleDateString().split('/')[1] + '/' + this.fecha.toLocaleDateString().split('/')[0];
    this.u_obj['DeliveryDate']  = this.u_obj['DeliveryDate'] == null ? fecha : this.u_obj['DeliveryDate'];
    await this.getUserResponsable();
    await this.getQuestions();
    await this.getGroup_SubGroup();
    this.behaviourPoints = 0;
    this.conditionsPoints = 0;
    this.prepareQuestions();

    if (this.u_obj['AreaResponsable'] != null) {
      this.usersResponsableList.forEach(responsable => {
        if (responsable.EmployeeId == this.u_obj['AreaResponsable']) {
          this.employee = responsable.EmployeeId
        }
      })
    }
    if (this.u_obj['hseDetails'].length > 0) {
      // console.log(this.mapG)
      this.mapG.forEach((subgrupo, grupo) => {
        subgrupo.forEach((questions, key) => {  
          questions.forEach(question => {
            // console.log(question.preguntaid,' ',question.id)
            this.u_obj['hseDetails'].forEach(pregunta => {
              if (question.preguntaid == pregunta.PreguntaID) {
                question.id = Number(pregunta.Answer);
                question.comment = pregunta.Commentary;
              }
            });
          });
        });
      });
    }    
  }

  public prepareQuestions() {
    this.map = new Map();
    this.mapG = new Map();
    this.questionsList.forEach(question => {
      if (! this.mapG.has(question.GroupName)) {
        this.mapG.set(question.GroupName, new Map<string, []>())
      }
      if (!this.map.has(question.SubGroupName) ) {
        this. map.set(question.SubGroupName, 
          [
            {
              options: [{id: 1, value:'Cumple'}, {id: 2, value:'No cumple'}, {id: 3, value:'No aplica'}],
              question: question.Description,
              group: question.GroupName,
              comment: '',
              id: 0,
              codeGroup: question.GroupId,
              preguntaid: question.PreguntaId,
              codeSubgroup: question.SubgroupId
            }
          ]);
      } else {
        let tmp = this.map.get(question.SubGroupName);
        let line = 
        {
          options: [{id: 1, value:'Cumple'}, {id: 2, value:'No cumple'}, {id: 3, value:'No aplica'}],
          question: question.Description,
          group: question.GroupName,
          comment: '',
          id: 0,
          codeGroup: question.GroupId,
          preguntaid: question.PreguntaId,
          codeSubgroup: question.SubgroupId
        }
        tmp.push(line);
        this.map.set(question.SubGroupName, tmp)
      }
    })
    const newDataBar = [...this.map].map(([name, value]) => ({ label:name, value:value}));
    this.mapG.forEach((subgrupo,key) => {
      newDataBar.forEach(fila => {
        fila.value.forEach(question => {
          if (key == question.group) {
            if (!subgrupo.has(fila.label)) { // si no tiene el subgrupo, lo ingresa
              subgrupo.set(fila.label, [question]);
            } else {
              let tmp = subgrupo.get(fila.label);
              tmp.push(question);
              subgrupo.set(fila.label, tmp);
            } 
          }
        });
      })
    });
    // console.log(this.mapG)
  }
    


  public checkOption(value) {
    // console.log(value)
    if (value.comment != '' && (value.id != 1 && value.id != 2)) {
      value.comment = '';
    } 
    let count = 0;
    this.mapG.forEach((subgrupo, grupo) => {
      subgrupo.forEach((questions, key) => {  
        questions.forEach(question => {
          // console.log(question.preguntaid,' ',question.id)
          if (question.id == 0) {
            count ++;
          }
        });
      });
    });
    if (count > 0) {
      this.canEvaluate = false;
    } else {
      this.canEvaluate = true;
    }
  }

  public calificate() {
    let contadorSumaComportamiento = 0;
    let contadorSumaCondiciones = 0;
    let contadorPreguntasComportamiento = 0;
    let contadorPreguntasCondiciones = 0;
    let contadorPreguntasNoAplicaComportamiento = 0;
    let contadorPreguntasNoAplicaCondiciones = 0;
    this.mapG.forEach((subgrupo, grupo) => {
      subgrupo.forEach((questions, key) => {
        questions.forEach(question => {
          if (question.group == 'Comportamiento'){
            contadorPreguntasComportamiento ++;
          } else {
            contadorPreguntasCondiciones ++;
          }
          if ((question.group == 'Comportamiento') && (question.id == 1)) {
            contadorSumaComportamiento ++;
          } else if ((question.group == 'Comportamiento') && (question.id == 3)) {
            contadorPreguntasNoAplicaComportamiento ++;
          }
          if ((question.group == 'Condiciones') && (question.id == 1)) {
            contadorSumaCondiciones ++;
          } else if ((question.group == 'Condiciones') && (question.id == 3)) {
            contadorPreguntasNoAplicaCondiciones ++;
          }
        });
      });
    });
   
    this.behaviourPoints =
      Number(((contadorSumaComportamiento/ (contadorPreguntasComportamiento - contadorPreguntasNoAplicaComportamiento)) * 100).toFixed(0));
    this.conditionsPoints =
      Number(((contadorSumaCondiciones/ (contadorPreguntasCondiciones - contadorPreguntasNoAplicaCondiciones)) * 100).toFixed(0));
    
  }


  public addPermission(row) {
    // console.log(row)
    Swal.fire({
      title: this.translations.are_you_sure,
      text: this.translations.text_comment,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#005c3c',
      cancelButtonColor: '#C8102E',
      confirmButtonText: this.translations.send,
      input: 'text',
      allowOutsideClick: () => Swal.isLoading(),
      preConfirm: (value) => {
        if(value == '') {
          Swal.showValidationMessage(
            this.translations.should_add_comment
          )
        }
      }
    }).then((result) => {
      if (result.value) {
        this.udpatePermission(row.HseID, result.value);
      }
    })
  }

  public udpatePermission(HseID, value) {
    let obj = {
      HseId: HseID,
      Commentary: value,
      LastModifierUser: this.userinfo['logon'],

    }
    this.srv.postCall(API_UPDATE_HSE_PERMISSION, obj).subscribe(x => {
      if (x.success) {
        Swal.fire(this.translations.sol_send,'','success');
      }
    })
  }
  
  

  public updateItem(modal) {
    let url = API_UPDATE_HSE_BY_EMPLOYEE;
    let statushse = "C";
    if (this.canEvaluate) {
      this.calificate();
    } else {
      statushse = "U";
    }
    this.isUpdating = true;
    let listadoPreguntas = [];
    this.mapG.forEach(subgrupo => {
      subgrupo.forEach(questions => {
        questions.forEach(question => {          
          listadoPreguntas.push({
            PreguntaId: question.preguntaid,
            Answer: question.id,
            Commentary: question.comment,
            HseID: this.u_obj['HseID']
          });
        });
      });
    });
    let new_obj = {
      HseID: this.u_obj['HseID'],
      HseDetail: listadoPreguntas,
      BehaviourValue: this.behaviourPoints,
      TermsValue: this.conditionsPoints,
      DeliveryDate: this.u_obj['DeliveryDate'],
      QuantityEmployee: this.u_obj['QuantityEmployee'],
      StatusHSE: statushse,
      AreaResponsable: this.employee || null,
      LastModifierUser: this.userinfo['logon']
    }
    // console.log(new_obj)

    this.srv.postCall(url, new_obj).subscribe(data => {
      this.isUpdating = false;
      if (data.success) {
        this.u_obj = {};
        this.employeeId = 0;
        Swal.fire(this.translations.msg_update, '', 'success');
        this.getList();
        this.closeModal(modal);
      } else {
        Swal.fire(data.msg, '', 'error');
      }
    },catchError =>{
      this.isUpdating = false;
      Swal.fire(catchError.message, '', 'error');
    });

  }

}
