import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseCrudComponent } from 'src/app/ic2/base-crud/base-crud.component';
import { LoginService } from 'src/app/services/authentication/login.service';
import { ApiService } from 'src/app/services/main/api.service';
import { GlobalService } from 'src/app/services/main/global.service';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { isNumber } from 'util';

const API_GET_SCORE_PER_MONTH_AND_WEEK = environment.Global.API_GET_SCORE_PER_MONTH_AND_WEEK;
const API_GET_WEEKS_PER_YEAR = environment.Global.API_GET_WEEKS_PER_YEAR;
const API_GET_YEARS = environment.Global.API_GET_YEARS;
const API_SET_BONUS = environment.Global.API_SET_BONUS;

@Component({
  selector: 'app-calificaciones',
  templateUrl: './calificaciones.component.html',
  styleUrls: ['./calificaciones.component.css']
})
export class CalificacionesComponent extends BaseCrudComponent implements OnInit {
  filtroGeneral = [{id: 1, name:"Mes"}, {id:2, name: "Semana"}];
  calificaciones = [];
  anioObtenido;
  listaScore = [];
  lista = [];
  anios = [];
  
  filtroEscogido = null;
  anioEscogido = null;
  userinfo:any;
  
  
  constructor( srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    public auth: LoginService,
    private globalService: GlobalService,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    super(srv, modalService, storage, router);
  }

  ngOnInit() {
    this.translate.getTranslation(this.translate.currentLang)
      .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
    });
    this.search_fields = ['Employee','PlantaName','SeccionName'];
    this.storage.get('userinfo').then(x => {
      this.userinfo = x;
      // console.log(this.userinfo)
    });
    this.getYears();
  }

  public getYears() {
    this.srv.getCall(API_GET_YEARS).subscribe(x => {
      if(x.success) {
        this.anios = x.val;
        // console.log(this.anios)
      } else {
        Swal.fire(x.msg,'','error')
      }
    })
  }

  public getWeeksPerYear() {
    if (this.anioEscogido == null) {
      Swal.fire(this.translations.msg_all_required, '','error')
    } else {
      this.srv.getCall(API_GET_WEEKS_PER_YEAR + this.anioEscogido).subscribe(x => {
        if(x.success) {
          this.anioObtenido = x.val;
          this.anioObtenido['weekGroupList'].forEach(semana => {
            semana.Procesos = [
              "% Comportamiento",
              "% Condiciones",
              "Plan de acción",
              "Auditorías",
              "Charlas de Seguridad",
              "Bonus (1-10)",
              "Total " + semana.month
            ]
          })
          // console.log(this.anioObtenido)
        } else {
          Swal.fire(x.msg,'','error')
        }
      })
    } 
  }


  public setFilterToData() {
    if (this.filtroEscogido != null) {
      this.getDataPerMonthAndWeek();
    }
  }

  public getData() {
    if (this.anioEscogido != null) {
      this.getWeeksPerYear();
    } else {
      this.rawdata = [];
      this.rows = [];
      this.filtroEscogido = null;
      Swal.fire(this.translations.msg_all_required, '','error')
    }
  }


  public getDataPerMonthAndWeek () {
    this.loadingList = true;
    if (this.anioEscogido != null) {
      this.srv.getCall(API_GET_SCORE_PER_MONTH_AND_WEEK + this.anioEscogido).subscribe(x => {
        this.loadingList = false;
        if(x.success) {
          this.rawdata = x.val;
          this.rows = x.val;
          // console.log(this.rows)
          this.rows.forEach(score =>{
            score.MonthScores.forEach(element => {
              element.puntos = null;
            });
          })
          this.filterAll();
        } else {
          Swal.fire(x.msg,'','error')
        }
      })
    } else {
      this.loadingList = false;
      Swal.fire(this.translations.msg_all_required, '','error')
    }
    
  }

  public addBonus(month) {
    // console.log(month)
    if (month.puntos != null && month.puntos > 0 && month.ScoreDetailID != 0) {
      let to = {
        puntos : month.puntos,
        ScoreDetailID : month.ScoreDetailID
      }
      if (month.Total == "100") {
        Swal.fire(this.translations.exced_value, '','error');
      } else {
        Swal.fire({
          title: this.translations.are_you_sure,
          text: this.translations.commentary_message_bonus,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#003d6a',
          cancelButtonColor: '#d33',
          confirmButtonText: this.translations.yes,
          cancelButtonText: this.translations.cancel
        }).then((result) => {
          if (result.value) {
            this.loadingList = true; 
            this.srv.postCall(API_SET_BONUS, to).subscribe(x => {
              if(x.success) {
                Swal.fire(this.translations.msg_update, this.translations.add_points, 'success' );
                this.loadingList = false; 
                this.getDataPerMonthAndWeek();
              } else {
                Swal.fire(x.msg,'','error')
              }
            });
          }
        })
      }
    } else {
      month.puntos = "";
      this.loadingList = false; 
      Swal.fire(this.translations.not_exist_data_month, '','error');
    }
  }

  public onlyNumberKey(event: any): boolean {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }


}
