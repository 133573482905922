export class ValidationPathQr {

    public urlProcesed: string;
    public redirect: boolean;

    constructor(private location: Location) {
        this.redirect = this.validatePath(location);
    }

    public validatePath(location: Location): boolean {
        const urlTo = '/qrcodeValidationComponent';
        const url = location.href;
        const path = location.pathname;
        const params = url.split('?')[1];
        let paramsLower = '';

        if (params) {
            paramsLower = '?'+params.toLowerCase();
        }

        this.urlProcesed = urlTo + paramsLower;

        if (path.toLowerCase().includes(urlTo.toLowerCase()) && path !== urlTo){
            return true;
        }
        return false;
    }
}