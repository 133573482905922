import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { LoginService } from '../../../services/authentication/login.service';

@Component({
  selector: 'app-crud',
  templateUrl: './crud.component.html',
  styleUrls: ['./crud.component.css']
})
export class CrudComponent implements OnInit {

    translations:any;
    available_languages:any;
    currlang:any;
    version:any;
    items:any;
    permissions = [];

  	constructor(
  		public _router: Router,
      public translate: TranslateService,
      public auth: LoginService
  	) { }

  	ngOnInit() {
        this.items = [
          {title: 'menu_title_man_tipotrabajo', url: '/paramjobtype', icon: 'fa fa-hand-rock', perm: 'SI_CONFTRABAJO'},
          {title: 'menu_title_man_planta', url: '/paramplanta', icon: 'far fa-building', perm: 'SI_CONFPLANTAS'},
          {title: 'menu_title_man_generales', url: '/paramgenerales', icon: 'fa fa-wrench', perm: 'SI_CONSOLPERMISO'},
          {title: 'menu_title_man_area_godfather', url: '/maintareagf', icon: 'icon icon-docs', perm: 'SI_MAINTAREAGF'},
          {title: 'menu_title_man_docu', url: '/paramdocu', icon: 'icon icon-docs', perm: 'SI_CONFDOCUMENTOS'},
          {title: 'menu_title_man_foult', url: '/paramtypefouls', icon: 'mdi mdi-adjust', perm: 'SI_CONFFALTAS'},
          {title: 'menu_title_man_comunicados', url: '/paramcomunicados', icon: 'icon icon-refresh', perm: 'SI_COMUNICADOS'},
          {title: 'menu_title_man_tipo_preguntas', url: '/paramtipopregunta', icon: 'mdi mdi-adjust', perm: 'SI_QUESTIONTYPE'},
          {title: 'menu_title_man_evaluacion', url: '/paramevaluacion', icon: 'mdi mdi-adjust', perm: 'SI_CONFEVALUACION'},
          {title: 'menu_title_man_pregunta', url: '/questions', icon: 'mdi mdi-adjust', perm: 'SI_QUESTIONS'},
          {title: 'menu_opportunities', url: '/opportunities', icon: 'mdi mdi-adjust', perm: 'SI_CONFOPORTUNIDADESEVALUACION'},
          {title: 'menu_form_questions', url: '/formsQuestions', icon: 'mdi mdi-adjust', perm: 'SI_FORMSQUESTIONS'},
          {title: 'maint_user', url: '/maintuser', icon: 'mdi mdi-adjust', perm: 'SI_MAINTUSER'}
        ];

        this.auth.getPermissions().then(x => {
            this.permissions = x;
        });
  	}

    hasPermission(str:string){
        if( this.permissions.indexOf(str) > -1 ){
            return true;
        }
        else{
            return false;
        }
    }

}
