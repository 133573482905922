import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { isNumeric } from 'rxjs/util/isNumeric';
import { Newsubcomjob } from '../../../interfaces/newsubcomjob';
import {saveAs as importedSaveAs  } from "file-saver";

@Component({
  selector: 'app-proapprovedocumentsemployees',
  templateUrl: './proapprovedocumentsemployees.component.html',
  styleUrls: ['./proapprovedocumentsemployees.component.css']
})
export class ProapprovedocumentsemployeesComponent extends BaseCrudComponent  {
  id:any;
  userinfo:any;
  name_company:any;
  flag:any;
  username: any;

  constructor(srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService) {
      super(srv, modalService, storage, router);
      this.id = route.snapshot.params.id;
      this.flag = 0;

      this.read_url="EmployeeDocument/GetDocumentstoApprove";
      this.search_fields = ['EmployeeDocumentId','identification','EmployeeName','NameDoc','plazo'];

      this.c_obj['EmployeeDocument'] = [];

      this.reload.subscribe(val => {
        if( val ){
            super.clearData();
            this.getList();
        }
      });
    }


  async ngOnInit() {
    await this.translate.getTranslation(this.translate.currentLang)
    .toPromise().then((translations) => {
          this.translations = translations;
          super.ngOnInit();
          this.getList();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';
    this.route.queryParams.subscribe(params => {
      this.name_company = params.state
    });
    await this.storage.get('username').then(x => {
      this.username = x;
  });

    await this.storage.get('userinfo').then(x => {
        this.userinfo = x;
    });
    // debugger
console.log(this.userinfo.userId);
  }

  public getList()
  {
    var qst = this.read_url+"/"+this.id;
    this.loadingList = true;
    Swal.fire(
      {
        title: this.translations['loading'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
      this.srv.getCall(qst).subscribe(x=>{
      this.loadingList = false;
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          this.rows.forEach(element => {
            element.valor = null;
          });
          // console.log(this.rows)
          this.page = 1;
          if( Array.isArray(this.rows) ){
             this.pageTotal = this.rows.length;
          }
          this.filterAll();
          Swal.close()
          //console.log("this.name_company:",this.name_company);
          //console.log("rs:",rs);
          // if(x.val != null){
          //   if (x.val.length == 0 ) {
          //     Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          //   } else {
          //     this.rawdata = x.val;
          //     var rs = x.val;
          //     this.rows = rs;
          //     this.name_company=this.rows[0].name_company;
          //     this.page = 1;
          //     if( Array.isArray(this.rows) ){
          //        this.pageTotal = this.rows.length;
          //     }
          //     this.filterAll();
          //     Swal.close()
          //   }
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
          }
     });
  }

  downloadDocFile(data) {
    const DocFileName = data.EmployeeId;
    const DocFileId = data.EmployeeDocumentId;
    const name=data.FileName;
    //var DocFile = DocFileName.slice(0, -5);
    this.srv.downloadFileEmp(DocFileName, DocFileId).subscribe((data) => {
       importedSaveAs(data,name )//DocFileName)
   });
  }

  verificateprocesardoc(row:any){
    var qst = "EmployeeDocument/ProApproveToDoc"
    var mensaje:any;

    //debugger;
    var to:any;
    let trans = this.translations;
    to={};
    to.EmployeeDocumentId=row.EmployeeDocumentId;
    to.EmployeeId=row.EmployeeId;
      // to.VerificateUser=this.userinfo.logon;

      console.log(this.userinfo.userId)
    to.VerificateUser=this.userinfo.userId;


    if (this.flag == undefined) {
      return Swal.fire('', this.translations.aprroveorrevoce , 'error');
    }
    //if (row.VerificateComment==" " || row.VerificateComment==null || row.VerificateComment=="")
    if(this.flag==0)
    {
      to.Status="V";
      to.StatusId=39;
      to.VerificateComment="";
      mensaje=trans.show_message_approve;
    }
    else{
      if (row.VerificateComment==" " || row.VerificateComment==null || row.VerificateComment=="")
      {
        Swal.fire('¡Warning!', trans.docapprove_error_missingcomment, 'warning');
        return;
      }
      to.Status="R";
      to.StatusId=40;
      to.VerificateComment=row.VerificateComment;
      mensaje=trans.show_message_reject;
    }


    Swal.fire({
        title: mensaje,//trans.sampapp_refmethodswarn,
        text: '',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: trans.cancel,
        confirmButtonText: trans.ok,
        preConfirm: () => {
            this.procesardoc(qst,to);

        }
    }).then((result) => {
    });

  }

  procesardoc(qst:any,to:any){
    this.loadingList = true;
debugger
    this.srv.postCall(qst, to).subscribe(x => {
      console.log("procesardoc:",x)
      this.loadingList = false;
      
      if(x.success)
      {
        Swal.fire(this.translations.msg_update, x.msg, 'success');
        this.getList();
      }else {
        Swal.fire( x.msg,'', 'error');
      }
     });

  }



  changeSelect(row) {
    // console.log(row)
    if(row.valor == 0) {
      this.flag = 0;
    } else {
      this.flag = 1;
    }
  }

}
