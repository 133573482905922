import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent extends BaseCrudComponent {

  	id:any;
    loadingRequired = false;
    listAgr:any;
    translations:any;

    degrees:any;
    positions:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        private _lightbox: Lightbox
      ) {
        super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        this.read_url = 'User/All';
        this.create_url = '';
        this.update_url = 'User/Update';
        this.delete_url = '';
        this.search_fields = ['name', 'usrEmail', 'UsrDegreeName', 'UsrPositionName'];

        this.c_obj['SiteAddress'] = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();

              var batch = [];
              batch.push(this.getDegrees());
              batch.push(this.getPositions());

              this.loadingList = true;

              concat(...batch).subscribe({
                complete: () => {
                    this.loadingList = false;
                    this.getList();
                }
              });
        });
    }

    public getList(){
        var qst = this.read_url;
        this.loadingList = true;
        Swal.fire(
          {
            title: this.translations['loading'],
            html: '',
            icon: 'info',
          }
        );
        Swal.showLoading();
        this.srv.getCall(qst).subscribe(x => {
            this.loadingList = false;
            
            if(x.success){
                x.val.forEach(y => {
                    y['name'] = y['usrFirstName'] + ' ' + y['usrLastName'];
                });

                this.rawdata = x.val;
                this.rows = x.val;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                }
                Swal.close()
            }
          });
    }

    public getVal(obj:any, key:any){
        let resp = '';
        if( (obj !== undefined) && (obj !== null) ){
            resp = obj[key];
        }
        return resp;
    }

    public getFromArray(arr:any, key:any, val:any, to:any, def:any){
        let resp = arr.filter(f => {
            return f[key] == val;
        });

        return (resp.length > 0) ? resp[0][to] : def;
    }

    public getDegrees(){
        let url = 'SystemParameter/GetByName/USR_DEG';
        return this.srv.getCall(url).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;
                        
                        if(mydata.success == true ){
                          this.degrees = mydata.val[0];
                          return of(true);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    public getPositions(){
        let url = 'SystemParameter/GetByName/USER_POS';
        return this.srv.getCall(url).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;
                        
                        if(mydata.success == true ){
                          this.positions = mydata.val[0];
                          return of(true);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    public updateItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        form['usrDegree'] = form['UsrDegreeId'];
        form['usrPosition'] = form['UsrPositionId'];
        form['usrBelongHA '] = form['usrBelongHA'];

        var to:any;
        to = form;

        var qst = this.update_url;
        this.isUpdating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isUpdating = false;
            if(x.success){
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations['updated'], '', 'success');
            }//end success
            else{
                Swal.fire(x.msg, '', 'error');
              //this.closeModal(content);
            }
        });
    }

    handleInputChange(e, obj:any) {
        var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        var pattern = /image-*/;
        var reader = new FileReader();
        /*if (!file.type.match(pattern)) {
          alert('invalid format');
          return;
        }*/

        //console.log(file);

        reader.onload = (_event) => { 
          /*obj.img[0].src = reader.result;
          obj.img[0].fname = file.name;*/
          
          obj.usrSign = reader.result;
        }

        reader.readAsDataURL(file);
    }

    openPreview(obj:any): void {
        var album:any;
        album = [
            //{src: obj.src}
            {src: obj}
        ];
        // open lightbox
        this._lightbox.open(album, 0);
    }

    clearImgPrev(obj:any){
        obj.usrSign = '';
        //console.log(obj);
    }


}
