import { Component } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import {saveAs as importedSaveAs  } from "file-saver";
import {Global} from "../../../services/global";

const API_UPDATESTATUSEMPLOYEE = Global.API_STATUSEMPLOYEE_INSURANCE;

@Component({
  selector: 'app-proapprovedocumentscompany',
  templateUrl: './proapprovedocumentscompany.component.html',
  styleUrls: ['./proapprovedocumentscompany.component.css']
})
export class ProapprovedocumentscompanyComponent extends BaseCrudComponent  {
  id:any;
  userinfo:any;
  name_company:any;
  flag:any;

  public modalPage: number;
  public modalPageTotal: number;
  public modalPageSize: number;
  public modalRows: any[];

  public rowEmployee: any;

  constructor(srv: ApiService,
    modalService: NgbModal,
    storage: Storage,
    router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService) {

      super(srv, modalService, storage, router);
      this.id = route.snapshot.params.id;
      this.flag = 0;

      this.active_url = API_UPDATESTATUSEMPLOYEE;

      this.read_url="CompanyDocument/GetDocumentstoApprove";
      this.search_fields = ['CompDocumentId','NameDoc','plazo'];

      this.c_obj['CompanyDocument'] = [];

      this.reload.subscribe(val => {
        if( val ){
            super.clearData();
            this.getList();
        }
      });

      this.modalPage = 1;
      this.modalPageTotal = 0;
      this.modalPageSize = 10;
      this.modalRows = [];
    }

  async ngOnInit() {
    await this.translate.getTranslation(this.translate.currentLang)
    .toPromise().then((translations) => {
          this.translations = translations;
          super.ngOnInit();
          this.getList();
    });
    this.userinfo = [];
    this.userinfo['name'] = '';
    this.userinfo['emailAddress'] = '';
    this.route.queryParams.subscribe(params => {
      this.name_company = params.state
      //console.log(params)
    });
    await this.storage.get('userinfo').then(x => {
        this.userinfo = x;
    });
  }

  public getList()
  {
    var qst = this.read_url+"/"+this.id;
    this.loadingList = true;

    Swal.fire(
      {
        title: this.translations['loading'],
        html: '',
        icon: 'info',
      }
    );
    Swal.showLoading();
    this.srv.getCall(qst).subscribe(x=>{
      this.loadingList = false;
      //console.log(x)
      if(x.success){
        if(x.val != null){
          this.rawdata = x.val;
          var rs = x.val;
          this.rows = rs;
          this.rows.forEach(element => {
            element.valor = null;
          });
          // //console.log(this.rows)
          this.page = 1;
          if( Array.isArray(this.rows) ){
              this.pageTotal = this.rows.length;
          }
          this.filterAll();
          Swal.close()
          // if (x.val.length == 0) {
          //   Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          // } else {
          //   this.rawdata = x.val;
          //   var rs = x.val;
          //   this.rows = rs;
          //   this.name_company=this.rows[0].name_company;
          //   this.page = 1;
          //   if( Array.isArray(this.rows) ){
          //       this.pageTotal = this.rows.length;
          //   }
          //   this.filterAll();
          //   Swal.close()
          // }
        }else {
          Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
          this.rows = [];
          return;
            }
      }else {
        Swal.fire(x.msg, '', 'error');
        return;
          }
     });



  }

  downloadDocFile(data) {
    const DocFileName = data.CompDocumentId;
    const name=data.ImageFileName;
    //var DocFile = DocFileName.slice(0, -5);
    this.srv.downloadFile(DocFileName).subscribe((data) => {
       importedSaveAs(data,name )//DocFileName)
   });
  }

  verificateprocesardoc(row:any){
    var qst = "CompanyDocument/ProApproveToDoc"
    var mensaje:any;

    // //console.log("flag:",this.flag);
    // //console.log("row.sel:",row.sel);


    //debugger;
    var to:any;
    let trans = this.translations;
    to={};
    to.CompDocumentId=row.CompDocumentId;
    to.CompanyID=row.CompanyID;
    //to.VerificateUser=this.userinfo.logon;
    to.VerificateUser=this.userinfo.userId;
    if (this.flag == undefined) {
      return Swal.fire('', this.translations.aprroveorrevoce , 'error');
    }
    //debugger;
    //if (row.VerificateComment==" " || row.VerificateComment==null || row.VerificateComment=="")
    if(this.flag==0)
    {
      to.Status="V";
      to.StatusId=39;
      to.VerificateComment="";
      mensaje=trans.show_message_approve;
    }
    else{
      if (row.VerificateComment==" " || row.VerificateComment==null || row.VerificateComment=="")
      {
        Swal.fire('¡Warning!', trans.docapprove_error_missingcomment, 'success');
        return;
      }
      to.Status="R";
      to.StatusId=40;
      to.VerificateComment=row.VerificateComment;
      mensaje=trans.show_message_reject;
    }


    Swal.fire({
        title: mensaje,//trans.sampapp_refmethodswarn,
        text: '',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: trans.cancel,
        confirmButtonText: trans.ok,
        preConfirm: () => {
            this.procesardoc(qst,to);

        }
    }).then((result) => {
    });

  }

  procesardoc(qst:any,to:any){
    this.loadingList = true;

    this.srv.postCall(qst, to).subscribe(x => {
      this.loadingList = false;
      if(x.success)
      {
        Swal.fire(this.translations.msg_update, x.msg, 'success');
        this.getList();
      }else {
        Swal.fire( x.msg,'', 'error');
      }
     });

  }

  changeSelect(row) {
    if(row.valor == 0) {
      this.flag = 0;
    } else {
      this.flag = 1;
    }
  }

  //PARA MODAL DE DETALLE DE EMPLEADOS POR DOCUMENTO DE IESS
  public openUpdateModal(content: any, obj1: any) {
    //console.log("objeto::",obj1)
    // console.log("u_objSub::",this.u_objSub)
    let obj = JSON.parse(JSON.stringify(obj1));
    this.u_objSub = obj;
    // console.log("u_objSub::",this.u_objSub)
    this.getEmployeeList();

    this.modalService.open(content, { backdrop: 'static', keyboard: false, size: 'lg', centered: true });
  }

  public setupModalPagination(data: any[]) {
      this.rowEmployee = data;
      //console.log(this.rowEmployee.length)
      //console.log(this.modalPageSize)
      this.modalPageTotal = Math.ceil(this.rowEmployee.length / this.modalPageSize);

      //console.log(this.modalPageTotal)
      this.updateModalPage();
  }

  public updateModalPage() {
      const start = (this.modalPage - 1) * this.modalPageSize;
      //console.log(start)
      const end = start + this.modalPageSize;
      //console.log(end)
      this.modalRows = this.rowEmployee.slice(start, end); 
      //console.log(this.modalRows)
  }

  public changeModalPage(page: number) {
      if (page > 0 && page <= this.modalPageTotal) {
          this.modalPage = page;
          this.updateModalPage();
      }
  }

  public getEmployeeList() {
    var qst = Global.API_ALLEMPLOYEECOMPANYONLYID + "/" + this.id;
    //console.log("qst:",qst)
    this.loadingList = true;

    Swal.fire({
        title: this.translations['loading'],
        html: '',
        icon: 'info',
    });
    Swal.showLoading();
    this.srv.getCall(qst).subscribe(x => {
        this.loadingList = false;

        if (x.success) {
            if (x.val != null) {
                var rs = x.val;
                this.rowEmployee = rs;
                this.modalPage = 1;
                //console.log("objEmployee:",this.rowEmployee)
                if (Array.isArray(this.rowEmployee)) {
                    this.setupModalPagination(this.rowEmployee);
                }
                Swal.close();
            } else {
                Swal.fire(this.translations.empty, this.translations.not_registers, 'success');
                this.rowEmployee = [];
            }
        } else {
            Swal.fire(x.msg, '', 'error');
        }
    });
  }

  toggleEditable(event, id) {
    if (event.target.checked) {
      this.Activar(id, 1);
    } else {
      this.Activar(id, 2);
    }
  }

  Activar(obj: any, valor: any) {
    var to: any;
    to = {};
    to.EmployeeId = obj;
    to.Status = valor;
    var qst = this.active_url;
    this.srv.postCall(qst, to).subscribe(x => {
      this.isCreating = false;
      Swal.close();
      if (x.success) {
        // this.reload.next(true);
        if (valor == "1") {
          Swal.fire('¡Activado!', x.msg, 'success');
        }
        else {
          Swal.fire('¡Desactivado!', x.msg, 'success');
        }
        // this.getEmployeeList();
      }
      else {
        Swal.fire(x.msg, '', 'error');
      }
    }, err => {
      Swal.close();
      Swal.fire('Error');
    });
  }



}
